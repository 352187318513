import React, { useContext, useEffect, useState } from "react";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import BasicFormButtons from "../../../../../components/Deprecated/BasicFormButtons";
import {
    createGeneralTask,
    getTask,
    updateTask,
} from "../../../../../services/Deprecated/calendar/taskServices";
import { FormInput } from "../../../../../components/Deprecated/FormComponents";
import { nameValidator } from "../../../../../resources/Deprecated/formValidators";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";

const GeneralTaskActionModal = (props) => {
    const { close, visible, isUpdate, isCreate, id } = props;
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);
    const title = isUpdate ? "Edit General Task" : "Create General Task";

    useEffect(() => {
        if (!visible) return;

        if (isCreate) {
            setInitialFormData({});
            return;
        }

        // Fetch the general task by id and set the initial form data
        getTask(id).then((res) => {
            setInitialFormData(res.data);
        });
    }, [visible, id]);

    const onSubmit = (data) => {
        setLoaderVisible(true);

        if (isUpdate) {
            updateTask(data).then(() => {
                triggerReload();
                onClose();
                setLoaderVisible(false);
            });
        } else {
            createGeneralTask(data).then(() => {
                triggerReload();
                onClose();
                setLoaderVisible(false);
            });
        }
    };

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    return (
        <>
            {visible && initialFormData && (
                <ResponsiveDialog
                    title={title}
                    onClose={onClose}
                    size={"small"}
                >
                    <Form
                        initialValues={initialFormData}
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <Field
                                    name={"name"}
                                    component={FormInput}
                                    label={"Name:"}
                                    validator={nameValidator}
                                />
                                <br />
                                <Field
                                    name={"isAutoAssigned"}
                                    component={Checkbox}
                                    defaultChecked={false}
                                    label={"Is Auto Assigned"}
                                    className={"JustifyLeftAlignLeft"}
                                />
                                <BasicFormButtons
                                    loaderVisible={loaderVisible}
                                    isCreate={isCreate}
                                    onCancel={() => onClose()}
                                    allowSubmit={!formRenderProps.allowSubmit}
                                />
                            </FormElement>
                        )}
                    />
                </ResponsiveDialog>
            )}
        </>
    );
};

export default GeneralTaskActionModal;
