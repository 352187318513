// Import axios library
import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

// Function to add an identification type
export const addIdentificationType = async (idTypeData) => {
    try {
        // Send a POST request to the server with the identification data
        const response = await axios.post(
            "/HumanResources/IdentificationType/Create",
            idTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getIdentificationType = async (id) => {
    try {
        const response = await axios.get(
            `/HumanResources/IdentificationType/Get?id=${id}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

// Function to get all identification types
export const getAllIdentificationTypes = async () => {
    try {
        const response = await axios.get(
            `/HumanResources/IdentificationType/GetAll`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

// Function to update an identification type
export const updateIdentificationType = async (idTypeData) => {
    try {
        // Send a PUT request to the server with the identification data
        const response = await axios.put(
            "/HumanResources/IdentificationType/Update",
            idTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

// Function to delete an identification type
export const deleteIdentificationType = async (identificationTypeId) => {
    try {
        // Send a DELETE request to the server with the tool ID
        const response = await axios.delete(
            `/HumanResources/IdentificationType/Delete?id=${identificationTypeId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};
