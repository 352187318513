import React, { createContext, useContext, useState } from "react";
import { VendorDocumentContext } from "./VendorDocumentProvider";

export const VendorBillPaymentContext = createContext(null);

const VendorBillPaymentProvider = ({ children }) => {
    const { selectedDocument, setSelectedDocumentId } = useContext(
        VendorDocumentContext
    );
    const [modalOperation, setModalOperation] = useState(undefined);
    const [selectedPayment, setSelectedPayment] = useState(undefined);

    const closeModal = () => {
        setModalOperation(undefined);
        setSelectedPayment(undefined);
        setSelectedDocumentId(undefined);
    };

    const value = {
        modalOperation,
        setModalOperation,
        selectedPayment,
        setSelectedPayment,
        closeModal,
        selectedDocument,
    };

    return (
        <VendorBillPaymentContext.Provider value={value}>
            {children}
        </VendorBillPaymentContext.Provider>
    );
};

export default VendorBillPaymentProvider;
