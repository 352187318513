import React, { createContext, useState } from "react";

export const ReloadDataContext = createContext(null);

const ReloadDataProvider = ({ children }) => {
    const [reloadData, setReloadDataInternal] = useState({});

    const setReloadData = (value) => {
        console.warn("DO NOT USE setReloadData() FROM RELOAD PROVIDER", value);
        console.log("please update usage to triggerReload() with no data");
        triggerReload();
    };

    const triggerReload = (value) => {
        if (value) {
            // if triggerReload is called with a value, it is likely an not fully refactored call
            // to setReloadData() which can be changed. triggerReload() does not take a prop to
            // ensure that the reload is called via a random number, as opposed to accidentally similar
            // values passed by the caller
            console.debug(
                "triggerReload() called with value where not needed / expected",
                value
            );
        }
        setReloadDataInternal(Math.random());
    };

    return (
        <ReloadDataContext.Provider
            value={{ reloadData, setReloadData, triggerReload }}
        >
            {children}
        </ReloadDataContext.Provider>
    );
};

export default ReloadDataProvider;
