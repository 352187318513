import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import VendorDetails from "./VendorDetails";
import ContactDetails from "./ContactDetails";
import BillingDetails from "./BillingDetail";

const VendorTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
        >
            <TabStripTab title="Vendor">
                <VendorDetails />
            </TabStripTab>
            <TabStripTab title="Contact">
                <ContactDetails />
            </TabStripTab>
            <TabStripTab title="Billing">
                <BillingDetails />
            </TabStripTab>
        </TabStrip>
    );
};

export default VendorTabs;
