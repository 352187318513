import * as React from "react";
import { TreeListCell } from "@progress/kendo-react-treelist";
import { AccountType } from "../../../../resources/Enums/AccountType";

const CoATLAccountTypeColumnCell = (props) => {
    const { dataItem } = props;

    dataItem.accountTypeDisplay = AccountType.ParseToString(dataItem.type);

    return <TreeListCell {...props} />;
};

export default CoATLAccountTypeColumnCell;
