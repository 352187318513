import {useEffect, useState} from "react";
import {getCurrentAvailableTasks} from "../../../../services/Deprecated/humanResources/employeeServices";

/**
 * Fetches the available tasks for the given employee.
 * @param {Object} props The component props.
 * @param {string} props.employeeId The employee ID.
 * @param {string} props.jobId The job ID to filter further.
 * @param {string} props.calendarEventId The calendar event ID to filter further.
 * @returns {Object} The available tasks
 */
export function useAvailableTasks(props) {
    const [availableTasks, setAvailableTasks] = useState([]);
    const [isLoadingTasks, setIsLoadingTasks] = useState(true);
    const { employeeId, jobId, calendarEventId } = props;

    if (!employeeId) {
        throw new ReferenceError("EmployeeId is undefined.");
    }

    useEffect(() => {
        setIsLoadingTasks(true);
        getCurrentAvailableTasks(
            buildRequest(employeeId, jobId, calendarEventId)
        ).then((res) => {
            setAvailableTasks(res.data);
            setIsLoadingTasks(false);
        });
    }, [employeeId]);

    return { availableTasks, isLoadingTasks };
}

const buildRequest = (employeeId, jobId, calendarEventId) => {
    let request = {
        employeeId: employeeId,
    };

    if (jobId) {
        request.jobId = jobId;
    }

    if (calendarEventId) {
        request.calendarEventId = calendarEventId;
    }

    return request;
};
