/**
 * @function useLocalStorage
 * @description A hook to manage local storage
 */
export const useLocalStorage = () => {
    const add = (key, value) => {
        if (!key) throw new Error("Key is required");
        if (!value) throw new Error("Value is required");

        const items = JSON.parse(localStorage.getItem("items")) || [];
        const index = items.findIndex((i) => i.key === key);
        let newItems;
        if (index === -1) {
            newItems = [...items, { key, value }];
        } else {
            const spreadItems = [...items];
            spreadItems[index] = { key, value };
            newItems = spreadItems;
        }

        localStorage.setItem("items", JSON.stringify(newItems));
    };

    const remove = (key) => {
        if (!key) throw new Error("Key is required");

        const items = JSON.parse(localStorage.getItem("items"));
        const newItems = items.filter((item) => item.key !== key);
        localStorage.setItem("items", JSON.stringify(newItems));
    };

    const get = (key) => {
        if (!key) throw new Error("Key is required");

        const items = JSON.parse(localStorage.getItem("items"));

        if (!items) return null;

        return items.find((item) => item.key === key);
    };

    const clear = () => {
        localStorage.removeItem("items");
    };

    return {
        add,
        remove,
        get,
        clear,
    };
};
