import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

export const addTaskCategoryToEventType = async (
    eventTypeId,
    taskCategoryId
) => {
    const res = await axios.put("/Customer/EventType/TaskCategory/Add", {
        eventTypeId,
        taskCategoryId,
    });
    return formatServiceResponse(res);
};

export const removeTaskCategoryFromEventType = async (
    eventTypeId,
    taskCategoryId
) => {
    const res = await axios.put("/Customer/EventType/TaskCategory/Remove", {
        eventTypeId,
        taskCategoryId,
    });
    return formatServiceResponse(res);
};

export const addTaskToTaskCategory = async (taskCategoryId, taskId) => {
    const res = await axios.put("/Customer/TaskCategory/Task/Add", {
        taskCategoryId,
        taskId,
    });
    return formatServiceResponse(res);
};

export const removeTaskFromTaskCategory = async (taskCategoryId, taskId) => {
    const res = await axios.put("/Customer/TaskCategory/Task/Remove", {
        taskCategoryId,
        taskId,
    });
    return formatServiceResponse(res);
};

/**
 * @function createTaskCategory
 * @description Function to create an event task category
 * @param {Object} eventTaskCategoryData
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const createTaskCategory = async (eventTaskCategoryData) => {
    try {
        const response = await axios.post(
            `/Customer/TaskCategory/Create`,
            eventTaskCategoryData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateTaskCategory
 * @description Function to update an event task category
 * @param {Object} eventTaskCategoryData
 * @returns {Promise<ServiceResponse>}
 */
export const updateTaskCategory = async (eventTaskCategoryData) => {
    try {
        const response = await axios.put(
            `/Customer/TaskCategory/Update`,
            eventTaskCategoryData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteTaskCategory
 * @description Function to delete an event task category
 * @param {String} taskCategoryId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteTaskCategory = async (taskCategoryId) => {
    try {
        const response = await axios.delete(
            `/Customer/TaskCategory/Delete?id=${taskCategoryId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllTaskCategories
 * @description Function to get all event task categories
 * @returns {Object}
 */
export const getAllTaskCategories = async () => {
    try {
        const response = await axios.get("/Customer/TaskCategory/GetAll");
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllEventTaskCategoriesByEventTypeId
 * @description Function to get all event task categories by eventTypeId
 * @returns {Object}
 */
export const getAllEventTaskCategoriesByEventTypeId = async (eventTypeId) => {
    const kendoRequest = {
        filter: {
            logic: "and",
            filters: [
                {
                    field: "eventTypeId",
                    operator: "eq",
                    value: eventTypeId,
                },
            ],
        },
    };
    const queryParams = toDataSourceRequestString(kendoRequest);
    try {
        const response = await axios.get(
            `/Customer/TaskCategory/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getEventTaskCategory
 * @description Function to get an event task category by id
 * @param eventTaskCategoryId string
 * @returns {Object}
 */
export const getTaskCategory = async (eventTaskCategoryId) => {
    try {
        const response = await axios.get(
            `/Customer/TaskCategory/Get?id=${eventTaskCategoryId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
