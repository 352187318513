import React from "react";
import { Route } from "react-router-dom";
import {
    checkRequiredPermissions,
    getUserPermissions,
} from "../resources/Deprecated/permissionsHelper";
import NoPermissionComponent from "../components/Deprecated/NoPermissionComponent";

const PermissionRoute = ({
    component: Component,
    requiredPermissions,
    ...rest
}) => {
    const userPermissions = getUserPermissions();

    const hasPermissions = checkRequiredPermissions(
        userPermissions,
        requiredPermissions
    );

    return (
        <Route
            {...rest}
            render={(props) =>
                hasPermissions ? (
                    <Component {...props} />
                ) : (
                    <NoPermissionComponent />
                )
            }
        />
    );
};

export default PermissionRoute;
