import dayjs from "dayjs";

export const reduceString = (string, length) => {
    if (string !== null && string !== undefined) {
        if (string.length > length) {
            return `${string.substring(0, length)}...`;
        }
    }

    return string;
};

/**
 * Capitalizes the first char of an given string
 * @param string
 * @return {string}
 */
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const fixedDecimal = (number, decimalPlaces) => {
    if (number !== null && number !== undefined) {
        return number.toFixed(decimalPlaces);
    }

    return number?.toString() ?? "0.00";
};

/**
 * @function customDateFormatter
 * @description Formats a date as MM/DD/YYYY.
 * @param {String} date - The date to convert.
 * @returns {String}
 */
export const customDateFormatter = (date) => {
    if (!date) {
        return "";
    }

    //? Convert the string to a date object
    //?
    date = new Date(date);

    //? Format the date as MM/DD/YYYY
    //?
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

/**
 * @function utcToLocalFormatter
 * @description Formats a UTC DateTime to a users local DateTime.
 * @param {String} date - The date to convert.
 * @param {Format} format - The format to convert the date to.
 * @returns {String}
 */
export const utcToLocalFormatter = (date, format) => {
    if (!date || !format) {
        return "Date or format not provided.";
    }

    return dayjs.utc(date).local().format(format);
};

/**
 * @function parseDateLocal
 * @description Formats a UTC date string to a date object locally.
 * @param {String} dateString - The date to convert.
 * @returns {Date}
 */
export const parseDateLocal = (dateString) => {
    const dayjsDate = utcToLocalFormatter(dateString, "MMM DD YYYY h:mm A");
    return new Date(dayjsDate);
};

/**
 * @function jobDocumentTypeFormatter
 * @description Formats job document type enum to its paired string value.
 * @returns {string}
 * @param jobDocumentType
 */
export const jobDocumentTypeFormatter = (jobDocumentType) => {
    switch (jobDocumentType) {
        case 0:
            return "Estimate";
        case 1:
            return "Sales Order";
        case 2:
            return "Invoice";
        case 3:
            return "Purchase Order";
        case 4:
            return "Field Purchase Order";
        case 5:
            return "Credit";
        case 6:
            return "ChangeOrder";
        case 7:
            return "StockOrder";
        default:
            return "Unknown";
    }
};
