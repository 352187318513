import React from "react";
import { axiosInstance } from "../services/axiosInstance";

const sendAuthMe = () => {
    axiosInstance
        .get("auth/user/me")
        .then((_) => {
            // console.log(response);
        })
        .catch((error) => {
            console.log(error);
            if (error.response.status === 403) {
                alert("Your access is not allowed.");
            }
            console.error(error);
        });
    return <button onClick={sendAuthMe}>Auth Me</button>;
};

export default sendAuthMe;
