import React, { useContext } from "react";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import FormField from "../../../../components/Deprecated/StylingField";

const BillingDetails = () => {
    const vendorContext = useContext(VendorContext);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
                padding: "10px",
            }}
        >
            {/* Column 1 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <FormField
                    label="Federal Tax Identification"
                    value={vendorContext.vendor?.federalTaxId}
                />
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    <FormField
                        label="Expense Account"
                        value={vendorContext.vendor?.expenseAccount?.name}
                    />
                    <FormField
                        label="Income Account"
                        value={vendorContext.vendor?.incomeAccount?.name}
                    />
                </div>
            </div>
        </div>
    );
};

export default BillingDetails;
