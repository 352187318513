import React, { useContext } from "react";
import "../../styles/CommonTextStyles.scss";
import { BaseCell } from "./BaseCell";
import { BreadcrumbContext } from "../../providers/BreadCrumbProvider.jsx";

/**
 * @function LinkCell
 * @description
 * A cell component that renders a clickable link.
 * This component cannot be used with the "lock" prop on the GridColumn component.
 * @param {Object} props
 * @param {string} props.uri - The URI for the link
 * @param {Array|object} props.additionalBreadcrumbInfo - Additional breadcrumb info to be added to the breadcrumb
 * @param {string} props.target - The target for the link
 * @param {string} props.field - The field to use for the display text
 * @param {Object} props.dataItem - The data item for the row
 * @param {Function} props.onClick - The function to call when the link is clicked
 * @return {React.JSX.Element}
 * @constructor
 */
export const LinkCell = (props) => {
    const {
        dataItem,
        field,
        uri,
        onClick,
        target = "_self",
        additionalBreadcrumbInfo = [],
    } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    let displayText = "";
    try {
        // supports a nested field like "eventType.name" being passed in
        displayText = field
            .split(".")
            .reduce((acc, field) => acc[field], dataItem);
    } catch (e) {
        // Just to remove the console.log for occurring and filling the console.
        const _ = `Error getting field ${field} from dataItem`;
    }

    const handleClick = (e) => {
        // Allow default behavior for mailto: links or other external URIs
        if (
            uri.startsWith("mailto:") ||
            uri.startsWith("tel:") ||
            uri.startsWith("http")
        ) {
            return; // Do not prevent default, allow mailto: or external links to work
        }
        e.preventDefault();
        // Call the onClick function if it is present
        onClick && onClick();

        // If the URI is not present, do not attempt to navigate
        if (!uri) return;

        const routeObj = {
            uri: uri,
            breadcrumbInfo: [
                {
                    id: dataItem.id,
                    text: displayText,
                },
                ...additionalBreadcrumbInfo,
            ],
        };

        handleRouteChange(routeObj);
    };

    return (
        <BaseCell {...props}>
            <a
                className={"Link"}
                onClick={handleClick}
                href={uri}
                target={target}
                rel="noopener noreferrer"
            >
                {displayText}
            </a>
        </BaseCell>
    );
};
