import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { CenterLoader } from "./CenterLoader";

const ConfirmDialogue = ({
    visible,
    onConfirm,
    onCancel,
    loaderVisible,
    actionName,
    secondaryText,
}) => {
    return (
        <div>
            {/* This is the confirmation toggle, this will be visible once the confirm button is toggled */}
            {visible && (
                <Dialog
                    title={"Please confirm"}
                    onClose={onCancel}
                    width={300}
                    height={"auto"}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px",
                        }}
                    >
                        Are you sure you want to {actionName ?? "Delete"}?
                    </p>
                    {secondaryText && (
                        <p
                            style={{
                                textAlign: "center",
                                margin: "10px",
                                fontSize: "12px",
                            }}
                        >
                            {secondaryText}
                        </p>
                    )}
                    {/* If the loader is not visible, display the buttons, otherwise display the loader */}
                    {!loaderVisible ? (
                        <div>
                            <Button
                                themeColor={"primary"}
                                fillMode={"solid"}
                                rounded={"medium"}
                                onClick={onCancel}
                                style={{ width: "100px", margin: "10px" }}
                            >
                                No
                            </Button>
                            <Button
                                themeColor={
                                    actionName === "Delete" || !actionName
                                        ? "error"
                                        : "info"
                                }
                                fillMode={"solid"}
                                rounded={"medium"}
                                onClick={onConfirm}
                                style={{ width: "100px", margin: "10px" }}
                            >
                                Yes
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <CenterLoader />
                        </div>
                    )}
                </Dialog>
            )}
        </div>
    );
};

export default ConfirmDialogue;
