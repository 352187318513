import * as React from "react";
import { useContext, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { FormDateInput } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import LeadService from "../../../../services/LeadService";
import dayjs from "dayjs";

/**
 * @function LeadHoldUntilModal
 * @description Handles the modal for creating and editing leads
 * @param props.id {String} - The id of the lead
 * @param props.close {Function} - Function to close the modal
 * @param props.visible {Boolean} - Whether the modal is visible
 * @param props.record {Object} - The record to pre-populate the form with
 * @return {Element}
 * @constructor
 */
export const LeadHoldUntilModal = (props) => {
    const { id, close, visible } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);
        const service = new LeadService("Customer/Lead");
        const heldTillDate = dayjs(dataItem.heldTill).format("YYYY-MM-DD");
        service
            .updateStatus(dataItem.id, dataItem.status, heldTillDate)
            .then(() => {
                triggerReload();
                close();
            })
            .finally(() => {
                setLoaderVisible(false);
            });
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Hold lead until what date?"}
                onClose={close}
                size={"small"}
            >
                <Form
                    initialValues={{ id, status: 1 }}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Field
                                name={"heldTill"}
                                component={FormDateInput}
                                label={"Hold Until"}
                                validator={requiredValidator}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={close}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!id}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
