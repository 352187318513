import * as React from "react";
import { useMemo } from "react";
import { utcToLocalFormatter } from "../../../resources/Deprecated/stringFormatter";
import { decimalToTime } from "../../../resources/Deprecated/dateHelper";
import { TreeList } from "@progress/kendo-react-treelist";

const CurrentTaskCell = (props) => {
    const { dataItem } = props;

    // We only want to display information about Employees
    if (dataItem.jobName) return;

    let currentTask = undefined;

    if (dataItem.timeClockRecords.length !== 0) {
        currentTask = dataItem.timeClockRecords[0];
    }

    return currentTask ? (
        <td>
            <span>{currentTask.laborTaskName}</span>
        </td>
    ) : (
        <td></td>
    );
};

const TaskStartedCell = (props) => {
    const { dataItem } = props;

    // We only want to display information about Employees
    if (dataItem.jobName) return;

    let currentTask = undefined;

    if (dataItem.timeClockRecords.length !== 0) {
        currentTask = dataItem.timeClockRecords[0];
    }

    return currentTask ? (
        <td>
            <span>
                {utcToLocalFormatter(
                    currentTask.startTime,
                    "h:mm A - MM/DD/YYYY"
                )}
            </span>
        </td>
    ) : (
        <td></td>
    );
};

const TimeOnTaskCell = (props) => {
    const { dataItem } = props;

    // We only want to display information about Employees
    if (dataItem.jobName) return;

    let currentTask = undefined;

    if (dataItem.timeClockRecords.length !== 0) {
        currentTask = dataItem.timeClockRecords[0];
    }

    return currentTask ? (
        <td>
            <span>{decimalToTime(currentTask.timeWorked)}</span>
        </td>
    ) : (
        <td></td>
    );
};

const columns = [
    {
        field: "name",
        title: "Job/Employee",
        expandable: true,
    },
    {
        field: "currentTask",
        title: "Current Task",
        cell: CurrentTaskCell,
    },
    {
        field: "taskStart",
        title: "Task Started",
        cell: TaskStartedCell,
    },
    {
        field: "timeOnTask",
        title: "Time On Task",
        cell: TimeOnTaskCell,
    },
];

const ActiveTasksOnTaskTreeList = (data) => {
    // Format the data for the TreeList
    const formattedData = useMemo(
        () =>
            data.data.map((i) => {
                i.expanded = i.employees.length > 0;
                i.name = i.jobName;
                i.employees = i.employees.map((e) => {
                    e.name = e.fullName;
                    return e;
                });

                return i;
            }),
        [data]
    );

    return (
        <TreeList
            data={formattedData}
            expandField={"expanded"}
            columns={columns}
            style={{ width: "100%" }}
            subItemsField={"employees"}
        />
    );
};

export default ActiveTasksOnTaskTreeList;
