import React, { useContext, useEffect, useState } from "react";
import SchedulerProvider, {
    SchedulerContext,
} from "../../../providers/Deprecated/Customer/SchedulerProvider.jsx";
import { SchedulerToolbar } from "./Components/SchedulerToolbar.jsx";
import { SchedulerGrid } from "./Components/SchedulerGrid.jsx";
import dayjs from "dayjs";
import { useLocalStorage } from "../../../hooks/useLocalStorage.js";

const WrappedSchedulerPage = () => {
    return (
        <SchedulerProvider>
            <SchedulerPage />
        </SchedulerProvider>
    );
};
const SchedulerPage = () => {
    const schedulerContext = useContext(SchedulerContext);
    const [date, setDate] = useState(undefined);
    const [schedulerWindow, setSchedulerWindow] = useState("work-week");
    const [numOfDays, setNumOfDays] = useState(5);
    const localStorage = useLocalStorage();

    useEffect(() => {
        const storedState = localStorage.get("lastSchedulerState");

        if (storedState) {
            const state = storedState.value;
            setSchedulerWindow(state.schedulerWindow);
            setNumOfDays(state.numOfDays);
            setDate(new Date(state.date));
            schedulerContext.setCurrentDate(new Date(state.date));
        } else {
            setDate(dayjs().startOf("week").add(1, "day").toDate());
        }
    }, []);

    /**
     * @function onDateChange
     * @description Takes in Date Change Event and sets event accordingly
     * @param {Date} newDate - new JS date for starting scheduler view
     */
    const onDateChange = (newDate) => {
        const newUtcDate = dayjs(newDate).tz("utc").toDate();
        setDate(newUtcDate);
        schedulerContext.setCurrentDate(newDate);
        localStorage.add("lastSchedulerState", {
            date: newUtcDate,
            schedulerWindow: schedulerWindow,
            numOfDays: numOfDays,
        });
    };

    const onSchedulerWindowChange = (newSchedulerWindow) => {
        setSchedulerWindow(newSchedulerWindow);
        setNumOfDays(newSchedulerWindow);
        localStorage.add("lastSchedulerState", {
            date: date,
            schedulerWindow: newSchedulerWindow,
            numOfDays: newSchedulerWindow,
        });
    };

    return (
        date && (
            <>
                <SchedulerToolbar
                    startDate={date}
                    schedulerWindow={schedulerWindow}
                    onDateChange={onDateChange}
                    onSchedulerWindowChange={onSchedulerWindowChange}
                    numOfDays={numOfDays}
                />
                <SchedulerGrid
                    events={schedulerContext.events}
                    startDate={date}
                    numOfDays={numOfDays}
                />
            </>
        )
    );
};

export default WrappedSchedulerPage;
