import React, { useContext, useEffect, useState } from "react";
import { ItemRelationshipsContext } from "../../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { getVendorItemsByItemId } from "../../../../../services/Deprecated/inventory/vendorService";
import FormField from "../../../../../components/Deprecated/StylingField";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";

const ItemDetails = () => {
    const itemContext = useContext(ItemRelationshipsContext);
    const [maxVendorCost, setMaxVendorCost] = useState(0);

    useEffect(() => {
        const calculateMaxVendorCost = async () => {
            if (!itemContext.item?.id) return;

            try {
                const response = await getVendorItemsByItemId(
                    itemContext.item?.id
                );
                const vendorItems = response.data;

                // Calculate the max vendor cost
                const maxCost = vendorItems.reduce((max, vendorItem) => {
                    const totalCost =
                        vendorItem.vendorCost.amount +
                        vendorItem.freightCharge.amount;
                    return totalCost > max ? totalCost : max;
                }, 0);
                setMaxVendorCost(maxCost.toFixed(2));
            } catch (error) {
                console.error("Error calculating max vendor cost:", error);
            }
        };

        calculateMaxVendorCost();
    }, [itemContext.item]);

    const shouldDisplayDimensions = () => {
        const itemType = itemContext.item.itemType?.name.toLowerCase();
        return itemType === "slab" || itemType === "sheet";
    };

    return itemContext.item ? (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
                padding: "10px",
            }}
        >
            {/* Column 1 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "60%",
                }}
            >
                <FormField label="Name" value={itemContext.item.name} />
                <FormField
                    label="Description"
                    value={itemContext.item.baseDescription}
                />
                <FormField
                    label="MFR #"
                    value={itemContext.item.manufacturerNumber}
                />
                <FormField
                    label="Status"
                    value={itemContext.item.isActive ? "Active" : "Inactive"}
                />
            </div>
            {/* Column 2 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "60%",
                }}
            >
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    <FormField
                        label="Item Category"
                        value={itemContext.item.category?.name}
                    />
                    <FormField
                        label="Item Type"
                        value={itemContext.item.itemType?.name}
                    />
                </div>
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    {shouldDisplayDimensions(
                        <FormField
                            label="Default Length(Ft)"
                            value={itemContext.item.defaultLength}
                        />
                    )}
                    {shouldDisplayDimensions(
                        <FormField
                            label="Default Width(Ft)"
                            value={itemContext.item.defaultWidth}
                        />
                    )}
                </div>
                <FormField
                    label="Order UOM"
                    value={itemContext.item.orderingUom?.name}
                />
                <FormField
                    label="Retail UOM"
                    value={itemContext.item.retailUom?.name}
                />
            </div>
            {/* Column 3 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "60%",
                }}
            >
                <FormField
                    label="Retail Price"
                    value={`$${itemContext.item.retailPrice?.amount}`}
                />
                <FormField
                    label="Max Vendor Cost"
                    value={`$${maxVendorCost}`}
                />
                <FormField
                    label="Expense Account"
                    value={itemContext.item.expenseAccount?.name}
                />
                <FormField
                    label="Income Account"
                    value={itemContext.item.incomeAccount?.name}
                />
            </div>
            {/* Column 4 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            ></div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default ItemDetails;
