import React, { useContext } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import AppointmentTimeEdit from "./AppointmentTimeEdit";
import AppointmentTimeCreate from "./AppointmentTimeCreate";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { utcToLocalFormatter } from "../../../../resources/Deprecated/stringFormatter";
import { CustomCell } from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { deleteAppointmentTime } from "../../../../services/Deprecated/customer/eventServices";

const AppointmentTimePortlet = (props) => {
    const eventId = props?.match?.params?.id;
    const eventContext = useContext(EventContext);
    const appointmentTime = eventContext.event.appointmentTime
        ? [eventContext.event.appointmentTime]
        : [];

    //? This is a custom formatter for the appointment time
    //? It takes the start and end time and formats it to a string
    //? Example: 8:00 AM - 10:00 AM
    //?
    const displayAppointmentTime = (dataItem) => {
        if (!dataItem.startTime && !dataItem.endTime) {
            return "";
        }

        const startTime = utcToLocalFormatter(dataItem.startTime, "ddd h:mm A");
        const endTime = utcToLocalFormatter(dataItem.endTime, "ddd h:mm A");

        return `${startTime} - ${endTime}`;
    };

    return (
        <Card
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                minHeight: "110px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                    backgroundColor: "#67809F",
                    color: "#fff",
                    height: 60,
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}
                    >
                        <i className={"k-icon k-i-clock"} />
                        <strong>Appointment Time</strong>
                    </span>
                    {eventContext.event.appointmentTime?.id ? (
                        <Tooltip anchorElement="target" position="top">
                            <span
                                title={
                                    "Tip: Cannot create an appointment if one already exists."
                                }
                            >
                                <button
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled"
                                    style={{
                                        border: "none",
                                    }}
                                >
                                    <span className={"k-icon k-i-plus"} />
                                    Create
                                </button>
                            </span>
                        </Tooltip>
                    ) : (
                        <AppointmentTimeCreate />
                    )}
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {/* If the event is not the one we are looking for, show a loader, helps with re-renders */}
                {eventContext.event.id === eventId ? (
                    <Grid
                        data={appointmentTime}
                        style={{
                            height: "50px",
                        }}
                    >
                        {/* We leave this empty so no empty message is shown */}
                        <GridNoRecords> </GridNoRecords>
                        <GridColumn
                            field="appointmentTime"
                            title="Apppointment Time:"
                            filter={"text"}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <CustomCell {...props}>
                                    <strong>
                                        {displayAppointmentTime(props.dataItem)}
                                    </strong>
                                </CustomCell>
                            )}
                        />
                        <GridColumn
                            width={120}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    action={deleteAppointmentTime}
                                    EditComponent={AppointmentTimeEdit}
                                />
                            )}
                        />
                    </Grid>
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
        </Card>
    );
};

export default AppointmentTimePortlet;
