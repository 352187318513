import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import VendorDocumentService from "../../../../../../services/VendorDocumentService.js";
import { VendorDocumentModalHelper } from "../Helpers/VendorDocumentModalHelper.js";
import { AuthContext } from "../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider.jsx";
import { ConfirmationDialog } from "../../../../../../components/Dialogs/ConfirmationDialog.jsx";

export const VendorDocumentModalContext = createContext(null);

const VendorDocumentModalProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [reloadItemGridKey, setReloadItemGridKey] = useState(Math.random());
    const [reloadDocumentKey, setReloadDocumentKey] = useState(Math.random());
    const [formData, setFormData] = useState(undefined);
    const lineItems = useRef([]);
    const [canEdit, setCanEdit] = useState(false);
    const [canReceive, setCanReceive] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [submitLoaderVisible, setSubmitLoaderVisible] = useState(false);
    const closeAfterSubmit = useRef(false);
    const service = useRef(new VendorDocumentService());
    const pdfContainerRef = useRef(null);
    const [modalState, setModalState] = useState({});
    const [confirmationDialogVisible, setConfirmationDialogVisible] =
        useState(false);
    const hasPendingChanges = useRef(false);
    const dataHasInitialized = useRef(false);

    // Initialize FormData and LineItems
    useEffect(() => {
        if (!modalState.visible) return;

        if (modalState.id) {
            service.current.get(modalState.id).then((res) => {
                const data = VendorDocumentModalHelper.formatDataForModal(
                    res.data
                );
                lineItems.current = data.lineItems;
                delete data.lineItems;

                setFormData(data);
                setEditableStatuses(data);
                dataHasInitialized.current = true;
            });
        } else {
            const modalData = VendorDocumentModalHelper.formatDataForModal(
                modalState.record
            );
            modalData.orderedBy = user.activeEmployee;
            setFormData(modalData);
            setEditableStatuses(modalData);
            dataHasInitialized.current = true;
        }
    }, [modalState.visible, modalState.id]);

    // Handles the determination if there are pending changes to the Document
    useEffect(() => {
        if (!dataHasInitialized.current || !canEdit) return;
        hasPendingChanges.current = true;
    }, [reloadDocumentKey, reloadItemGridKey]);

    const setEditableStatuses = (data) => {
        const hasChildren = data.childVendorDocuments?.length > 0;
        setCanEdit(
            data.type === VendorDocumentType.Enum.PurchaseOrder && !hasChildren
        );
        setCanReceive(
            data.type === VendorDocumentType.Enum.ItemReceipt && !hasChildren
        );
        setCanDelete(
            !hasChildren &&
                modalState?.id &&
                !lineItems.current.some((i) => i.quantityReceived > 0)
        );
    };

    const onModalClose = () => {
        if (hasPendingChanges.current && !closeAfterSubmit.current) {
            setConfirmationDialogVisible(true);
            return;
        }

        setConfirmationDialogVisible(false);
        hasPendingChanges.current = false;
        dataHasInitialized.current = false;
        setFormData(undefined);
        setSubmitLoaderVisible(false);
        lineItems.current = [];
        closeAfterSubmit.current = false;
        modalState.close();
    };

    const onModalSubmit = (e) => {
        setSubmitLoaderVisible(true);

        const values = VendorDocumentModalHelper.formatDataForNetwork({
            ...e.values,
            id: modalState.id,
            lineItems: lineItems.current,
        });

        const request = modalState.id
            ? service.current.update(values)
            : service.current.create(values);
        request.then((res) => {
            triggerReload();

            setSubmitLoaderVisible(false);

            if (closeAfterSubmit.current) {
                onModalClose();
            } else {
                if (!modalState.id) {
                    setModalState({
                        ...modalState,
                        id: res.data.id,
                    });
                }
            }
        });
    };

    const onDelete = () => {
        service.current.delete(modalState.id).then((res) => {
            triggerReload();

            if (res.success) {
                onModalClose();
            }
        });
    };

    const onChangeStatus = (status) => {
        service.current.changeStatus(modalState.id, status).then((res) => {
            if (res.success) {
                triggerReload();
                onModalClose();
            }
        });
    };

    // Reloads the item grid
    const reloadItemGrid = () => {
        setReloadItemGridKey(Math.random());
    };

    // Reloads the document information
    const reloadDocumentInformation = () => {
        setReloadDocumentKey(Math.random());
    };

    return (
        <VendorDocumentModalContext.Provider
            value={{
                reloadItemGridKey,
                reloadItemGrid,
                reloadDocumentKey,
                reloadDocumentInformation,
                formData,
                setFormData,
                canEdit,
                canReceive,
                canDelete,
                lineItems,
                setModalState,
                modalState,
                onModalClose,
                onModalSubmit,
                onDelete,
                submitLoaderVisible,
                closeAfterSubmit,
                onChangeStatus,
                pdfContainerRef,
                hasPendingChanges,
            }}
        >
            <ConfirmationDialog
                visible={confirmationDialogVisible}
                actionOnConfirm={() => {
                    hasPendingChanges.current = false;
                    onModalClose();
                }}
                actionOnCancel={() => setConfirmationDialogVisible(false)}
                confirmationText={
                    "You have unsaved changes, do you wish to close?"
                }
                confirmationButtonText={"Yes"}
                confirmationButtonThemeColor={"error"}
                cancelButtonText={"No"}
                cancelButtonThemeColor={"primary"}
            />
            {children}
        </VendorDocumentModalContext.Provider>
    );
};

export default VendorDocumentModalProvider;
