import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import RelationshipForm from "./EmployeeRelationshipForm";
import { Loader } from "@progress/kendo-react-indicators";
import ContactInformationForm from "./EmployeeContactForm";
import BasicInfoForm from "./BasicInformationForm";
import dayjs from "dayjs";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import {
    getEmployee,
    updateEmployee,
} from "../../../../services/Deprecated/humanResources/employeeServices";
import ContactHelper from "../../../../resources/ContactHelper";
import { ContactType } from "../../../../resources/Enums/ContactType";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";

const EmployeeEditComponent = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const employeeContext = useContext(EmployeeContext);
    const relationships = employeeContext.relationships;
    const [employeeData, setEmployeeData] = useState(null);

    useEffect(() => {
        if (!visible || !record || !record.id) return;

        getEmployee(record.id).then((res) => {
            const employeeDataFromApi = res.data;
            employeeDataFromApi.dateOfBirth = employeeDataFromApi.dateOfBirth
                ? dayjs(employeeDataFromApi.dateOfBirth).format("MM/DD/YYYY")
                : null;

            if (employeeDataFromApi.locations) {
                employeeDataFromApi.locations =
                    employeeDataFromApi.locations.map((l) => ({
                        ...l,
                        id: l.locationId,
                    }));
                employeeDataFromApi.primaryLocation =
                    employeeDataFromApi.locations.find(
                        (l) => l.isPrimaryLocation
                    );
            }

            if (employeeDataFromApi.status && relationships.status) {
                employeeDataFromApi.status.selection =
                    relationships.status.find(
                        (s) => s.id === employeeDataFromApi.status.status
                    );
                employeeDataFromApi.status.hiredDate = dayjs(
                    employeeDataFromApi.status.hiredDate
                ).format("MM/DD/YYYY");
            }

            setEmployeeData(employeeDataFromApi);
        });
    }, [visible]);

    const [currentContext, setCurrentContext] = React.useState("Basic Info");

    const onContextSwitch = (context) => {
        setCurrentContext(context.target.innerText);
    };

    const submitEmployee = async (dataItem) => {
        toggleLoaderVisible(true);

        if (dataItem.emergencyContact) {
            dataItem.contacts = [
                ContactHelper.build(
                    dataItem.emergencyContact,
                    ContactType.Enum.Emergency
                ),
            ];
        }

        updateEmployee(dataItem).then(() => {
            setEmployeeData(null);
            reloadDataContext.triggerReload(dataItem);
            onCancel();
            toggleLoaderVisible(false);
        });
    };

    return (
        <div>
            {visible && employeeData && (
                <Form
                    initialValues={employeeData}
                    onSubmit={submitEmployee}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Employee: " + record.firstName}
                            onClose={onCancel}
                            size={"large"}
                        >
                            <div>
                                <Button
                                    themeColor={
                                        currentContext === "Basic Info"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Basic Info
                                </Button>
                                <Button
                                    themeColor={
                                        currentContext === "Details"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Details
                                </Button>
                                <Button
                                    themeColor={
                                        currentContext === "Contact"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Contact
                                </Button>
                            </div>
                            <FormElement>
                                {currentContext === "Basic Info" && (
                                    <BasicInfoForm
                                        formRenderProps={formRenderProps}
                                        record={record}
                                    />
                                )}
                                {currentContext === "Details" && (
                                    <RelationshipForm
                                        formRenderProps={formRenderProps}
                                        editMode={true}
                                    />
                                )}
                                {currentContext === "Contact" && (
                                    <ContactInformationForm
                                        formRenderProps={formRenderProps}
                                    />
                                )}
                                {!loaderVisible ? (
                                    <>
                                        <hr
                                            style={{
                                                borderTop:
                                                    "1px solid rgb(204, 204, 204)",
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "start",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                themeColor={"primary"}
                                                fillMode={"solid"}
                                                rounded={"medium"}
                                                onClick={onCancel}
                                                style={{
                                                    width: "100px",
                                                    margin: "10px",
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                themeColor={"info"}
                                                fillMode={"solid"}
                                                rounded={"medium"}
                                                style={{
                                                    width: "100px",
                                                    margin: "10px",
                                                }}
                                                icon={"k-icon k-i-save"}
                                                disabled={
                                                    !formRenderProps.allowSubmit
                                                }
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <Loader type="infinite-spinner" />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};
export default EmployeeEditComponent;
