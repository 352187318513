import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import PayrollService from "../../../../services/Deprecated/humanResources/PayrollService";
import { TimeCard } from "../../TimeClockEntry/Components/TimeCard";

const EmployeeTimeClockEntries = () => {
    const { employee } = useContext(EmployeeContext);
    const [dateRange, setDateRange] = useState(undefined);
    const [payPeriods, setPayPeriods] = useState([]);
    const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);

    useEffect(() => {
        PayrollService.getIntervalDates().then((res) => {
            const payPeriodsData = res.data.map((period) => ({
                ...period,
                rangeText: `${dayjs(period.start).format(
                    "MM/DD/YYYY"
                )} - ${dayjs(period.end).format("MM/DD/YYYY")}`,
            }));

            setPayPeriods(payPeriodsData);
            setSelectedPayPeriod(payPeriodsData[0]);
            setDateRange({
                start: dayjs(payPeriodsData[0].start).toDate(),
                end: dayjs(payPeriodsData[0].end).toDate(),
            });
        });
    }, []);

    // Handle pay period change
    const onPayPeriodChange = (e) => {
        setSelectedPayPeriod(e.value);
        setDateRange({
            start: dayjs(e.value.start).toDate(),
            end: dayjs(e.value.end).toDate(),
        });
    };

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
            {/* Dropdown to select pay period */}
            <DropDownList
                data={payPeriods}
                textField="rangeText"
                value={selectedPayPeriod}
                onChange={onPayPeriodChange}
                style={{ width: "250px", marginBottom: "10px" }}
            />
            <TimeCard
                employeeId={employee.id}
                dateRange={dateRange}
                showBreaks={true}
            />
        </span>
    );
};

export default EmployeeTimeClockEntries;
