import { BaseEnum } from "./BaseEnum";

export class ContactType extends BaseEnum {
    static Enum = {
        Primary: 0,
        Secondary: 1,
        Emergency: 4,
        Other: 5,
    };
}

export const contactTypeOptions = [
    { text: "Primary", value: ContactType.Enum.Primary },
    { text: "Secondary", value: ContactType.Enum.Secondary },
    { text: "Other", value: ContactType.Enum.Other },
];
