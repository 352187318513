import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { SourceModal } from "./Components/SourceModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import SourceService from "../../../../../services/Deprecated/customer/SourceService";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const SourceDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <SourceModal {...modal} />
            <ActionButton
                onClick={() => modal.open()}
                buttonText={"Add Source"}
                icon={"k-icon k-i-plus"}
                permissions={[PermissionsEnum.CreateLeadSource]}
            />
            <DataGrid
                uri={"/Customer/Source/GetAll?"}
                style={{ maxWidth: "420px" }}
            >
                <GridColumn field="name" title="Name:" width={"250px"} />
                <GridColumn
                    width={150}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            onDelete={() =>
                                SourceService.delete(props.dataItem.id).then(
                                    () => reloadDataContext.triggerReload()
                                )
                            }
                            onEdit={() => modal.open(props.dataItem.id)}
                            permissions={{
                                edit: [PermissionsEnum.UpdateLeadSource],
                                delete: [PermissionsEnum.DeleteLeadSource],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
export default SourceDashboardPage;
