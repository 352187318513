import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

//?
//? All API calls related to payments are defined here
//?

/**
 * Retrieves Job Summary information by jobId.
 * @function getJobSummary
 * @async
 * @param {number} jobId - The job id to retrieve the summary for.
 * @returns  {Promise<ServiceResponse>}
 * @throws {Error} - If an error occurs during the API call.
 */
export const getJobSummary = async (jobId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/Billing/GetSummary?jobId=${jobId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves payment information by payment id.
 * @function getPayment
 * @async
 * @param {number} paymentId - The payment id to retrieve.
 * @returns  {Promise<ServiceResponse>}
 * @throws {Error} - If an error occurs during the API call.
 */
export const getPayment = async (paymentId) => {
    try {
        const response = await axios.get(
            `/Customer/Customer/Billing/Get?id=${paymentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updatePayment
 * @description Function to update payment in Customer Module
 * @param {Object} payment
 * @returns  {Promise<ServiceResponse>}
 */
export const updatePayment = async (payment) => {
    try {
        const response = await axios.put(
            `/Customer/Customer/Billing/Update`,
            payment
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function transferJobPayment
 * @param payload
 * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
 */
export const transferJobPayment = async (payload) => {
    try {
        const response = await axios.put(
            "/Customer/Job/Billing/Transfer",
            payload
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function createPayment
 * @description Function to create payment in Customer Module
 * @param {Object} payment
 * @returns  {Promise<ServiceResponse>}
 */
export const createPayment = async (payment) => {
    try {
        const response = await axios.post(
            `/Customer/Customer/Billing/Create`,
            payment
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deletePayment
 * @description Function to delete payment in Customer Module
 * @param {String} paymentId
 * @returns  {Promise<ServiceResponse>}
 */
export const deletePayment = async (paymentId) => {
    try {
        const response = await axios.delete(
            `/Customer/Customer/Billing/Delete?id=${paymentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Disburses payment by making a POST request to the specified endpoint.
 * @async
 * @param {Object} disbursement - The disbursement details.
 * @returns  {Promise<ServiceResponse>}
 * @throws {Error} - If an error occurs during the request.
 */
export const disbursePayment = async (disbursement) => {
    let uri;

    if (disbursement.documentId) {
        uri = "/Customer/Job/Billing/Disburse";
    } else {
        uri = "/Customer/Customer/Billing/Disburse";
    }

    try {
        const response = await axios.post(uri, disbursement);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function multiDisburse
 * @description Function to disburse multiple payments
 * @param {string} data.paymentId - The payment id to disburse
 * @param {Array|object} data.documents - The documents to disburse the payment to
 * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
 */
export const multiJobDisburse = async (data) => {
    const res = await axios.post("/Customer/Job/Billing/MultiDisburse", data);
    return formatServiceResponse(res);
};

/**
 * Retrieves all invoices for a given job.
 * @param {string} jobId - The ID of the job.
 * @returns {Promise<ServiceResponse>}
 * @throws {Error} If an error occurs while retrieving the data.
 */
export const getAllInvoicesForJob = async (jobId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/Document/GetAll?filter=(jobId~eq~'${jobId}'~and~type~eq~'2')`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
