import React from "react";
import "../../../styles/CommonPositionalStyles.scss";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import VendorDetailsForOutstandingItemReceiptsGrid from "./VendorDetailsForOutstandingItemReceiptsGrid";

const OutstandingItemReceipts = ({ data }) => {
    if (!data) {
        return (
            <div style={{ margin: "25px" }}>
                <CenterLoader />
            </div>
        );
    }

    const fullData = data.fullData;
    const limitedData = data.limitedData;

    return (
        <div>
            <div
                className="summary JustifyBetweenAlignCenter"
                style={{ padding: "10px 20px", backgroundColor: "#f2f4f8" }}
            >
                <div
                    className="summary-left JustifyLeftAlignCenter"
                    style={{ width: "50%" }}
                >
                    <p style={{ margin: 0 }}>
                        <strong>Total POs:</strong> {limitedData.quantity}
                    </p>
                </div>
                <div
                    className="summary-right JustifyRightAlignCenter"
                    style={{ width: "50%" }}
                >
                    <p style={{ margin: 0 }}>
                        <strong>Total Outstanding Amount:</strong> $
                        {limitedData.total.toFixed(2)}
                    </p>
                </div>
            </div>
            <VendorDetailsForOutstandingItemReceiptsGrid data={fullData} />
        </div>
    );
};

export default OutstandingItemReceipts;
