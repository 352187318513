import React from "react";
import { DropDownButton } from "@progress/kendo-react-buttons";

/**
 * @function DetailsHamburger
 * @description Displays information for a Document
 * @param props.info {object} - document object
 * @return {React.JSX.Element}
 * @constructor
 */
export const DetailsHamburger = (props) => {
    const { options, text, style } = props;

    const handleAction = (e) => {
        const option = options.find(
            (option) => option.actionName === e.item.actionName
        );

        if (option.action) {
            option.action();
        }
    };

    return (
        options && (
            <DropDownButton
                style={style}
                text={text}
                items={options}
                iconClass={"k-icon k-i-align-right"}
                themeColor={"primary"}
                textField="actionName"
                className={"export-hide"}
                onItemClick={(e) => handleAction(e)}
            />
        )
    );
};
