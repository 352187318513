import React from "react";
import { ContactType } from "../../../../../../resources/Enums/ContactType";

export const CustomerAndJobInformation = (props) => {
    const { initialData } = props;

    return initialData?.customer ? (
        <span
            className={"DisplayFlex JustifyLeftAlignLeft FlexColumn SmallGap"}
            style={{
                border: "1px solid rgba(0,0,0,0.4)",
                borderRadius: "5px",
                padding: "5px",
            }}
        >
            <strong>
                {initialData?.customer?.combinedName}
                {" / "}
                {initialData?.job?.name}
                {" - "}
                {initialData?.customer?.phoneOne?.phoneNumber}
            </strong>
            <strong>{initialData?.customer?.address?.fullAddress}</strong>
            <strong>Contacts:</strong>
            <span
                style={{ marginLeft: "20px" }}
                className={"JustifyLeftAlignLeft SmallGap FlexColumn"}
            >
                {initialData?.job?.contacts?.map((contact, index) => (
                    <span
                        key={index}
                        className={"JustifyLeftAlignLeft SmallGap"}
                    >
                        <strong>
                            {ContactType.ParseToString(contact.type)}:
                        </strong>
                        <p className={"ZeroMargin"}>
                            {contact.name} - {contact?.phone?.phoneNumber}
                        </p>
                    </span>
                ))}
            </span>
        </span>
    ) : null;
};
