import React, { useContext } from "react";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import styles from "../Payments.module.scss";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import PaymentProvider from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import {
    CustomCell,
    CustomDateCell,
} from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { PaymentsHelper } from "../../../../resources/Deprecated/PaymentHelpers/paymentsHelper";
import { deletePayment } from "../../../../services/Deprecated/customer/paymentServices";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import PaymentCreateBtn from "../../Payments/Components/PaymentCreateBtn";
import DisbursementListModal from "../../Payments/Components/DisbursementListModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import PaymentFormModal from "../../Payments/Components/PaymentFormModal";
import DisbursementBtn from "../../Payments/Components/DisbursementBtn";

const PaymentDetails = () => {
    const customerContext = useContext(CustomerContext);
    const initialFilter = {
        logic: "and",
        filters: [
            {
                field: "jobId",
                operator: "eq",
                value: undefined,
            },
        ],
    };

    return customerContext.customer.id ? (
        <PaymentProvider>
            <div className={styles.PaymentCreateBtnContainer}>
                <PaymentCreateBtn />
            </div>
            <div className={styles.CustomerPaymentsContainer}>
                <DisbursementListModal />
                <DataGrid
                    uri={`/Customer/Customer/Billing/GetAll?customerId=${customerContext.customer.id}&`}
                    initialFilter={initialFilter}
                    className={styles.PaymentGrid}
                >
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        field="type"
                        title="Type:"
                        locked={true}
                        width={250}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field="details"
                        title="Details:"
                        width={300}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field="date"
                        title="Date:"
                        width={100}
                        cell={(props) => (
                            <CustomDateCell
                                {...props}
                                data={props.dataItem.date}
                            />
                        )}
                    />
                    <GridColumn
                        field="employeeWhoCreated.name"
                        title="Created By:"
                        width={200}
                    />
                    <GridColumn
                        field="total.amount"
                        title="Amount:"
                        format="{0:c}"
                        width={150}
                    />
                    <GridColumn
                        field="remainder"
                        title="Applied:"
                        width={150}
                        cell={(props) => (
                            <CustomCell {...props}>
                                {PaymentsHelper.percentageAndDisbursedDisplay(
                                    props
                                )}
                            </CustomCell>
                        )}
                    />
                    <GridColumn
                        width={200}
                        cell={(props) =>
                            //? Is the remainder less than the amount? If so, this payment has been disbursed.
                            props.dataItem.remainder < props.dataItem.amount ? (
                                <ActionButtonsCommandCell
                                    requiredPermissionsEdit={[
                                        PermissionsEnum.UpdateCustomerPayment,
                                    ]}
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    EditComponent={PaymentFormModal}
                                    additionalButtons={[
                                        <DisbursementBtn
                                            dataItem={props.dataItem}
                                            key={0}
                                        />,
                                    ]}
                                />
                            ) : (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    action={deletePayment}
                                    EditComponent={PaymentFormModal}
                                    additionalButtons={[
                                        <DisbursementBtn
                                            dataItem={props.dataItem}
                                            key={0}
                                        />,
                                    ]}
                                />
                            )
                        }
                    />
                </DataGrid>
            </div>
        </PaymentProvider>
    ) : (
        <CenterLoader />
    );
};

export default PaymentDetails;
