import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
    createVendorItem,
    getAllVendors,
    updateVendorItem,
} from "../../../../services/Deprecated/inventory/vendorService";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { Button } from "@progress/kendo-react-buttons";
import {
    cannotBeNegativeValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";

const AssignVendorForm = (props) => {
    const {
        closeForm,
        isUpdate,
        item,
        vendorItems,
        reloadAfterSubmit = true,
    } = props;
    const [vendorOptions, setVendorOptions] = useState([]);
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        const fetchVendors = async () => {
            if (!item) return; // Ensure item is available

            try {
                const vendorsResponse = await getAllVendors();
                // Extract vendors data from the response or initialize as an empty array
                const vendors = vendorsResponse.data || [];

                // Extract vendor IDs from vendorItems
                const vendorIdsFromItems = vendorItems.map(
                    (vendorItem) => vendorItem.vendor.id
                );

                // Combine vendor IDs from both item.vendorIds and vendorItems
                const allExcludedVendorIds = new Set(vendorIdsFromItems);

                // Filter vendors to exclude those already associated
                const filteredVendors = vendors.filter(
                    (vendor) => !allExcludedVendorIds.has(vendor.id)
                );
                setVendorOptions(filteredVendors);
            } catch (error) {
                console.error("Error fetching vendors:", error);
            }
        };

        fetchVendors();
    }, [item, vendorItems]);

    const handleSubmit = async (formData) => {
        setLoaderVisible(true);

        if (isUpdate) {
            await updateVendorItem({
                vendorItemId: props.vendorItemId,
                vendorId: formData.vendor.id,
                itemId: item.id,
                vendorCost: {
                    amount: formData.vendorCost,
                    currency: "USD",
                },
                freightCharge: {
                    amount: formData.freightCharge,
                    currency: "USD",
                },
            });
        } else {
            await createVendorItem({
                vendorId: formData.vendor.id,
                itemId: item.id,
                vendorCost: {
                    amount: formData.vendorCost,
                    currency: "USD",
                },
                freightCharge: {
                    amount: formData.freightCharge,
                    currency: "USD",
                },
            });
        }
        if (reloadAfterSubmit) {
            triggerReload();
        }
        closeForm();
        setLoaderVisible(false);
    };

    return (
        <ResponsiveDialog
            title={`Add Vendor`}
            onClose={closeForm}
            size={"medium"}
        >
            <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <FormElement style={{ maxWidth: 650 }}>
                            <fieldset className={"k-form-fieldset"}>
                                {/* Render a dropdown for selecting vendor */}
                                <div className="mb-3">
                                    <Field
                                        name={"vendor"}
                                        component={FormDropDown}
                                        data={vendorOptions}
                                        textField={"name"}
                                        dataItemKey={"id"}
                                        label={"Select Vendor"}
                                        validator={requiredValidator}
                                    />
                                    <Field
                                        name={"vendorCost"}
                                        component={FormNumericInput}
                                        label={"Vendor Cost:"}
                                        format={"c2"}
                                        defaultValue={0}
                                        validator={cannotBeNegativeValidator}
                                    />
                                    <Field
                                        name={"freightCharge"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Freight Charge:"}
                                        defaultValue={0}
                                        validator={cannotBeNegativeValidator}
                                    />
                                </div>
                            </fieldset>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    themeColor={"primary"}
                                    loaderVisible={loaderVisible}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={closeForm}
                                    style={{
                                        width: "100px",
                                        margin: "10px",
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    themeColor={"success"}
                                    loaderVisible={loaderVisible}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    style={{
                                        width: "100px",
                                        margin: "10px",
                                    }}
                                    icon={"k-icon k-i-plus"}
                                    disabled={!formRenderProps.allowSubmit}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>
        </ResponsiveDialog>
    );
};

export default AssignVendorForm;
