interface MsalConfig {
    authClientId: string;
    authTenantId: string;
    authRedirectUrl: string;
}

interface AzureB2CConfig {
    policyName: string;
    authorityUrl: string;
    authorityDomain: string;
    authScopes: string[];
    msal: MsalConfig;
}

interface Config {
    appName: string;
    env: string;
    baseUrl: string;
    localUrl: string;
    blackListedBreadCrumbUrls: string[];
    azureB2C: AzureB2CConfig;
}

const config: Config = {
    appName: import.meta.env.VITE_REACT_APP_NAME as string,
    env: import.meta.env.VITE_REACT_APP_ENV as string,
    baseUrl: import.meta.env.VITE_REACT_APP_API_ENDPOINT as string,
    localUrl: "https://localhost:7012",
    blackListedBreadCrumbUrls:
        import.meta.env.VITE_REACT_APP_BLACK_LISTED_BREAD_CRUMB_URLS?.split(
            ","
        ) as string[],
    azureB2C: {
        policyName: import.meta.env.VITE_REACT_APP_B2C_POLICY_NAME as string,
        authorityUrl: import.meta.env
            .VITE_REACT_APP_B2C_AUTHORITY_URL as string,
        authorityDomain: import.meta.env
            .VITE_REACT_APP_B2C_AUTHORITY_DOMAIN as string,
        authScopes: import.meta.env.VITE_REACT_APP_B2C_AUTH_SCOPES?.split(
            ","
        ) as string[],
        msal: {
            authClientId: import.meta.env
                .VITE_REACT_APP_B2C_MSAL_AUTH_CLIENT_ID as string,
            authTenantId: import.meta.env
                .VITE_REACT_APP_B2C_MSAL_AUTH_TENANT_ID as string,
            authRedirectUrl: import.meta.env
                .VITE_REACT_APP_B2C_MSAL_AUTH_REDIRECT_URI as string,
        },
    },
};

export default config;
