import { GridColumn } from "@progress/kendo-react-grid";
import {
    CustomDateCell,
    DisplayDocumentNumberCell,
} from "../../../../components/Deprecated/CustomGridCells";
import React, { useContext } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { VendorDocumentContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { HintHeaderCustomCell } from "../../../../components/Deprecated/CustomHeaderCells";
import BillPaymentDetailsGrid from "../../Payments/Components/BillPaymentDetailsGrid";
import VendorBillPaymentProvider, {
    VendorBillPaymentContext,
} from "../../../../providers/Deprecated/Inventory/Vendor/VendorBillPaymentProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import BillPaymentModal from "../../Payments/Components/BillPaymentModal";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell.jsx";
import { useModal } from "../../../../hooks/useModal.js";
import { VendorDocumentModalWrapper } from "../../VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";

const VendorBillsGridWrapper = () => {
    return (
        <VendorBillPaymentProvider>
            <VendorBillsGrid />
        </VendorBillPaymentProvider>
    );
};

const VendorBillsGrid = () => {
    const { vendor } = useContext(VendorContext);
    const { setSelectedDocumentId } = useContext(VendorDocumentContext);
    const documentModal = useModal();
    const billPaymentContext = useContext(VendorBillPaymentContext);

    const initialPOSort = [
        {
            field: "createdAt",
            dir: "dsc",
        },
    ];
    const initialPOFilter = {
        logic: "and",
        filters: [
            {
                field: "vendorId",
                operator: "eq",
                value: vendor.id,
            },
            {
                field: "type",
                operator: "eq",
                value: 2,
            },
        ],
    };

    const handleCreateBillPaymentClick = (documentId) => {
        setSelectedDocumentId(documentId);
        billPaymentContext.setModalOperation("Create");
    };

    return (
        <>
            {/* Opens if a ModalOperation is present in VendorBillPaymentProvider.jsx */}
            <BillPaymentModal />
            <VendorDocumentModalWrapper {...documentModal} />
            <DataGrid
                uri={`/Inventory/Vendor/Document/GetAll?`}
                searchFields={["documentNumber"]}
                sortable={true}
                detail={BillPaymentDetailsGrid}
                sort={initialPOSort}
                initialFilter={initialPOFilter}
            >
                <GridColumn
                    field="documentNumber"
                    title="Bill Number:"
                    locked={true}
                    width={150}
                    cell={DisplayDocumentNumberCell}
                />
                <GridColumn
                    field="created"
                    title="Date Ordered:"
                    locked={true}
                    width={150}
                    cell={(props) => {
                        const dateObj = {
                            data: props.dataItem.created,
                        };
                        return CustomDateCell(dateObj);
                    }}
                />
                <GridColumn
                    field="fullyReceivedDate"
                    title="Received:"
                    width={200}
                    headerCell={(props) => {
                        const hint = "Date the PO was fully received.";
                        const position = "top";
                        return (
                            <HintHeaderCustomCell
                                props={props}
                                hint={hint}
                                position={position}
                            />
                        );
                    }}
                    cell={(props) => {
                        const dateObj = {
                            data: props.dataItem.fullyReceivedDate,
                        };
                        return CustomDateCell(dateObj);
                    }}
                />
                <GridColumn
                    field="total.amount"
                    filter={"numeric"}
                    format={"{0:c}"}
                    title="Total:"
                    width={150}
                />
                <GridColumn
                    field="totalPaid.amount"
                    filter={"numeric"}
                    format={"{0:c}"}
                    title="Total Paid:"
                    width={150}
                />
                <GridColumn
                    width={180}
                    filterable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    buttonText: "View",
                                    onClick: () =>
                                        documentModal.open(props.dataItem.id),
                                    permissions: [
                                        PermissionsEnum.ViewVendorDocument,
                                    ],
                                },
                                {
                                    buttonText: "Add Payment",
                                    onClick: () =>
                                        handleCreateBillPaymentClick(
                                            props.dataItem?.id
                                        ),
                                    disabled: props.dataItem.isFullyPaid,
                                    disabledToolTipMessage:
                                        "Bill is fully paid",
                                    permissions: [
                                        PermissionsEnum.CreateVendorPayment,
                                    ],
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </>
    );
};

export default VendorBillsGridWrapper;
