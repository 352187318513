import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { BusinessInformationDisplay } from "../../../../../../components/Pages/Documents/BusinessInformationDisplay.jsx";

const BusinessInformationPreview = (props) => {
    const { formRenderProps, setPreviewHeadVisible } = props;

    const businessInformation = {
        name: formRenderProps.valueGetter("businessName"),
        tagLine: formRenderProps.valueGetter("tagLine"),
        address: formRenderProps.valueGetter("address"),
        email: formRenderProps.valueGetter("email"),
        phone: formRenderProps.valueGetter("phone"),
        website: formRenderProps.valueGetter("website"),
    };

    const files = formRenderProps.valueGetter("files");

    //? If file present assign it to the business info obj
    if (files?.length > 0) {
        const file = files[0];
        //? There is a possibility that there is no URI if the file has not yet been uploaded
        file.uri = file.uri ?? URL.createObjectURL(file.getRawFile());

        businessInformation.file = file;
    }

    const onClose = () => {
        setPreviewHeadVisible(false);
    };

    return (
        <Dialog
            title={"Business Header Preview"}
            onClose={() => onClose()}
            height={250}
            width={625}
        >
            <BusinessInformationDisplay formData={businessInformation} />
        </Dialog>
    );
};

export default BusinessInformationPreview;
