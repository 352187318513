import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import { FormInput } from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import {
    createTaskCategory,
    getTaskCategory,
    updateTaskCategory,
} from "../../../../../../services/Deprecated/calendar/eventTaskCategory";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";

export const TaskCategoryModal = (props) => {
    const { id, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getTaskCategory(id).then((res) => {
                setInitialFormData(res.data);
            });
        } else {
            setInitialFormData({});
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const submitData = id ? updateTaskCategory : createTaskCategory;

        submitData(dataItem).then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={id ? "Edit Category" : "Create Category"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div style={{ width: "100%" }}>
                                <Field
                                    name={"name"}
                                    component={FormInput}
                                    label={"Name"}
                                    validator={requiredValidator}
                                />
                                <br />
                                <span className={"JustifyLeftAlignLeft"}>
                                    <Field
                                        name={"isAutoAssigned"}
                                        component={Checkbox}
                                        defaultChecked={false}
                                        label={"Is Auto Assigned to Event Type"}
                                    />
                                </span>
                                <Hint className={"AlignTextLeft"}>
                                    Hint: If checked, will auto assign to the
                                    Event Type it is added to.
                                </Hint>
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!id}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
