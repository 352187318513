import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class EmployeeTimeClockService {
    /**
     * @function getAll
     * @description
     * Gets all time clock entries for a given employee within a date
     * @param {object} data.employeeId - The employee ID
     * @param {object} [data.startDate] - The start date for the time clock entries
     * @param {object} [data.endDate] - The end date for the time clock entries
     * @param {object} [data.kendoFilter] - The filter object to apply to the data
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getAll(data) {
        let queryParams = new URLSearchParams({
            employeeId: data.employeeId,
        });

        if (data.startDate) {
            queryParams.append("startDate", data.startDate);
        }

        if (data.endDate) {
            queryParams.append("endDate", data.endDate);
        }

        let uri = `/HumanResources/Employee/TimeClockEntry/GetAll?${queryParams}`;

        if (data.kendoFilter) {
            uri += `&filter=${data.kendoFilter}`;
        }

        const res = await axios.get(uri + "&");
        return formatServiceResponse(res);
    }

    /**
     * @function entryExistsBetweenDateTimes
     * @description
     * Checks if a time clock entry exists for a given employee between two date times
     * @param {string} data.employeeId - The employee ID
     * @param {string} data.start - The start date time
     * @param {string} data.end - The end date time
     * @param {Array|string} [data.voidIds] - The voided time clock entry IDs
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async entryExistsBetweenDateTimes(data) {
        let queryParams = new URLSearchParams({
            employeeId: data.employeeId,
            start: data.start,
            end: data.end,
        });

        if (data.voidIds) {
            queryParams.append("voidIds", data.voidIds);
        }

        const res = await axios.get(
            `/HumanResources/TimeClockEntry/EntryExistsBetweenDateTimes?${queryParams}`
        );
        return formatServiceResponse(res);
    }
}
