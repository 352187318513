import React, { useContext, useRef } from "react";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum";
import {
    deleteTaskCategory,
    removeTaskFromTaskCategory,
} from "../../../../../../services/Deprecated/calendar/eventTaskCategory";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";

export const TaskAndCategoryActionCell = (props) => {
    const { dataItem, parentCategory, modals } = props;
    const isCategory = useRef(dataItem.tasks !== undefined);
    const { triggerReload } = useContext(ReloadDataContext);

    const onDelete = () => {
        if (isCategory.current) {
            deleteTaskCategory(dataItem.id).then(() => {
                triggerReload();
            });
        } else {
            removeTaskFromTaskCategory(parentCategory.id, dataItem.id).then(
                () => {
                    triggerReload();
                }
            );
        }
    };

    const grabDeletePermissions = () => {
        if (isCategory.current) {
            return [PermissionsEnum.DeleteTaskCategory];
        } else {
            return [PermissionsEnum.DeleteTask];
        }
    };

    const grabAdditionalActions = () => {
        if (isCategory.current) {
            return [
                {
                    buttonText: "Create Task",
                    onClick: () =>
                        modals.task.open(null, { taskCategory: dataItem }),
                    permissions: [PermissionsEnum.CreateTask],
                },
                {
                    buttonText: "Add Task",
                    onClick: () =>
                        modals.addTask.open(null, { taskCategory: dataItem }),
                    permissions: [PermissionsEnum.UpdateTask],
                },
            ];
        } else {
            return [
                {
                    buttonText: "Add Skill to Employee",
                    onClick: () => modals.employeeSkill.open(dataItem.id),
                    permissions: [PermissionsEnum.UpdateTask],
                },
            ];
        }
    };

    return (
        <>
            <ActionCell
                // Basically allow removal of Tasks regardless, and delete of categories only if they can be deleted
                onDelete={
                    dataItem.tasks?.length === 0 || !isCategory.current
                        ? () => onDelete()
                        : undefined
                }
                deleteBtnText={dataItem.tasks ? "Delete" : "Remove"}
                onEdit={
                    isCategory.current
                        ? () => modals.taskCategory.open(dataItem.id)
                        : undefined
                }
                permissions={{
                    edit: [PermissionsEnum.UpdateTaskCategory],
                    delete: grabDeletePermissions(),
                }}
                additionalActions={grabAdditionalActions()}
            />
        </>
    );
};
