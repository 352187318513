import React, { createContext, useState } from "react";

export const EventTypeContext = createContext();

export const EventTypeProvider = ({ children }) => {
    const [eventType, setEventType] = useState(null);
    const [eventTypeId, setEventTypeId] = useState(null);

    return (
        <EventTypeContext.Provider
            value={{ eventType, setEventType, eventTypeId, setEventTypeId }}
        >
            {children}
        </EventTypeContext.Provider>
    );
};

export default EventTypeProvider;
