import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { getInitialDataQueryState } from "../../../utilities/kendoHelper";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

export const getAllVendors = async () => {
    try {
        const response = await axios.get(`/Inventory/Vendor/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getVendor = async (VendorId) => {
    try {
        const response = await axios.get(
            `/Inventory/Vendor/Get?id=${VendorId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const searchVendor = async (vendorData) => {
    try {
        const response = await axios.get(
            `/Inventory/Vendor/Search?q=${vendorData}`,
            vendorData
        );
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const getAllVendorItemsByVendor = async (vendorId) => {
    try {
        const response = await axios.get(
            `/Inventory/Vendor/Item/GetAll?vendorId=${vendorId}&filter=(isActive~eq~'true')&`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getVendorItem = async (id) => {
    const res = await axios.get(`/Inventory/Vendor/Item/Get?id=${id}`);
    return formatServiceResponse(res);
};

export const getVendorItemsByItemId = async (itemId) => {
    const kendoRequest = getInitialDataQueryState();
    kendoRequest.filter = {
        logic: "and",
        filters: [
            {
                field: "itemId",
                operator: "eq",
                value: itemId,
            },
        ],
    };

    try {
        const queryParams = toDataSourceRequestString(kendoRequest);
        const response = await axios.get(
            `/Inventory/Vendor/Item/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getVendorsByVendorIds = async (vendorIds) => {
    if (!vendorIds || !vendorIds.length) {
        return formatServiceResponse({
            status: 200,
            data: { data: [] },
        });
    }
    const kendoRequest = getInitialDataQueryState();
    kendoRequest.sort = [
        {
            field: "name",
            dir: "asc",
        },
    ];
    kendoRequest.filter = {
        logic: "or",
        filters: [
            ...(vendorIds ?? []).map((id) => ({
                field: "id",
                operator: "eq",
                value: id,
            })),
        ],
    };
    const queryParams = toDataSourceRequestString(kendoRequest);
    try {
        const response = await axios.get(
            `/Inventory/Vendor/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteVendor = async (VendorId) => {
    try {
        return await axios.delete(`/Inventory/Vendor/Delete?id=${VendorId}`);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updateVendor = async (vendorEditObject) => {
    try {
        const response = await axios.put(
            "/Inventory/Vendor/Update",
            vendorEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const createVendor = async (vendorCreationObject) => {
    try {
        const response = await axios.post(
            "/Inventory/Vendor/Create",
            vendorCreationObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const createVendorItem = async (vendorItemObject) => {
    try {
        const response = await axios.post(
            "/Inventory/Vendor/Item/Create",
            vendorItemObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateVendorItem = async (data) => {
    try {
        const response = await axios.put(`/Inventory/Vendor/Item/Update`, data);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteVendorItem = async (vendorId, itemId) => {
    try {
        return await axios.delete(
            `/Inventory/Vendor/Item/Delete?vendorId=${vendorId}&itemId=${itemId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const vendorItemBulkPriceChange = async (data) => {
    const res = await axios.put(`/Inventory/Vendor/Item/BulkPriceChange`, data);
    return formatServiceResponse(res);
};
