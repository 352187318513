import React from "react";

export const SignatureSection = () => {
    return (
        <span className={"JustifyLeftAlignLeft"} style={{ gap: 100 }}>
            <span className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
                <strong>Lead Installer Signature:</strong>
                <span
                    className={"JustifyLeftAlignLeft SmallGap"}
                    style={{ width: "200px" }}
                >
                    <strong>X</strong>
                    <hr className={"Separator"} style={{ marginTop: "15px" }} />
                </span>
            </span>
            <span className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
                <strong>Helper Installer Signature:</strong>
                <span
                    className={"JustifyLeftAlignLeft SmallGap"}
                    style={{ width: "200px" }}
                >
                    <strong>X</strong>
                    <hr className={"Separator"} style={{ marginTop: "15px" }} />
                </span>
            </span>
        </span>
    );
};
