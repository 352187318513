import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class DetailItemService {
    /**
     * Retrieves labor item by provided detailItemId.
     * @function get
     * @async
     * @param {string} detailItemId
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async get(detailItemId) {
        try {
            const response = await axios.get(
                `/Inventory/DetailItem/Get?id=${detailItemId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Creates a new labor item.
     * @function create
     * @async
     * @param {Object} detailItemData
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async create(detailItemData) {
        try {
            const response = await axios.post(
                "/Inventory/DetailItem/Create",
                detailItemData
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Updates an existing labor item.
     * @function update
     * @async
     * @param {Object} detailItemData
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async update(detailItemData) {
        try {
            const response = await axios.put(
                "/Inventory/DetailItem/Update",
                detailItemData
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    static async updateInLineGrid(item) {
        const res = await axios.put(
            `/Inventory/DetailItem/UpdateInLineGrid`,
            item
        );
        return formatServiceResponse(res);
    }

    /**
     * Deletes an existing labor item.
     * @function delete
     * @async
     * @param {string} detailItemId
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async delete(detailItemId) {
        try {
            const response = await axios.delete(
                `/Inventory/DetailItem/Delete?id=${detailItemId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
