import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ItemDetails from "../DetailItems/Components/ItemDetails";

const ItemTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
        >
            <TabStripTab title="Item">
                <ItemDetails />
            </TabStripTab>
        </TabStrip>
    );
};

export default ItemTabs;
