import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import clone from "just-clone";
import { Button } from "@progress/kendo-react-buttons";
import "../BillPayment.module.scss";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { VendorBillPaymentContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorBillPaymentProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { parseDateLocal } from "../../../../resources/Deprecated/stringFormatter";
import VendorBillPaymentService from "../../../../services/Deprecated/inventory/vendorBillPaymentService";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import {
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const BillPaymentModal = () => {
    const { modalOperation, selectedPayment, closeModal, selectedDocument } =
        useContext(VendorBillPaymentContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormValues, setInitialFormValues] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        //? This means the Payment has yet to load in, so we wait
        if (
            !modalOperation ||
            (modalOperation === "Update" && !selectedPayment)
        ) {
            return;
        }

        const recordClone = clone(selectedPayment) ?? {};

        if (recordClone.date) {
            recordClone.date = parseDateLocal(recordClone.date.toString());
        } else {
            recordClone.date = new Date();
        }

        if (modalOperation === "Create")
            recordClone.total = { currency: "USD" };

        setInitialFormValues(recordClone);
    }, [selectedPayment, modalOperation]);

    /**
     * @function onCancel
     * @description Closes the modal
     * @returns {void}
     */
    const onCancel = () => {
        setInitialFormValues(undefined);
        closeModal();
    };

    /**
     * @function handleCreateVendorBillPayment
     * @description Creates the VendorBillPayment by submitting it to the API
     * @async
     * @returns {void}
     */
    const handleCreateVendorBillPayment = async (dataItem) => {
        const response = await VendorBillPaymentService.createVendorBillPayment(
            dataItem
        );

        if (response.success) {
            setLoaderVisible(false);
            triggerReload();
            onCancel();
        }
    };

    /**
     * @function handleUpdateVendorBillPayment
     * @description Updates the VendorBillPayment by submitting it to the API
     * @async
     * @returns {void}
     */
    const handleUpdateVendorBillPayment = async (dataItem) => {
        const serviceResponse =
            await VendorBillPaymentService.updateVendorBillPayment(dataItem);

        if (serviceResponse) {
            setLoaderVisible(false);
            triggerReload();
            onCancel();
        }
    };

    /**
     * @function onSubmit
     * @description Submits the object to the API
     * @returns {void}
     */
    const onSubmit = async (dataItem) => {
        setLoaderVisible(true);

        if (!dataItem.isValid) {
            setLoaderVisible(false);
            return;
        }

        if (modalOperation === "Create") {
            dataItem.values.vendorDocumentId = selectedDocument.id;

            handleCreateVendorBillPayment(dataItem.values);
        } else {
            handleUpdateVendorBillPayment(dataItem.values);
        }
    };

    /**
     * Ensures the total does not exceed the current remainder on the selected document
     * @param value
     * @return {string|string}
     */
    const totalValidator = (value) => {
        if (!value) {
            return "Amount is a required field.";
        }

        if (value < 0) {
            return "Amount cannot be less than 0.01.";
        }

        if (value <= selectedDocument.totalDue) {
            return "";
        }

        return "Amount cannot be greater than the total remainder of the document.";
    };

    return (
        <div>
            {initialFormValues && modalOperation && (
                <Form
                    initialValues={initialFormValues}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={modalOperation + " " + "Bill Payment"}
                            onClose={() => onCancel()}
                            size={"small"}
                        >
                            <FormElement>
                                <Field
                                    name={"date"}
                                    component={DatePicker}
                                    label={"Date:"}
                                    validator={requiredValidator}
                                />
                                {modalOperation === "Create" && (
                                    <Field
                                        name={"total.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Amount:"}
                                        validator={totalValidator}
                                    />
                                )}
                                <Field
                                    name={"type"}
                                    component={FormInput}
                                    label={"Type:"}
                                    validator={requiredValidator}
                                />
                                <div style={{ marginTop: 0.5 }}>
                                    <div style={{ textAlign: "left" }}>
                                        <h3
                                            style={{
                                                margin: "0",
                                                marginTop: "8px",
                                                marginBottom: "8px",
                                                borderBottom: "1px solid #ccc",
                                                paddingBottom: "5px",
                                                width: "55%",
                                            }}
                                        >
                                            Details:
                                        </h3>
                                    </div>
                                    <Field
                                        name={"details"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        label={"Details:"}
                                    />
                                </div>
                                <div className={"ActionButtons"}>
                                    {!loaderVisible ? (
                                        <>
                                            <Button
                                                themeColor={
                                                    modalOperation === "Create"
                                                        ? "success"
                                                        : "info"
                                                }
                                                fillMode={"solid"}
                                                rounded={"medium"}
                                                icon={`k-icon ${
                                                    modalOperation === "Create"
                                                        ? "k-i-plus"
                                                        : "k-i-save"
                                                }`}
                                                type={"submit"}
                                                disabled={
                                                    !formRenderProps.allowSubmit
                                                }
                                            >
                                                {modalOperation}
                                            </Button>
                                            <div>
                                                <Button
                                                    themeColor={"primary"}
                                                    fillMode={"solid"}
                                                    rounded={"medium"}
                                                    onClick={onCancel}
                                                    style={{
                                                        padding: "6px",
                                                        paddingRight: "12px",
                                                        paddingLeft: "12px",
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <CenterLoader />
                                    )}
                                </div>
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default BillPaymentModal;
