import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class VendorBillPaymentService {
    /**
     * @function getAllVendorBillPaymentLineItems
     * @description Function to get all VendorBillPaymentLineItems for VendorBillPayment
     * @async
     * @param {string} documentId
     * @returns {Object} response.data
     */
    static async getAllVendorBillPaymentsForDocument(documentId) {
        const response = await axios.get(
            `/Inventory/Vendor/Payment/GetAll?vendorDocumentId=${documentId}`
        );
        return response.data;
    }

    /**
     * @function updateVendorBillPayment
     * @description Function to update VendorBillPayment
     * @async
     * @param {Object} vendorBillPayment
     * @returns {Promise<ServiceResponse>} response.data
     */
    static async updateVendorBillPayment(vendorBillPayment) {
        try {
            const response = await axios.put(
                "/Inventory/Vendor/Payment/Update",
                vendorBillPayment
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * @function createVendorBillPayment
     * @description Function to create VendorBillPayment
     * @async
     * @param {Object} vendorBillPayment
     * @returns {Promise<ServiceResponse>} response.data
     */
    static async createVendorBillPayment(vendorBillPayment) {
        try {
            const response = await axios.post(
                "/Inventory/Vendor/Payment/Create",
                vendorBillPayment
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * @function deleteVendorBillPayment
     * @description Function to delete a VendorBillPayment
     * @async
     * @param {string} vendorBillPaymentId
     * @returns {Promise<ServiceResponse>}
     */
    static async deleteVendorBillPayment(vendorBillPaymentId) {
        try {
            const response = await axios.delete(
                `/Inventory/Vendor/Payment/Delete?id=${vendorBillPaymentId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            formatServiceResponse(error);
        }
    }
}
