import React from "react";
import styles from "../Scheduler.module.scss";
import dayjs from "dayjs";
import LinkTag from "../../../../components/LinkTag.jsx";
import { EventModal } from "../../CalendarEvents/Components/EventModal/EventModal.jsx";
import { useModal } from "../../../../hooks/useModal.js";
import { AvailabilityModal } from "./AvailabilityModal.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";

export const SchedulerColumnHeader = ({ date }) => {
    const eventModal = useModal();
    const availabilityModal = useModal();

    const onAddEventClick = () => {
        eventModal.open(undefined, {
            eventDate: date,
        });
    };

    const onAvailabilityClick = () => {
        availabilityModal.open(undefined, {
            eventDate: date,
        });
    };

    return (
        <div className={styles.columnHeader}>
            <EventModal {...eventModal} />
            <AvailabilityModal {...availabilityModal} />
            <div className={styles.columnHeaderDate}>
                {dayjs(date).format("dddd MM/DD")}
            </div>
            <div className={styles.columnHeaderAction}>
                <LinkTag
                    onClick={onAddEventClick}
                    text={"Add Event"}
                    permissions={[PermissionsEnum.CreateCalendarEvent]}
                />
            </div>
            <div className={styles.columnHeaderAction}>
                <LinkTag
                    onClick={onAvailabilityClick}
                    text={"Availability"}
                    permissions={[PermissionsEnum.ViewEmployee]}
                />
            </div>
        </div>
    );
};
