import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import LeadDashboardPage from "../LeadDashboardPage";
import DeadLeadDashboardPage from "../DeadLeadDashboardPage";
import HeldLeadDashboardPage from "../HeldLeadDashboardPage";

const LeadDashboardTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)}>
            <TabStripTab title="Active">
                <LeadDashboardPage />
            </TabStripTab>
            <TabStripTab title="Dead">
                <DeadLeadDashboardPage />
            </TabStripTab>
            <TabStripTab title="Held">
                <HeldLeadDashboardPage />
            </TabStripTab>
        </TabStrip>
    );
};

export default LeadDashboardTabs;
