//?
//? This file is used to convert currency values to a readable format.
//?

/**
 * @function formatUSDCurrency
 * @description This function is used to format a number to USD currency format.
 * @param {string} number
 * @returns {string} formatted number
 */
export const formatUSDCurrency = (number) => {
    const numberToFormat = parseFloat(number);
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings/16233919#16233919
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(numberToFormat);
};

export const roundToTwoDecimals = (number) => {
    return Math.round(number * 100) / 100;
};
