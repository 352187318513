import React from "react";
import { Loader } from "@progress/kendo-react-indicators";

export const CenterLoader = (props) => {
    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Loader type={"infinite-spinner"} {...props} />
        </div>
    );
};
