import * as React from "react";
import { useContext, useEffect, useState } from "react";
import "../../../styles/CommonPositionalStyles.scss";
import ResponsiveDialog from "../../../components/Deprecated/DialogWrapper";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
    CustomCell,
    CustomDateCell,
} from "../../../components/Deprecated/CustomGridCells";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider";
import DashboardService from "../../../services/Deprecated/reporting/DashboardService";

const TrafficReportDetails = (props) => {
    const { close, record } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (!record || !record.dateRange || !record.reportType) {
            return;
        }

        DashboardService.getTrafficReportCustomers(record).then((res) => {
            if (!res) return;

            setData(res.data);
        });
    }, [record]);

    return (
        <ResponsiveDialog
            title={" "}
            onClose={() => close()}
            size={"large"}
            className={"TrafficReportDetails"}
        >
            <Grid data={data}>
                <GridColumn
                    field="combinedName"
                    title="Customer"
                    className={"text-overflow"}
                    cell={(props) => (
                        <CustomCell>
                            <a
                                onClick={() =>
                                    handleRouteChange({
                                        uri: `/customer/${props.dataItem.id}`,
                                        breadcrumbInfo: [
                                            {
                                                id: props.dataItem.id,
                                                text: props.dataItem
                                                    .combinedName,
                                            },
                                        ],
                                    })
                                }
                                style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                            >
                                {props.dataItem.combinedName}
                            </a>
                        </CustomCell>
                    )}
                />
                <GridColumn
                    field="address.fullAddress"
                    title="Address"
                    className={"text-overflow"}
                />
                <GridColumn
                    field="created"
                    title="Creation Date"
                    width={125}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.created}
                        />
                    )}
                />
            </Grid>
        </ResponsiveDialog>
    );
};

export default TrafficReportDetails;
