import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../ReloadDataProvider";
import dayjs from "dayjs";
import { useToggle } from "../../../hooks/Deprecated/useToggle.js";
import {
    endOfNextWeek,
    startOfWeek,
} from "../../../resources/Deprecated/dateHelper.js";
import { getAllCustomers } from "../../../services/Deprecated/customer/customerServices.js";
import {
    getAllCustomerEventTypes,
    getAllEvents,
} from "../../../services/Deprecated/customer/eventServices.js";

export const SchedulerContext = createContext(null);

const SchedulerProvider = ({ children }) => {
    const {
        setTrue: setLoadingEventsTrue,
        setFalse: setLoadingEventsFalse,
        bool: eventsLoading,
    } = useToggle(true);
    const [relationships, setRelationships] = useState({});
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(undefined);
    const [dateRange, setDateRange] = useState(undefined);
    const [calendarType, setCalendarType] = useState("week");
    const reloadDataContext = useContext(ReloadDataContext);

    useEffect(() => {
        if (!currentDate) return;

        setDateRange({
            start: startOfWeek(currentDate),
            end: endOfNextWeek(currentDate),
        });
    }, [currentDate]);

    useEffect(() => {
        getAllCustomers().then((res) => {
            setRelationships({
                customers: res.data,
            });
        });
    }, []);

    useEffect(() => {
        if (!dateRange) return;

        setLoadingEventsTrue();

        // This filters out all EventTypes that currently do not have Events.
        // This is so the scheduler does not show unneeded empty rows that just ends up
        // adding confusion to the end user.
        getAllEvents(dateRange).then((res) => {
            const events = res.data;

            getAllCustomerEventTypes().then((res) => {
                setRelationships({
                    ...relationships,
                    eventTypes: filterEventTypes(events, res.data),
                });

                setEvents(events);
                setLoadingEventsFalse();
            });
        });
    }, [dateRange, reloadDataContext.reloadData]);

    // This is done to ensure when switching pages we continue to filter out Events
    const filterEventTypes = (events, eventTypes) => {
        const startDate = dateRange.start;
        let endDate;

        // Scheduler always looks ahead by a week. So if we are in week view,
        // And went back a week, empty rows for EventTypes will be shown since it sees them in the next week.
        // This is why we want to either show the next week or the current week. (Based on the calendarType)
        if (calendarType === "week") {
            endDate = dayjs(startDate).add(7, "days").toDate();
        } else {
            endDate = dateRange.end;
        }

        return eventTypes.filter((et) => {
            const eventsInRange = events.filter((e) => {
                const eventDate = dayjs(e.eventDate).toDate();
                return eventDate >= startDate && eventDate < endDate;
            });

            return eventsInRange.some((e) => e?.eventType?.id === et.id);
        });
    };

    const value = {
        relationships,
        events,
        setCurrentDate,
        eventsLoading,
        calendarType,
        setCalendarType,
    };

    return (
        <SchedulerContext.Provider value={value}>
            {children}
        </SchedulerContext.Provider>
    );
};

export default SchedulerProvider;
