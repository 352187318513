import React, { useContext } from "react";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import FormField from "../../../../components/Deprecated/StylingField";

const ContactDetails = () => {
    const { vendor } = useContext(VendorContext);

    return (
        <div className={"JustifyLeftAlignLeft LargeGap"}>
            <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                <FormField
                    label="Primary Contact"
                    value={vendor?.primaryContact?.combinedName}
                />
                {vendor?.primaryContact?.email && (
                    <FormField
                        label="Email"
                        value={vendor.primaryContact.email}
                    />
                )}
                {vendor?.primaryContact?.phone && (
                    <FormField
                        label="Phone"
                        value={`(${vendor.primaryContact.phone.phoneNumberType.name}) ${vendor.primaryContact.phone.phoneNumber}`}
                    />
                )}
            </span>
            <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                <FormField
                    label="Secondary Contact"
                    value={vendor?.secondaryContact?.combinedName}
                />
                {vendor?.secondaryContact?.email && (
                    <FormField
                        label="Email"
                        value={vendor.secondaryContact.email}
                    />
                )}
                {vendor?.secondaryContact?.phone && (
                    <FormField
                        label="Phone"
                        value={`(${vendor.secondaryContact.phone.phoneNumberType.name}) ${vendor.secondaryContact.phone.phoneNumber}`}
                    />
                )}
            </span>
        </div>
    );
};

export default ContactDetails;
