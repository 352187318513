import * as React from "react";
import { useContext } from "react";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider";
import { utcToLocalFormatter } from "../../../resources/Deprecated/stringFormatter";
import { TreeList } from "@progress/kendo-react-treelist";

const FullNameCell = (props) => {
    const { dataItem } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    return (
        <td>
            <span>
                <a
                    onClick={() =>
                        handleRouteChange({
                            uri: `/HumanResources/Employee/${dataItem.employeeId}`,
                            breadcrumbInfo: [
                                {
                                    id: dataItem.employeeId,
                                    text: dataItem.fullName,
                                },
                            ],
                        })
                    }
                    className={"link"}
                >
                    {dataItem.fullName}
                </a>
            </span>
        </td>
    );
};

const LastTaskEndTimeCell = (props) => {
    const { dataItem } = props;

    let lastTask = undefined;

    if (dataItem.timeClockRecords.length === 2) {
        lastTask = dataItem.timeClockRecords[1];
    }

    return lastTask ? (
        <td>
            <span>
                {utcToLocalFormatter(lastTask.endTime, "h:mm A - MM/DD/YYYY")}
            </span>
        </td>
    ) : (
        <td></td>
    );
};

const LastTaskCell = (props) => {
    const { dataItem } = props;

    let lastTimeClock = undefined;

    if (dataItem.timeClockRecords.length === 2) {
        lastTimeClock = dataItem.timeClockRecords[1];
    }

    return lastTimeClock?.laborTaskName ? (
        <td>
            <span>{lastTimeClock?.laborTaskName}</span>
        </td>
    ) : (
        <td>No Last Task</td>
    );
};

const columns = [
    {
        field: "fullName",
        title: "Employee",
        cell: FullNameCell,
    },
    {
        field: "lastTask",
        title: "Last Task",
        cell: LastTaskCell,
    },
    {
        field: "lastTaskEndTime",
        title: "Last Time Clock End Time",
        cell: LastTaskEndTimeCell,
    },
];

const ActiveTasksOffTaskTreeList = (data) => {
    return (
        <TreeList
            data={data.data}
            columns={columns}
            style={{ width: "100%" }}
        />
    );
};

export default ActiveTasksOffTaskTreeList;
