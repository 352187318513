import { useContext, useMemo } from "react";
import { AuthContext } from "../../providers/Deprecated/Authentication/User/AuthProvider.jsx";

/**
 * @function getUserPermissions
 * @description Function to get all permissions for the current user
 * @returns {Object} permissions
 */

export const getUserPermissions = () => {
    // Accessing the AuthContext using useContext hook
    const authContext = useContext(AuthContext);

    // Checking if AuthContext or user permissions are available
    if (!authContext?.user?.role?.permissions) {
        // Throw an error if user data or permissions are not available
        throw new Error("User data or permissions not available");
    }

    // Return the user permissions if available
    return useMemo(
        () => authContext?.user?.role?.permissions ?? [],
        [authContext.user.role.permissions]
    );
};

export const checkRequiredPermissions = (
    userPermissions,
    requiredPermissions
) => {
    return requiredPermissions.every((requiredPermissionId) => {
        return userPermissions.some((userPermission) => {
            return (
                userPermission.id.toUpperCase() ===
                requiredPermissionId.toUpperCase()
            );
        });
    });
};
