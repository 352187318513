import * as React from "react";
import { useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import "../../../../styles/CommonPositionalStyles.scss";
import "../../../../styles/CommonTextStyles.scss";
import { RadioButton } from "@progress/kendo-react-inputs";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import { searchCustomer } from "../../../../services/Deprecated/customer/customerServices";
import {
    FormInput,
    FormMaskedTextBox,
} from "../../../../components/Deprecated/FormComponents";
import {
    phoneValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import LinkTag from "../../../../components/LinkTag";

const PreCreateCustomerSearchModal = (props) => {
    const { close, setRecord } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [existingCustomers, setExistingCustomers] = useState([]);
    const [customerType, setCustomerType] = useState(
        CustomerTypeEnum.Enum.Individual
    );
    const [formValues, setFormValues] = useState({});

    const onSubmit = (props) => {
        setLoaderVisible(true);
        setFormValues(props);
        // Ensure we are only sending the correct data to the API
        // Individual Customers have a name2, Business Customers have a name1
        const formattedData = {
            phone: props.phoneOne.phoneNumber,
        };

        if (customerType === CustomerTypeEnum.Enum.Individual) {
            formattedData.name = props.name2;
        } else {
            formattedData.name = props.name1;
        }

        searchCustomer(
            `${formattedData.phone} ${formattedData.name}`,
            customerType
        ).then((res) => {
            if (res.data.length > 0) {
                setLoaderVisible(false);
                setExistingCustomers(res.data);
                return;
            }

            setLoaderVisible(false);
            onContinue(props);
        });
    };

    const onContinue = (props) => {
        if (customerType === CustomerTypeEnum.Enum.Individual) {
            setRecord({
                name2: props.name2,
                primaryContact: { name2: props.name2 },
                phoneOne: props.phoneOne,
                type: CustomerTypeEnum.Enum.Individual,
            });
        }

        if (customerType === CustomerTypeEnum.Enum.Business) {
            setRecord({
                name1: props.name1,
                phoneOne: props.phoneOne,
                type: CustomerTypeEnum.Enum.Business,
            });
        }
        resetStateToDefault();
    };

    const onClose = () => {
        resetStateToDefault();
        close();
    };

    const onCustomerTypeChange = (event) => setCustomerType(event.value);

    const resetStateToDefault = () => {
        setCustomerType(CustomerTypeEnum.Enum.Individual);
        setExistingCustomers([]);
        setFormValues({});
        setLoaderVisible(false);
    };

    return (
        <ResponsiveDialog
            title={"Search for existing Customer"}
            onClose={onClose}
            size={"small"}
        >
            <Form
                onSubmit={onSubmit}
                render={(formRenderProps) => (
                    <FormElement>
                        <h4 className={"AlignTextLeft"}>Customer Type:</h4>
                        <div className={"JustifyLeftAlignCenter"}>
                            <RadioButton
                                name="Individual"
                                value={CustomerTypeEnum.Enum.Individual}
                                checked={
                                    customerType ===
                                    CustomerTypeEnum.Enum.Individual
                                }
                                label="Individual"
                                onChange={onCustomerTypeChange}
                            />
                            <span style={{ margin: 10 }} />
                            <RadioButton
                                name="Business"
                                value={CustomerTypeEnum.Enum.Business}
                                checked={
                                    customerType ===
                                    CustomerTypeEnum.Enum.Business
                                }
                                label="Business"
                                onChange={onCustomerTypeChange}
                            />
                        </div>
                        {customerType === CustomerTypeEnum.Enum.Individual && (
                            <Field
                                name={"name2"}
                                component={FormInput}
                                label={"Last Name:"}
                                validator={requiredValidator}
                            />
                        )}
                        {customerType === CustomerTypeEnum.Enum.Business && (
                            <Field
                                name={"name1"}
                                component={FormInput}
                                label={"Business Name:"}
                                validator={requiredValidator}
                            />
                        )}
                        <Field
                            name={"phoneOne.phoneNumber"}
                            component={FormMaskedTextBox}
                            label={"Phone Number:"}
                            mask="000-000-0000"
                            validator={phoneValidator}
                        />
                        <FormButtons
                            actionOnCancel={onClose}
                            allowSubmit={formRenderProps.allowSubmit}
                            buttonText={"Search"}
                            theme={"primary"}
                            loaderVisible={loaderVisible}
                        />
                        {existingCustomers.length > 0 && (
                            <>
                                <hr className={"Separator"} />
                                <span
                                    className={
                                        "JustifyCenterAlignLeft FlexColumn"
                                    }
                                >
                                    <p>
                                        You can select a Customer below or
                                        continue to{" "}
                                        <LinkTag
                                            onClick={() =>
                                                onContinue(formValues)
                                            }
                                            text={"create the new Customer"}
                                        />
                                    </p>
                                    {existingCustomers.map((customer, key) => (
                                        <li key={key}>
                                            <LinkTag
                                                breadCrumbInfo={{
                                                    uri: `/Customer/${customer.id}/`,
                                                    breadcrumbInfo: [
                                                        {
                                                            id: customer.id,
                                                            text: customer.combinedName,
                                                        },
                                                    ],
                                                }}
                                                text={`${customer.combinedName} ${customer?.address?.fullAddress}`}
                                            />
                                        </li>
                                    ))}
                                </span>
                            </>
                        )}
                    </FormElement>
                )}
            />
        </ResponsiveDialog>
    );
};

export default PreCreateCustomerSearchModal;
