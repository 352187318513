import React from "react";
import { ADPPayrollGrid } from "./PayrollGrids/ADPPayrollGrid";

const Grids = {
    ADP: (data) => <ADPPayrollGrid data={data} />,
};

export const PayrollContainer = (props) => {
    const { data, gridType } = props;

    return <div>{Grids[gridType](data)}</div>;
};
