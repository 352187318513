import React from "react";

/**
 * @function GridCellWrapper
 * @description Wraps children in a table to allow placement of Cell Components to be placed outside a Grid without throwing React errors.
 * @param {Array|Object} props.children - The children to be wrapped in a table.
 * @param {String} props.className - The class name to be applied to the wrapping table row.
 * @return {Element}
 * @constructor
 */
export const GridCellWrapper = (props) => {
    const { children, className } = props;

    return (
        <table>
            <tbody>
                <tr className={className}>{children}</tr>
            </tbody>
        </table>
    );
};
