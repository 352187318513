import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";

import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { useModal } from "../../../../../hooks/useModal";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { UnitOfMeasureModal } from "./Components/UnitOfMeasureModal";
import { deleteUnitOfMeasure } from "../../../../../services/Deprecated/general/unitOfMeasureService";
import { DataGrid } from "../../../../../components/Grids/DataGrid";

const UnitOfMeasureDashboardPage = () => {
    const reloadDataContext = useContext(ReloadDataContext);
    const modal = useModal();

    return (
        <div>
            {!reloadDataContext.relationshipsLoading ? (
                <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
                    <UnitOfMeasureModal {...modal} />
                    <ActionButton
                        onClick={() => modal.open()}
                        buttonText={"Add UnitOfMeasure"}
                        icon={"k-icon k-i-plus"}
                        permissions={[PermissionsEnum.CreateUnitOfMeasure]}
                    />
                    <DataGrid
                        uri={"/General/UnitOfMeasure/GetAll?"}
                        style={{ maxWidth: "420px" }}
                    >
                        <GridColumn
                            field="name"
                            title="Name:"
                            width={"250px"}
                        />
                        <GridColumn
                            filterable={false}
                            sortable={false}
                            width={150}
                            cell={(props) => (
                                <ActionCell
                                    onDelete={() =>
                                        deleteUnitOfMeasure(
                                            props.dataItem.id
                                        ).then(() =>
                                            reloadDataContext.triggerReload()
                                        )
                                    }
                                    onEdit={() => modal.open(props.dataItem.id)}
                                    permissions={{
                                        edit: [
                                            PermissionsEnum.UpdateUnitOfMeasure,
                                        ],
                                        delete: [
                                            PermissionsEnum.DeleteUnitOfMeasure,
                                        ],
                                    }}
                                />
                            )}
                        />
                    </DataGrid>
                </span>
            ) : (
                <CenterLoader />
            )}
        </div>
    );
};
export default UnitOfMeasureDashboardPage;
