import React, { useEffect, useState } from "react";
import DebounceTextBox from "../../../../components/Deprecated/DebounceTextBox";
import { findEmployee } from "../../../../services/Deprecated/humanResources/employeeServices";
import { ConfirmationDialog } from "../../../../components/Dialogs/ConfirmationDialog";

export const TCEmployeeSearch = (props) => {
    const { setter } = props;
    const [payrollId, setPayrollId] = useState(undefined);
    const [internalEmployee, setInternalEmployee] = useState(undefined);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [employeeNotFoundMsg, setEmployeeNotFoundMsg] = useState(undefined);

    const onConfirm = () => {
        setConfirmVisible(false);
        setEmployeeNotFoundMsg(undefined);
        setPayrollId(undefined);
        setter(internalEmployee);
    };

    const onCancel = () => {
        setConfirmVisible(false);
        setEmployeeNotFoundMsg(undefined);
        setPayrollId(undefined);
        setInternalEmployee(undefined);
    };

    useEffect(() => {
        setEmployeeNotFoundMsg(undefined);

        if (!payrollId) {
            return;
        }

        findEmployee(payrollId).then((res) => {
            if (res.data) {
                setInternalEmployee(res.data);
                setConfirmVisible(true);
            } else {
                setInternalEmployee(undefined);
                setConfirmVisible(false);
                setEmployeeNotFoundMsg(
                    "Employee not found for query: " + payrollId
                );
            }
        });
    }, [payrollId]);

    return (
        <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <ConfirmationDialog
                visible={confirmVisible}
                actionOnConfirm={onConfirm}
                actionOnCancel={onCancel}
                confirmationText={`Are you ${internalEmployee?.fullName}?`}
            />
            <p className={"ZeroMargin"}>Enter PayrollId:</p>
            <DebounceTextBox setter={setPayrollId} timeout={750} />
            <small style={{ color: "red" }}>{employeeNotFoundMsg}</small>
        </span>
    );
};
