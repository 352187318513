import React, { createContext, useEffect, useState } from "react";
import BusinessInformationService from "../../../services/Deprecated/customer/businessInformationService.js";

export const BusinessInformationContext = createContext(null);

//? Default state of the selectedBusinessInfo
const defaultState = {
    id: undefined,
    businessInformation: undefined,
    operation: undefined,
};

const BusinessInformationProvider = ({ children }) => {
    const [selectedBusinessInfo, setSelectedBusinessInfo] =
        useState(defaultState);
    //? This data comes from GridState in the form of a grid export
    const [exportedBusinessInfos, setExportedBusinessInfos] = useState([]);

    useEffect(() => {
        //? If Id not present, we do not want to do service call
        if (!selectedBusinessInfo.id) {
            return;
        }

        const fetchBusinessInfo = async () =>
            BusinessInformationService.getBusinessInformation(
                selectedBusinessInfo.id
            );

        fetchBusinessInfo().then((res) =>
            setSelectedBusinessInfo({
                id: res.data.id,
                businessInformation: res,
                operation: "Edit",
            })
        );
    }, [selectedBusinessInfo.id]);

    /**
     * Reset SelectedBusinessInfo to its default state
     * @return void
     */
    const resetSelectedBusinessInfo = () => {
        setSelectedBusinessInfo(defaultState);
    };

    /**
     * Sets the SelectedBusinessInfo to its edit state
     * @param id
     * @return void
     */
    const setSelectedBusinessInfoToEdit = (id) => {
        setSelectedBusinessInfo({
            id: id,
            businessInformation: undefined,
            operation: "Edit",
        });
    };

    /**
     * Sets the SelectedBusinessInfo to its create state
     * @return void
     */
    const setSelectedBusinessInfoToCreate = () => {
        setSelectedBusinessInfo({
            id: undefined,
            businessInformation: undefined,
            operation: "Create",
        });
    };

    const value = {
        selectedBusinessInfo,
        exportedBusinessInfos,
        setExportedBusinessInfos,
        resetSelectedBusinessInfo,
        setSelectedBusinessInfoToEdit,
        setSelectedBusinessInfoToCreate,
    };

    return (
        <BusinessInformationContext.Provider value={value}>
            {children}
        </BusinessInformationContext.Provider>
    );
};

export default BusinessInformationProvider;
