import React, { useState } from "react";
import { Field } from "@progress/kendo-react-form";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents";
import { JobDocumentTypeEnum } from "../../../../../../resources/Enums/JobDocumentTypeEnum";

/**
 * @function EventModalStepTwo
 * @description The second step of the event modal
 * @param {Object} props.formRenderProps - The FormRenderProps object
 * @param {object} props.initialValues - The initial values of the event
 * @return {Element}
 * @constructor
 */
export const EventModalStepTwo = (props) => {
    const { formRenderProps, initialValues } = props;
    const [refreshToken, setRefreshToken] = useState({
        customer: Math.random(),
        job: Math.random(),
        jobDocument: Math.random(),
    });

    const onCustomerChange = (e) => {
        // If a Customer is removed, refresh its DropDown
        if (!e.value) {
            setRefreshToken({
                customer: Math.random(),
            });
        }

        // Ensure fields are refreshed due to Customer Change
        formRenderProps.onChange("job", { value: null });
        formRenderProps.onChange("jobDocument", { value: null });
        setRefreshToken({
            job: Math.random(),
            jobDocument: Math.random(),
        });
    };

    const onJobChange = () => {
        // Ensure fields are refreshed due to Job Change
        formRenderProps.onChange("jobDocument", { value: null });
        setRefreshToken({
            jobDocument: Math.random(),
        });
    };

    return (
        <>
            {!initialValues.customer && (
                <Field
                    key={refreshToken.customer}
                    url={"/Customer/Customer/GetAll?"}
                    name={"customer"}
                    textField={"combinedName"}
                    searchFields={["name1", "name2"]}
                    component={FormRemoteDropDown}
                    label={"Customer:"}
                    onChange={onCustomerChange}
                    initialSort={[
                        {
                            field: "name1",
                            dir: "asc",
                        },
                    ]}
                />
            )}
            {!initialValues.job && (
                <Field
                    key={refreshToken.job}
                    url={"/Customer/Job/GetAll?"}
                    name={"job"}
                    component={FormRemoteDropDown}
                    disabled={!formRenderProps?.valueGetter("customer")}
                    label={"Job:"}
                    onChange={onJobChange}
                    initialFilters={[
                        {
                            field: "IsMarkedDead",
                            operator: "eq",
                            value: false,
                        },
                        {
                            field: "IsArchived",
                            operator: "eq",
                            value: false,
                        },
                        {
                            field: "CustomerId",
                            operator: "eq",
                            value: formRenderProps?.valueGetter("customer")?.id,
                        },
                    ]}
                />
            )}
            <Field
                key={refreshToken.jobDocument}
                url={"/Customer/Job/Document/GetAll?"}
                name={"jobDocument"}
                component={FormRemoteDropDown}
                disabled={!formRenderProps?.valueGetter("job")}
                label={"Estimate:"}
                dataMutator={(data) =>
                    data.map((item) => {
                        item.combinedName = `#${item.documentNumber} - ${item.name}`;
                        return item;
                    })
                }
                textField={"combinedName"}
                searchFields={["name", "documentNumber"]}
                initialFilters={[
                    {
                        field: "Type",
                        operator: "eq",
                        value: JobDocumentTypeEnum.Estimate,
                    },
                    {
                        field: "JobId",
                        operator: "eq",
                        value: formRenderProps?.valueGetter("job")?.id,
                    },
                ]}
                initialSort={[
                    {
                        field: "documentNumber",
                        dir: "asc",
                    },
                ]}
            />
        </>
    );
};
