import React, { useContext, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import JobTransactions from "./JobTransactions";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import JobDocumentProvider from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import VendorDocumentProvider from "../../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import JobTimeClockEntries from "../../Jobs/Components/JobTimeClockEntries";
import JobEvents from "../../Jobs/Components/JobEvents";
import JobCommunications from "../../Jobs/Components/JobCommunications";
import JobFiles from "../../Jobs/Components/JobFiles";
import JobAccounting from "../../Jobs/Components/JobAccounting";
import { ItemAllocationTabPage } from "./Tabs/ItemAllocation/ItemAllocationTabPage";

const JobDocumentTabs = () => {
    const { job } = useContext(JobContext);
    const [selected, setSelected] = useState(0);

    return (
        <JobDocumentProvider>
            <VendorDocumentProvider>
                <TabStrip
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    className="tabs"
                >
                    <TabStripTab title="Transactions">
                        <JobTransactions />
                    </TabStripTab>
                    <TabStripTab title="Logged Time">
                        <JobTimeClockEntries jobId={job.id} />
                    </TabStripTab>
                    <TabStripTab title="Job Dates">
                        <JobEvents />
                    </TabStripTab>
                    <TabStripTab title="Item Allocation">
                        <ItemAllocationTabPage />
                    </TabStripTab>
                    <TabStripTab title="Communications">
                        <JobCommunications />
                    </TabStripTab>
                    <TabStripTab title="Files">
                        <JobFiles jobId={job.id} />
                    </TabStripTab>
                    <TabStripTab title="Accounting">
                        <JobAccounting />
                    </TabStripTab>
                </TabStrip>
            </VendorDocumentProvider>
        </JobDocumentProvider>
    );
};

export default JobDocumentTabs;
