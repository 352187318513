import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ItemAllocationHelper } from "../Helpers/ItemAllocationHelper";
import { ReloadDataContext } from "../../../../../../../providers/ReloadDataProvider";
import InventoryService from "../../../../../../../services/Deprecated/inventory/InventoryService";
import ResponsiveDialog from "../../../../../../../components/Deprecated/DialogWrapper";
import {
    FormDropDown,
    FormNumericInput,
} from "../../../../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../../components/Buttons/FormButtons";
import StorageLocationService from "../../../../../../../services/StorageLocationService.js";

export const ItemAllocationModal = (props) => {
    const { close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [storageLocations, setStorageLocations] = useState([]);
    const [currentMaxQty, setCurrentMaxQty] = useState({});

    useEffect(() => {
        if (!record) return;

        const service = new StorageLocationService("Inventory/StorageLocation");
        service
            .getAllContainingItem(record.itemId, record.jobId)
            .then((response) => {
                let locations = response.data.filter((l) => {
                    if (record.isDeallocate) {
                        return l.allocatedInventories.length > 0;
                    }

                    return l.availableInventories.length > 0;
                });

                setStorageLocations(locations);
                if (locations.length !== 0) {
                    const storageLocation = locations[0];
                    const maxQuantity = calculateMaxQty(
                        storageLocation,
                        record.isDeallocate
                    );
                    setCurrentMaxQty(maxQuantity);
                    setInitialFormData({
                        ...record,
                        storageLocation: storageLocation,
                        quantity: maxQuantity,
                    });
                } else {
                    setInitialFormData(record);
                }
            });
    }, [record]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (e) => {
        if (!e.isValid) return;

        const dataItem = e.values;

        setLoaderVisible(true);

        const data = ItemAllocationHelper.formatDataForNetwork(dataItem);
        const request = record.isDeallocate
            ? InventoryService.deallocate(data)
            : InventoryService.allocate(data);

        request.then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    const calculateMaxQty = (storageLocation, isDeallocate) => {
        const qtyOfItems = isDeallocate
            ? storageLocation.allocatedInventories.length
            : storageLocation.availableInventories.length;

        if (qtyOfItems < record.remainingNeeded) {
            return qtyOfItems;
        }

        return record.remainingNeeded;
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={"Allocate"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <span style={{ width: "100%" }}>
                                <Field
                                    name={"storageLocation"}
                                    data={storageLocations}
                                    component={FormDropDown}
                                    label={"Select a Storage Location..."}
                                    validator={requiredValidator}
                                    style={{ marginBottom: "15px" }}
                                    onChange={(e) => {
                                        const storageLocation = e.value;
                                        const maxQuantity = calculateMaxQty(
                                            storageLocation,
                                            record.isDeallocate
                                        );
                                        setCurrentMaxQty(maxQuantity);
                                        formRenderProps.onChange("quantity", {
                                            value: maxQuantity,
                                        });
                                    }}
                                />
                                <Field
                                    name={"quantity"}
                                    component={FormNumericInput}
                                    label={`Amount to ${
                                        record.isDeallocate
                                            ? "Deallocate"
                                            : "Allocate"
                                    } from Stock:`}
                                    validator={requiredValidator}
                                    min={1}
                                    max={currentMaxQty}
                                />
                            </span>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                buttonText={
                                    record.isDeallocate
                                        ? "Deallocate"
                                        : "Allocate"
                                }
                                isCreate={true}
                                theme={"primary"}
                                icon={record.isDeallocate ? "minus" : "plus"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
