import React, { useCallback, useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Stepper } from "@progress/kendo-react-layout";
import ItemInformationForm from "./ItemInformationForm";
import MultiItemForm from "./MultiItemForm";
import clone from "just-clone";
import { ItemRelationshipsContext } from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { createItems } from "../../../../services/Deprecated/inventory/itemService";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const InitialStepState = [
    {
        label: "Base Information",
        isValid: undefined,
    },
    {
        label: "Multi Item",
        isValid: undefined,
    },
];

// This is the component that will be rendered when the user clicks the create button
const ItemCreationComponent = ({
    selectedItem,
    visible,
    close,
    id,
    record,
}) => {
    const rc = useContext(ItemRelationshipsContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const [formSize, setFormSize] = useState("large");
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [step, setStep] = useState(0);
    const [formState, setFormState] = useState({
        vendorId: id,
        vendor: record,
        isActive: true,
        categoryId: selectedItem.id,
        category: selectedItem,
    });
    const [steps, setSteps] = useState(InitialStepState);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    useEffect(() => {
        if (step === 0) {
            setFormSize("large");
        } else {
            setFormSize("extraLarge");
        }
    }, [step]);

    // Add CustomizationLists to the relationships, so we can gain access to it in the child component
    useEffect(() => {
        rc.relationships.customizationLists = selectedItem.customizationLists;
        rc.setRelationships(rc.relationships);

        setFormState({
            vendorId: id,
            vendor: record,
            isActive: true,
            categoryId: selectedItem.id,
            category: selectedItem,
        });
    }, [selectedItem]);

    /**
     Callback function triggered when a step is submitted. This function sets the steps and
     determines if a step is valid or not. It also will submit the item if the last step is valid.

     @param {Object} event - The event object containing information about the step submission.
     */
    const onStepSubmit = useCallback(
        async (event) => {
            const { isValid, values } = event;
            const buttonClicked =
                event?.event?.nativeEvent?.submitter?.innerText;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));

            setSteps(currentSteps);

            if (!isValid) return;

            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            if (isLastStep && buttonClicked === "Create") {
                await submitItem(values);
            }
        },
        [steps, isLastStep, step, lastStepIndex]
    );

    /**
     Function that will be called when the user clicks the previous button.

     @param {Object} event - The event object containing information about the prev step submission.
     */
    const onPrevClick = React.useCallback(() => {
        setStep(() => Math.max(step - 1, 0));
    }, [step, setStep]);

    const resetForm = () => {
        close();
        setFormState({});
        setStep(0);
        setSteps(InitialStepState);
    };

    /**
     * Function that will be called when the user submits the vendor form.
     * @Method
     * @param {{
     * itemType: Object,
     * orderingUom: Object,
     * retailUom: Object,
     * vendor: Object,
     * itemTypeId: string,
     * unitOfMeasureId: string,
     * retailUomId: string,
     * orderingUomId: string,
     * categoryId: string,
     * vendorId: string,
     * items: array
     * item: Object,
     * defaultLength: number,
     * defaultWidth: number,
     * isActive: boolean,
     * isStockOrSupplyItem: boolean
     * }} dataItem - Dropdown Data from API.
     */
    const submitItem = async (dataItem) => {
        toggleLoaderVisible();
        const formData = clone(dataItem);

        const items = formData.items.map((item) => {
            item.vendorId = formData?.vendor?.id;
            item.itemTypeId = formData?.itemType?.id;
            item.orderingUomId = formData?.orderingUom?.id;
            item.retailUomId = formData?.retailUom?.id;
            item.incomeAccountId = formData.incomeAccount?.id;
            item.expenseAccountId = formData.expenseAccount?.id;
            item.categoryId = selectedItem.id;
            item.sku = formData.manufacturerNumber;
            item.defaultLength = formData.defaultLength;
            item.defaultWidth = formData.defaultWidth;
            item.isActive = !!formData.isActive;
            item.isStockOrSupplyItem = !!formData.isStockOrSupplyItem;
            item.isDescriptionEditAllowed = !!formData.isDescriptionEditAllowed;
            item.retailPrice = {
                amount: item.retailPrice,
                currency: "USD",
            };
            item.vendorCost = {
                amount: item.vendorCost,
                currency: "USD",
            };
            item.freightCharge = {
                amount: item.freightCharge,
                currency: "USD",
            };

            return item;
        });

        const requestObject = { items };

        const serviceResponse = await createItems(requestObject);

        if (serviceResponse.success) {
            reloadDataContext.triggerReload();
            resetForm();
        }
        toggleLoaderVisible();
    };

    return (
        <div>
            {visible && (
                <span>
                    <Form
                        onSubmitClick={onStepSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Item"}
                                onClose={() => resetForm()}
                                size={formSize}
                            >
                                <Stepper value={step} items={steps} />
                                <FormElement>
                                    {step === 0 && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <ItemInformationForm
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                                itemCount={"multi"}
                                            />
                                        </div>
                                    )}
                                    {step === 1 && (
                                        <MultiItemForm
                                            formRenderProps={formRenderProps}
                                            categoryId={selectedItem.id}
                                        />
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            Step {step + 1} of 2
                                        </span>
                                        <FormButtons
                                            isCreate={true}
                                            allowSubmit={
                                                formRenderProps.allowSubmit
                                            }
                                            buttonText={"Create"}
                                            loaderVisible={loaderVisible}
                                            theme={"primary"}
                                            actionOnCancel={() => resetForm()}
                                            stepState={{
                                                isLastStep,
                                                currentStep: step,
                                                onPreviousStepSubmit:
                                                    onPrevClick,
                                            }}
                                        />
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};

export default ItemCreationComponent;
