import React, {useEffect, useState} from "react";
import {TimeCard} from "./TimeCard";
import PayrollService from "../../../../services/Deprecated/humanResources/PayrollService";
import dayjs from "dayjs";
import {ToggleButton} from "../../../../components/Buttons/ToggleButton/ToggleButton";
import {DropDownList} from "@progress/kendo-react-dropdowns";

/**
 * @function TimeCardExtension
 * @description A component that extends the TimeCard component to include additional functionality
 * @param {Object} props.initialFilter - The initial filter to apply to the TimeCard component
 * @param {Object} props.initialDateRange - The initial date range to apply to the TimeCard component
 * @param {string} props.employeeId - The employee id to use for the TimeCard component
 * @return {Element}
 * @constructor
 */
export const TimeCardExtension = (props) => {
    const { initialFilter, initialDateRange, employeeId } = props;
    const [payPeriods, setPayPeriods] = useState([]);
    const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [filter, setFilter] = useState(initialFilter);
    const [showBreaks, setShowBreaks] = useState(false);

    useEffect(() => {
        // If there is no date range, fetch the interval dates to fill it
        if (!initialDateRange) {
            fetchIntervalDates();
        }
    }, [initialDateRange]);

    const fetchIntervalDates = () => {
        PayrollService.getIntervalDates().then((res) => {
            const payPeriodsData = res.data.map((period) => ({
                ...period,
                rangeText: `${dayjs(period.start).format(
                    "MM/DD/YYYY"
                )} - ${dayjs(period.end).format("MM/DD/YYYY")}`,
            }));

            setPayPeriods(payPeriodsData);
            setSelectedPayPeriod(payPeriodsData[0]);
            setDateRange({
                start: dayjs(payPeriodsData[0].start).toDate(),
                end: dayjs(payPeriodsData[0].end).toDate(),
            });
        });
    };

    const onToggle = () => {
        fetchIntervalDates();

        setFilter(undefined);
        setShowBreaks(true);
    };

    const onToggleOff = () => {
        // If there is an initialDateRange, clear out the payPeriods and selectedPayPeriod
        if (initialDateRange) {
            setPayPeriods([]);
            setSelectedPayPeriod(null);
            setDateRange(initialDateRange);
        }

        setFilter(initialFilter);
        setShowBreaks(false);
    };

    const onPayPeriodChange = (e) => {
        setSelectedPayPeriod(e.value);
        setDateRange({
            start: dayjs(e.value.start).toDate(),
            end: dayjs(e.value.end).toDate(),
        });
    };

    return (
        <span
            className={"JustifyLeftAlignLeft FlexColumn"}
            style={{ gap: "20px" }}
        >
            <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
                <ToggleButton
                    label={"Show All TimeClockEntries?"}
                    onToggleOn={onToggle}
                    onToggleOff={onToggleOff}
                />
                {payPeriods.length !== 0 && (
                    <DropDownList
                        data={payPeriods}
                        textField="rangeText"
                        value={selectedPayPeriod}
                        onChange={onPayPeriodChange}
                        style={{
                            width: "250px",
                        }}
                    />
                )}
            </span>
            <TimeCard
                employeeId={employeeId}
                dateRange={dateRange}
                kendoFilter={filter}
                showBreaks={showBreaks}
            />
        </span>
    );
};
