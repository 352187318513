import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import styles from "./UserDetails.module.scss";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../../components/Grids/DataGrid";

const UserDashboardPage = () => {
    return (
        <div className={styles.UserDetailsContainer}>
            <DataGrid
                uri={"/Authentication/User/GetAll?"}
                initialSort={[
                    {
                        field: "firstName",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    width={250}
                    field="name"
                    title="Name:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Admin/Security/Users/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    width={350}
                    field="primaryEmail"
                    title="Email:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`mailto:${props.dataItem.email}`}
                            text={props.dataItem.email}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default UserDashboardPage;
