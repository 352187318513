import * as React from "react";
import { useState } from "react";
import "../AccountingModule.module.scss";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import IncomeStatementTreeList from "./Components/IncomeStatementTreeList";

const IncomeStatementPage = () => {
    const [selected, _setSelected] = useState(0);

    return (
        <TabStrip id={"IncomeStatement"} selected={selected}>
            <TabStripTab title="Income Statement">
                <IncomeStatementTreeList />
            </TabStripTab>
        </TabStrip>
    );
};

export default IncomeStatementPage;
