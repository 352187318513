import React, { useContext, useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import clone from "just-clone";
import { Checkbox } from "@progress/kendo-react-inputs";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import "../../../../../styles/CommonTextStyles.scss";
import "../../../../../styles/CommonPositionalStyles.scss";
import { ItemPackageAddItemModal } from "./ItemPackageAddItemModal";
import ItemPackageService from "../../../../../services/Deprecated/inventory/ItemPackageService";
import { useModal } from "../../../../../hooks/useModal";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import {
    FormInput,
    FormTextArea,
} from "../../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../../resources/Deprecated/formValidators";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { EditableNumericCell } from "../../../../../components/GridColumnComponents/EditableNumericCell";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import { FormButtons } from "../../../../../components/Buttons/FormButtons";
import ItemCategoryModal from "../../Components/ItemCategoryModal.jsx";

const DialogTitleEntity = "Package";

/**
 * @function ItemPackageModal
 * @description Modal for creating and updating packages
 * @param props.visible {boolean} - Determines if the modal is visible
 * @param props.close {function} - Function to close the modal
 * @param props.record {object} - The record of the package
 * @param props.id {string} - The id of the package
 * @param props.isCreate {boolean} - Determines if the item is being created
 * @param props.isUpdate {boolean} - Determines if the item is being updated
 * @return {React.JSX.Element}
 * @constructor
 */
export const ItemPackageModal = (props) => {
    const { visible, close, record, id, isCreate, isUpdate } = props;
    const [initFormValues, setInitFormValues] = useState(undefined);
    const lineItems = useRef([]);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [refreshGridKey, setRefreshGridKey] = useState(0);
    const { triggerReload } = useContext(ReloadDataContext);
    const itemModal = useModal();
    const categoryModal = useModal();

    // Handles initializing the form values
    useEffect(() => {
        if (visible === false) return;

        if (isUpdate) {
            ItemPackageService.get(id).then((response) => {
                setInitFormValues(response.data);
                lineItems.current = response.data.lineItems.map((item) => {
                    return {
                        ...item,
                        IN_LINE_EDIT: true,
                    };
                });
            });
        } else {
            setInitFormValues({
                ...record,
                isActive: true,
            });
        }
    }, [visible]);

    const onFormSubmit = (data) => {
        if (!data.isValid) return;

        const buttonClicked = data.event?.nativeEvent?.submitter?.innerText;
        const submitButtonClicked =
            buttonClicked === "Create" || buttonClicked === "Update";

        if (!submitButtonClicked) return;

        setLoaderVisible(true);
        const submissionData = clone(data.values);
        submissionData.itemCategoryId = submissionData.category.id;
        submissionData.lineItems = lineItems.current.map((item) => {
            return {
                id: item.id,
                quantity: item.quantity,
                itemId: item.itemId,
                detailItemId: item.detailItemId,
            };
        });
        delete submissionData.category;

        const service = isCreate
            ? ItemPackageService.create(submissionData)
            : ItemPackageService.update(submissionData);

        service
            .then((res) => {
                if (res.success) {
                    successSubmit();
                } else {
                    setLoaderVisible(false);
                }
            })
            .catch(() => setLoaderVisible(false));
    };

    const closeForm = () => {
        setInitFormValues(undefined);
        lineItems.current = [];
        close();
    };

    const successSubmit = () => {
        triggerReload();
        closeForm();
        setLoaderVisible(false);
    };

    const appendItemToPackage = (item) => {
        lineItems.current = [...lineItems.current, item];
        setRefreshGridKey(refreshGridKey + 1);
    };

    const removeItemFromPackage = (item) => {
        lineItems.current = lineItems.current.filter((i) => i !== item);
        setRefreshGridKey(refreshGridKey + 1);
    };

    const modifyQuantity = (item, quantity) => {
        lineItems.current = lineItems.current.map((i) => {
            if (i === item) {
                i.quantity = quantity;
                i.total.amount = i.retailPrice.amount * quantity;
            }
            return i;
        });
        setRefreshGridKey(refreshGridKey + 1);
    };

    return (
        visible &&
        initFormValues && (
            <Form
                initialValues={initFormValues}
                onSubmitClick={onFormSubmit}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={
                            isUpdate
                                ? `Edit ${DialogTitleEntity}`
                                : `Create ${DialogTitleEntity}`
                        }
                        onClose={closeForm}
                        size={"large"}
                    >
                        <ItemPackageAddItemModal
                            {...itemModal}
                            appendItemToPackage={appendItemToPackage}
                        />
                        <FormElement>
                            <Field
                                name={"name"}
                                component={FormInput}
                                label={"Name:"}
                                validator={nameValidator}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "end",
                                    gap: 5,
                                }}
                            >
                                <Field
                                    style={{ width: "325px" }}
                                    name={"category.name"}
                                    component={FormInput}
                                    label={"Category:"}
                                    readOnly={true}
                                />
                                <ItemCategoryModal
                                    {...categoryModal}
                                    item={initFormValues}
                                    formRenderProps={formRenderProps}
                                />
                                <ActionButton
                                    onClick={() => categoryModal.open()}
                                    buttonText={"Change"}
                                />
                            </div>
                            <h4
                                style={{
                                    margin: "0",
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    borderBottom: "1px solid #ccc",
                                    paddingBottom: "5px",
                                    width: "55%",
                                    textAlign: "left",
                                }}
                            >
                                SE/SO Description:
                            </h4>
                            <Field
                                name={"baseDescription"}
                                component={FormTextArea}
                                validator={requiredValidator}
                                autoSize={true}
                                rows={4}
                                label={"SE/SO Description:"}
                            />
                            <span
                                style={{ marginTop: 10 }}
                                className={
                                    "JustifyLeftAlignCenter FlexColumn LargeGap"
                                }
                            >
                                <Field
                                    name={"isActive"}
                                    component={Checkbox}
                                    label={"Is Active"}
                                    rounded={"large"}
                                    size={"medium"}
                                />
                                <Field
                                    name={"isDescriptionEditAllowed"}
                                    component={Checkbox}
                                    label={"Is Description Edit Allowed"}
                                    rounded={"large"}
                                    size={"medium"}
                                />
                            </span>
                            <hr
                                className={"Separator"}
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                            <span
                                className={"JustifyLeftAlignCenter"}
                                style={{
                                    marginBottom: 10,
                                }}
                            >
                                <ActionButton
                                    buttonText={"Add Item"}
                                    onClick={() =>
                                        itemModal.open(undefined, {
                                            uri: "/Inventory/Item/GetAll?",
                                        })
                                    }
                                    permissions={[PermissionsEnum.CreateItem]}
                                    icon={"k-icon k-i-plus"}
                                />
                                <ActionButton
                                    buttonText={"Add Detail Item"}
                                    onClick={() =>
                                        itemModal.open(undefined, {
                                            uri: "/Inventory/DetailItem/GetAll?",
                                        })
                                    }
                                    permissions={[PermissionsEnum.CreateItem]}
                                    icon={"k-icon k-i-plus"}
                                />
                            </span>
                            <DataGrid
                                key={refreshGridKey}
                                data={lineItems.current}
                                useInlineEdit={true}
                                showSaveChangesButtonGroup={false}
                                showColumnsMultiSelect={false}
                                resizable={true}
                                style={{
                                    width: "99.99%",
                                }}
                            >
                                <GridToolbar>
                                    <h4 className={"ZeroMargin"}>
                                        Package Total:{" "}
                                        {formatUSDCurrency(
                                            lineItems.current
                                                .map((i) => i.total.amount)
                                                .reduce((a, b) => a + b, 0)
                                        )}
                                    </h4>
                                </GridToolbar>
                                <GridColumn
                                    field={"name"}
                                    title={"Item:"}
                                    width={300}
                                    minResizableWidth={250}
                                    editable={false}
                                />
                                <GridColumn
                                    field={"quantity"}
                                    title={"Qty:"}
                                    width={150}
                                    minResizableWidth={50}
                                    cell={(props) => (
                                        <EditableNumericCell
                                            {...props}
                                            min={1}
                                            editField={"IN_LINE_EDIT"}
                                            onCustomChange={(e) =>
                                                modifyQuantity(
                                                    props.dataItem,
                                                    e.value
                                                )
                                            }
                                        />
                                    )}
                                />
                                <GridColumn
                                    field="retailPrice.amount"
                                    title="Retail Price:"
                                    width={150}
                                    minResizableWidth={50}
                                    format={"{0:c}"}
                                    editable={false}
                                />
                                <GridColumn
                                    field="total.amount"
                                    title="Total Price:"
                                    width={150}
                                    minResizableWidth={50}
                                    format={"{0:c}"}
                                    editable={false}
                                />
                                <GridColumn
                                    width={125}
                                    resizable={false}
                                    filterable={false}
                                    editable={false}
                                    sortable={false}
                                    cell={(props) => (
                                        <ActionCell
                                            additionalActions={[
                                                {
                                                    buttonText: "Remove",
                                                    permissions: [
                                                        PermissionsEnum.DeleteItem,
                                                    ],
                                                    onClick: () =>
                                                        removeItemFromPackage(
                                                            props.dataItem
                                                        ),
                                                    icon: "k-icon k-i-delete",
                                                },
                                            ]}
                                        />
                                    )}
                                />
                            </DataGrid>
                            <FormButtons
                                actionOnCancel={closeForm}
                                loaderVisible={loaderVisible}
                                isCreate={isCreate}
                                allowSubmit={true}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};
