import * as React from "react";
import { useContext, useEffect, useState } from "react";
import "../../AccountingModule.module.scss";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";
import CoAActionModal from "./CoAActionModal";
import { TreeList } from "@progress/kendo-react-treelist";
import CoATLNameColumnCell from "./CoATLNameColumnCell";
import CoATLActionColumnCell from "./CoATLActionColumnCell";
import CoATLAccountTypeColumnCell from "./CoATLAccountTypeColumnCell";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { AccountingContext } from "../../Providers/AccountingProvider";
import { AccountHelper } from "../../Helpers/AccountHelper";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { useModal } from "../../../../hooks/useModal";

const ChartOfAccounts = () => {
    const accountingContext = useContext(AccountingContext);
    const { reloadData } = useContext(ReloadDataContext);
    const [accounts, setAccounts] = useState([]);
    const accountModal = useModal();
    const columns = [
        {
            field: "name",
            title: " ",
            expandable: true,
            cell: CoATLNameColumnCell,
        },
        {
            field: "code",
            title: "Code:",
            width: "200px",
        },
        {
            field: "accountTypeDisplay",
            title: "Account Type:",
            width: "200px",
            cell: CoATLAccountTypeColumnCell,
        },
        {
            title: "Actions",
            cell: (props) => (
                <CoATLActionColumnCell {...props} accountModal={accountModal} />
            ),
            width: "200px",
        },
    ];

    useEffect(() => {
        ChartOfAccountsService.getChartOfAccounts().then((res) => {
            const data = res.data.map((acc) => {
                AccountHelper.expandTreeList(acc, "childAccounts");
                return acc;
            });

            accountingContext.setAccounts(data);
            setAccounts(data);
        });
    }, [reloadData]);

    return (
        <div className={"CoAContainer"}>
            <CoAActionModal {...accountModal} />
            <div
                className={"CoAHeader"}
                style={{ marginRight: "20px", marginTop: "10px" }}
            >
                <p className={"CoAHeaderTitle"}>Chart of Accounts</p>
                <ActionButton
                    buttonText={"Add"}
                    theme={"info"}
                    rounded={"large"}
                    icon={"k-icon k-i-add"}
                    className={"CoAHeaderBtn"}
                    onClick={() => accountModal.open()}
                    permissions={[PermissionsEnum.CreateAccount]}
                    style={{
                        height: "35px",
                    }}
                />
            </div>
            <div className={"CoASummary"} style={{ marginRight: "20px" }}>
                <span className={"CoASummaryTitle"}>
                    <span className="k-icon k-font-icon k-i-graph" />
                    <p>Summary</p>
                </span>
                <span className={"CoABalanceInfo"}>
                    <p>
                        Accounts:
                        <strong>
                            {accountingContext?.data?.accountsCount ?? 0}
                        </strong>
                    </p>
                </span>
            </div>
            <TreeList
                expandField={"expanded"}
                subItemsField={"childAccounts"}
                data={accounts}
                columns={columns}
            />
        </div>
    );
};

export default ChartOfAccounts;
