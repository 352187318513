import * as React from "react";
import { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import "../AccountingModule.module.scss";
import ChartOfAccounts from "./Components/ChartOfAccounts";

const ChartOfAccountsPage = () => {
    const [selected, _setSelected] = useState(0);

    return (
        <TabStrip id={"AccountingDashboard"} selected={selected}>
            <TabStripTab title="Chart Of Accounts">
                <ChartOfAccounts />
            </TabStripTab>
        </TabStrip>
    );
};
export default ChartOfAccountsPage;
