import React, { useContext, useEffect, useState } from "react";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { UserContext } from "../../../../providers/Deprecated/Authentication/User/UserProvider";
import styles from "./UserDetails.module.scss";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import UserDetailsCard from "./Components/UserDetailsCard";
import UserEmployeeDetails from "./Components/UserEmployeeDetails";

const AdminUserDetailsPage = (props) => {
    const userId = props?.match?.params?.id;
    const userContext = useContext(UserContext);
    const employeeContext = useContext(EmployeeContext);
    const [topTabSelected, setTopTabSelected] = useState(0);

    /** @type {User} */
    const user = userContext.user;

    useEffect(() => {
        userContext.setUserId(userId);
    }, [userId]);

    useEffect(() => {
        if (user?.activeEmployeeId) {
            employeeContext.setEmployeeId(user.activeEmployeeId);
        }
    }, [user]);

    // JSX code for rendering the page
    //
    return (
        <div className={styles.UserDetailsContainer}>
            {!userContext.relationshipsLoading && user ? (
                <>
                    <div className="k-flex-100 flex-container">
                        <div className="content-wrapper">
                            <Card
                                className="card-container"
                                style={{ border: "none" }}
                            >
                                <TabStrip
                                    selected={topTabSelected}
                                    onSelect={(e) =>
                                        setTopTabSelected(e.selected)
                                    }
                                >
                                    <TabStripTab title="Details">
                                        <UserDetailsCard />
                                    </TabStripTab>
                                    {user?.activeEmployeeId && (
                                        <TabStripTab title="Employee Details">
                                            <UserEmployeeDetails />
                                        </TabStripTab>
                                    )}
                                </TabStrip>
                            </Card>
                        </div>
                    </div>
                </>
            ) : (
                <CenterLoader />
            )}
        </div>
    );
};

export default AdminUserDetailsPage;
