import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./PaymentDisbursement.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import InvoiceDetailComponent from "../../../Payments/Components/InvoiceDetailComponent";
import { EnumCell } from "../../../../../components/GridColumnComponents/EnumCell";
import { NewJobDocumentType } from "../../../../../resources/Enums/JobDocumentTypeEnum";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { CustomCell } from "../../../../../components/Deprecated/CustomGridCells";
import { PaymentsHelper } from "../../../../../resources/Deprecated/PaymentHelpers/paymentsHelper";
import { useModal } from "../../../../../hooks/useModal.js";
import { JobDocumentModalWrapper } from "../../../JobDocuments/Components/JobDocumentModal/JobDocumentModal.jsx";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell.jsx";

const PaymentDisbursement = () => {
    const [data, setData] = useState([]);
    const paymentContext = useContext(PaymentContext);
    const { job } = useContext(JobContext);
    const documentModal = useModal();

    useEffect(() => {
        setData(
            paymentContext.documents?.filter(
                (document) => document.appliedPayments.length > 0
            )
        );
    }, [paymentContext.documents]);

    const expandChange = (event) => {
        let newData = data.map((invoice) => {
            if (invoice.id === event.dataItem.id) {
                invoice.expanded = !event.dataItem.expanded;
            }
            return invoice;
        });
        setData(newData);
    };

    return (
        <Card className={styles.DisbursementCard}>
            <JobDocumentModalWrapper {...documentModal} />
            <CardHeader
                style={{
                    backgroundColor: "#67809F",
                    color: "#fff",
                }}
            >
                <CardTitle>Payment Disbursement Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid
                    className={styles.DisbursementGrid}
                    data={data}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    detail={InvoiceDetailComponent}
                >
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        field="type"
                        title="Type:"
                        cell={(props) => (
                            <EnumCell
                                {...props}
                                enumerable={NewJobDocumentType}
                            />
                        )}
                    />
                    <GridColumn
                        field="documentNumber"
                        title="Document:"
                        width={100}
                        cell={(props) => (
                            <BaseCell {...props}>
                                <span
                                    className={"Link"}
                                    onClick={() =>
                                        documentModal.open(props.dataItem.id, {
                                            businessInformation:
                                                job.businessInformation,
                                        })
                                    }
                                >
                                    #{props.dataItem.documentNumber}
                                </span>
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="total.amount"
                        title="Total:"
                        format="{0:c}"
                    />
                    <GridColumn
                        field="balance"
                        title="Balance:"
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    {formatUSDCurrency(
                                        props.dataItem.total.amount -
                                            props.dataItem.totalPaid.amount
                                    )}
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn
                        field="totalPaid"
                        title="Total Paid:"
                        cell={(props) => {
                            const total = props.dataItem.total.amount;
                            const balance =
                                props.dataItem.total.amount -
                                props.dataItem.totalPaid.amount;
                            const paidFormatted = formatUSDCurrency(
                                props.dataItem.totalPaid.amount
                            );
                            const percentage =
                                PaymentsHelper.getPercentageForDisbursedPayment(
                                    total,
                                    balance
                                );

                            return (
                                <CustomCell>{`${paidFormatted} (${percentage})`}</CustomCell>
                            );
                        }}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default PaymentDisbursement;
