import * as React from "react";
import "../../../styles/CommonPositionalStyles.scss";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import ActiveTasksOffTaskTreeList from "./ActiveTasksOffTaskTreeList";
import ActiveTasksOnTaskTreeList from "./ActiveTasksOnTaskTreeList";

const ActiveTasks = (props) => {
    const { data } = props;

    return data !== undefined ? (
        <span className={"JustifyCenterAlignLeft FlexColumn"}>
            <h3
                style={{
                    margin: "10px 0 0 25px",
                }}
                className={"JustifyCenterAndAlignCenter SmallGap"}
            >
                <i className={"k-icon k-i-x-circle"} style={{ color: "red" }} />{" "}
                Employees On Clock But Not On Task
            </h3>
            <hr className={"Separator"} />
            <ActiveTasksOffTaskTreeList data={data.employeesOffTask} />
            <h3
                style={{
                    margin: "10px 0 0 25px",
                }}
                className={"JustifyCenterAndAlignCenter SmallGap"}
            >
                <i
                    className={"k-icon k-i-check-circle"}
                    style={{ color: "green" }}
                />
                Employees On Task
            </h3>
            <hr className={"Separator"} />
            <ActiveTasksOnTaskTreeList data={data.employeesOnTask} />
        </span>
    ) : (
        <div style={{ margin: "25px" }}>
            <CenterLoader />
        </div>
    );
};

export default ActiveTasks;
