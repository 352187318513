import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getPaymentType = async (paymentTypeId) => {
    try {
        const response = await axios.get(
            `/General/PaymentType/Get?id=${paymentTypeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllPaymentTypes = async () => {
    try {
        const response = await axios.get(`/General/PaymentType/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updatePaymentType = async (paymentTypeData) => {
    try {
        // Send a PUT request to the server with the marital data
        const response = await axios.put(
            "/General/PaymentType/Update",
            paymentTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const addPaymentType = async (paymentTypeData) => {
    try {
        const response = await axios.post(
            "/General/PaymentType/Create",
            paymentTypeData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deletePaymentType = async (paymentTypeId) => {
    try {
        const response = await axios.delete(
            `/General/PaymentType/Delete?id=${paymentTypeId}`
        );
        console.log("delete response", response);
        // Return the response data
        return response.data;
    } catch (error) {
        console.error("delete error", error);
        // Log any errors and throw them
        throw error;
    }
};
