import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import EventTaskCategoryCreate from "./EventTaskCategoryCreate";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import {
    deleteEventTaskCategory,
    getAllTaskCategoriesForCalendarEvent,
} from "../../../../services/Deprecated/customer/eventServices";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";

const EventTaskCategoriesPortlet = ({ props }) => {
    const eventId = props?.match?.params?.id;
    const eventContext = useContext(EventContext);
    const [data, setData] = useState(undefined);
    const { triggerReload, reloadData } = useContext(ReloadDataContext);
    const [forceReloadKey, setForceReloadKey] = useState(0);

    useEffect(() => {
        getAllTaskCategoriesForCalendarEvent(eventId).then((res) => {
            setData(res.data);
            setForceReloadKey(Math.random());
        });
    }, [eventId, reloadData]);

    return (
        <Card
            style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                minHeight: "300px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                    backgroundColor: "#67809F",
                    color: "#fff",
                    height: 60,
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                        }}
                    >
                        <i className={"k-icon k-i-wrench"} />
                        <strong>Task Categories (Employee Skill-Sets)</strong>
                    </span>
                    <EventTaskCategoryCreate
                        taskCategoryDataImport={data ?? []}
                    />
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "5px",
                    overflowY: "auto",
                }}
            >
                {/* If the event is not the one we are looking for, show a loader, helps with re-renders */}
                {eventContext.event.id === eventId &&
                data &&
                !eventContext.relationshipsLoading ? (
                    <DataGrid
                        showColumnsMultiSelect={false}
                        key={forceReloadKey}
                        data={data}
                        disablePagination={true}
                        style={{
                            height: "235px",
                        }}
                    >
                        {/* We leave this empty so no empty message is shown */}
                        <GridNoRecords> </GridNoRecords>
                        <GridColumn
                            field="name"
                            title="Name:"
                            filter={"text"}
                            headerClassName={"hide-element"}
                        />
                        <GridColumn
                            width={80}
                            headerClassName={"hide-element"}
                            cell={(props) => (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    secondaryDataId={eventId}
                                    action={(id1, id2) => {
                                        deleteEventTaskCategory(id1, id2).then(
                                            () => {
                                                triggerReload();
                                            }
                                        );
                                    }}
                                    requiredPermissionsAction={[
                                        PermissionsEnum.DeleteTaskCategory,
                                    ]}
                                />
                            )}
                        />
                    </DataGrid>
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
        </Card>
    );
};

export default EventTaskCategoriesPortlet;
