/**
 * Returns a basic Data Query object for Kendo Data Source Request
 * @returns {DataSourceRequestState}
 */
export const getInitialDataQueryState = () => {
    return {
        page: {
            skip: 0,
            take: 100,
        },
        filter: {},
        sort: [],
    };
};
