import React, { useContext, useEffect, useState } from "react";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import styles from "../Styles/JobDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { BreadcrumbContext } from "../../../../../../providers/BreadCrumbProvider.jsx";

/**
 * @function JobDocumentSalesPerson
 * @description Display the sales person of the job document
 * @return {Element}
 * @constructor
 */
export const JobDocumentSalesPerson = () => {
    const { formData } = useContext(JobDocumentModalContext);
    const salesPersonModal = useModal();

    return (
        formData && (
            <span
                style={{
                    fontSize: 16,
                    color: "#666666",
                }}
            >
                <SalesPersonModal {...salesPersonModal} />
                <span>
                    Sales Person:{" "}
                    <DisplaySalesPerson
                        formData={formData}
                        modal={salesPersonModal}
                    />
                </span>
            </span>
        )
    );
};

/**
 * @function DisplaySalesPerson
 * @param props.formData {object} - formData object
 * @param props.modal {object} - modal object
 * @return {Element}
 * @constructor
 */
const DisplaySalesPerson = (props) => {
    const { formData, modal } = props;
    const { canEdit } = useContext(JobDocumentModalContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const displayName = () => {
        if (!formData.salesPerson) {
            return "Not provided";
        }

        if (formData.salesPerson.fullName) {
            return formData.salesPerson.fullName;
        }

        return formData.salesPerson.name;
    };

    return canEdit ? (
        <span
            className={`text-overflow ${styles.LinkDisplay}`}
            onClick={() => modal.open()}
            style={!formData.salesPerson ? { color: "red" } : undefined}
        >
            <i className={"k-icon k-i-pencil"} style={{ marginBottom: 4 }} />{" "}
            {displayName()}
        </span>
    ) : (
        <a
            className={"text-overflow Link"}
            onClick={() =>
                handleRouteChange({
                    uri: `/HumanResources/Employee/${formData.salesPerson.id}`,
                    breadcrumbInfo: [
                        {
                            id: formData.salesPerson.id,
                            text: formData.salesPerson.name,
                        },
                    ],
                })
            }
        >
            {formData.salesPerson.name}
        </a>
    );
};

const SalesPersonModal = (props) => {
    const { close, visible } = props;
    const [data, setData] = useState(undefined);
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        JobDocumentModalContext
    );

    useEffect(() => {
        setData({
            salesPerson: formData.salesPerson,
        });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        setFormData({
            ...formData,
            salesPerson: data.salesPerson,
        });
        reloadDocumentInformation();
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Edit Sales Person"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        url={`HumanResources/Employee/GetAll?`}
                        name={"salesPerson"}
                        textField={"fullName"}
                        component={FormRemoteDropDown}
                        label={"Sales Person:"}
                        validator={requiredValidator}
                        initialSort={[]}
                        searchFields={["firstName", "lastName"]}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            setData({
                                ...data,
                                salesPerson: e.value,
                                isValid: e.value !== undefined,
                            });
                        }}
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    buttonText={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
