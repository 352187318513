import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth();

export const daysOfTheWeek = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
];

export const nextMonth = () => {
    let nextMonthIndex = currentMonth + 1;

    if (nextMonthIndex > 11) {
        return 0;
    }

    return nextMonthIndex;
};

export const startOfDay = (date) => {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
};

export const endOfDay = (date) => {
    const end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
};

export const currentDay = () => {
    const currentDate = new Date();
    return {
        start: startOfDay(currentDate),
        end: endOfDay(currentDate),
    };
};

export const startOfWeek = (date) => {
    const diff = date.getDate() - date.getDay();
    const startOfWeek = new Date(date.setDate(diff));
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
};

export const endOfWeek = (date) => {
    const startDate = startOfWeek(date);
    startDate.setHours(23, 59, 59, 999);
    return new Date(startDate.setDate(startDate.getDate() + 6));
};

export const startOfLastWeek = (date) => {
    const startDate = startOfWeek(date);
    return new Date(startDate.setDate(startDate.getDate() - 7));
};

export const endOfNextWeek = (date) => {
    const endDate = endOfWeek(date);
    return new Date(endDate.setDate(endDate.getDate() + 7));
};

//? 2023-10-11T00-00-00
//?
export const formatDateForNetwork = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
};

//? 2023-10-11T00-00-00
//?
export const formatDateOnlyForNetwork = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

/**
 * Converts a decimal to a time of h:m:s
 * @param input
 * @returns {string}
 */
export const decimalToTime = (input) => {
    const decimal = input;
    const hours = Math.floor(decimal);
    const minutes = Math.floor((decimal - hours) * 60);

    return hours + "h " + minutes + "m ";
};

// Function to format only the date
export const formatDateOnly = (dateString) => {
    const date = dayjs.utc(dateString).local();
    return date.isValid() ? date.format("MM/DD/YYYY") : "";
};

// Function to format only the time
export const formatTimeOnly = (dateString) => {
    const date = dayjs.utc(dateString).local();
    return date.isValid() ? date.format("h:mm A") : "";
};

/**
 * Grabs difference of time between clock in and out and converts to human read-able time
 * @param dataItem
 * @return {string}
 */
export const formatClockedTime = (dataItem) => {
    const milliseconds = dayjs(dataItem.endTime).diff(
        dayjs(dataItem.startTime)
    );

    return dayjs.duration(milliseconds).format("H[h] m[m] s[s]");
};

/**
 * Parses a dateString in "MM/DD/YYYY" format and checks for validity.
 * Returns a JavaScript Date object if valid, otherwise null.
 *
 * @param {string} dateString - The date string to parse.
 * @return {Date|null} A JavaScript Date object if the date is valid, otherwise null.
 */
export const parseAndValidateDate = (dateString) => {
    const date = dayjs(dateString, "MM/DD/YYYY", true); // Use strict parsing
    if (date.isValid()) {
        return date.toDate();
    } else {
        return null; // Indicate invalid or unparseable date
    }
};

/**
 * Formats a JavaScript Date object into a string in "MM/DD/YYYY" format.
 * If the input is not a valid date, returns an empty string.
 *
 * @param {Date} date - The date to format.
 * @return {string} The formatted date string or an empty string if invalid.
 */
export const formatDateToString = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date)) return "";
    return dayjs(date).format("MM/DD/YYYY");
};

/**
 * @function getAllDatesBetween
 * @description Get all dates between a start and end date
 * @param {dayjs} startDate - The start date
 * @param {dayjs} endDate - The end date
 * @param {string} [format="YYYY-MM-DD"] - The format to return the dates in
 * @return {*[]}
 */
export function getAllDatesBetween(startDate, endDate, format = "YYYY-MM-DD") {
    let start = dayjs(startDate);
    const end = dayjs(endDate);
    const dates = [];

    while (start.isBefore(end) || start.isSame(end)) {
        dates.push(start.format(format));
        start = start.add(1, "day");
    }

    return dates;
}
