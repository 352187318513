import React, { createContext, useContext, useEffect, useState } from "react";
import {
    getAllEmployees,
    getEmployee,
} from "../../../../services/Deprecated/humanResources/employeeServices.js";
import { getAllPhoneTypes } from "../../../../services/Deprecated/general/phoneTypeServices.js";
import { getAllLocations } from "../../../../services/Deprecated/humanResources/locationServices.js";
import { getAllSupervisors } from "../../../../services/Deprecated/humanResources/supervisorServices.js";
import { getAllLaborTypes } from "../../../../services/Deprecated/humanResources/laborTypeServices.js";
import { getAllStatuses } from "../../../../services/Deprecated/humanResources/statusServices.js";
import { getAllIdentificationTypes } from "../../../../services/Deprecated/humanResources/identificationTypeServices.js";
import { getAllMaritalStatus } from "../../../../services/Deprecated/humanResources/maritalStatusServices.js";
import { useToggle } from "../../../../hooks/Deprecated/useToggle.js";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";

export const EmployeeContext = createContext(null);

const EmployeeProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    // Load in the relationships
    //
    const { bool: relationshipsLoading, toggle: toggleRelationshipsLoading } =
        useToggle(true);
    const [relationships, setRelationships] = useState({});
    const [employee, setEmployee] = useState({});
    const [employeeId, setEmployeeId] = useState();

    // Fetch data on component mount
    //
    useEffect(() => {
        const fetchData = async () => {
            const employeesPromise = getAllEmployees();
            const phoneTypePromise = getAllPhoneTypes();
            const locationsPromise = getAllLocations();
            const supervisorsPromise = getAllSupervisors();
            const laborTypesPromise = getAllLaborTypes();
            const statusPromise = getAllStatuses();
            const identificationPromise = getAllIdentificationTypes();
            const maritalPromise = getAllMaritalStatus();

            const [
                employeeResponse,
                phoneTypeResponse,
                locationsResponse,
                supervisorsResponse,
                laborTypesResponse,
                statusResponse,
                identificationResponse,
                maritalStatusResponse,
            ] = await Promise.all([
                employeesPromise,
                phoneTypePromise,
                locationsPromise,
                supervisorsPromise,
                laborTypesPromise,
                statusPromise,
                identificationPromise,
                maritalPromise,
            ]);

            setRelationships({
                employees: employeeResponse.data,
                phoneType: phoneTypeResponse.data,
                locations: locationsResponse.data,
                supervisors: supervisorsResponse.data,
                laborTypes: laborTypesResponse.data,
                status: statusResponse.data,
                identification: identificationResponse.data,
                maritalStatus: maritalStatusResponse.data,
            });
        };

        fetchData().then(() => {
            toggleRelationshipsLoading();
        });
    }, []);

    useEffect(() => {
        if (!employeeId) {
            return;
        }

        getEmployee(employeeId).then((serviceResponse) => {
            if (serviceResponse.success) setEmployee(serviceResponse.data);
        });
    }, [employeeId, reloadDataContext.reloadData]);

    const value = {
        relationships,
        relationshipsLoading,
        employee,
        setEmployeeId,
        employeeId,
    };

    return (
        <EmployeeContext.Provider value={value}>
            {children}
        </EmployeeContext.Provider>
    );
};

export default EmployeeProvider;
