import React from "react";
import { ContentHeader } from "../../../../../../components/ContentHeader";

export const InstallerNotesSection = () => {
    return (
        <span
            style={{
                width: "99%",
                height: "200px",
                border: "1px solid rgba(0,0,0,0.4)",
                borderRadius: "5px",
            }}
        >
            <ContentHeader
                title={"INSTALLERS NOTES BELOW:"}
                style={{
                    fontSize: "14px",
                }}
            />
        </span>
    );
};
