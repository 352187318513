import React, { useContext, useEffect, useMemo, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import ItemCategoryModal from "./ItemCategoryModal";
import { ItemRelationshipsContext } from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { useModal } from "../../../../hooks/useModal";
import { AccountHelper } from "../../../Accounting/Helpers/AccountHelper";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import ItemVendorsGrid from "./ItemVendorsGrid";
import { FileManager } from "../../../../components/FileManager/FileManager";

const AccountDropdownItemRenderer = (li, itemProps) => {
    const isRoot = itemProps.dataItem.isRoot;
    let itemChildren;

    if (isRoot) {
        itemChildren = (
            <span>
                <strong>{itemProps.dataItem.name}</strong>
            </span>
        );
    } else {
        itemChildren = (
            <span>
                <span>{itemProps.dataItem.parentAccount.name} -</span>
                <span> {itemProps.dataItem.name}</span>
            </span>
        );
    }

    return React.cloneElement(li, li.props, itemChildren);
};

const ItemInformationForm = ({ itemCount, item, formRenderProps }) => {
    const { relationships } = useContext(ItemRelationshipsContext);
    const [itemType, setItemType] = useState("");
    const categoryModal = useModal();

    useEffect(() => {
        if (item) {
            setItemType(item.itemType.name);
        }
    }, [item]);

    const fileRestrictions = {
        allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
    };

    const expenseAccounts = useMemo(
        () =>
            relationships.expenseAccounts?.flatMap((acc) =>
                AccountHelper.flatmapAccounts(acc)
            ),
        [relationships.expenseAccounts]
    );
    const incomeAccounts = useMemo(
        () =>
            relationships.incomeAccounts?.flatMap((acc) =>
                AccountHelper.flatmapAccounts(acc)
            ),
        [relationships.incomeAccounts]
    );

    const handleItemTypeChange = (e) => {
        const selectedItemType = e.target.value;
        const selectedItemTypeName = selectedItemType.name;
        setItemType(selectedItemTypeName);
    };

    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{ rows: 15, cols: 30 }}
            style={{ width: 1200 }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Item Information:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <Field
                        name={"name"}
                        component={FormInput}
                        label={"Item Name:"}
                        validator={nameValidator}
                        hint={
                            itemCount === "multi"
                                ? "Hint: Will be used for all items in this form."
                                : ""
                        }
                    />
                    <Field
                        name={"manufacturerNumber"}
                        component={FormInput}
                        label={"MFR #:"}
                    />
                    <Field
                        name={"retailPrice"}
                        component={FormNumericInput}
                        format={"c2"}
                        label={"Retail Price:"}
                    />
                    <span
                        style={{ marginTop: 10 }}
                        className={"JustifyLeftAlignCenter FlexColumn LargeGap"}
                    >
                        <Field
                            name={"isActive"}
                            component={Checkbox}
                            label={"Is Active"}
                            rounded={"large"}
                            size={"medium"}
                        />
                        <Field
                            name={"isStockOrSupplyItem"}
                            component={Checkbox}
                            label={"Is Stock or Supply Item"}
                            rounded={"large"}
                            size={"medium"}
                        />
                        <Field
                            name={"isDescriptionEditAllowed"}
                            component={Checkbox}
                            label={"Is Description Edit Allowed"}
                            rounded={"large"}
                            size={"medium"}
                        />
                    </span>
                </fieldset>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    {/*
                     vendor can only be set initially, updating vendor later
                     must be done through vendor items list (or support something
                     other than a simple dropdown for vendor)
                     */}
                    {itemCount === "multi" ? (
                        <Field
                            name={"vendor"}
                            component={FormDropDown}
                            validator={requiredValidator}
                            data={relationships.vendors}
                            dataItemKey="id"
                            textField="name"
                            label={"Vendor:"}
                        />
                    ) : null}
                    <Field
                        name={"itemType"}
                        component={FormDropDown}
                        validator={requiredValidator}
                        data={relationships.itemTypes}
                        dataItemKey="id"
                        textField="name"
                        label={"Item Type:"}
                        onChange={handleItemTypeChange}
                    />
                    {["Slab", "Sheet"].includes(itemType) && (
                        <>
                            <Field
                                name={"defaultWidth"}
                                component={FormNumericInput}
                                label={"Default Width (Inch):"}
                                min={0}
                                format={"0"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"defaultLength"}
                                component={FormNumericInput}
                                min={0}
                                format={"0"}
                                label={"Default Length (Inch):"}
                                validator={requiredValidator}
                            />
                        </>
                    )}
                    <Field
                        name={"orderingUom"}
                        component={FormDropDown}
                        validator={requiredValidator}
                        data={relationships.unitOfMeasures}
                        dataItemKey="id"
                        textField="name"
                        label={"Ordering UOM:"}
                    />
                    <Field
                        name={"retailUom"}
                        component={FormDropDown}
                        validator={requiredValidator}
                        data={relationships.unitOfMeasures}
                        dataItemKey="id"
                        textField="name"
                        label={"Retail UOM:"}
                    />
                    <Field
                        name={"expenseAccount"}
                        component={FormDropDown}
                        data={expenseAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Expense Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                    <Field
                        name={"incomeAccount"}
                        component={FormDropDown}
                        data={incomeAccounts}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                        label={"Income Account:"}
                        itemRender={AccountDropdownItemRenderer}
                    />
                    <div style={{ display: "flex", alignItems: "end", gap: 5 }}>
                        <Field
                            style={{ width: "325px" }}
                            name={"category.name"}
                            component={FormInput}
                            label={"Category:"}
                            readOnly={true}
                        />
                        <ItemCategoryModal
                            {...categoryModal}
                            item={item}
                            formRenderProps={formRenderProps}
                        />
                        <ActionButton
                            onClick={() => categoryModal.open()}
                            buttonText={"Change"}
                        />
                    </div>
                </fieldset>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                {item?.id && (
                    <>
                        <div style={{ marginBottom: "20px" }}>
                            <ItemVendorsGrid item={item} />
                        </div>
                        <div>
                            <FileManager
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "start",
                                }}
                                disabled={false}
                                allowedExtensions={fileRestrictions}
                                saveUri={"/General/File/UploadFile"}
                                removeUri={"/General/File/DeleteFile"}
                                readUri={"/General/File/GetDirectoryContents"}
                                location={`ItemFiles-${item.id}`}
                            />
                        </div>
                    </>
                )}
            </GridLayoutItem>
            <GridLayoutItem col={1} row={4} colSpan={2}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <h4
                        style={{
                            margin: "0",
                            marginTop: "8px",
                            marginBottom: "8px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                            textAlign: "left",
                        }}
                    >
                        SE/SO Description:
                    </h4>
                    <Field
                        name={"baseDescription"}
                        component={FormTextArea}
                        validator={requiredValidator}
                        autoSize={true}
                        rows={4}
                        label={"SE/SO Description:"}
                    />
                    <h4
                        style={{
                            margin: "0",
                            marginTop: "8px",
                            marginBottom: "8px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                            textAlign: "left",
                        }}
                    >
                        Purchase Order Description:
                    </h4>
                    <Field
                        name={"purchaseOrderDescription"}
                        component={FormTextArea}
                        autoSize={true}
                        rows={4}
                        label={"Purchase Order Description:"}
                    />
                </fieldset>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default ItemInformationForm;
