import React, { useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ContentHeader } from "../../../../components/ContentHeader";
import BillingTermDashboardPage from "./BillingTerms/BillingTermDashboardPage";
import ItemTypeDashboardPage from "./ItemTypes/ItemTypeDashboardPage";
import StorageLocationDashboardPage from "./StorageLocations/StorageLocationDashboardPage";

const EmployeeTabPage = () => {
    const [selected, setSelected] = useState(0);

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Inventory Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <TabStrip
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    className="tabs"
                >
                    <TabStripTab title="Billing Terms">
                        <BillingTermDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Item Types">
                        <ItemTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Storage Locations">
                        <StorageLocationDashboardPage />
                    </TabStripTab>
                </TabStrip>
            </Card>
        </div>
    );
};

export default EmployeeTabPage;
