import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * @function createEventType
 * @description Function to create a job event type
 * @param {Object} eventTypeObject
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const createEventType = async (eventTypeObject) => {
    try {
        const response = await axios.post(
            `/Customer/EventType/Create`,
            eventTypeObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateEventType
 * @description Function to update a job event type
 * @param {Object} eventTypeEditObject
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const updateEventType = async (eventTypeEditObject) => {
    try {
        const response = await axios.put(
            `/Customer/EventType/Update`,
            eventTypeEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteEventType
 * @description Function to delete an event type
 * @param {String} eventTypeId
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const deleteEventType = async (eventTypeId) => {
    try {
        const response = await axios.delete(
            `/Customer/EventType/Delete?id=${eventTypeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllEventTypes
 * @description Function to get all event types
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllEventTypes = async () => {
    try {
        const response = await axios.get("/Customer/EventType/GetAll");
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getEventType
 * @description Function to get event type
 * @param eventTypeId
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getEventType = async (eventTypeId) => {
    try {
        const response = await axios.get(
            `/Customer/EventType/Get?id=${eventTypeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
