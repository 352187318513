import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getSocialMedia = async (socialMediaId) => {
    try {
        const response = await axios.get(
            `/General/SocialMedia/Get?id=${socialMediaId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllSocialMedias = async () => {
    try {
        const response = await axios.get(`/General/SocialMedia/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateSocialMedia = async (socialMediaData) => {
    try {
        // Send a PUT request to the server with the marital data
        const response = await axios.put(
            "/General/SocialMedia/Update",
            socialMediaData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const addSocialMedia = async (socialMediaData) => {
    try {
        const response = await axios.post(
            "/General/SocialMedia/Create",
            socialMediaData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteSocialMedia = async (socialMediaId) => {
    try {
        const response = await axios.delete(
            `/General/SocialMedia/Delete?id=${socialMediaId}`
        );
        console.log("delete response", response);
        // Return the response data
        return response.data;
    } catch (error) {
        console.error("delete error", error);
        // Log any errors and throw them
        throw error;
    }
};
