import React, { createContext, useEffect, useState } from "react";
import { AccountHelper } from "../Helpers/AccountHelper";

export const AccountingContext = createContext(null);

const AccountingProvider = ({ children }) => {
    const [data, setData] = useState({});
    const [modalOperation, setModalOperation] = useState({
        isCreate: false,
        isDelete: false,
        isUpdate: false,
        isCreateSub: false,
    });
    const [selectedEntity, setSelectedEntity] = useState({
        manualCreatedAt: new Date(),
    });

    useEffect(() => {
        const accounts = data?.accounts;

        if (accounts == null) {
            return;
        }

        const flattenedAccounts = data.accounts.flatMap((acc) =>
            AccountHelper.flatmapAccounts(acc)
        );

        setData((prevData) => ({
            ...prevData,
            accountsCount: flattenedAccounts.length,
        }));
    }, [data?.accounts]);

    /**
     * Sets the accounts in the component state.
     *
     * @param {Array} accounts - An array of account objects to be added.
     * @returns {void}
     */
    const setAccounts = (accounts) => {
        setData((prevData) => ({
            ...prevData,
            accounts: [...accounts],
        }));
    };

    const setModalOperationCreate = () => {
        setModalOperation({
            isCreate: true,
            isDelete: false,
            isUpdate: false,
            isCreateSub: false,
        });
    };

    const setModalOperationDelete = (dataItem) => {
        setSelectedEntity(dataItem);
        setModalOperation({
            isCreate: false,
            isDelete: true,
            isUpdate: false,
            isCreateSub: false,
        });
    };

    const setModalOperationUpdate = (dataItem) => {
        setSelectedEntity(dataItem);
        setModalOperation({
            isCreate: false,
            isDelete: false,
            isUpdate: true,
            isCreateSub: false,
        });
    };

    const setModalOperationCreateSub = (parentDataItem) => {
        setSelectedEntity(parentDataItem);
        setModalOperation({
            isCreate: false,
            isDelete: false,
            isUpdate: false,
            isCreateSub: true,
        });
    };

    /**
     * Closes the current modal by resetting state to default
     */
    const closeModal = () => {
        setModalOperation({
            isCreate: false,
            isDelete: false,
            isUpdate: false,
            isCreateSub: false,
        });
        setSelectedEntity({});
    };

    const value = {
        data,
        setAccounts,
        modalOperation,
        selectedEntity,
        setSelectedEntity,
        closeModal,
        setModalOperationCreate,
        setModalOperationDelete,
        setModalOperationUpdate,
        setModalOperationCreateSub,
    };

    return (
        <AccountingContext.Provider value={value}>
            {children}
        </AccountingContext.Provider>
    );
};

export default AccountingProvider;
