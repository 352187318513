import React from "react";

export const TaskInformation = (props) => {
    const { categories } = props;

    return (
        <table>
            <tbody>
                <tr>
                    <th
                        style={{
                            width: "300px",
                            borderBottom: "1px solid rgba(0,0,0,0.8)",
                        }}
                        className={"AlignTextLeft"}
                    >
                        Tasks
                    </th>
                    <th
                        style={{
                            width: "150px",
                            borderBottom: "1px solid rgba(0,0,0,0.8)",
                        }}
                    >
                        Is This Complete?
                    </th>
                    <th
                        style={{ borderBottom: "1px solid rgba(0,0,0,0.8)" }}
                        className={"AlignTextLeft"}
                    >
                        Notes
                    </th>
                </tr>
                {categories.map((category, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td className={"AlignTextLeft"}>
                                <strong>{category.name}</strong>
                            </td>
                        </tr>
                        {category.tasks.map((task, index) => (
                            <tr key={index}>
                                <td className={"AlignTextLeft"}>
                                    <strong
                                        style={{
                                            marginLeft: "25px",
                                        }}
                                    >
                                        *{task.name}
                                    </strong>
                                </td>
                                <td style={{ width: "100px" }}>
                                    <strong>Yes / No</strong>
                                </td>
                                <td className={"AlignTextLeft"}>
                                    <hr
                                        style={{ marginTop: "15px" }}
                                        className={"Separator"}
                                    />
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};
