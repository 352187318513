import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardActions,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import JobDocumentSummaries from "./JobDocumentSummaries";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { useModal } from "../../../../hooks/useModal.js";
import { VendorDocumentModalWrapper } from "../../../Inventory/VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { JobDocumentModalWrapper } from "./JobDocumentModal/JobDocumentModal.jsx";
import BaseService from "../../../../services/BaseService.js";
import { NewJobDocumentType } from "../../../../resources/Enums/JobDocumentTypeEnum.js";
import { VendorDocumentType } from "../../../../resources/Enums/VendorDocumentType.js";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";

const JobDocumentPortlet = ({ portletName, jobDocType, uri }) => {
    const { job } = useContext(JobContext);
    const [documents, setDocuments] = useState(undefined);
    const vendorDocumentModal = useModal();
    const jobDocumentModal = useModal();

    useEffect(() => {
        if (!uri || !job?.id) return;

        let type = jobDocType;
        if (jobDocType === NewJobDocumentType.Enum.VendorDocument) {
            type = VendorDocumentType.Enum.PurchaseOrder;
        }

        const service = new BaseService(uri);
        service
            .getAll(`filter=(type~eq~${type}~and~jobId~eq~'${job.id}')&`)
            .then((r) => setDocuments(r.data));
    }, [job, uri]);

    const onCreateClick = () => {
        if (jobDocType === NewJobDocumentType.Enum.VendorDocument) {
            vendorDocumentModal.open(undefined, {
                businessInformation: job.businessInformation,
                documentNumber: "000000",
                address: job.businessInformation.address,
                job: job,
            });
        } else if (jobDocType === NewJobDocumentType.Enum.Estimate) {
            jobDocumentModal.open(undefined, {
                job: job,
                businessInformation: job.businessInformation,
                documentNumber: "000000",
                type: NewJobDocumentType.Enum.Estimate,
            });
        }
    };

    return (
        <Card
            style={{
                width: 400,
                height: 415,
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                marginTop: "15px",
            }}
        >
            <CardHeader
                className="k-hbox"
                style={{
                    background: "transparent",
                    width: "100%",
                    backgroundColor: "#67809F",
                    color: "#fff",
                    height: 50,
                }}
            >
                <CardTitle
                    style={{
                        marginBottom: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <strong>{portletName}</strong>
                    {(jobDocType === NewJobDocumentType.Enum.VendorDocument ||
                        jobDocType === NewJobDocumentType.Enum.Estimate) && (
                        <>
                            <VendorDocumentModalWrapper
                                {...vendorDocumentModal}
                            />
                            <JobDocumentModalWrapper {...jobDocumentModal} />
                            <ActionButton
                                buttonText={"Create"}
                                permissions={
                                    jobDocType ===
                                    NewJobDocumentType.Enum.VendorDocument
                                        ? [PermissionsEnum.CreateVendorDocument]
                                        : [PermissionsEnum.CreateJobDocument]
                                }
                                theme={"primary"}
                                icon={"k-icon k-i-plus"}
                                style={{
                                    border: "none",
                                }}
                                onClick={onCreateClick}
                            />
                        </>
                    )}
                </CardTitle>
            </CardHeader>
            <CardBody
                style={{
                    padding: "2px",
                }}
            >
                {documents ? (
                    <JobDocumentSummaries jobDocuments={documents} />
                ) : (
                    <CenterLoader />
                )}
            </CardBody>
            <CardActions
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <span
                    style={{
                        fontSize: "13px",
                        alignSelf: "center",
                        color: "#656565",
                    }}
                >
                    {documents ? documents.length : 0} Documents
                </span>
            </CardActions>
        </Card>
    );
};

export default JobDocumentPortlet;
