import React, { useContext, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import JobDetails from "./JobDetails/JobDetails.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider.jsx";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum.js";

const JobTabs = () => {
    const [selected, setSelected] = useState(0);
    const { job } = useContext(JobContext);
    const { customer } = useContext(CustomerContext);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
            style={{
                height: "430px",
                maxWidth: "1180px",
                width: "100%",
            }}
        >
            <TabStripTab title="Details">
                <JobDetails
                    job={job}
                    customer={customer}
                    includeJobContactInformation={
                        customer.type === CustomerTypeEnum.Enum.Business
                    }
                />
            </TabStripTab>
        </TabStrip>
    );
};

export default JobTabs;
