import React, {useEffect, useState} from "react";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {FormButtons} from "../../../../components/Buttons/FormButtons";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import {FormDropDown} from "../../../../components/Deprecated/FormComponents";
import {requiredValidator} from "../../../../resources/Deprecated/formValidators";
import ItemService from "../../../../services/ItemService";

/**
 * @function InventoryItemVendorSelectModal
 * @description Modal for creating and updating labor items
 * @param props.visible {boolean} - Determines if the modal is visible
 * @param props.close {function} - Function to close the modal
 * @param props.invModal {object} - Object containing the inventory modal data
 * @return {React.JSX.Element}
 * @constructor
 */
export const InventoryItemVendorSelectModal = (props) => {
    const { visible, close, invModal, record } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [vendorData, setVendorData] = useState([]);
    const [initialFormData, setInitialFormData] = useState(undefined);

    useEffect(() => {
        if (!visible || !record) return;

        const service = new ItemService("Inventory/Item");
        service.getVendors(record.item.item.id).then((res) => {
            setVendorData(res.data);

            if (res.data.length === 1) {
                onFormSubmit({
                    isValid: true,
                    values: { vendor: res.data[0] },
                });
            } else if (res.data.length > 1) {
                setInitialFormData({
                    vendor: res.data[0],
                });
            } else {
                setInitialFormData({});
            }
        });
    }, [visible]);

    const onFormSubmit = (event) => {
        if (!event.isValid) return;

        setLoaderVisible(true);

        const values = event.values;
        let data = {
            ...record,
            vendor: values.vendor,
            cost: values.vendor.cost,
            freight: values.vendor.freight,
        };

        if (record.item.item.defaultLength && record.item.item.defaultWidth) {
            data.length = record.item.item.defaultLength;
            data.width = record.item.item.defaultWidth;
        }

        invModal.open(undefined, data);

        close();
        resetState();
    };

    const closeForm = () => {
        resetState();
        close();
    };

    const resetState = () => {
        setInitialFormData(undefined);
        setVendorData([]);
        setLoaderVisible(false);
    };

    return (
        visible &&
        initialFormData && (
            <Form
                initialValues={initialFormData}
                onSubmitClick={onFormSubmit}
                render={() => (
                    <ResponsiveDialog
                        title={"Select a Vendor"}
                        onClose={closeForm}
                        size={"small"}
                    >
                        <FormElement>
                            <Field
                                data={vendorData}
                                name={"vendor"}
                                dataItemKey={"id"}
                                textField={"name"}
                                component={FormDropDown}
                                label={"Select a Vendor..."}
                                style={{ width: "100%" }}
                                validator={requiredValidator}
                            />
                            <FormButtons
                                actionOnCancel={closeForm}
                                loaderVisible={loaderVisible}
                                isCreate={false}
                                theme={"primary"}
                                buttonText={"Continue"}
                                allowSubmit={true}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};
