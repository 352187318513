import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeRelations from "./EmployeeRelations";
import EmergencyContacts from "./EmergencyContact";

const EmployeeTabs = () => {
    const [selected, setSelected] = useState(0);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
        >
            <TabStripTab title="Employee">
                <EmployeeDetails />
            </TabStripTab>
            <TabStripTab title="Relationships">
                <EmployeeRelations />
            </TabStripTab>
            <TabStripTab title="Emergency Contact">
                <EmergencyContacts />
            </TabStripTab>
        </TabStrip>
    );
};

export default EmployeeTabs;
