import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import CompanyInformationForm from "./CompanyInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import ContactInformationForm from "./ContactInformationForm";
import VendorSocialMediaForm from "./VendorSocialMediaForm";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import {
    getVendor,
    updateVendor,
} from "../../../../services/Deprecated/inventory/vendorService";
import { ContactType } from "../../../../resources/Enums/ContactType";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";

const VendorEditComponent = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const [currentContext, setCurrentContext] = React.useState("Company");
    const { triggerReload } = useContext(ReloadDataContext);
    const [vendor, setVendor] = useState(undefined);

    useEffect(() => {
        if (!visible || !record || !record.id) return;

        getVendor(record.id).then((res) => {
            setVendor(res.data);
        });
    }, [visible, record]);

    const onContextSwitch = (context) => {
        setCurrentContext(context.target.innerText);
    };

    /**
     * Function that will be called when the user submits the vendor form.
     * @Method
     * @param {{
     * paymentType: Object,
     * billingTerm: Object,
     * paymentTypeId: string,
     * vendorId: string,
     * billingTermId: string,
     * vendorSocialMedias: Array<{ socialMediaId: string, socialMedia: socialMedia, previousSocialMediaId: string }>
     * }} dataItem - Dropdown Data from API.
     * @typedef {Object} socialMedia
     * @property {socialMediaId: string}
     */
    const submitVendor = async (dataItem) => {
        toggleLoaderVisible(true);

        const formattedDataItem = {
            ...dataItem,
            paymentTypeId: dataItem?.paymentType?.id,
            billingTermId: dataItem?.billingTerm?.id,
            businessPhone: {
                typeId: dataItem.businessPhone?.phoneNumberType?.id,
                phoneNumber: dataItem.businessPhone?.phoneNumber,
            },
            incomeAccountId: dataItem.incomeAccount?.id,
            expenseAccountId: dataItem.expenseAccount?.id,
            contacts: [],
        };

        if (dataItem.primaryContact?.name1) {
            dataItem.primaryContact.type = ContactType.Enum.Primary;
            formattedDataItem.contacts.push(dataItem.primaryContact);
        }

        if (dataItem.secondaryContact?.name1) {
            dataItem.secondaryContact.type = ContactType.Enum.Secondary;
            formattedDataItem.contacts.push(dataItem.secondaryContact);
        }

        if (dataItem.orderingContact?.name1) {
            dataItem.orderingContact.type = ContactType.Enum.Ordering;
            formattedDataItem.contacts.push(dataItem.orderingContact);
        }

        if (dataItem.billingContact?.name1) {
            dataItem.billingContact.type = ContactType.Enum.Billing;
            formattedDataItem.contacts.push(dataItem.billingContact);
        }

        (formattedDataItem.vendorSocialMedias ?? []).map((socialMedia) => {
            socialMedia.socialMediaId = socialMedia.socialMedia.id;
            socialMedia.website = socialMedia.socialMedia.website;
        });

        await updateVendor(formattedDataItem).then(() => {
            triggerReload();
            toggleLoaderVisible(false);
            setVendor(undefined);
            onCancel();
        });
    };

    return (
        <div>
            {visible && vendor && (
                <Form
                    initialValues={vendor}
                    onSubmit={submitVendor}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Vendor: " + vendor.name}
                            onClose={onCancel}
                            size={"large"}
                        >
                            <div>
                                <Button
                                    themeColor={
                                        currentContext === "Company"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Company
                                </Button>
                                <Button
                                    themeColor={
                                        currentContext === "Billing"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Billing
                                </Button>
                                <Button
                                    themeColor={
                                        currentContext === "Contact"
                                            ? "info"
                                            : "primary"
                                    }
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    onClick={onContextSwitch}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Contact
                                </Button>
                            </div>
                            <FormElement>
                                {currentContext === "Company" && (
                                    <CompanyInformationForm
                                        formRenderProps={formRenderProps}
                                    />
                                )}
                                {currentContext === "Billing" && (
                                    <BillingInformationForm />
                                )}
                                {currentContext === "Contact" && (
                                    <span>
                                        <ContactInformationForm
                                            formRenderProps={formRenderProps}
                                        />
                                        <VendorSocialMediaForm
                                            formRenderProps={formRenderProps}
                                        />
                                    </span>
                                )}
                                {!loaderVisible ? (
                                    <div className="k-form-buttons">
                                        <Button
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={onCancel}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            themeColor={"info"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                            icon={"k-icon k-i-save"}
                                            disabled={
                                                !formRenderProps.allowSubmit
                                            }
                                        >
                                            Update
                                        </Button>
                                    </div>
                                ) : (
                                    <Loader type="infinite-spinner" />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default VendorEditComponent;
