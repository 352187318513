import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./LaborSummary.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider";
import { getAllLaborInfoForJobEvents } from "../../../../../services/Deprecated/customer/eventServices";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import LaborDetailComponent from "../../../Payments/Components/LaborDetailComponent";
import RowRenderHighlighter from "../../../../../components/Deprecated/RowRenderHighlighter";
import { decimalToTime } from "../../../../../resources/Deprecated/dateHelper";
import {
    CustomCell,
    YesNoBooleanCell,
} from "../../../../../components/Deprecated/CustomGridCells";

const LaborSummary = () => {
    const history = useHistory();
    const jobContext = useContext(JobContext);
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoadingData(true);

        const fetchLaborInfo = async () =>
            await getAllLaborInfoForJobEvents(jobContext.job?.id);

        fetchLaborInfo().then((res) => {
            setData(res.data);
            setLoadingData(false);
        });
    }, [jobContext.job?.id]);

    const expandChange = (event) => {
        let newData = data.map((labor) => {
            if (labor.employeeId === event.dataItem.employeeId) {
                labor.expanded = !event.dataItem.expanded;
            }
            return labor;
        });
        setData(newData);
    };

    const gotoEmployee = (employeeId) =>
        history.push(`/HumanResources/Employee/${employeeId}/`);

    return loadingData ? (
        <CenterLoader />
    ) : (
        <Card className={styles.LaborCard}>
            <CardHeader
                style={{
                    backgroundColor: "#67809F",
                    color: "#fff",
                }}
            >
                <CardTitle>Labor Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid
                    className={styles.LaborGrid}
                    data={data}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    detail={LaborDetailComponent}
                    rowRender={(row, props) =>
                        RowRenderHighlighter(row, {
                            isWarning: !props.dataItem.isAssignedToJob,
                        })
                    }
                >
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        title={"Laborer"}
                        field={"name"}
                        width={300}
                        locked={true}
                        className={"text-overflow"}
                        cell={(props) => (
                            <CustomCell>
                                <a
                                    className={"Link"}
                                    onClick={() =>
                                        gotoEmployee(props.dataItem.employee.id)
                                    }
                                >
                                    {props.dataItem.employee.name}
                                </a>
                            </CustomCell>
                        )}
                    />
                    <GridColumn
                        title={"Time Worked"}
                        field={"timeWorked"}
                        cell={(props) => (
                            <td>{decimalToTime(props.dataItem.timeWorked)}</td>
                        )}
                    />
                    <GridColumn
                        title={"Is Assigned to Job"}
                        field={"isAssignedToJob"}
                        cells={{
                            data: YesNoBooleanCell,
                        }}
                    />
                    <GridColumn title={"Total Labor"} format={"{0:c}"} />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default LaborSummary;
