import React, { useContext } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import {
    FormDateInput,
    FormInput,
    FormPhoneInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    dateValidator,
    emailValidator,
    nameValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { AddressInput } from "../../../../components/FormInputs/AddressInput.jsx";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts.js";

export const BasicInfoForm = ({ formRenderProps }) => {
    const employeeContext = useContext(EmployeeContext);
    const relationships = employeeContext.relationships;

    const selectedStatus = React.useMemo(() => {
        const selectedStatusId = formRenderProps?.value?.status?.id;
        return relationships?.status?.find(
            (status) => status.id === selectedStatusId
        );
    }, [formRenderProps, relationships]);

    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{
                rows: "10px",
                cols: "15px",
            }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Basic Information:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <Field
                        name={"firstName"}
                        component={FormInput}
                        label={"First Name:"}
                        validator={nameValidator}
                    />
                    <Field
                        name={"middleName"}
                        component={FormInput}
                        label={"Middle Name:"}
                    />
                    <Field
                        name={"lastName"}
                        component={FormInput}
                        label={"Last Name:"}
                        validator={nameValidator}
                    />
                    <Field
                        name={"address"}
                        component={AddressInput}
                        label={"Address"}
                        isRequired={true}
                        layout={InputLayouts.Vertical}
                    />
                </fieldset>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <div>
                        <Field
                            name={"phoneOne"}
                            component={FormPhoneInput}
                            formRenderProps={formRenderProps}
                        />
                    </div>
                    <div>
                        <Field
                            name={"phoneTwo"}
                            component={FormPhoneInput}
                            formRenderProps={formRenderProps}
                        />
                    </div>
                    <div>
                        <Field
                            name={"phoneThree"}
                            component={FormPhoneInput}
                            formRenderProps={formRenderProps}
                        />
                    </div>
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Field
                            name={"status.selection"}
                            component={DropDownList}
                            data={relationships?.status}
                            validator={requiredValidator}
                            dataItemKey="id"
                            textField="name"
                            label={"Status:"}
                            value={selectedStatus}
                        />
                        <Field
                            name="status.hiredDate"
                            label="Hire Date:"
                            component={FormDateInput}
                            validator={dateValidator}
                            hint="MM/DD/YYYY"
                            isRequired={true}
                        />
                    </div>
                    <Field
                        name={"email"}
                        component={FormInput}
                        validator={emailValidator}
                        label={"Email:"}
                    />
                    <Field
                        name={"hourlyRate.amount"}
                        component={NumericTextBox}
                        label={"Hourly Rate:"}
                        format="c2"
                        min={0}
                    />
                </fieldset>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default BasicInfoForm;
