import { ContactType } from "../../../../resources/Enums/ContactType";

export default class JobNetworkFormatter {
    static FormatJob(data) {
        let job = {
            id: data.id,
            name: data.name,
            description: data.description,
            businessInformationId: data.businessInformation.id,
            customerId: data.customer.id,
            address: {
                city: data.address?.city,
                state: data.address?.state?.name,
                zipCode: data.address?.zipCode,
                address1: data.address?.address1,
                address2: data.address?.address2,
            },
            contacts: [],
            existingContactIds: [],
        };

        if (
            data.primaryContact?.name1 &&
            (!data.isUsingCustomerPrimaryContact || data.id)
        ) {
            job.contacts.push({
                id: data.primaryContact.id,
                name1: data.primaryContact.name1,
                name2: data.primaryContact.name2,
                label: data.primaryContact.label,
                email: data.primaryContact.email,
                phone: data.primaryContact.phone
                    ? {
                          typeId:
                              data.primaryContact.phone?.typeId ??
                              data.primaryContact.phone?.phoneNumberType.id,
                          phoneNumber: data.primaryContact.phone?.phoneNumber,
                      }
                    : undefined,
                address: {},
                relationship: data.primaryContact.relationship,
                type: ContactType.Enum.Primary,
            });
        } else if (data.isUsingCustomerPrimaryContact) {
            job.existingContactIds.push(data.primaryContact.id);
        }

        if (
            data.secondaryContact?.name1 &&
            (!data.isUsingCustomerSecondaryContact || data.id)
        ) {
            job.contacts.push({
                id: data.secondaryContact.id,
                name1: data.secondaryContact.name1,
                name2: data.secondaryContact.name2,
                label: data.secondaryContact.label,
                email: data.secondaryContact.email,
                phone: data.secondaryContact.phone && {
                    typeId:
                        data.secondaryContact.phone?.typeId ??
                        data.secondaryContact.phone?.phoneNumberType.id,
                    phoneNumber: data.secondaryContact.phone?.phoneNumber,
                },
                address: {},
                relationship: data.secondaryContact.relationship,
                type: ContactType.Enum.Secondary,
            });
        } else if (
            data.isUsingCustomerSecondaryContact &&
            data.secondaryContact?.id
        ) {
            job.existingContactIds.push(data.secondaryContact.id);
        }

        return job;
    }
}
