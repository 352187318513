import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const AlertModal = ({ message, visible, onClose }) => {
    return (
        <>
            {visible && (
                <Dialog
                    title={"Alert"}
                    onClose={onClose}
                    width={300}
                    height={"auto"}
                >
                    <p
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px",
                        }}
                    >
                        {message}
                    </p>
                    <Button
                        themeColor={"primary"}
                        fillMode={"solid"}
                        rounded={"medium"}
                        onClick={onClose}
                        style={{ width: "100px", margin: "10px" }}
                    >
                        OK
                    </Button>
                </Dialog>
            )}
        </>
    );
};
export default AlertModal;
