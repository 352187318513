import * as React from "react";
import { useContext } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { BasicInfoForm } from "./BasicInformationForm";
import RelationshipForm from "./EmployeeRelationshipForm";

import { Loader } from "@progress/kendo-react-indicators";
import ContactInformationForm from "./EmployeeContactForm";
import PreCreateEmployeeSearchModal from "./PreCreateEmployeeSearchModal";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { useModal } from "../../../../hooks/useModal";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ContactHelper from "../../../../resources/ContactHelper";
import { ContactType } from "../../../../resources/Enums/ContactType";
import { addEmployee } from "../../../../services/Deprecated/humanResources/employeeServices";
import PermissionButton from "../../../../components/Deprecated/PermissionButton";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";

// Define a component called "ToolCreateForm" that accepts "cancelAdd" and "onAdd" as props
const EmployeeCreateForm = () => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const preCreateModal = useModal();
    // Set up state variables to hold the Employee data
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const initialStepState = [
        {
            label: "Basic Info",
            isValid: undefined,
        },
        {
            label: "Details",
            isValid: undefined,
        },
        {
            label: "Contact Info",
            isValid: undefined,
        },
    ];
    const [steps, setSteps] = React.useState(initialStepState);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const { triggerReload } = useContext(ReloadDataContext);

    /**
     Callback function triggered when a step is submitted. This function sets the steps and
     determines if a step is valid or not. It also will submit the vendor if the last step is valid.

     @param {Object} event - The event object containing information about the step submission.
     */

    const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));
            setSteps(currentSteps);
            if (!isValid) {
                return;
            }
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);
            if (isLastStep) {
                submitEmployee(values);
            }
        },
        [steps, isLastStep, step, lastStepIndex]
    );

    /**
     Function that will be called when the user clicks the previous button.

     @param {Object} event - The event object containing information about the prev step submission.
     */
    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    // Define a function to handle form submission
    const submitEmployee = async (dataItem) => {
        toggleLoaderVisible();

        if (dataItem.emergencyContact) {
            dataItem.contacts = [
                ContactHelper.build(
                    dataItem.emergencyContact,
                    ContactType.Enum.Emergency
                ),
            ];
        }

        addEmployee(dataItem)
            .then(() => {
                triggerReload();
                toggleCreateVisible();
                setFormState({});
                setStep(0);
                setSteps(initialStepState);
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {/* This is the confirmation toggle, this will be visible once the create button is toggled */}
            <PermissionButton
                requiredPermissions={[PermissionsEnum.CreateEmployee]}
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                onClick={() => preCreateModal.open()}
                style={{
                    width: "180px",
                    margin: "5px",
                }}
            >
                Create New Employee
            </PermissionButton>
            <PreCreateEmployeeSearchModal
                {...preCreateModal}
                setFormState={setFormState}
                toggleCreateVisible={toggleCreateVisible}
            />
            {createVisible && (
                <span>
                    <Form
                        onSubmitClick={onStepSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Employee"}
                                onClose={toggleCreateVisible}
                                size={"large"}
                            >
                                <Stepper value={step} items={steps} />
                                <FormElement>
                                    {step === 0 && (
                                        <BasicInfoForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {step === 1 && (
                                        <RelationshipForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {step === 2 && (
                                        <span>
                                            <ContactInformationForm
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            Step {step + 1} of 3
                                        </span>
                                        {/* If the loader is not visible, display the buttons, otherwise display the loader */}
                                        {!loaderVisible ? (
                                            <div>
                                                {step !== 0 ? (
                                                    <Button
                                                        onClick={onPrevClick}
                                                        themeColor={"primary"}
                                                        fillMode={"solid"}
                                                        rounded={"medium"}
                                                        style={{
                                                            width: "100px",
                                                            margin: "10px",
                                                        }}
                                                        icon={"k-icon k-i-undo"}
                                                    >
                                                        Previous
                                                    </Button>
                                                ) : undefined}
                                                <Button
                                                    themeColor={
                                                        isLastStep
                                                            ? "success"
                                                            : "primary"
                                                    }
                                                    disabled={
                                                        !formRenderProps.allowSubmit
                                                    }
                                                    onClick={
                                                        formRenderProps.onSubmit
                                                    }
                                                    fillMode={"solid"}
                                                    rounded={"medium"}
                                                    style={{
                                                        width: "100px",
                                                        margin: "10px",
                                                    }}
                                                    icon={`k-icon ${
                                                        isLastStep
                                                            ? "k-i-plus"
                                                            : "k-i-redo"
                                                    }`}
                                                >
                                                    {isLastStep
                                                        ? "Submit"
                                                        : "Next"}
                                                </Button>
                                            </div>
                                        ) : (
                                            <Loader type="infinite-spinner" />
                                        )}
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};
export default EmployeeCreateForm;
