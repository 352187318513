import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class LeadService
 * @extends BaseService
 * @description Service class that handles all lead related requests
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class LeadService extends BaseService {
    constructor(uri) {
        super(uri);
    }

    /**
     * @method search
     * @description Searches for leads
     * @param query {String} - The query to search for
     * @param type {String} - The type of lead to search for
     * @return {Promise<ServiceResponse>}
     */
    async search(query, type) {
        const response = await axios.get(
            `/Customer/Lead/Search?q=${query}&type=${type}&`
        );
        return formatServiceResponse(response);
    }

    /**
     * @method search
     * @description Searches for leads
     * @param id {String} - ID for lead to update
     * @param status {String} - The status for the lead to be set to
     * @param heldTill {String} - optional - date to hold lead until
     * @return {Promise<ServiceResponse>}
     */
    async updateStatus(id, status, heldTill = null) {
        const response = await axios.put(`/Customer/Lead/UpdateStatus`, {
            id,
            status,
            heldTill,
        });
        return formatServiceResponse(response);
    }

    /**
     * @method search
     * @description Searches for leads
     * @param leadId {String} - ID for lead to update
     * @return {Promise<ServiceResponse>}
     */
    async convertToCustomer(leadId) {
        const response = await axios.post(`/Customer/Lead/ConvertToCustomer`, {
            leadId,
        });
        return formatServiceResponse(response);
    }
}
