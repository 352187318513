import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import InventoryService from "../../../../services/Deprecated/inventory/InventoryService";
import {ReloadDataContext} from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import {FormButtons} from "../../../../components/Buttons/FormButtons";
import {FormNumericInput, FormRemoteDropDown,} from "../../../../components/Deprecated/FormComponents";
import {requiredValidator} from "../../../../resources/Deprecated/formValidators";
import {InventoryModalHelper} from "../Helpers/InventoryModalHelper";

/**
 * @function InventoryModal
 * @param props.id {string} - The ID of the inventory item.
 * @param props.isUpdate {boolean} - Whether the modal is for updating an existing item.
 * @param props.close {function} - Function to close the modal.
 * @param props.visible {boolean} - Whether the modal is visible.
 * @param props.record {object} - The record to be updated.
 * @param props.setRecord {function} - Function to set the record.
 * @return {unknown}
 * @constructor
 */
export const InventoryModal = (props) => {
    const { id, isUpdate, close, visible, record, setRecord } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(undefined);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            InventoryService.get(id).then((res) => {
                const data = InventoryModalHelper.formatDataForModal(res.data);
                setInitialFormData(data);
            });
        } else {
            setInitialFormData(record ?? {});
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        setRecord(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = InventoryModalHelper.formatDataForNetwork(dataItem);

        const service = isUpdate
            ? InventoryService.update
            : InventoryService.create;

        service(data).then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={isUpdate ? "Edit Inventory" : "Add Inventory"}
                onClose={onClose}
                size={"medium"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div style={{ width: "100%", maxWidth: "650px" }}>
                                <span>
                                    <Field
                                        name={"storageLocation"}
                                        component={FormRemoteDropDown}
                                        url={
                                            "/Inventory/StorageLocation/GetAll?"
                                        }
                                        label={"Select a Storage Location..."}
                                        style={{ width: "100%" }}
                                        validator={requiredValidator}
                                    />
                                    {!record?.hideVendor && (
                                        <Field
                                            name={"vendor"}
                                            component={FormRemoteDropDown}
                                            url={"/Inventory/Vendor/GetAll?"}
                                            label={"Select a Vendor..."}
                                            style={{ width: "100%" }}
                                            validator={requiredValidator}
                                            onChange={(e) => {
                                                setSelectedVendor(e.value);
                                                formRenderProps.onChange(
                                                    "item",
                                                    {
                                                        value: "",
                                                    }
                                                );
                                            }}
                                        />
                                    )}
                                    {!record?.hideItem && (
                                        <Field
                                            name={"item"}
                                            key={selectedVendor?.id}
                                            component={FormRemoteDropDown}
                                            textField={"item.name"}
                                            url={
                                                "/Inventory/Vendor/Item/GetAll?"
                                            }
                                            searchFields={["item.name"]}
                                            label={"Select a Item..."}
                                            style={{ width: "100%" }}
                                            disabled={!selectedVendor}
                                            initialFilters={[
                                                {
                                                    field: "vendorId",
                                                    operator: "eq",
                                                    value: selectedVendor?.id,
                                                },
                                                {
                                                    field: "item.IsActive",
                                                    operator: "eq",
                                                    value: true,
                                                },
                                            ]}
                                            onChange={(e) => {
                                                const itemType =
                                                    e.value?.item?.itemType;
                                                setRecord({
                                                    ...record,
                                                    itemType,
                                                });
                                                formRenderProps.onChange(
                                                    "length",
                                                    {
                                                        value:
                                                            itemType?.name ===
                                                            "Slab"
                                                                ? e.value?.item
                                                                      ?.defaultLength
                                                                : "",
                                                    }
                                                );
                                                formRenderProps.onChange(
                                                    "width",
                                                    {
                                                        value:
                                                            itemType?.name ===
                                                            "Slab"
                                                                ? e.value?.item
                                                                      ?.defaultWidth
                                                                : "",
                                                    }
                                                );
                                            }}
                                        />
                                    )}
                                    <Field
                                        name={"quantity"}
                                        component={FormNumericInput}
                                        label={"Quantity:"}
                                        min={1}
                                        defaultValue={1}
                                    />
                                </span>
                                <span>
                                    <Field
                                        name={"cost.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Cost:"}
                                        min={0}
                                        validator={requiredValidator}
                                        defaultValue={0}
                                    />
                                    <Field
                                        name={"tax.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Tax:"}
                                        min={0}
                                    />
                                    <Field
                                        name={"adjustment.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Adjustment:"}
                                        min={0}
                                    />
                                    <Field
                                        name={"freight.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Freight:"}
                                        min={0}
                                    />
                                    {record?.itemType?.name === "Slab" && (
                                        <>
                                            <hr
                                                className={"Separator"}
                                                style={{
                                                    marginTop: "15px",
                                                    marginBottom: "15px",
                                                }}
                                            />
                                            <Field
                                                name={"length"}
                                                component={FormNumericInput}
                                                label={"Length (Inch):"}
                                                min={0}
                                                format={"0"}
                                                validator={
                                                    record?.itemType?.name ===
                                                        "Slab" &&
                                                    requiredValidator
                                                }
                                            />
                                            <Field
                                                name={"width"}
                                                component={FormNumericInput}
                                                label={"Width (Inch):"}
                                                min={0}
                                                format={"0"}
                                                validator={
                                                    record?.itemType?.name ===
                                                        "Slab" &&
                                                    requiredValidator
                                                }
                                            />
                                        </>
                                    )}
                                </span>
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!isUpdate}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
