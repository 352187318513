import React from "react";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { TimeCardExtension } from "../../../HumanResources/TimeClockEntry/Components/TimeCardExtension";

const SchedulerTimeClockViewModal = (props) => {
    const { record, close, visible, dateRange } = props;

    return (
        visible && (
            <ResponsiveDialog
                title={record.employeeName + "'s Time Card for Event"}
                onClose={() => close()}
                size={"medium"}
            >
                <TimeCardExtension
                    employeeId={record.employeeId}
                    initialDateRange={dateRange}
                    initialFilter={`calendarEventId~eq~'${record.calendarEventId}'`}
                />
            </ResponsiveDialog>
        )
    );
};

export default SchedulerTimeClockViewModal;
