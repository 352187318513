import * as React from "react";
import { useMsal } from "@azure/msal-react";

const NoTenantPage = () => {
    const { instance } = useMsal();

    const handleSignOut = () => {
        instance.logoutRedirect();
    };
    return (
        <div id="no-tenant">
            <div id="no-tenant-content">
                <h2 className="form-title">Clear Business Insight</h2>
                <div className="panel">
                    <div className="panel-body">
                        <h1>Error</h1>
                        <p>
                            You have logged in to an account without a company.
                            Please <a onClick={handleSignOut}>sign out</a> and
                            try a different account, or contact your company
                            administrator to add your email to their employee
                            records.
                        </p>
                        <p>
                            If you are the company administrator, you may need
                            to{" "}
                            <a href={"mailto:support@sevenhillstechnology.com"}>
                                contact support
                            </a>{" "}
                            to have your company properly configured.
                        </p>
                    </div>
                </div>
                <div className="copyright">
                    <p> © 2014–2023 Clear Business Insight</p>
                </div>
            </div>
        </div>
    );
};

export default NoTenantPage;
