/**
 * Take an Axios Response and return it in a standardized format
 * @function formatServiceResponse
 * @param {axios.AxiosResponse<any> | axios.AxiosError<any> | any} response
 * @param {string} errorMessage
 * @return {ServiceResponse}
 */
export function formatServiceResponse(response, errorMessage = "") {
    if (response?.message && response?.name === "AxiosError") {
        // response is an error from axios
        console.error("Axios Request Failed", response);
        return {
            success: false,
            data: null,
            error: errorMessage ?? response.message,
            originalResponse: response,
        };
    } else if (response?.status) {
        // response from axios was successful
        return {
            success: true,
            data: response?.data?.data ?? response?.data?.value,
            error: errorMessage ?? "",
            originalResponse: response,
        };
    } else {
        // unhandled error or data was passed to the function
        // assume this is not a successful case
        console.error(
            "Service Request Failed. Review attached response, or enable trace logging in serviceUtility.js for debugging.",
            response
        );
        // console.trace();
        return {
            success: false,
            data: null,
            error:
                errorMessage ??
                "Return value was not an axios request or response, see originalResponse for more details",
            originalResponse: response,
        };
    }
}
