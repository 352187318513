import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class ItemService
 * @extends BaseService
 * @description Service class that handles all item related requests
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class ItemService extends BaseService {
    constructor(uri) {
        super(uri ?? "Inventory/Item");
    }

    /**
     * @method getInventoryInformation
     * @description Gets inventory information for an item
     * @param itemId {String} - The ID of the item to get inventory information for
     * @return {Promise<ServiceResponse>}
     */
    async getInventoryInformation(itemId) {
        const res = await axios.get(
            `${this.uri}/Inventory/GetInformation?itemId=${itemId}&`
        );
        return formatServiceResponse(res);
    }

    /**
     * @method getVendors
     * @description Gets all vendors for an item
     * @param itemId {String} - The ID of the item to get vendors for
     * @return {Promise<ServiceResponse>}
     */
    async getVendors(itemId) {
        const res = await axios.get(
            `${this.uri}/Vendor/GetAll?itemId=${itemId}&`
        );
        return formatServiceResponse(res);
    }

    /**
     * @method duplicate
     * @description Duplicates an item
     * @param itemId {String} - The ID of the item to duplicate
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async duplicate(itemId) {
        const res = await axios.post(`${this.uri}/Duplicate?`, {
            itemId,
        });
        return formatServiceResponse(res);
    }
}
