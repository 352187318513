import React, { useContext } from "react";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import FormField from "../../../../components/Deprecated/StylingField";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const VendorDetails = () => {
    const vendorContext = useContext(VendorContext);

    return vendorContext.vendor.id ? (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
                padding: "10px",
            }}
        >
            {/* Column 1 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <FormField label="Name" value={vendorContext.vendor.name} />
                <FormField
                    label="City"
                    value={vendorContext.vendor.address.city}
                />
                <FormField
                    label="State"
                    value={vendorContext.vendor.address.state}
                />
            </div>
            {/* Column 2 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <FormField
                    label="Address"
                    value={vendorContext.vendor.address.address1}
                />
                <FormField
                    label="Secondary Address"
                    value={vendorContext.vendor.address.address2}
                />
                <FormField
                    label="Zip"
                    value={vendorContext.vendor.address.zipCode}
                />
            </div>
            {/* Column 3 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <FormField
                    label="Business Phone"
                    value={
                        "[" +
                        (vendorContext.vendor?.businessPhone?.phoneNumberType
                            ?.name ?? "NA") +
                        "] " +
                        (vendorContext.vendor?.businessPhone?.phoneNumber ??
                            "NA")
                    }
                />

                <FormField
                    label="Business Email"
                    value={vendorContext.vendor?.businessEmailAddress}
                />
                <FormField
                    label="Business Website"
                    value={vendorContext.vendor?.businessWebsite}
                />
            </div>
            {/* Column 4 */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <FormField
                    label="Can be used for Field PO"
                    value={`${vendorContext.vendor.usableForFieldPurchaseOrder}`}
                />
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default VendorDetails;
