import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../ReloadDataProvider";
import { useToggle } from "../../../hooks/Deprecated/useToggle.js";
import { getAllCustomerEventTypes } from "../../../services/Deprecated/customer/eventServices.js";
import { getAllEmployees } from "../../../services/Deprecated/humanResources/employeeServices.js";
import {
    getJob,
    hasAllocationsNeeded,
} from "../../../services/Deprecated/customer/jobServices.js";

export const JobContext = createContext(null);

const JobProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const { bool: relationshipsLoading, toggle: toggleRelationshipsLoading } =
        useToggle(true);
    const [relationships, setRelationships] = useState({});
    const [job, setJob] = useState({});
    const [jobId, setJobId] = useState();
    const [needsAllocations, setNeedsAllocations] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const eventTypes = await getAllCustomerEventTypes();
            const employeesServiceResponse = await getAllEmployees();
            setRelationships({
                eventTypes,
                employees: employeesServiceResponse.data,
            });
            toggleRelationshipsLoading();
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (!jobId) {
            setJob({});
            return;
        }

        const fetchJobData = async (jobId) => {
            try {
                const serviceResponse = await getJob(jobId);
                setJob(serviceResponse.data);
                hasAllocationsNeeded(jobId).then((res) => {
                    setNeedsAllocations(res.data);
                });
            } catch (error) {
                console.error("Error fetching customer details:", error);
            }
        };

        fetchJobData(jobId).then(() => {
            console.log("Fetched Job Details");
        });
    }, [jobId, reloadDataContext.reloadData]);

    const value = {
        relationships,
        relationshipsLoading,
        job,
        jobId,
        setJobId,
        needsAllocations,
        setNeedsAllocations,
    };

    return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobProvider;
