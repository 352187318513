import React, { useContext, useEffect } from "react";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { ContentHeader } from "../../../../components/ContentHeader";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";

const JobContact = ({ formRenderProps, job }) => {
    const customerContext = useContext(CustomerContext);
    const useCustomerPrimaryContact = formRenderProps.valueGetter(
        "isUsingCustomerPrimaryContact"
    );
    const useCustomerSecondaryContact = formRenderProps.valueGetter(
        "isUsingCustomerSecondaryContact"
    );

    const isBusinessCustomer =
        customerContext.customer?.type === CustomerTypeEnum.Enum.Business;

    const resetContact = (contact) => {
        if (job) {
            formRenderProps.onChange(contact, {
                value: job[contact],
            });
        } else {
            formRenderProps.onChange(contact, { value: "" });
        }
    };

    useEffect(() => {
        if (useCustomerPrimaryContact) {
            const { primaryContact } = customerContext.customer;

            formRenderProps.onChange("primaryContact", {
                value: primaryContact,
            });
        } else {
            resetContact("primaryContact");
        }
    }, [
        customerContext.customer,
        useCustomerPrimaryContact,
        isBusinessCustomer,
    ]);

    useEffect(() => {
        if (useCustomerSecondaryContact) {
            const { secondaryContact } = customerContext.customer;

            formRenderProps.onChange("secondaryContact", {
                value: secondaryContact,
            });
        } else {
            resetContact("secondaryContact");
        }
    }, [
        customerContext.customer,
        useCustomerSecondaryContact,
        isBusinessCustomer,
    ]);

    return (
        <div>
            <ContentHeader
                title={"Primary"}
                style={{ textAlign: "left", fontSize: 16 }}
            />
            {isBusinessCustomer && (
                <Field
                    name={"isUsingCustomerPrimaryContact"}
                    label={"Use Customer's Primary Contact"}
                    component={Checkbox}
                    className={"JustifyLeftAlignCenter"}
                />
            )}
            <ContactInput
                field={"primaryContact"}
                isRequired={!useCustomerPrimaryContact}
                formRenderProps={formRenderProps}
                disabled={useCustomerPrimaryContact || !isBusinessCustomer}
            />
            {isBusinessCustomer && (
                <Field
                    name={"primaryContact.label"}
                    component={FormInput}
                    label={"Business Title:"}
                    disabled={useCustomerPrimaryContact}
                    keepValue={true}
                />
            )}
            <ContentHeader
                title={"Secondary"}
                style={{ textAlign: "left", fontSize: 16 }}
            />
            {isBusinessCustomer && (
                <Field
                    name={"isUsingCustomerSecondaryContact"}
                    label={"Use Customer's Secondary Contact"}
                    component={Checkbox}
                    className={"JustifyLeftAlignCenter"}
                />
            )}
            <ContactInput
                field={"secondaryContact"}
                isRequired={false}
                formRenderProps={formRenderProps}
                disabled={useCustomerSecondaryContact || !isBusinessCustomer}
            />
            {isBusinessCustomer && (
                <Field
                    name={"secondaryContact.label"}
                    component={FormInput}
                    label={"Business Title:"}
                    disabled={useCustomerSecondaryContact}
                    keepValue={true}
                />
            )}
        </div>
    );
};

export default JobContact;
