import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * @function createRole
 * @description Function to create a role
 * @param {Object} roleCreationObject
 * @returns {Promise<ServiceResponse>}
 */
export const createRole = async (roleCreationObject) => {
    try {
        const response = await axios.post(
            `/Authentication/Role/Create`,
            roleCreationObject
        );

        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateRole
 * @description Function to update a role
 * @param {Object} roleEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateRole = async (roleEditObject) => {
    try {
        const response = await axios.put(
            `/Authentication/Role/Update`,
            roleEditObject
        );

        // Assuming response.data contains the updated role data
        const responseData = {
            id: response.data.id,
            name: response.data.name,
            // Include other properties as needed
        };
        return formatServiceResponse(responseData);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteRole
 * @description Function to delete a role
 * @param {String} roleId
 * @returns {Object}
 */
export const deleteRole = async (roleId) => {
    try {
        const response = await axios.delete(
            `/Authentication/Role/Delete?id=${roleId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllRoles
 * @description Function to get all roles
 * @returns {Promise<ServiceResponse>}
 */
export const getAllRoles = async () => {
    try {
        const response = await axios.get("/Authentication/Role/GetAll");
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Sends GET request to API to get the current default role
 * @function getDefaultRole
 * @async
 * @returns  {Promise<ServiceResponse>}
 */
export const getDefaultRole = async () => {
    try {
        const response = await axios.get(
            "/Authentication/Role/GetDefaultRole",
            {
                validateStatus: (status) =>
                    (status >= 200 && status < 300) || status === 404,
            }
        );
        return formatServiceResponse(response);
    } catch (error) {
        formatServiceResponse(error);
    }
};

/**
 * Sends PUT request to API to set the current default role
 * @function setDefaultRole
 * @async
 * @param {string} roleId
 * @returns {Promise<ServiceResponse>}
 */
export const setDefaultRole = async (roleId) => {
    const response = await axios.post(`/Authentication/Role/SetDefault`, {
        roleId,
    });
    return formatServiceResponse(response);
};
