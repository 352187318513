// Import axios library
import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * @function addMaritalStatus
 * @description Function to create a Martial Status
 * @param maritalStatusData
 * @returns {Promise<ServiceResponse>}
 */
export const addMaritalStatus = async (maritalStatusData) => {
    try {
        // Send a POST request to the server with the marital data
        const response = await axios.post(
            "/HumanResources/MaritalStatus/Create",
            maritalStatusData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const getMaritalStatus = async (maritalStatusId) => {
    try {
        // Send a GET request to the server with the maritalStatusId ID
        const response = await axios.get(
            `/HumanResources/MaritalStatus/Get?id=${maritalStatusId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllMaritalStatus
 * @description Function to get all Martial Statuses in Employee Module
 * @returns {Promise<ServiceResponse>}
 */
export const getAllMaritalStatus = async () => {
    try {
        const response = await axios.get(
            `/HumanResources/MaritalStatus/GetAll`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateMartialStatus
 * @description Function to update a Marital Status in the Employee Module
 * @param maritalStatusData
 * @returns {Promise<ServiceResponse>}
 */
export const updateMaritalStatus = async (maritalStatusData) => {
    try {
        // Send a PUT request to the server with the marital data
        const response = await axios.put(
            "/HumanResources/MaritalStatus/Update",
            maritalStatusData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteMaritalStatus
 * @description Function to delete a Martial Status from the Employee Module
 * @param maritalStatusId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteMaritalStatus = async (maritalStatusId) => {
    try {
        // Send a DELETE request to the server with the maritalStatusId ID
        const response = await axios.delete(
            `/HumanResources/MaritalStatus/Delete?id=${maritalStatusId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};
