import { useEffect, useState } from "react";
import ChartOfAccountsService from "../../services/Deprecated/accounting/ChartOfAccountsService.js";
import { AccountHelper } from "../../pages/Accounting/Helpers/AccountHelper.js";
import { AccountType } from "../../resources/Enums/AccountType.js";

/**
 * Fetches all accounts.
 * @param {boolean} allowLoad - Whether or not to allow the component to load in the data.
 * @returns {Object} The employees for the HumanResources Module.
 */
export function useAccounts(allowLoad = true) {
    const [accounts, setAccounts] = useState([]);
    const [incomeAccounts, setIncomeAccounts] = useState([]);
    const [expenseAccounts, setExpenseAccounts] = useState([]);

    useEffect(() => {
        if (!allowLoad) return;

        ChartOfAccountsService.getAllAccounts().then((res) => {
            const accounts = res.data.flatMap((acc) =>
                AccountHelper.flatmapAccounts(acc)
            );
            const incomeAccounts = accounts.filter((a) =>
                AccountType.IsIncomeType(a.type)
            );
            const expenseAccounts = accounts.filter((a) =>
                AccountType.IsExpenseType(a.type)
            );

            setAccounts(accounts);
            setIncomeAccounts(incomeAccounts);
            setExpenseAccounts(expenseAccounts);
        });
    }, [allowLoad]);

    return { accounts, incomeAccounts, expenseAccounts };
}
