import { axiosInstance as axios } from "../axiosInstance";
import { formatServiceResponse } from "../../utilities/serviceUtility";

//?
//? All API calls related to files are defined here
//?

/**
 * @function getDirectoryContents
 * @description Function to get all files in the file directory
 * @param {string} uri
 * @param {string} location
 * @returns {Promise<ServiceResponse>} response.data
 */
export const getDirectoryContents = async (uri, location) => {
    try {
        const response = await axios.get(`${uri}?location=${location}`, {
            validateStatus: (status) =>
                (status >= 200 && status < 300) || status === 404,
        });

        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getDirectoryContents
 * @description Function to get all files in the file directory
 * @param {string} uri
 * @param {string} location
 * @param {string} fileName
 * @returns {Object} response.data
 */
export const deleteFile = async (uri, location, fileName) => {
    try {
        const response = await axios.delete(
            `${uri}?location=${location}&fileName=${fileName}`
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
