import * as React from "react";
import { TreeListCell } from "@progress/kendo-react-treelist";

const BalanceSheetTLNameColumnCell = (props) => {
    const itemIsRoot = !props.dataItem.parentReferenceId;
    const itemIsTotalLine = !props.dataItem.referenceId;
    //? Looks rough but basically capitalize the name if there is no parent (root)
    props.dataItem.name = itemIsRoot
        ? props.dataItem.name.toUpperCase()
        : props.dataItem.name;

    const style = itemIsTotalLine
        ? {
              fontWeight: "bold",
              borderTop: "1px solid",
          }
        : undefined;

    return (
        <TreeListCell {...props} style={style} className={"RemoveRightCaret"} />
    );
};

export default BalanceSheetTLNameColumnCell;
