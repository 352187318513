import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { ContentHeader } from "../../../../components/ContentHeader";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { AddressInput } from "../../../../components/FormInputs/AddressInput";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators.js";

const ContactInformationForm = ({ formRenderProps }) => {
    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{
                rows: "10px",
                cols: "15px",
            }}
            style={{ paddingBottom: "30px" }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Emergency Information:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <ContentHeader
                    title={"Primary"}
                    style={{ textAlign: "left", fontSize: 16 }}
                />
                <ContactInput
                    field={"emergencyContact"}
                    isRequired={true}
                    formRenderProps={formRenderProps}
                />
                <Field
                    name={"emergencyContact.relationship"}
                    component={FormInput}
                    validator={requiredValidator}
                    label={"Relation:"}
                />
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                <ContentHeader
                    title={"Emergency Address"}
                    style={{ textAlign: "left", fontSize: 16 }}
                />
                <Field
                    name={"emergencyContact.address"}
                    component={AddressInput}
                    label={"Address"}
                    isRequired={true}
                    layout={InputLayouts.Vertical}
                />
            </GridLayoutItem>
        </GridLayout>
    );
};

export default ContactInformationForm;
