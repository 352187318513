import React, { useState } from "react";

export const ToastContext = React.createContext(null);

export const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState(undefined);

    const showToast = (message, type) => {
        setToast({ message, type });
    };

    const clearToast = () => {
        setToast(undefined);
    };

    const value = {
        toast,
        showToast,
        clearToast,
    };

    return (
        <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
    );
};

export default ToastProvider;
