// Import axios library
import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getPhoneType = async (phoneTypeId) => {
    try {
        // Send a GET request to the server with the phoneType ID
        const response = await axios.get(
            `/General/PhoneType/Get?id=${phoneTypeId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

// Function to add a phone type
export const addPhoneType = async (phoneTypeData) => {
    try {
        // Send a POST request to the server with the phoneType data
        const response = await axios.post(
            "/General/PhoneType/Create",
            phoneTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const getAllPhoneTypes = async () => {
    try {
        const response = await axios.get(`/General/PhoneType/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

// Function to update a phone type
export const updatePhoneType = async (phoneTypeData) => {
    try {
        // Send a PUT request to the server with the phone type data
        const response = await axios.put(
            "/General/PhoneType/Update",
            phoneTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

// Function to delete a phone type
export const deletePhoneType = async (phoneTypeId) => {
    try {
        // Send a DELETE request to the server with the phoneType ID
        const response = await axios.delete(
            `/General/PhoneType/Delete?id=${phoneTypeId}`
        );
        // Return the response data
        return response.data;
    } catch (error) {
        // Log any errors and throw them
        console.error(error);
        throw error;
    }
};
