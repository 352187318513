import { cloneElement } from "react";

export const BackgroundColorWarning = {
    backgroundColor: "rgba(238, 210, 2, 0.3)",
};
export const BackgroundColorDanger = {
    backgroundColor: "rgba(243, 23, 0, 0.3)",
};
export const BackgroundColorSuccess = {
    backgroundColor: "rgba(144, 238, 144, 0.3)",
};

/**
 * Returns new row element with applied warning or danger highlight style
 * @param row
 * @param highlightObj
 * @constructor
 */
const RowRenderHighlighter = (row, highlightObj) => {
    let trProps;

    if (highlightObj.isWarning) {
        trProps = {
            style: BackgroundColorWarning,
        };
    } else if (highlightObj.isDanger) {
        trProps = {
            style: BackgroundColorDanger,
        };
    } else if (highlightObj.isSuccess) {
        trProps = {
            style: BackgroundColorSuccess,
        };
    }

    return cloneElement(
        row,
        {
            ...trProps,
        },
        row.props.children
    );
};

export default RowRenderHighlighter;
