import React, { createContext, useContext } from "react";
import { ToastContext } from "./ToastProvider.jsx";

export const ErrorContext = createContext(null);

const ErrorProvider = ({ children }) => {
    const { showToast } = useContext(ToastContext);

    const setErrorObject = (error) => {
        const errorArr = parseError(error);
        let messages = "";

        for (let i = 0; i < errorArr.length; i++) {
            messages += `${errorArr[i].title}: ${errorArr[i].message}\n`;
        }

        showToast(messages, "error");
    };

    const value = {
        setErrorObject,
    };

    return (
        <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
    );
};

export const parseError = (error) => {
    const response = error?.response;

    switch (response?.status) {
        case 400:
            return parse400Error(response);
        case 401:
            return parse401Error(response);
        case 403:
            return parse403Error(response);
        case 404:
            return parse404Error(response);
        case 409:
            return parse409Error(response);
        case 500:
            return parse500Error(response);
        default:
    }
};

const parse400Error = (response) => {
    const apiErrors = Object.entries(response.data.errors);
    const errObjectArr = [];

    for (let i = 0; i < apiErrors.length; i++) {
        const [_, value] = apiErrors[i];

        errObjectArr.push({
            title: "Bad Request",
            message: value[0],
        });
    }

    return errObjectArr;
};

const parse401Error = (response) => {
    return [
        {
            title: "Unauthorized",
            message: response.data.detail,
        },
    ];
};

const parse403Error = (response) => {
    return [
        {
            title: "Forbidden",
            message: response.data.detail,
        },
    ];
};

const parse404Error = (response) => {
    return [
        {
            title: "Not Found",
            message: response.data.detail,
        },
    ];
};

const parse409Error = (response) => {
    return [
        {
            title: "Conflict",
            message: response.data.detail,
        },
    ];
};

const parse500Error = (response) => {
    return [
        {
            title: "Server Error",
            message: response.data.detail,
        },
    ];
};

export default ErrorProvider;
