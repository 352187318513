import * as React from "react";
import { useContext, useEffect } from "react";
import { Breadcrumb } from "@progress/kendo-react-layout";
import { useHistory, useLocation } from "react-router-dom";
import config from "../config";
import { BreadcrumbContext } from "../providers/BreadCrumbProvider";
import { capitalize } from "../resources/Deprecated/stringFormatter";

const BreadCrumb = () => {
    const history = useHistory();
    const location = useLocation();
    const breadCrumbProvider = useContext(BreadcrumbContext);
    const [data, setData] = React.useState([]);

    useEffect(() => {
        ParseUrlLocation(location);
    }, [location]);

    const handleItemSelect = (event) => {
        const itemIndex = data.findIndex(
            (curValue) => curValue.id === event.id
        );
        const newData = data.slice(0, itemIndex + 1);
        // Map over each array item and return the id property, then join the array with a slash and replace any double slashes with a single slash
        // This is to prevent the url from looking like this: //products/computer//gaming//keyboard
        const urlString = newData
            .map((curValue) => curValue.id)
            .join("/")
            .replace(/\/{2,}/g, "/")
            .substring(2); // remove leading "/#" as React will handle that
        history.push(`${urlString}`);
    };

    // Parse the url and return an array of objects that contain the id and text properties
    const ParseUrlLocation = (location) => {
        const url = location.pathname;
        const urlArr = url.split("/").filter((curValue) => curValue !== "");
        const urlObjArr = FilterUrlAndConvertToObjectArr(
            urlArr,
            breadCrumbProvider
        );

        setData(urlObjArr);
    };

    return (
        <div
            style={{
                marginBottom: "25px",
            }}
        >
            <Breadcrumb data={data} onItemSelect={handleItemSelect} />
        </div>
    );
};

export default BreadCrumb;

// Filter the url array to remove any items that are not needed in the breadcrumb
// Or to Grey out blacklisted items
const FilterUrlAndConvertToObjectArr = (urlArr, breadCrumbProvider) => {
    const blackList = config.blackListedBreadCrumbUrls;

    const urlObjArr = urlArr.map((urlItem) => {
        if (blackList.includes(urlItem.toUpperCase())) {
            return {
                id: urlItem,
                text: capitalize(urlItem),
                disabled: true,
            };
        } else {
            return breadCrumbProvider.fetchCrumb(urlItem);
        }
    });

    // Always append Home to the front of the Arr
    urlObjArr.unshift({
        id: "/#",
        text: "Home",
        iconClass: "k-i-home",
    });

    return urlObjArr;
};
