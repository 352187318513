import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
    FormNumericInput,
    FormRemoteDropDown,
} from "../../../../components/Deprecated/FormComponents";
import {
    cannotBeNegativeValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import {
    createVendorItem,
    getAllVendorItemsByVendor,
    getVendorItem,
    updateVendorItem,
} from "../../../../services/Deprecated/inventory/vendorService";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const VendorItemModal = (props) => {
    const {
        id,
        record,
        close,
        visible,
        isUpdate,
        reloadAfterSubmit = true,
    } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [vendorItems, setVendorItems] = useState([]);
    const [initialFormData, setInitialFormData] = useState(undefined);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getVendorItem(id).then((res) => {
                setInitialFormData(res.data);
            });
        } else {
            setInitialFormData(record);
        }

        if (id) return;

        // Get Items already associated to the Vendor
        getAllVendorItemsByVendor(record.vendor.id).then((res) => {
            setVendorItems(res.data);
        });
    }, [visible]);

    const onSubmit = async (formData) => {
        setLoaderVisible(true);

        const submitData = isUpdate ? updateVendorItem : createVendorItem;
        submitData({
            id: formData?.id,
            vendorId: initialFormData.vendor.id,
            itemId: formData.item.id,
            vendorCost: {
                amount: formData.vendorCost?.amount ?? 0,
                currency: "USD",
            },
            freightCharge: {
                amount: formData.freightCharge?.amount ?? 0,
                currency: "USD",
            },
        }).then(onClose);
    };

    const onClose = () => {
        if (reloadAfterSubmit) {
            triggerReload();
        }
        setLoaderVisible(false);
        setInitialFormData(undefined);
        setVendorItems([]);
        close();
    };

    // Filter out items that are already associated with the vendor
    const filterItemData = (data) => {
        return data.filter((i) => !vendorItems.some((e) => e.itemId === i.id));
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={
                    isUpdate
                        ? "Edit Vendor Item"
                        : "Add existing Item to Vendor"
                }
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {!id && (
                                <Field
                                    name={"item"}
                                    component={FormRemoteDropDown}
                                    url={"/Inventory/Item/GetAll?"}
                                    validator={requiredValidator}
                                    dataMutator={filterItemData}
                                    label={"Select an Item"}
                                    style={{
                                        width: "100%",
                                        marginBottom: 10,
                                    }}
                                    initialFilters={{
                                        field: "IsActive",
                                        operator: "eq",
                                        value: true,
                                    }}
                                />
                            )}
                            <Field
                                name={"vendorCost.amount"}
                                component={FormNumericInput}
                                label={"Vendor Cost:"}
                                format={"c2"}
                                defaultValue={0}
                                validator={cannotBeNegativeValidator}
                            />
                            <Field
                                name={"freightCharge.amount"}
                                component={FormNumericInput}
                                format={"c2"}
                                label={"Freight Charge:"}
                                defaultValue={0}
                                validator={cannotBeNegativeValidator}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                isCreate={!id}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default VendorItemModal;
