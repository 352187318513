import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import JobCommunicationEdit from "./JobCommunicationEdit";
import JobCommunicationCreate from "./JobCommunicationCreate";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { ColumnMenu } from "../../../../components/Deprecated/ColumnMenu";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteJobCommunication } from "../../../../services/Deprecated/customer/jobServices";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const JobCommunications = () => {
    const jobContext = useContext(JobContext);

    return jobContext.job.id ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {jobContext.job.isMarkedDead || jobContext.job.isArchived ? (
                <Tooltip anchorElement="target" position="right">
                    <span
                        title={
                            "Tip: Cannot create a communication for a job that is marked dead or archived."
                        }
                    >
                        <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled"
                            style={{ marginBottom: "5px" }}
                        >
                            <span className="k-icon k-i-plus"></span>
                            Create New Communication
                        </button>
                    </span>
                </Tooltip>
            ) : (
                <JobCommunicationCreate />
            )}
            <DataGrid
                uri={`/Customer/Job/Communication/GetAll?jobId=${jobContext.job.id}&`}
                style={{ height: "225px" }}
            >
                <GridColumn
                    field="note"
                    title="Notes:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                />
                <GridColumn field="employeeWhoCreated.name" title="Creator:" />
                <GridColumn
                    field="created"
                    title="Date Created:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.created}
                        />
                    )}
                />
                <GridColumn
                    field="lastModified"
                    title="Date Modified:"
                    filter={"text"}
                    locked={true}
                    columnMenu={ColumnMenu}
                    width={150}
                    cell={(props) => (
                        <CustomDateCell
                            {...props}
                            data={props.dataItem.lastModified}
                        />
                    )}
                />
                <GridColumn
                    width={130}
                    cell={(props) =>
                        jobContext.job.isMarkedDead ||
                        jobContext.job.isArchived ? (
                            <td
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Tooltip anchorElement="target" position="left">
                                    <span
                                        title={
                                            "Tip: Cannot edit or delete a job communication that is marked dead or archived."
                                        }
                                    >
                                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled">
                                            Edit
                                        </button>
                                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-disabled">
                                            Delete
                                        </button>
                                    </span>
                                </Tooltip>
                            </td>
                        ) : (
                            <ActionButtonsCommandCell
                                record={props.dataItem}
                                dataId={props.dataItem.id}
                                action={deleteJobCommunication}
                                EditComponent={JobCommunicationEdit}
                                requiredPermissionsEdit={[
                                    PermissionsEnum.UpdateJob,
                                ]}
                                requiredPermissionsAction={[
                                    PermissionsEnum.DeleteJob,
                                ]}
                            />
                        )
                    }
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default JobCommunications;
