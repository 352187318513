import React from "react";
import { Link } from "react-router-dom";

const NoPermissionComponent = () => {
    return (
        <div>
            <h2>
                Sorry, you do not have the required permission to view this
                page.
            </h2>
            <p>
                Please go <Link to="/">Home</Link> or navigate to another page
                through the sidebar. If you believe this is an error, please
                contact your supervisor or support.
            </p>
        </div>
    );
};

export default NoPermissionComponent;
