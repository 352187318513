import React, { useState } from "react";
import dayjs from "dayjs";
import StringHelper from "../../../resources/StringHelper.js";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Field } from "@progress/kendo-react-form";
import styles from "../../../pages/Customer/JobDocuments/Components/JobDocumentModal/Styles/JobDocumentModal.module.scss";

/**
 * @function DocumentInformationDisplay
 * @description Displays information for a Document
 * @param props.formData {object} - the data for the document
 * @param props.setFormData {function} - the function to set the form data
 * @param props.type {string} - the type of document
 * @return {React.JSX.Element}
 * @constructor
 */
export const DocumentInformationDisplay = (props) => {
    const { formData, type, canDateBeEdited } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [date, setDate] = useState(formData.date);

    const displayType = () => {
        return StringHelper.addSpacesToCapitalLetters(type);
    };

    const onDateChange = (e) => {
        const value = e.value;
        setDate(value);
        setIsEditing(false);
    };

    return (
        <div className={`JustifyLeftAlignLeft FlexColumn MediumGap`}>
            <span
                className={"JustifyLeftAlignLeft SmallGap"}
                style={{ fontSize: 32 }}
            >
                <strong>{displayType()}: </strong>
                <span>(#{formData.documentNumber})</span>
            </span>
            {isEditing ? (
                <Field
                    name={"date"}
                    component={DatePicker}
                    value={date}
                    onChange={onDateChange}
                    show={true}
                />
            ) : canDateBeEdited ? (
                <span style={{ fontSize: 16, color: "#666666" }}>
                    Date:{" "}
                    <span
                        className={`text-overflow ${styles.LinkDisplay}`}
                        onClick={() => {
                            if (canDateBeEdited) {
                                setIsEditing(true);
                            }
                        }}
                    >
                        <i
                            className={"k-icon k-i-pencil"}
                            style={{ marginBottom: 4 }}
                        />{" "}
                        {dayjs(date).format("MM/DD/YYYY")}
                    </span>
                </span>
            ) : (
                <span
                    style={{ fontSize: 16, color: "#666666" }}
                    onClick={() => {
                        if (canDateBeEdited) {
                            setIsEditing(true);
                        }
                    }}
                >
                    Date: {dayjs(date).format("MM/DD/YYYY")}
                </span>
            )}
        </div>
    );
};
