import React, { useContext, useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import JobDetails from "../../Jobs/Components/JobDetails/JobDetails.jsx";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";

export const EventTabs = () => {
    const [selected, setSelected] = useState(0);
    const { customer } = useContext(CustomerContext);
    const { job } = useContext(JobContext);

    return (
        <TabStrip
            selected={selected}
            onSelect={(e) => setSelected(e.selected)}
            className="tabs"
            style={{ height: "350px" }}
        >
            <TabStripTab title="Details">
                <JobDetails
                    customer={customer}
                    job={job}
                    includeJobContactInformation={true}
                />
            </TabStripTab>
        </TabStrip>
    );
};
