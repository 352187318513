import React from "react";
import { FileManager } from "../../../../components/FileManager/FileManager";

const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
};

const JobFiles = (props) => {
    const { jobId } = props;

    return (
        <FileManager
            disabled={false}
            allowedExtensions={fileRestrictions}
            saveUri={"/General/File/UploadFile"}
            removeUri={"/General/File/DeleteFile"}
            readUri={"/General/File/GetDirectoryContents"}
            location={`JobFiles-${jobId}`}
        />
    );
};

export default JobFiles;
