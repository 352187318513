import * as React from "react";
import { useEffect, useState } from "react";
import { EventInformation } from "./Components/EventInformation";
import { CustomerAndJobInformation } from "./Components/CustomerAndJobInformation";
import { SignatureSection } from "./Components/SignatureSection";
import { InstallerNotesSection } from "./Components/InstallerNotesSection";
import { TaskInformation } from "./Components/TaskInformation";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import styles from "./PrintCalendarEventCardModal.module.scss";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { getEvent } from "../../../../../services/Deprecated/customer/eventServices";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";

export const PrintCalendarEventCardModal = (props) => {
    const { id, close, visible } = props;
    const [initialData, setInitialData] = useState(undefined);
    let componentRef = null;

    useEffect(() => {
        if (!visible || !id) return;

        getEvent(id).then((res) => {
            setInitialData(res.data);
        });
    }, [visible, id]);

    const onClose = () => {
        close();
    };

    return (
        visible &&
        initialData && (
            <ResponsiveDialog
                title={`Print ${initialData.eventDateTitle} Information`}
                onClose={onClose}
                size={"large"}
            >
                <span
                    className={"JustifyRightAlignCenter"}
                    style={{
                        marginBottom: "20px",
                    }}
                >
                    <ReactToPrint
                        trigger={() => (
                            <ActionButton
                                buttonText={"Print"}
                                icon={"k-i-icon k-i-print"}
                            />
                        )}
                        content={() => componentRef}
                        documentTitle={`${initialData.eventDateTitle}-${
                            initialData?.customer?.combinedName
                        }-${dayjs(initialData.eventDate).format("MM-DD-YYYY")}`}
                        bodyClass={styles.EventPrintBody}
                    />
                </span>
                <span
                    id={"EventCard"}
                    className={"DisplayFlex FlexColumn LargeGap"}
                    ref={(el) => (componentRef = el)}
                >
                    <EventInformation initialData={initialData} />
                    <CustomerAndJobInformation initialData={initialData} />
                    <TaskInformation categories={initialData.categories} />
                    <SignatureSection />
                    <InstallerNotesSection />
                </span>
            </ResponsiveDialog>
        )
    );
};
