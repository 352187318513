import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import {
    ContactType,
    contactTypeOptions,
} from "../../../../resources/Enums/ContactType";
import {
    getCustomer,
    updateCustomer,
} from "../../../../services/Deprecated/customer/customerServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import {
    FormDropDown,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import StringHelper from "../../../../resources/StringHelper";

const CustomerContactModal = (props) => {
    const { isUpdate, close, visible, id } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const customerContext = useContext(CustomerContext);
    const record = customerContext.customer;

    useEffect(() => {
        if (!visible) return;

        if (id && record) {
            // Find the contact by ID among primary or other contacts
            const primaryContact =
                record.primaryContact?.id === id ? record.primaryContact : null;
            const otherContact =
                record.otherContacts?.find((contact) => contact.id === id) ||
                null;

            const selectedContact = primaryContact || otherContact;

            if (selectedContact) {
                const contactType = contactTypeOptions.find(
                    (contactType) => contactType.value === selectedContact.type
                );
                setInitialFormData({
                    id: selectedContact.id || "",
                    type: contactType || "",
                    label: selectedContact.label || "",
                    name1: selectedContact.name1 || "",
                    name2: selectedContact.name2 || "",
                    email: selectedContact.email || "",
                    phone: selectedContact.phone
                        ? {
                              phoneNumber:
                                  selectedContact.phone?.phoneNumber || "",
                              phoneNumberType:
                                  selectedContact.phone?.phoneNumberType ||
                                  null,
                          }
                        : undefined,
                    address: { ...selectedContact.address },
                });
            }
        } else {
            setInitialFormData({});
        }
    }, [visible, id, record]);

    const toggleLoaderVisible = () => {
        setLoaderVisible((prevState) => !prevState);
    };

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const submitCustomerContacts = (dataItem) => {
        toggleLoaderVisible();

        let contacts = []; // Format the contacts array

        // Add primary contact to the contacts array
        if (record.primaryContact) {
            const formattedPrimaryContact = {
                ...record.primaryContact,
                phone: !StringHelper.isNullOrEmpty(
                    record.primaryContact?.phone?.phoneNumber
                )
                    ? {
                          phoneNumber:
                              record.primaryContact.phone?.phoneNumber || "",
                          typeId:
                              record.primaryContact.phone?.phoneNumberType
                                  ?.id || null,
                      }
                    : undefined,
            };
            contacts.push(formattedPrimaryContact);
        }

        // Add other contacts to the contacts array if they exist
        if (Array.isArray(record.otherContacts)) {
            record.otherContacts.forEach((contact) => {
                const formattedOtherContact = {
                    ...contact,
                    phone: !StringHelper.isNullOrEmpty(
                        contact.phone?.phoneNumber
                    )
                        ? {
                              phoneNumber: contact.phone?.phoneNumber || "",
                              typeId:
                                  contact.phone?.phoneNumberType?.id || null,
                          }
                        : undefined,
                };
                contacts.push(formattedOtherContact);
            });
        }

        const formatContact = (contact, type) => {
            return {
                ...contact,
                type: type,
                label: contact.label || "",
                email: contact.email || "",
                address: {
                    ...contact.address,
                    state:
                        contact.address?.state?.name ||
                        contact.address?.state ||
                        null,
                },
                phone: !StringHelper.isNullOrEmpty(contact.phone?.phoneNumber)
                    ? {
                          phoneNumber: contact.phone?.phoneNumber || "",
                          typeId: contact.phone?.phoneNumberType?.id || null,
                      }
                    : undefined,
            };
        };

        if (dataItem) {
            const existingContact = contacts.find(
                (contact) => contact.id === dataItem.id
            );

            // Ensure that dataItem.type exists before attempting to access it
            const contactType = dataItem.type ? dataItem.type.value : null;

            if (existingContact) {
                // Update existing contact
                Object.assign(
                    existingContact,
                    formatContact(dataItem, contactType)
                );
            } else {
                // Auto-assign the type and add new contact
                const newContactType = assignContactType();
                contacts.push(formatContact(dataItem, newContactType));
            }

            // Check if a new Primary contact has been assigned
            if (contactType === ContactType.Enum.Primary) {
                // Demote current primary contact to 'Other'
                contacts = contacts.map((contact) => {
                    if (
                        contact.id !== dataItem.id &&
                        contact.type === ContactType.Enum.Primary
                    ) {
                        return { ...contact, type: ContactType.Enum.Other };
                    }
                    return contact;
                });
            }
        }

        // Format the entire customer object before sending to the API
        const formattedDataItem = {
            ...record,
            contacts,
            address: {
                ...record.address,
                state:
                    record.address?.state?.name ||
                    record.address?.state ||
                    undefined,
            },
        };

        // Update customer with formatted contacts
        updateCustomer(formattedDataItem)
            .then((res) => {
                if (res.success) {
                    getCustomer(record.id).then((i) => {
                        customerContext.setCustomer(i.data);
                        triggerReload();
                        close();
                    });
                }
            })
            .finally(() => {
                setInitialFormData(null);
                toggleLoaderVisible();
            });
    };

    const assignContactType = () => {
        if (!record.primaryContact) return ContactType.Enum.Primary;
        return ContactType.Enum.Other;
    };

    return (
        visible &&
        initialFormData && (
            <>
                <Form
                    onSubmit={submitCustomerContacts}
                    initialValues={initialFormData}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={isUpdate ? "Edit Contact" : "Create Contact"}
                            onClose={onClose}
                            size={"medium"}
                        >
                            <FormElement>
                                <ContactInput
                                    isRequired={true}
                                    formRenderProps={formRenderProps}
                                />
                                <Field
                                    name={"label"}
                                    component={FormInput}
                                    label={"Title:"}
                                />
                                {isUpdate && (
                                    <Field
                                        name={"type"}
                                        component={FormDropDown}
                                        data={[
                                            {
                                                value: ContactType.Enum.Primary,
                                                text: "Primary",
                                            },
                                            {
                                                value: ContactType.Enum.Other,
                                                text: "Other",
                                            },
                                        ]}
                                        dataItemKey="value"
                                        textField="text"
                                        label={"Type:"}
                                    />
                                )}
                                <FormButtons
                                    isCreate={!id}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onClose}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            </>
        )
    );
};

export default CustomerContactModal;
