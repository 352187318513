import { NewJobDocumentType } from "./Enums/JobDocumentTypeEnum.js";

export class JobDocumentHelper {
    static hasPendingChangeOrder = (jobDocument) => {
        if (!jobDocument?.childJobDocuments) return false;
        if (jobDocument?.type !== NewJobDocumentType.Enum.SalesOrder)
            return false;

        const children = jobDocument.childJobDocuments;

        return children.some(
            (child) => child.type === NewJobDocumentType.Enum.PendingChangeOrder
        );
    };

    static hasChildrenExceptOrders = (jobDocument) => {
        if (!jobDocument?.childJobDocuments) return false;

        const children = jobDocument.childJobDocuments;

        return children.some(
            (child) =>
                child.type !== NewJobDocumentType.Enum.PendingChangeOrder &&
                child.type !== NewJobDocumentType.Enum.ApprovedChangeOrder
        );
    };

    static isChangeOrder = (jobDocument) => {
        return (
            jobDocument.type === NewJobDocumentType.Enum.PendingChangeOrder ||
            jobDocument.type === NewJobDocumentType.Enum.ApprovedChangeOrder
        );
    };
}
