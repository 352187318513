import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { getInitialDataQueryState } from "../../../utilities/kendoHelper";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

//?
//? All API calls related to items for the Inventory Module are defined here
//?

/**
 * @function getItem
 * @description Function to get a single item
 * @returns {Promise <ServiceResponse>}
 */
export const getItem = async (itemId) => {
    try {
        const response = await axios.get(`/Inventory/Item/Get?id=${itemId}`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllItems
 * @description Function to get all items
 * @returns {Promise<ServiceResponse>} response.data
 */
export const getAllItems = async () => {
    try {
        const response = await axios.get(`/Inventory/Item/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllItemsByCategory
 * @description Function to get all items by category
 * @param {string} categoryId
 * @returns {Promise<ServiceResponse>} response.data
 */
export const getAllItemsByCategory = async (categoryId) => {
    try {
        const kendoRequest = {
            filter: {
                logic: "and",
                filters: [
                    {
                        field: "categoryId",
                        operator: "eq",
                        value: categoryId,
                    },
                ],
            },
            sort: [
                {
                    field: "created",
                    dir: "asc",
                },
            ],
        };
        const queryParams = toDataSourceRequestString(kendoRequest);
        const response = await axios.get(
            `/Inventory/Item/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllJobItems
 * @description Function to get all items that can be assigned to a Job Document
 * @returns {Promise<ServiceResponse>} response.data
 */
export const getAllJobItems = async () => {
    try {
        const response = await axios.get(`/Customer/Job/Item/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteItem
 * @description Function to delete an item
 * @param {string} itemId
 * @returns {void}
 */
export const deleteItem = async (itemId) => {
    try {
        const response = await axios.delete(
            `/Inventory/Item/Delete?id=${itemId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getItemType = async (id) => {
    try {
        const response = await axios.get(`/Inventory/ItemType/Get?id=${id}&`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllItemTypes
 * @description Function to get all item types
 * @returns {Promise<ServiceResponse>} response.data
 */
export const getAllItemTypes = async () => {
    try {
        const kendoRequest = getInitialDataQueryState();
        kendoRequest.sort = [{ field: "name", dir: "asc" }];
        const queryParams = toDataSourceRequestString(kendoRequest);

        const response = await axios.get(
            `/Inventory/ItemType/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function createItems
 * @description Function to create items
 * @param {Object} items
 * @returns {Promise<ServiceResponse>} created
 */
export const createItems = async (items) => {
    try {
        const response = await axios.post(`/Inventory/Item/MultiCreate`, items);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function createItem
 * @description Function to create an Item
 * @params {Object} item
 * @returns {Promise<ServiceResponse>}
 **/

export const createItem = async (item) => {
    try {
        const response = await axios.post(`/Inventory/Item/Create`, item);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateItem
 * @description Function to update an item
 * @param {Object} item
 * @returns {Promise<ServiceResponse>}
 */
export const updateItem = async (item) => {
    try {
        const response = await axios.put(`/Inventory/Item/Update`, item);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateInLineGridItem = async (item) => {
    const res = await axios.put(`/Inventory/Item/UpdateInLineGrid`, item);
    return formatServiceResponse(res);
};

export const createItemType = async (item) => {
    try {
        const response = await axios.post(`/Inventory/ItemType/Create`, item);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateItemType = async (item) => {
    try {
        const response = await axios.put(`/Inventory/ItemType/Update`, item);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteItemType = async (itemTypeId) => {
    try {
        return await axios.delete(
            `/Inventory/ItemType/Delete?id=${itemTypeId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const bulkPriceChange = async (data) => {
    const res = await axios.put(`/Inventory/Item/BulkPriceChange`, data);
    return formatServiceResponse(res);
};
