import React, { cloneElement, useContext } from "react";
import {
    customDateFormatter,
    utcToLocalFormatter,
} from "../../resources/Deprecated/stringFormatter";
import { addressFormatter } from "../../resources/Deprecated/helpers";
import { JobDocumentContext } from "../../providers/Deprecated/Customer/JobDocumentProvider";
import { VendorDocumentContext } from "../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";

export const CustomCell = (props) => {
    return (
        <td {...props.tdProps} style={props.style} colSpan={props.colSpan ?? 1}>
            {props.children}
        </td>
    );
};

export const DisplayDocumentNumberCell = (props) => {
    const { dataItem } = props;
    return (
        <CustomCell style={{ fontWeight: "bold" }}>
            #{dataItem.documentNumber}
        </CustomCell>
    );
};

export const CombinedAddressCell = (props) => {
    const { dataItem } = props;
    return <CustomCell>{addressFormatter(dataItem)}</CustomCell>;
};

export const CustomDateCell = (props) => {
    return (
        <CustomCell {...props}>{customDateFormatter(props.data)}</CustomCell>
    );
};

export const CustomAppointmentTimeCell = (props) => {
    const dataItem = props.dataItem;
    const appointmentTime = dataItem.appointmentTime
        ? `${utcToLocalFormatter(
              dataItem.appointmentTime.startTime,
              "h:mm A"
          )} - ${utcToLocalFormatter(
              dataItem.appointmentTime.endTime,
              "h:mm A"
          )}`
        : "";
    return <CustomCell {...props}>{appointmentTime}</CustomCell>;
};

export const YesNoBooleanCell = (props) => (
    <CustomBooleanCell {...props} trueText={"Yes"} falseText={"No"} />
);

export const CustomBooleanCell = ({ trueText, falseText, ...props }) => {
    const boolValue = props.dataItem[props.field];
    return (
        <CustomCell {...props}>{boolValue ? trueText : falseText}</CustomCell>
    );
};

//? For Kendo In Cell Deletion
//? https://www.telerik.com/kendo-react-ui/components/Deprecated/grid/editing/editing-inline/
//?
export const JobDocCellEditCommandCell = (props) => {
    const {
        dataItem,
        remove,
        moveUp,
        moveDown,
        onAddBelow,
        onAddDiscountBelow,
    } = props;
    const { isJobDocumentEditable } = useContext(JobDocumentContext);

    return (
        <td
            className="k-command-cell export-hide"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
            }}
        >
            {moveUp && (
                <span
                    className="k-icon k-i-arrow-n"
                    onClick={() => moveUp(dataItem)}
                    title="Move Up"
                    style={{ cursor: "pointer", marginRight: "8px" }}
                />
            )}
            {moveDown && (
                <span
                    className="k-icon k-i-arrow-s"
                    onClick={() => moveDown(dataItem)}
                    title="Move Down"
                    style={{ cursor: "pointer", marginRight: "8px" }}
                />
            )}
            {onAddBelow && (
                <span
                    className="k-icon k-i-plus-sm"
                    onClick={() => onAddBelow(dataItem)}
                    title="Add"
                    style={{ cursor: "pointer", marginRight: "8px" }}
                />
            )}
            {onAddDiscountBelow && (
                <span
                    className="k-icon k-i-dollar"
                    onClick={() => onAddDiscountBelow(dataItem)}
                    title="Add a Discount to Line Item"
                    style={{ cursor: "pointer", marginRight: "8px" }}
                />
            )}
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
                onClick={() => remove(dataItem)}
                title="Remove"
                disabled={!isJobDocumentEditable}
            >
                Remove
            </button>
        </td>
    );
};

export const POCellEditCommandCell = (props) => {
    const { dataItem, remove } = props;
    const { selectedDocument } = useContext(VendorDocumentContext);
    const isEditable =
        selectedDocument?.childVendorDocuments?.length === 0 &&
        selectedDocument.type === 0;

    return (
        <td
            className="k-command-cell"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
            }}
        >
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
                onClick={() => remove(dataItem)}
                title="Remove"
                disabled={!isEditable}
            >
                Remove
            </button>
        </td>
    );
};

//? For Kendo In Cell Editing
//? https://www.telerik.com/kendo-react-ui/components/Deprecated/grid/editing/editing-in-cell/
//?
export const CellEditRender = (props) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ""];
    const additionalProps =
        cellField && cellField === inEditField
            ? {
                  ref: (td) => {
                      const input = td && td.querySelector("input");
                      const activeElement = document.activeElement;
                      if (
                          !input ||
                          !activeElement ||
                          input === activeElement ||
                          !activeElement.contains(input)
                      ) {
                          return;
                      }
                      if (input.type === "checkbox") {
                          input.focus();
                      } else {
                          input.select();
                      }
                  },
              }
            : {
                  onClick: () => {
                      props.enterEdit(dataItem, cellField);
                  },
              };
    const clonedProps = {
        ...props.td.props,
        ...additionalProps,
    };

    let tdChildren = props.td.props.children;

    if (typeof tdChildren === "string" || typeof tdChildren === "number") {
        // if children to display under <td> element is a string or number, allow us to alter the display
        if (props.cellUnit) {
            tdChildren += props.cellUnit;
        }
    }

    if (props.renderNoChildren) {
        tdChildren = null;
    }

    return cloneElement(props.td, clonedProps, tdChildren);
};

export const EnumDisplayColumn = (props, enumerable) => {
    const { dataItem, field } = props;
    return <CustomCell>{enumerable.ParseToString(dataItem[field])}</CustomCell>;
};

//? For Kendo In Cell Editing
//? https://www.telerik.com/kendo-react-ui/components/Deprecated/grid/editing/editing-in-cell/
//?
export const RowEditRender = (props) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            props.exitEdit();
        },
    };
    return cloneElement(
        props.tr,
        {
            ...trProps,
        },
        props.tr.props.children
    );
};
