import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import "../../AccountingModule.module.scss";
import {
    FormDropDown,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import TransactionService from "../../../../services/Deprecated/accounting/TransactionService";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AccountHelper } from "../../Helpers/AccountHelper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

//? Reference type mappings for field dropdown
const referenceType = [
    {
        key: 0,
        value: "Payment",
    },
    {
        key: 1,
        value: "Invoice",
    },
    {
        key: 2,
        value: "Bill",
    },
    {
        key: 3,
        value: "Bill Payment",
    },
    {
        key: 4,
        value: "Payroll",
    },
    {
        key: 5,
        value: "TimeClockEntry",
    },
    {
        key: 6,
        value: "Transaction",
    },
];

const TransactionModal = (props) => {
    const { id, isCreate, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined);
    const [accounts, setAccounts] = useState([]);
    //? Even though create is currently only supported, mind as well have it setup for both

    useEffect(() => {
        if (!visible) return;

        ChartOfAccountsService.getAllAccounts().then((res) => {
            setAccounts(
                res.data.flatMap((acc) => AccountHelper.flatmapAccounts(acc))
            );

            if (id) {
                TransactionService.get(id).then((res) => {
                    setInitialValues({
                        ...res.data,
                        manualCreatedAt: new Date(res.data.manualCreatedAt),
                        amount: res.data.debitAmount.amount,
                        documentType: referenceType.find(
                            (rt) => rt.key === res.data.documentType
                        ),
                    });
                });
            } else {
                setInitialValues({
                    manualCreatedAt: new Date(),
                    documentType: referenceType[0],
                });
            }
        });
    }, [visible, id]);

    const onSubmit = (props) => {
        const { values, isValid } = props;

        if (!isValid) {
            // Form is not valid, prevent submission
            return;
        }

        setLoaderVisible(true);

        values.creditAccountId = values.creditAccount.id;
        values.debitAccountId = values.debitAccount.id;
        values.debitAmount = {
            amount: values.amount,
            currency: "USD",
        };
        values.creditAmount = {
            amount: values.amount,
            currency: "USD",
        };
        values.isManual = true;

        values.documentType = values.documentType.key;

        setLoaderVisible(false);

        TransactionService.createTransaction(values).then(async (_) => {
            if (!isCreate) {
                await TransactionService.deleteTransaction(id);
            }

            triggerReload();
            onClose();
            setLoaderVisible(false);
        });
    };

    const onClose = () => {
        setInitialValues(undefined);
        setAccounts([]);
        close();
    };

    return (
        visible &&
        initialValues && (
            <Form
                onSubmitClick={onSubmit}
                initialValues={initialValues}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={
                            isCreate
                                ? "Create Transaction"
                                : "Update Transaction"
                        }
                        onClose={() => onClose()}
                        size={"medium"}
                        className={"TransactionModal"}
                    >
                        <FormElement>
                            <span id={"DescriptionFieldSet"}>
                                <p>Description:</p>
                                <Field
                                    name={"description"}
                                    component={FormTextArea}
                                    rows={4}
                                    validator={requiredValidator}
                                />
                            </span>
                            <Field
                                name={"manualCreatedAt"}
                                component={DatePicker}
                                label={"Created Date:"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"documentType"}
                                component={FormDropDown}
                                data={referenceType}
                                dataItemKey="key"
                                textField="value"
                                label={"Document Type:"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"amount"}
                                component={FormNumericInput}
                                format={"c2"}
                                label={"Amount:"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"creditAccount"}
                                component={FormDropDown}
                                data={accounts}
                                dataItemKey="id"
                                textField="name"
                                label={"Credit Account:"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"debitAccount"}
                                component={FormDropDown}
                                data={accounts}
                                dataItemKey="id"
                                textField="name"
                                label={"Debit Account:"}
                                validator={requiredValidator}
                            />
                            <FormButtons
                                isCreate={isCreate}
                                allowSubmit={formRenderProps.allowSubmit}
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};

export default TransactionModal;
