import { BaseEnum } from "./BaseEnum";

export class SchedulerWindowEnum extends BaseEnum {
    static Enum = {
        WorkWeek: 5,
        SixDayWeek: 6,
        FullWeek: 7,
        TwoWeek: 14,
    };
}
