import * as React from "react";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";

const BalanceSheetTLTotalColumnCell = (props) => {
    const { dataItem } = props;
    const isRootAccount = !dataItem.parentReferenceId;
    const isTotalLine = !props.dataItem.referenceId;
    const cellData = String(dataItem[props.field || ""]);
    let displayData = undefined;

    if (cellData === "0" && (!isRootAccount || isTotalLine)) {
        displayData = "0.00";
    } else if (cellData !== "0") {
        displayData = formatUSDCurrency(cellData);
    }

    const totalLineStyle = isTotalLine
        ? {
              fontWeight: "bold",
              borderTop: "1px solid",
          }
        : undefined;

    return (
        <td style={totalLineStyle}>
            <span
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}
            >
                {displayData}
            </span>
        </td>
    );
};

export default BalanceSheetTLTotalColumnCell;
