import * as React from "react";
import { useContext } from "react";
import {
    ListBox,
    ListBoxToolbar,
    processListBoxData,
} from "@progress/kendo-react-listbox";
import { PermissionContext } from "../../providers/Deprecated/Authentication/Permission/PermissionListBoxProvider.jsx";

const SELECTED_FIELD = "selected";
const PermissionListBox = ({ permissionGroupHeader }) => {
    const permissionContext = useContext(PermissionContext);

    // Retrieves state and a setter function for managing list box state from context
    const listBoxRoleState =
        permissionContext.permissionListBoxState[permissionGroupHeader];
    const setListBoxRoleState = (state) => {
        permissionContext.setPermissionListBoxStateForPermissionGroupId(
            permissionGroupHeader,
            state
        );
    };

    const handleItemClick = (event, data, connectedData) => {
        setListBoxRoleState({
            ...listBoxRoleState,
            [data]: listBoxRoleState[data].map((item) => {
                if (item.name === event.dataItem.name) {
                    item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                } else if (!event.nativeEvent.ctrlKey) {
                    item[SELECTED_FIELD] = false;
                }
                return item;
            }),
            [connectedData]: listBoxRoleState[connectedData].map((item) => {
                item[SELECTED_FIELD] = false;
                return item;
            }),
        });
    };
    const handleToolBarClick = (e) => {
        let toolName = e.toolName || "";
        let result = processListBoxData(
            listBoxRoleState.assigned,
            listBoxRoleState.unassigned,
            toolName,
            SELECTED_FIELD
        );
        setListBoxRoleState({
            ...listBoxRoleState,
            assigned: result.listBoxOneData,
            unassigned: result.listBoxTwoData,
        });
    };

    return (
        <div>
            <div className="listbox-container">
                <div className="col k-pr-2">
                    <h5>Assigned Permissions</h5>
                    <ListBox
                        style={{
                            height: 200,
                            width: "100%",
                        }}
                        data={listBoxRoleState.assigned}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e) =>
                            handleItemClick(e, "assigned", "unassigned")
                        }
                    />
                </div>
                <div className="col k-pr-2">
                    <h5>Unassigned Permissions</h5>
                    <ListBox
                        style={{
                            height: 200,
                            width: "100%",
                        }}
                        toolbar={() => {
                            return (
                                <ListBoxToolbar
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    tools={[
                                        "transferTo",
                                        "transferFrom",
                                        "transferAllTo",
                                        "transferAllFrom",
                                    ]}
                                    data={listBoxRoleState.assigned}
                                    dataConnected={listBoxRoleState.unassigned}
                                    onToolClick={handleToolBarClick}
                                />
                            );
                        }}
                        data={listBoxRoleState.unassigned}
                        textField="name"
                        selectedField={SELECTED_FIELD}
                        onItemClick={(e) =>
                            handleItemClick(e, "unassigned", "assigned")
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default PermissionListBox;
