import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";

export const ADPPayrollGrid = (props) => {
    const { data } = props;

    return (
        <Grid data={data} style={{ maxWidth: 695 }}>
            <GridColumn
                field={"fullName"}
                title={"Employee"}
                width={200}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`/HumanResources/Employee/${props.dataItem.id}`}
                    />
                )}
            />
            <GridColumn field="coCode" title="Co Code" width={75} />
            <GridColumn field="batchId" title="Batch Id" width={75} />
            <GridColumn field="fileId" title="File Id" width={75} />
            <GridColumn
                field="regularHours"
                title="Regular Hours"
                width={125}
            />
            <GridColumn
                field="overTimeHours"
                title="Overtime Hours"
                width={125}
            />
        </Grid>
    );
};
