import { formatServiceResponse } from "../utilities/serviceUtility";
import { axiosInstance as axios } from "./axiosInstance";

/**
 * @class BaseService
 * @description Base service class to be inherited by other services, it contains the basic CRUD operations
 * @param {string} uri - The uri of the service
 */
export default class BaseService {
    constructor(uri) {
        this.uri = uri;
    }

    /**
     * @method get
     * @description Get a single record by id
     * @param id {string} - The id of the record to get
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async get(id) {
        const response = await axios.get(`${this.uri}/Get?id=${id}`);
        return formatServiceResponse(response);
    }

    /**
     * @method getAll
     * @description Get all records
     * @param [queryParams] {object} - The query parameters to filter
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async getAll(queryParams) {
        let uri = `${this.uri}/GetAll?`;

        if (queryParams) {
            uri += new URLSearchParams(queryParams).toString();
        }

        const response = await axios.get(uri);
        return formatServiceResponse(response);
    }

    /**
     * @method create
     * @description Create a new record
     * @param data {object} - The data to create the record with
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async create(data) {
        const response = await axios.post(`${this.uri}/Create`, data);
        return formatServiceResponse(response);
    }

    /**
     * @method update
     * @description Update an existing record
     * @param data {object} - The data to update the record with
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async update(data) {
        const response = await axios.put(`${this.uri}/Update`, data);
        return formatServiceResponse(response);
    }

    /**
     * @method delete
     * @description Delete a record by id
     * @param id {string} - The id of the record to delete
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async delete(id) {
        const response = await axios.delete(`${this.uri}/Delete?id=${id}`);
        return formatServiceResponse(response);
    }
}
