import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import EventLaborCreate from "./EventLaborCreate";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import {
    deleteEventLabor,
    getAllLaborForEvent,
} from "../../../../services/Deprecated/customer/eventServices";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import ActionButtonsCommandCell from "../../../../components/Deprecated/ActionButtonsCommandCell";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";

const EventLaborersPortlet = (props) => {
    const [data, setData] = useState(undefined);
    const eventId = props?.match?.params?.id;
    const eventContext = useContext(EventContext);
    const { triggerReload, reloadData } = useContext(ReloadDataContext);
    const [forceReloadKey, setForceReloadKey] = useState(0);

    useEffect(() => {
        getAllLaborForEvent(eventId).then((res) => {
            setData(res.data);
            setForceReloadKey(Math.random());
        });
    }, [eventId, reloadData]);

    return (
        data && (
            <Card
                style={{
                    flexGrow: 1,
                    boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                    minHeight: "300px",
                }}
            >
                <CardHeader
                    className="k-hbox"
                    style={{
                        background: "transparent",
                        width: "100%",
                        backgroundColor: "#67809F",
                        color: "#fff",
                        height: 60,
                    }}
                >
                    <CardTitle
                        style={{
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                            }}
                        >
                            <i className={"k-icon k-i-user"} />
                            <strong>Laborers</strong>
                        </span>
                        <EventLaborCreate
                            triggerReload={triggerReload}
                            laborDataImport={data}
                        />
                    </CardTitle>
                </CardHeader>
                <CardBody
                    style={{
                        padding: "5px",
                        overflowY: "auto",
                    }}
                >
                    {/* If the event is not the one we are looking for, show a loader, helps with re-renders */}
                    {eventContext.event.id === eventId &&
                    !eventContext.relationshipsLoading ? (
                        <DataGrid
                            key={forceReloadKey}
                            data={data}
                            disablePagination={true}
                            showColumnsMultiSelect={false}
                            style={{
                                height: "235px",
                            }}
                        >
                            {/* We leave this empty so no empty message is shown */}
                            <GridNoRecords> </GridNoRecords>
                            <GridColumn
                                field="fullName"
                                title="Full Name:"
                                cell={(props) => (
                                    <LinkCell
                                        {...props}
                                        uri={`/HumanResources/Employee/${props.dataItem.id}`}
                                    />
                                )}
                                headerClassName={"hide-element"}
                            />
                            <GridColumn
                                width={80}
                                headerClassName={"hide-element"}
                                cell={(props) => (
                                    <ActionButtonsCommandCell
                                        record={props.dataItem}
                                        dataId={props.dataItem.id}
                                        secondaryDataId={eventId}
                                        triggerReload={triggerReload}
                                        action={deleteEventLabor}
                                        requiredPermissionsAction={[
                                            PermissionsEnum.UpdateCalendarEvent,
                                        ]}
                                    />
                                )}
                            />
                        </DataGrid>
                    ) : (
                        <CenterLoader />
                    )}
                </CardBody>
            </Card>
        )
    );
};

export default EventLaborersPortlet;
