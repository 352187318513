import * as React from "react";
import { useEffect, useState } from "react";
import { TreeList } from "@progress/kendo-react-treelist";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";
import BalanceSheetTLTotalColumnCell from "./BalanceSheetTLTotalColumnCell";
import BalanceSheetTLTotalHeaderCell from "./BalanceSheetTLTotalHeaderCell";
import BalanceSheetTLNameColumnCell from "./BalanceSheetTLNameColumnCell";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import dayjs from "dayjs";
import { AccountHelper } from "../../Helpers/AccountHelper";

const columns = [
    {
        field: "name",
        title: " ",
        expandable: true,
        cell: BalanceSheetTLNameColumnCell,
    },
    {
        field: "total",
        title: "TOTAL",
        width: 200,
        cell: BalanceSheetTLTotalColumnCell,
        headerCell: BalanceSheetTLTotalHeaderCell,
    },
];

//? Default date to today
const reportDate = new Date();

const BalanceSheetTreeList = () => {
    const [data, setData] = useState([]);
    const [date, setDate] = useState(reportDate);

    useEffect(() => {
        const fetchData = async () => {
            const serviceResponse =
                await ChartOfAccountsService.getBalanceSheet(date);
            return serviceResponse.data.lineRecords.map((acc) => {
                AccountHelper.expandTreeList(acc, "lineRecords");
                return acc;
            });
        };

        fetchData().then((res) => setData(res));
    }, [date]);

    /**
     * Handles the date selection change
     * Will not update unless both start and end dates present
     * @param props
     */
    const onDateChange = (props) => {
        const { value } = props;

        if (value) {
            setDate(value);
        }
    };

    return (
        <div>
            {/* HEADER */}
            <div className={"BalanceSheetHeader"}>
                <span className={"TitleAndDateContainer"}>
                    <p className={"BalanceSheetHeaderTitle"}>Balance Sheet</p>
                    <small>
                        As of {dayjs(date).format("dddd, MMM DD, YYYY")}
                    </small>
                </span>
                <span>
                    <DatePicker
                        className={"BalanceSheetDatePicker"}
                        defaultValue={date}
                        onChange={onDateChange}
                        style={{
                            marginRight: "20px",
                        }}
                    />
                </span>
            </div>
            <TreeList
                expandField={"expanded"}
                subItemsField={"lineRecords"}
                data={data}
                columns={columns}
            />
        </div>
    );
};

export default BalanceSheetTreeList;
