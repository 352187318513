import {useEffect, useState} from "react";
import {getLastTimeClockEntry} from "../../../../services/Deprecated/humanResources/timeClockServices";

/**
 * Fetches the last time clock entry for the given employee.
 * @param {string} employeeId The employee ID.
 * @returns {Object} The last time clock entry and a function to trigger a reload.
 */
export function useLastTimeClock(employeeId) {
    const [lastTimeClock, setLastTimeClock] = useState(null);
    const [reloadTimeClock, setReloadTimeClock] = useState(false);

    if (!employeeId) {
        throw new ReferenceError("EmployeeId is undefined.");
    }

    const triggerTimeClockReload = () => {
        setReloadTimeClock(!reloadTimeClock);
    };

    useEffect(() => {
        getLastTimeClockEntry(employeeId).then((response) => {
            setLastTimeClock(response);
        });
    }, [reloadTimeClock, employeeId]);

    return { lastTimeClock, triggerTimeClockReload };
}
