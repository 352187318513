import * as React from "react";
import { useState } from "react";
import "../AccountingModule.module.scss";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import Journal from "./Components/Journal";

const AccountingJournalPage = () => {
    const [selected, _setSelected] = useState(0);

    return (
        <TabStrip id={"AccountingJournal"} selected={selected}>
            <TabStripTab title="Accounting Journal">
                <Journal />
            </TabStripTab>
        </TabStrip>
    );
};

export default AccountingJournalPage;
