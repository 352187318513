import React, { useContext } from "react";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import FormField from "../../../../components/Deprecated/StylingField";
import { addressFormatter } from "../../../../resources/Deprecated/helpers";

const EmergencyContacts = () => {
    const { employee } = useContext(EmployeeContext);

    return (
        employee.id && (
            <div className={"JustifyLeftAlignLeft LargeGap"}>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Emergency Contact"
                        value={employee?.emergencyContact?.combinedName}
                    />
                    {employee?.emergencyContact?.email && (
                        <FormField
                            label="Email"
                            value={employee.emergencyContact.email}
                        />
                    )}
                    {employee?.emergencyContact?.phone && (
                        <FormField
                            label="Phone"
                            value={`(${employee.emergencyContact.phone.phoneNumberType.name}) ${employee.emergencyContact.phone.phoneNumber}`}
                        />
                    )}
                    {employee?.emergencyContact?.relationship && (
                        <FormField
                            label="Relationship"
                            value={employee.emergencyContact.relationship}
                        />
                    )}
                </span>
                <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
                    <FormField
                        label="Address"
                        value={addressFormatter(
                            employee.emergencyContact?.address,
                            "short"
                        )}
                    />
                    <FormField
                        label="City"
                        value={employee.emergencyContact?.address?.city}
                    />
                    <FormField
                        label="State"
                        value={employee.emergencyContact?.address?.state}
                    />
                    <FormField
                        label="Zip"
                        value={employee.emergencyContact?.address?.zipCode}
                    />
                </span>
            </div>
        )
    );
};

export default EmergencyContacts;
