import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class HRTaskService {
    /**
     * Sends GET request to get all Tasks
     * @function getAllTasks
     * @async
     * @returns {Promise<ServiceResponse>} A Promise that resolves to a ServiceResponse
     */
    static async getAllTasks() {
        try {
            const response = await axios.get("/Customer/Task/GetAll");

            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
