import React, { useContext } from "react";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import PermissionButton from "../../../../components/Deprecated/PermissionButton";

const DisbursementBtn = ({ dataItem }) => {
    const paymentProvider = useContext(PaymentContext);

    return (
        <div>
            <PermissionButton
                requiredPermissions={[PermissionsEnum.DisburseCustomerPayment]}
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                onClick={() =>
                    paymentProvider.setDisbursingPaymentId(dataItem.id)
                }
            >
                Apply
            </PermissionButton>
        </div>
    );
};

export default DisbursementBtn;
