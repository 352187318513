import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {ReloadDataContext} from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import {FormButtons} from "../../../../components/Buttons/FormButtons";
import BaseService from "../../../../services/BaseService";
import {ContentHeader} from "../../../../components/ContentHeader";
import {requiredValidator} from "../../../../resources/Deprecated/formValidators";
import {TextAreaInput} from "../../../../components/FormInputs/TextAreaInput";
import {CommunicationModalHelper} from "../Helpers/CommunicationModalHelper";
import {Checkbox} from "@progress/kendo-react-inputs";
import {FormRemoteDropDown} from "../../../../components/Deprecated/FormComponents";

/**
 * @function CommunicationModal
 * @description Handles the modal for creating and editing communications
 * @param props.id {String} - The id of the reminder
 * @param props.close {Function} - Function to close the modal
 * @param props.visible {Boolean} - Whether the modal is visible
 * @param props.record {Object} - Arguments to pass to network request
 * @return {Element}
 * @constructor
 */
export const CommunicationModal = (props) => {
    const { id, close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const baseService = useRef(new BaseService("Customer/Lead/Communication"));

    useEffect(() => {
        if (!visible) return;

        if (id) {
            baseService.current.get(id).then((res) => {
                const data = CommunicationModalHelper.formatDataForModal(
                    res.data
                );
                setInitialFormData(data);
            });
        } else {
            setInitialFormData({
                ...record,
                shouldCreateReminder: true,
            });
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = CommunicationModalHelper.formatDataForNetwork(dataItem);
        const service = id
            ? baseService.current.update(data)
            : baseService.current.create(data);

        service.then(() => {
            triggerReload();
            onClose();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={id ? "Edit Communication" : "Create Communication"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <ContentHeader
                                title={"Note"}
                                style={{
                                    fontSize: 14,
                                    textAlign: "left",
                                    fontWeight: 400,
                                }}
                            />
                            <Field
                                name={"note"}
                                component={TextAreaInput}
                                autoSize={true}
                                rows={4}
                                validator={requiredValidator}
                            />
                            <Field
                                url={"/Customer/CommunicationType/GetAll?"}
                                name={"communicationType"}
                                component={FormRemoteDropDown}
                                label={"Communication Type:"}
                                validator={requiredValidator}
                            />
                            <br />
                            {!id && (
                                <Field
                                    className={"JustifyLeftAlignCenter"}
                                    name={"shouldCreateReminder"}
                                    component={Checkbox}
                                    label={"Should Create Reminder"}
                                    rounded={"large"}
                                    size={"medium"}
                                />
                            )}
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={close}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!id}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
