import React, {useState} from "react";
import {ConfirmationDialog} from "../Dialogs/ConfirmationDialog";
import PermissionButton from "../Deprecated/PermissionButton.jsx";

/**
 * @function ActionButton
 * @description
 * A cell component that renders a button that performs an action when clicked.
 * Handles permissions, and confirmation dialogs.
 * @param {Object} props.style - The style of the button
 * @param {CallableFunction} props.onClick - Function to call when the button is clicked
 * @param {boolean} props.needsConfirmation - Whether the button needs confirmation before performing the action
 * @param {string} props.confirmationText - The text to display in the confirmation dialog
 * @param {string[]} props.permissions - The permissions required to display the button
 * @param {string} props.theme - The theme color of the button
 * @param {string} props.buttonText - The text to display on the button
 * @param {boolean} props.disabled - Whether the button is disabled
 * @param {string} props.disabledToolTipMessage - The message to display in the tooltip when the button is disabled
 * @param {number} props.buttonTimeOutInterval - The time in milliseconds to display the button as disabled after it has been clicked
 * @return {Element}
 * @constructor
 */
export const ActionButton = (props) => {
    const {
        onClick,
        needsConfirmation,
        confirmationText,
        permissions,
        theme,
        buttonText,
        disabled,
        disabledToolTipMessage,
        confirmationButtonText,
        confirmationButtonThemeColor,
        cancelButtonText,
        cancelButtonThemeColor,
        buttonTimeOutInterval = 500,
        ...others
    } = props;
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [buttonTimedOut, setButtonTimedOut] = useState(false);

    const handleClick = () => {
        if (needsConfirmation) {
            setConfirmVisible(true);
            return;
        }

        setButtonTimedOut(true);

        // If the onClick function is provided, call it
        if (onClick) {
            onClick();
        }

        setTimeout(() => {
            setButtonTimedOut(false);
        }, buttonTimeOutInterval);
    };

    const onConfirmation = () => {
        if (onClick) {
            onClick();
        }
        setConfirmVisible(false);
    };

    return (
        <>
            {needsConfirmation && (
                <ConfirmationDialog
                    visible={confirmVisible}
                    actionOnConfirm={onConfirmation}
                    actionOnCancel={() => setConfirmVisible(false)}
                    confirmationText={confirmationText}
                    confirmationButtonText={confirmationButtonText}
                    confirmationButtonThemeColor={confirmationButtonThemeColor}
                    cancelButtonText={cancelButtonText}
                    cancelButtonThemeColor={cancelButtonThemeColor}
                />
            )}
            {/** If the Button is timed out, we will display a dummy Button **/}
            {buttonTimedOut && buttonTimeOutInterval !== 0 ? (
                <PermissionButton
                    requiredPermissions={permissions ?? []}
                    themeColor={theme ?? "primary"}
                    disabled={disabled}
                    disabledToolTipMessage={disabledToolTipMessage}
                    toolTipPosition={"top"}
                    {...others}
                >
                    {buttonText}
                </PermissionButton>
            ) : (
                <PermissionButton
                    requiredPermissions={permissions ?? []}
                    themeColor={theme ?? "primary"}
                    onClick={
                        needsConfirmation
                            ? () => setConfirmVisible(true)
                            : handleClick
                    }
                    disabled={disabled}
                    disabledToolTipMessage={disabledToolTipMessage}
                    toolTipPosition={"top"}
                    {...others}
                >
                    {buttonText}
                </PermissionButton>
            )}
        </>
    );
};
