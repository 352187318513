import { useEffect, useState } from "react";
import { getAllEmployees } from "../../services/Deprecated/humanResources/employeeServices.js";

/**
 * Fetches all employees.
 * @param {boolean} allowLoad - Whether or not to allow the component to load in the employees.
 * @returns {Object} The employees for the HumanResources Module.
 */
export function useHREmployees(allowLoad = true) {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        if (!allowLoad) return;

        getAllEmployees().then((res) => setEmployees(res.data));
    }, []);

    return { employees };
}
