import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { BaseCell } from "./BaseCell";
import { NumericTextBox } from "@progress/kendo-react-inputs";

/**
 * @function EditableNumericCell
 * @description A cell that allows for editing via a numeric text box.
 * @param props.field {string} - The field to edit
 * @param props.dataItem {object} - The data item to edit
 * @param props.editField {string} - The field to watch for editing
 * @param props.onChange {function} - The function to call when the value changes
 * @param props.onCustomChange {function} - A custom function to call when the value changes
 * @param props.disabled {boolean} - Whether the cell is disabled
 * @param props.min {number} - The minimum value
 * @param props.max {number} - The maximum value
 * @param props.format {string} - The format of the value
 * @return {Element}
 * @constructor
 */
export const EditableNumericCell = (props) => {
    const {
        field = "",
        dataItem,
        editField,
        onCustomChange,
        onChange,
        disabled,
        min,
        max,
        onBlur,
        onFocus,
        format,
        ...others
    } = props;
    const [value, setValue] = useState(dataItem[field]);
    const prevValue = useRef(dataItem[field]);

    useEffect(() => {
        const fields = field.split(".");
        let current = dataItem;

        for (let i = 0; i < fields.length - 1; i++) {
            if (!current[fields[i]]) {
                current[fields[i]] = {};
            }
            current = current[fields[i]];
        }

        let setter = current[fields[fields.length - 1]];

        if (format === "p") {
            setter = setter / 100;
        }

        setValue(setter);
    }, [dataItem[field]]);

    const handleChange = (e) => {
        if (onChange || onCustomChange) {
            let curValue = e.target.value;

            if (max && curValue >= max) {
                curValue = max;
            } else if (min && curValue <= min) {
                curValue = min;
            }

            const data = {
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: curValue,
                prevValue: prevValue.current,
            };

            if (onChange) {
                onChange(data);
            }

            if (onCustomChange) {
                onCustomChange(data);
            }

            setValue(curValue);
        }
    };

    const formatValue = (value) => {
        if (format === "p") {
            return `${value * 100}%`;
        }

        return value;
    };

    const handleFocus = (e) => {
        e.nativeEvent.target.select();

        if (typeof onFocus === "function") {
            onFocus(e);
        }
    };

    return (
        <BaseCell {...others}>
            {dataItem[editField] ? (
                <NumericTextBox
                    value={value ?? 0}
                    format={format}
                    onChange={handleChange}
                    disabled={disabled}
                    min={min}
                    max={max}
                    onBlur={(e) =>
                        onBlur &&
                        onBlur({
                            ...e,
                            dataItem: dataItem,
                            prevValue: prevValue.current,
                        })
                    }
                    onFocus={handleFocus}
                />
            ) : (
                formatValue(value)
            )}
        </BaseCell>
    );
};
