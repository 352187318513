import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./AccountingFiles.module.scss";
import { FileManager } from "../../../../../components/FileManager/FileManager";

const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
};

const AccountingFiles = (props) => {
    const { jobId } = props;

    return (
        <Card className={styles.FilesCard}>
            <CardHeader
                style={{
                    backgroundColor: "#67809F",
                    color: "#fff",
                }}
            >
                <CardTitle>Accounting Files</CardTitle>
            </CardHeader>
            <CardBody>
                <div>
                    You can only upload <strong>JPEG</strong>,{" "}
                    <strong>PDF</strong>, <strong>JPG</strong> and{" "}
                    <strong>PNG</strong> files.
                </div>
                <FileManager
                    disabled={false}
                    allowedExtensions={fileRestrictions}
                    saveUri={"/General/File/UploadFile"}
                    removeUri={"/General/File/DeleteFile"}
                    readUri={"/General/File/GetDirectoryContents"}
                    location={`JobAccounting-${jobId}`}
                />
            </CardBody>
        </Card>
    );
};

export default AccountingFiles;
