import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getBillingTerm = async (billingTermId) => {
    try {
        const response = await axios.get(
            `/Inventory/BillingTerm/Get?id=${billingTermId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllBillingTerms = async () => {
    try {
        const response = await axios.get(`/Inventory/BillingTerm/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateBillingTerm = async (billingTermData) => {
    try {
        // Send a PUT request to the server with the marital data
        const response = await axios.put(
            "/Inventory/BillingTerm/Update",
            billingTermData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const addBillingTerm = async (billingTermData) => {
    try {
        const response = await axios.post(
            "/Inventory/BillingTerm/Create",
            billingTermData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteBillingTerm = async (billingTermId) => {
    try {
        const response = await axios.delete(
            `/Inventory/BillingTerm/Delete?id=${billingTermId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
