import React, { useContext } from "react";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import { NewJobDocumentType } from "../../../../../../resources/Enums/JobDocumentTypeEnum.js";
import { formatUSDCurrency } from "../../../../../../resources/Deprecated/currencyHelper.js";

/**
 * @function JobDocumentTotals
 * @description Display the totals of the job document
 * @param props.style {object} - style object
 * @return {Element}
 * @constructor
 */
export const JobDocumentTotals = (props) => {
    const { style } = props;
    const {
        formData: { type, totalPaid, totalDue, paidFromSalesOrder },
    } = useContext(JobDocumentModalContext);
    const { runningTotalRef } = useContext(DocumentLineItemContext);

    const generateHtml = () => {
        const totalFormatted = formatUSDCurrency(runningTotalRef.current);
        const totalPaidFormatted = formatUSDCurrency(totalPaid?.amount);
        const totalDueFormatted = formatUSDCurrency(
            runningTotalRef.current -
                totalPaid?.amount -
                paidFromSalesOrder?.amount
        );
        const totalFromSoFormatted = formatUSDCurrency(
            paidFromSalesOrder?.amount
        );
        const h2Wrapper = (text, textColor = "black") => {
            return (
                <h2
                    style={{
                        fontWeight: "300",
                        margin: 0,
                        textAlign: "right",
                        color: textColor,
                    }}
                >
                    {text}
                </h2>
            );
        };

        switch (type) {
            case NewJobDocumentType.Enum.Estimate:
                return h2Wrapper(`Grand Total: ${totalFormatted}`);
            case NewJobDocumentType.Enum.PendingChangeOrder:
                return h2Wrapper(`Grand Total: ${totalFormatted}`);
            case NewJobDocumentType.Enum.ApprovedChangeOrder:
                return h2Wrapper(`Grand Total: ${totalFormatted}`);
            case NewJobDocumentType.Enum.SalesOrder:
                return (
                    <>
                        {h2Wrapper(`Total: ${totalFormatted}`)}
                        {h2Wrapper(`Paid/Invoiced: ${totalPaidFormatted}`)}
                        {h2Wrapper(
                            `Balance Due: ${totalDueFormatted}`,
                            totalDue > 0 && "red"
                        )}
                    </>
                );
            case NewJobDocumentType.Enum.Invoice:
                return (
                    <>
                        {h2Wrapper(`Total: ${totalFormatted}`)}
                        {h2Wrapper(`Payments: ${totalPaidFormatted}`)}
                        {paidFromSalesOrder?.amount > 0 &&
                            h2Wrapper(
                                `Credited From SO: ${totalFromSoFormatted}`
                            )}
                        {h2Wrapper(
                            `Balance Due: ${totalDueFormatted}`,
                            totalDue > 0 && "red"
                        )}
                    </>
                );
        }
    };

    return <div style={style}>{generateHtml()}</div>;
};
