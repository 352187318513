import React, { useContext } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import CompanyInformationForm from "./CompanyInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import ContactInformationForm from "./ContactInformationForm";
import { Loader } from "@progress/kendo-react-indicators";
import VendorSocialMediaForm from "./VendorSocialMediaForm";
import { useModal } from "../../../../hooks/useModal";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { createVendor } from "../../../../services/Deprecated/inventory/vendorService";
import PreCreateVendorSearchModal from "./PreCreateVendorSearchModal";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ContactType } from "../../../../resources/Enums/ContactType";

const VendorCreationComponent = () => {
    const preCreateModal = useModal();
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const initialStepState = [
        {
            label: "Company",
            isValid: undefined,
        },
        {
            label: "Billing",
            isValid: undefined,
        },
        {
            label: "Contact",
            isValid: undefined,
        },
    ];
    const [steps, setSteps] = React.useState(initialStepState);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const reloadDataContext = useContext(ReloadDataContext);

    const toggleAndResetForm = () => {
        if (createVisible) {
            setFormState({});
            setStep(0);
            setSteps(
                initialStepState.map((s) => ({ ...s, isValid: undefined }))
            );
        }
        toggleCreateVisible();
    };

    const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;
            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid,
            }));
            setSteps(currentSteps);
            if (!isValid) {
                return;
            }
            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);
            if (isLastStep) {
                submitVendor(values);
            }
        },
        [steps, isLastStep, step, lastStepIndex]
    );

    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    const submitVendor = (dataItem) => {
        toggleLoaderVisible();

        // Stripping the address object from the dataItem
        // and drilling down into state to ensure that upon submission state stays as string
        const formattedDataItem = {
            ...dataItem,
            paymentTypeId: dataItem?.paymentType?.id,
            billingTermId: dataItem?.billingTerm?.id,
            businessPhone: {
                typeId: dataItem.businessPhone?.phoneNumberType?.id,
                phoneNumber: dataItem.businessPhone?.phoneNumber,
            },
            incomeAccountId: dataItem.incomeAccount?.id,
            expenseAccountId: dataItem.expenseAccount?.id,
            contacts: [],
        };

        if (dataItem.primaryContact?.name1) {
            dataItem.primaryContact.type = ContactType.Enum.Primary;
            formattedDataItem.contacts.push(dataItem.primaryContact);
        }

        if (dataItem.secondaryContact?.name1) {
            dataItem.secondaryContact.type = ContactType.Enum.Secondary;
            formattedDataItem.contacts.push(dataItem.secondaryContact);
        }

        if (dataItem.orderingContact?.name1) {
            dataItem.orderingContact.type = ContactType.Enum.Ordering;
            formattedDataItem.contacts.push(dataItem.orderingContact);
        }

        if (dataItem.billingContact?.name1) {
            dataItem.billingContact.type = ContactType.Enum.Billing;
            formattedDataItem.contacts.push(dataItem.billingContact);
        }

        // Convert object into Array, then map over values to give correct naming for BE
        if (dataItem.vendorSocialMedias !== undefined) {
            formattedDataItem.vendorSocialMedias = Object.values(
                dataItem.vendorSocialMedias
            );
            formattedDataItem.vendorSocialMedias.map((socialMedia) => {
                socialMedia.socialMediaId = socialMedia.socialMedia?.id;
            });
        }

        createVendor(formattedDataItem)
            .then((response) => {
                if (response) {
                    reloadDataContext.triggerReload(formattedDataItem);
                    toggleCreateVisible();
                    setFormState({});
                    setStep(0);
                    setSteps(initialStepState);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            <Button
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                onClick={() => preCreateModal.open()}
                style={{
                    width: "160px",
                    margin: "5px",
                }}
            >
                Create New Vendor
            </Button>
            <PreCreateVendorSearchModal
                {...preCreateModal}
                setFormState={setFormState}
                toggleCreateVisible={toggleCreateVisible}
            />
            {createVisible && (
                <span>
                    <Form
                        onSubmitClick={onStepSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Vendor"}
                                onClose={toggleAndResetForm}
                                size={"large"}
                            >
                                <Stepper value={step} items={steps} />
                                <FormElement>
                                    {step === 0 && (
                                        <CompanyInformationForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {step === 1 && <BillingInformationForm />}
                                    {step === 2 && (
                                        <span>
                                            <ContactInformationForm
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                            <VendorSocialMediaForm
                                                formState={formState}
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            Step {step + 1} of 3
                                        </span>
                                        {!loaderVisible ? (
                                            <div>
                                                {step !== 0 ? (
                                                    <Button
                                                        onClick={onPrevClick}
                                                        themeColor={"primary"}
                                                        fillMode={"solid"}
                                                        rounded={"medium"}
                                                        style={{
                                                            width: "100px",
                                                            margin: "10px",
                                                        }}
                                                        icon={"k-icon k-i-undo"}
                                                    >
                                                        Previous
                                                    </Button>
                                                ) : undefined}
                                                <Button
                                                    themeColor={
                                                        isLastStep
                                                            ? "success"
                                                            : "primary"
                                                    }
                                                    disabled={
                                                        !formRenderProps.allowSubmit
                                                    }
                                                    onClick={
                                                        formRenderProps.onSubmit
                                                    }
                                                    fillMode={"solid"}
                                                    rounded={"medium"}
                                                    style={{
                                                        width: "100px",
                                                        margin: "10px",
                                                    }}
                                                    icon={`k-icon ${
                                                        isLastStep
                                                            ? "k-i-plus"
                                                            : "k-i-redo"
                                                    }`}
                                                >
                                                    {isLastStep
                                                        ? "Submit"
                                                        : "Next"}
                                                </Button>
                                            </div>
                                        ) : (
                                            <Loader type="infinite-spinner" />
                                        )}
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};

export default VendorCreationComponent;
