import { useMemo, useState } from "react";

/**
 * A state toggle modals and handle basic modal state
 * Takes in an optional record Id used for editing
 *
 * @param defaultVisibility
 * @param {string} defaultId
 * @param defaultRecord
 */
export function useModal(
    defaultId = undefined,
    defaultVisibility = false,
    defaultRecord = undefined
) {
    const [visible, setVisible] = useState(defaultVisibility);
    const [id, setId] = useState(defaultId);
    const [record, setRecord] = useState(defaultRecord);
    const [onSuccessCallback, setOnSuccessCallback] = useState(undefined);
    const [onFailureCallback, setOnFailureCallback] = useState(undefined);

    /**
     * @function open
     * @description Open the modal with the given record id and record and callback functions
     * @param recordId {string} Entity Id
     * @param record {object} Entity Object
     * @param onSuccess {function} Callback function on successful submit
     * @param onFailure {function} Callback function on failed submit
     */
    const open = (
        recordId = undefined,
        record = undefined,
        onSuccess = undefined,
        onFailure = undefined
    ) => {
        setId(recordId);
        setRecord(record);
        setOnSuccessCallback(() => onSuccess);
        setOnFailureCallback(() => onFailure);
        setVisible(true);
    };

    /**
     * @function close
     * @description Close the modal and clear the record id and record
     */
    const close = () => {
        setId(undefined);
        setRecord(undefined);
        setOnSuccessCallback(undefined);
        setOnFailureCallback(undefined);
        setVisible(false);
    };

    const isCreate = useMemo(() => id === undefined, [id, record]);
    const isUpdate = !isCreate;

    return {
        visible,
        open,
        close,
        id,
        setId,
        isCreate,
        isUpdate,
        record,
        setRecord,
        setVisible,
        onSuccessCallback,
        onFailureCallback,
    };
}
