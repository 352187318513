import React, { useContext, useEffect, useState } from "react";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { getAllLaborInfoForJobEvents } from "../../../../services/Deprecated/customer/eventServices";
import { TimeCardExtension } from "../../../HumanResources/TimeClockEntry/Components/TimeCardExtension";

const JobTimeClockEntries = () => {
    const { job } = useContext(JobContext);
    const [expanded, setExpanded] = useState("");
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!job.id) return;

        getAllLaborInfoForJobEvents(job.id).then((res) => {
            setData(res.data);
        });
    }, [job]);

    return (
        <div>
            {data.length === 0 && (
                <span className={"JustifyCenterAndAlignCenter MediumGap"}>
                    <i
                        className={"k-icon k-i-warning"}
                        style={{ color: "orange", fontSize: "1.5rem" }}
                    />
                    <h3>No time clock entries found</h3>
                </span>
            )}
            {data.map((record) => (
                <ExpansionPanel
                    title={record.employee.name}
                    subtitle={"View Job Time Card"}
                    expanded={expanded === record.employee.id}
                    tabIndex={0}
                    key={record.employee.id}
                    onAction={(event) => {
                        setExpanded(event.expanded ? "" : record.employee.id);
                    }}
                >
                    <Reveal>
                        {expanded === record.employee.id && (
                            <ExpansionPanelContent>
                                <TimeCardExtension
                                    employeeId={record.employee.id}
                                    initialFilter={`jobId~eq~'${job.id}'`}
                                />
                            </ExpansionPanelContent>
                        )}
                    </Reveal>
                </ExpansionPanel>
            ))}
        </div>
    );
};

export default JobTimeClockEntries;
