import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { CenterLoader } from "../Deprecated/CenterLoader";
import { ConfirmationButtons } from "../Buttons/ConfirmationButtons";

/**
 * @function ConfirmationDialog
 * @description
 * Confirmation dialog component
 * Handles execution of actions on confirm and cancel, as well as rendering the loader
 * @param {boolean} props.visible - Whether the dialog is visible
 * @param {CallableFunction} props.actionOnConfirm - Function to call on confirm
 * @param {CallableFunction} props.actionOnCancel - Function to call on cancel
 * @param {string|Element} props.confirmationText - The text to display in the dialog
 * @param {string} props.confirmationButtonText - The text to display on the confirmation button
 * @param {string} props.confirmationButtonThemeColor - The theme color of the confirmation button
 * @param {string} props.cancelButtonText - The text to display on the cancel button
 * @param {string} props.cancelButtonThemeColor - The theme color of the cancel button
 * @param {boolean} props.hideCancelButton - Whether to hide the cancel button
 * @return {Dialog|null}
 * @constructor
 */
export const ConfirmationDialog = (props) => {
    const {
        visible,
        actionOnConfirm,
        actionOnCancel,
        confirmationText,
        confirmationButtonText,
        confirmationButtonThemeColor,
        cancelButtonText,
        cancelButtonThemeColor,
        hideCancelButton = false,
    } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        setLoaderVisible(false);
    }, [visible]);

    if (!visible) return null;

    const onConfirm = () => {
        setLoaderVisible(true);
        actionOnConfirm();
    };

    const onCancel = () => {
        actionOnCancel();
    };

    return (
        <div>
            <Dialog
                title={"Please confirm"}
                onClose={actionOnCancel}
                width={300}
                height={"auto"}
            >
                <strong
                    style={{
                        textAlign: "center",
                        margin: "10px",
                    }}
                >
                    {confirmationText}
                </strong>
                {!loaderVisible ? (
                    <ConfirmationButtons
                        actionOnConfirm={onConfirm}
                        actionOnCancel={onCancel}
                        confirmationButtonText={confirmationButtonText}
                        confirmationButtonThemeColor={
                            confirmationButtonThemeColor
                        }
                        cancelButtonText={cancelButtonText}
                        cancelButtonThemeColor={cancelButtonThemeColor}
                        hideCancelButton={hideCancelButton}
                    />
                ) : (
                    <div>
                        <CenterLoader />
                    </div>
                )}
            </Dialog>
        </div>
    );
};
