import React, { useContext, useEffect } from "react";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import {
    FormDropDown,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { AddressInput } from "../../../../components/FormInputs/AddressInput";
import { InputLayouts } from "../../../../components/FormInputs/InputLayouts";
import { ContentHeader } from "../../../../components/ContentHeader";
import { TextAreaInput } from "../../../../components/FormInputs/TextAreaInput";

const JobInformation = ({ formRenderProps, job, values }) => {
    const customerContext = useContext(CustomerContext);
    const useCustomerAddress = formRenderProps.valueGetter(
        "isUsingCustomerAddress"
    );

    const resetAddress = () => {
        if (!job) {
            formRenderProps.onChange("address.address1", { value: "" });
            formRenderProps.onChange("address.address2", { value: "" });
            formRenderProps.onChange("address.city", { value: "" });
            formRenderProps.onChange("address.state", {
                value: { name: "", id: null },
            });
            formRenderProps.onChange("address.zipCode", { value: "" });
        }
    };

    useEffect(() => {
        if (useCustomerAddress) {
            const { address } = values.customer;
            formRenderProps.onChange("address.address1", {
                value: address.address1,
            });
            formRenderProps.onChange("address.address2", {
                value: address.address2,
            });
            formRenderProps.onChange("address.city", { value: address.city });
            formRenderProps.onChange("address.state", { value: address.state });
            formRenderProps.onChange("address.zipCode", {
                value: address.zipCode,
            });
        } else {
            resetAddress();
        }
    }, [useCustomerAddress]);

    return (
        <div>
            <Field
                name={"name"}
                component={FormInput}
                label={"Job Name:"}
                validator={nameValidator}
            />
            <Field
                name={"isUsingCustomerAddress"}
                label={"Use Customer's Address"}
                className={"JustifyLeftAlignCenter"}
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                }}
                component={Checkbox}
            />
            <Field
                name={"businessInformation"}
                component={FormDropDown}
                data={customerContext.relationships.businessInfos}
                dataItemKey="id"
                textField="nickName"
                label={"Business Information:"}
                hint={
                    "Hint: This will display in the Job information for all Job Documents"
                }
                validator={requiredValidator}
            />
            <Field
                name={"address"}
                component={AddressInput}
                required={true}
                isDisabled={useCustomerAddress}
                layout={InputLayouts.Vertical}
            />
            <ContentHeader
                title={"Description"}
                style={{
                    fontSize: 14,
                    textAlign: "left",
                    fontWeight: 400,
                }}
            />
            <Field
                name={"description"}
                component={TextAreaInput}
                autoSize={true}
                rows={4}
                validator={requiredValidator}
            />
        </div>
    );
};

export default JobInformation;
