import React, { useContext } from "react";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { BreadcrumbContext } from "../../../../../../providers/BreadCrumbProvider.jsx";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";

/**
 * @function EmployeeDetailsPortlet
 * @description Displays information for a Document about the Employees
 * @return {React.JSX.Element}
 * @constructor
 */
export const EmployeeDetailsPortlet = () => {
    const { formData } = useContext(VendorDocumentModalContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const handleEmployeeClick = (employee) => {
        handleRouteChange({
            uri: `/HumanResources/Employee/${employee.id}`,
            breadcrumbInfo: [{ id: employee.id, text: employee.name }],
        });
    };

    return (
        formData && (
            <Card style={{ width: 350, height: 125 }}>
                <CardBody
                    className={"JustifyLeftAlignLeft FlexColumn SmallGap"}
                >
                    <CardTitle>Employee Details:</CardTitle>
                    <span className={"JustifyLeftAlignLeft SmallGap"}>
                        <span
                            className={
                                "JustifyRightAlignRight FlexColumn SmallGap"
                            }
                            style={{
                                width: 100,
                            }}
                        >
                            <strong>Ordered By:</strong>
                            <strong>Confirmed By:</strong>
                            <strong>Received By:</strong>
                        </span>
                        <span
                            className={
                                "JustifyLeftAlignLeft FlexColumn SmallGap"
                            }
                        >
                            <a
                                className={"Link text-overflow"}
                                onClick={() =>
                                    handleEmployeeClick(formData.orderedBy)
                                }
                            >
                                {formData.orderedBy?.name ?? <br />}
                            </a>
                            <a
                                className={"Link text-overflow"}
                                onClick={() =>
                                    handleEmployeeClick(formData.confirmedBy)
                                }
                            >
                                {formData.confirmedBy?.name ?? <br />}
                            </a>
                            <a
                                className={"Link text-overflow"}
                                onClick={() =>
                                    handleEmployeeClick(formData.receivedBy)
                                }
                            >
                                {formData.receivedBy?.name ?? <br />}
                            </a>
                        </span>
                    </span>
                </CardBody>
            </Card>
        )
    );
};
