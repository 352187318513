import React, { useContext, useEffect, useState } from "react";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";
import { DataGrid } from "../../../../../../components/Grids/DataGrid.jsx";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton.jsx";
import { EditableNumericCell } from "../../../../../../components/GridColumnComponents/EditableNumericCell.jsx";
import { CenterLoader } from "../../../../../../components/Deprecated/CenterLoader.jsx";
import { formatUSDCurrency } from "../../../../../../resources/Deprecated/currencyHelper.js";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { FormDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import { useModal } from "../../../../../../hooks/useModal.js";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell.jsx";
import { VendorDocumentModalHelper } from "../Helpers/VendorDocumentModalHelper.js";
import { EditableTextCell } from "../../../../../../components/GridColumnComponents/EditableTextCell.jsx";
import { EditableDropDownCell } from "../../../../../../components/GridColumnComponents/EditableDropDownCell.jsx";
import BaseService from "../../../../../../services/BaseService.js";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum.js";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import { ReceivalModal } from "./ReceivalModal.jsx";
import RowRenderHighlighter from "../../../../../../components/Deprecated/RowRenderHighlighter.jsx";
import { RemoteDropDown } from "../../../../../../components/FormInputs/RemoteDropDown.jsx";

/**
 * @function VendorDocumentLineItems
 * @description Component to display line items for a vendor document
 * @return {Element}
 * @constructor
 */
export const VendorDocumentLineItems = () => {
    const {
        formData,
        lineItems,
        reloadItemGridKey,
        reloadItemGrid,
        canEdit,
        canReceive,
        submitLoaderVisible,
        onModalClose,
        closeAfterSubmit,
        hasPendingChanges,
    } = useContext(VendorDocumentModalContext);
    const [runningTotal, setRunningTotal] = useState(0);
    const lineItemModal = useModal();
    const receivalModal = useModal();
    const [availableJobs, setAvailableJobs] = useState([]);

    useEffect(() => {
        if (!formData.vendor) return;

        const service = new BaseService("Inventory/Vendor/Job");
        service.getAll(`vendorId=${formData.vendor.id}`).then((res) => {
            setAvailableJobs(res.data);
        });
    }, [formData?.vendor?.id]);

    useEffect(() => {
        calculateAndSetRunningTotal();
    }, [lineItems.current]);

    const removeItemFromDocument = (item) => {
        lineItems.current = lineItems.current.filter((i) => i !== item);
        reloadItemGrid();
    };

    const canEditDescription = (li) => {
        if (!canEdit) return false;
        if (li.type !== NewItemTypeEnum.Enum.Item) return true;
        return li.item.isDescriptionEditAllowed;
    };

    const handlePriceAndQuantityChange = (e) => {
        lineItems.current = lineItems.current.map((item) => {
            if (item.id === e.dataItem.id) {
                item.total.amount = item.price.amount * item.quantity;
            }
            return item;
        });
    };

    const calculateAndSetRunningTotal = () => {
        const total = lineItems.current.reduce(
            (acc, item) => acc + item.total?.amount ?? 0,
            0
        );
        setRunningTotal(total);
    };

    const addNote = () => {
        lineItems.current = [
            ...lineItems.current,
            VendorDocumentModalHelper.formatLineItemForGrid({
                id: Math.random(),
                item: {
                    name: "---NOTE---",
                    poDescription: "Enter note details here...",
                    retailPrice: { amount: 0, currency: "USD" },
                },
                type: NewItemTypeEnum.Enum.Note,
                job: formData.job,
            }),
        ];
        reloadItemGrid();
    };

    return (
        <DataGrid
            key={reloadItemGridKey}
            style={{
                maxWidth: "99.99%",
                minHeight: 125,
            }}
            useInlineEdit={true}
            data={lineItems.current}
            resizable={true}
            initialSort={[]}
            disabledPagination={true}
            showSaveChangesButtonGroup={false} // We need our own custom solution
            rowRender={(row, props) =>
                RowRenderHighlighter(row, {
                    isDanger: !props.dataItem.item.isActive,
                })
            }
        >
            <GridToolbar>
                <span>
                    {submitLoaderVisible && (
                        <span className={"JustifyCenterAndAlignCenter"}>
                            <CenterLoader />
                        </span>
                    )}
                    {canEdit && (
                        <>
                            <ActionButton
                                permissions={[
                                    PermissionsEnum.UpdateVendorDocument,
                                ]}
                                theme={"primary"}
                                buttonText={"Save"}
                                disabled={
                                    !formData.vendor || submitLoaderVisible
                                }
                                disabledToolTipMessage={
                                    !formData.vendor
                                        ? "Please select a vendor"
                                        : "Loading..."
                                }
                                onClick={() =>
                                    (hasPendingChanges.current = false)
                                }
                                className={"export-hide"}
                            />
                            <ActionButton
                                permissions={[
                                    PermissionsEnum.UpdateVendorDocument,
                                ]}
                                theme={"primary"}
                                buttonText={"Save & Close"}
                                onClick={() =>
                                    (closeAfterSubmit.current = true)
                                }
                                disabled={
                                    !formData.vendor || submitLoaderVisible
                                }
                                disabledToolTipMessage={
                                    !formData.vendor
                                        ? "Please select a vendor"
                                        : "Loading..."
                                }
                                className={"export-hide"}
                            />
                        </>
                    )}
                    <ActionButton
                        permissions={[]}
                        theme={"primary"}
                        buttonText={"Cancel"}
                        type={"button"}
                        disabled={submitLoaderVisible}
                        onClick={onModalClose}
                        className={"export-hide"}
                    />
                    <strong
                        className={"JustifyLeftAlignCenter"}
                        style={{ marginLeft: 10 }}
                    >
                        Running Total: {formatUSDCurrency(runningTotal)}
                    </strong>
                </span>
                <ReceivalModal {...receivalModal} />
                {canEdit && (
                    <>
                        <AddLineItemModal {...lineItemModal} />
                        {/* Spacer, yes, I hate this */}
                        <span
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                        <span className={"export-hide"}>
                            <ActionButton
                                permissions={[]}
                                theme={"primary"}
                                buttonText={"Add Tax/Freight/Adjustments"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={() =>
                                    lineItemModal.open(undefined, {
                                        type: NewItemTypeEnum.Enum.Tax,
                                    })
                                }
                            />
                            <ActionButton
                                permissions={[]}
                                theme={"primary"}
                                buttonText={"Add Note"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={addNote}
                            />
                            <ActionButton
                                permissions={[]}
                                theme={"success"}
                                buttonText={"Add Item"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                disabled={!formData.vendor}
                                disabledToolTipMessage={
                                    "Please select a vendor"
                                }
                                onClick={() =>
                                    lineItemModal.open(undefined, {
                                        type: NewItemTypeEnum.Enum.Item,
                                    })
                                }
                            />
                        </span>
                    </>
                )}
            </GridToolbar>
            <GridColumn
                field={"item.name"}
                title={"Item:"}
                width={300}
                minResizableWidth={150}
                editable={false}
                locked={true}
            />
            <GridColumn
                className={"k-table-td"}
                field="description"
                title="Description:"
                width={300}
                minResizableWidth={100}
                cell={(props) => (
                    <EditableTextCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        disabled={!canEditDescription(props.dataItem)}
                    />
                )}
            />
            <GridColumn
                editor={"numeric"}
                field="quantity"
                title="Qty:"
                width={125}
                minResizableWidth={50}
                cell={(props) => (
                    <EditableNumericCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        min={1}
                        onCustomChange={handlePriceAndQuantityChange}
                        onBlur={calculateAndSetRunningTotal}
                        disabled={
                            !canEdit ||
                            props.dataItem.type !== NewItemTypeEnum.Enum.Item
                        }
                    />
                )}
            />
            {formData.type !== VendorDocumentType.Enum.PurchaseOrder && (
                <GridColumn
                    editor={"numeric"}
                    field="quantityReceived"
                    title="Received:"
                    width={125}
                    minResizableWidth={50}
                    cell={(props) => (
                        <EditableNumericCell
                            {...props}
                            editField={"IN_LINE_EDIT"}
                            min={0}
                            max={props.dataItem.quantity}
                            disabled={
                                !canReceive ||
                                props.dataItem.type !==
                                    NewItemTypeEnum.Enum.Item
                            }
                            onBlur={(e) => {
                                if (
                                    e.dataItem.quantityReceived === e.prevValue
                                ) {
                                    return;
                                }

                                receivalModal.open(undefined, {
                                    record: e.dataItem,
                                    prevValue: e.prevValue,
                                });
                            }}
                        />
                    )}
                />
            )}
            {formData.type !== VendorDocumentType.Enum.PurchaseOrder && (
                <GridColumn
                    className={"k-table-td"}
                    field="receiverInitials"
                    title="Receiver:"
                    width={125}
                    editable={false}
                    minResizableWidth={50}
                />
            )}
            <GridColumn
                className={"k-table-td"}
                field="job"
                title="Job:"
                width={300}
                minResizableWidth={100}
                cell={(props) => (
                    <EditableDropDownCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        data={availableJobs}
                        textField={"name"}
                        valueField={"id"}
                        disabled={!canEdit}
                    />
                )}
            />
            <GridColumn
                editor={"numeric"}
                field="price.amount"
                title="Price:"
                width={150}
                minResizableWidth={50}
                cell={(props) => (
                    <EditableNumericCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        format={"c2"}
                        min={0}
                        onCustomChange={handlePriceAndQuantityChange}
                        onBlur={calculateAndSetRunningTotal}
                        disabled={
                            !canEdit ||
                            props.dataItem.type === NewItemTypeEnum.Enum.Note
                        }
                    />
                )}
            />
            <GridColumn
                editor={"numeric"}
                field="total.amount"
                title="Total:"
                width={100}
                minResizableWidth={50}
                editable={false}
                format={"{0:c2}"}
            />
            {canEdit && (
                <GridColumn
                    className={"export-hide"}
                    width={125}
                    resizable={false}
                    filterable={false}
                    editable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    buttonText: "Remove",
                                    permissions: [],
                                    onClick: () =>
                                        removeItemFromDocument(props.dataItem),
                                    icon: "k-icon k-i-delete",
                                },
                            ]}
                        />
                    )}
                />
            )}
        </DataGrid>
    );
};

/**
 * @function AddLineItemModal
 * @description Modal to add a line item to the document
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const AddLineItemModal = (props) => {
    const { close, visible, record } = props;
    const [data, setData] = useState(undefined);
    const { reloadItemGrid, lineItems, formData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        setData(record);
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        lineItems.current = [...lineItems.current, data];
        reloadItemGrid();
        onClose();
    };

    // Math.random() is used because the DataGrid requires an ID to correctly find the editing entity.
    // If this is not passed in, the DataGrid will not be able to find the entity and will change all entities
    // with an undefined ID to the same value.
    const generateFields = (lineItemType) => {
        switch (lineItemType) {
            case NewItemTypeEnum.Enum.Item:
                return (
                    <Field
                        url={
                            formData.job
                                ? `Customer/Job/Vendor/Item/GetAll?jobId=${formData.job.id}&`
                                : `/Inventory/Vendor/Item/GetAll?vendorId=${formData.vendor.id}&`
                        }
                        name={"item"}
                        useExactMatch={true}
                        component={RemoteDropDown}
                        label={"Item:"}
                        validator={requiredValidator}
                        style={{ width: "100%" }}
                        textField={"item.name"}
                        searchFields={["item.name"]}
                        initialFilters={[
                            {
                                field: "vendorId",
                                operator: "eq",
                                value: formData.vendor.id,
                            },
                            {
                                field: "item.IsActive",
                                operator: "eq",
                                value: true,
                            },
                        ]}
                        onChange={(e) => {
                            const item = {
                                ...data,
                                item: e.value.item,
                                job: formData.job,
                                vendorItemId: e.value.id,
                                id: Math.random(),
                            };

                            setData(
                                VendorDocumentModalHelper.formatLineItemForGrid(
                                    item
                                )
                            );
                        }}
                    />
                );
            case NewItemTypeEnum.Enum.Tax:
                return (
                    <Field
                        name={"cost"}
                        component={FormDropDown}
                        textField={"item.name"}
                        data={[
                            {
                                id: Math.random(),
                                item: {
                                    name: "---TAX---",
                                    poDescription: "Enter tax details here...",
                                    retailPrice: { amount: 0, currency: "USD" },
                                },
                                type: NewItemTypeEnum.Enum.Tax,
                            },
                            {
                                id: Math.random(),
                                item: {
                                    name: "---FREIGHT---",
                                    poDescription:
                                        "Enter freight details here...",
                                    retailPrice: { amount: 0, currency: "USD" },
                                },
                                type: NewItemTypeEnum.Enum.Shipping,
                            },
                            {
                                id: Math.random(),
                                item: {
                                    name: "---ADJUSTMENT---",
                                    poDescription:
                                        "Enter adjustment details here...",
                                    retailPrice: { amount: 0, currency: "USD" },
                                },
                                type: NewItemTypeEnum.Enum.Adjustment,
                            },
                        ]}
                        label={"Cost:"}
                        onChange={(e) => {
                            const cost = {
                                ...e.value,
                                job: formData.job,
                                id: Math.random(),
                            };
                            setData(
                                VendorDocumentModalHelper.formatLineItemForGrid(
                                    cost
                                )
                            );
                        }}
                    />
                );
        }
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Add Line Item"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {generateFields(record.type)}
                            <FormButtons
                                loaderVisible={false}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={true}
                                buttonText={"Add"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
