import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { getInitialDataQueryState } from "../../../utilities/kendoHelper";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

export const getUnitOfMeasure = async (unitOfMeasureId) => {
    try {
        const response = await axios.get(
            `/General/UnitOfMeasure/Get?id=${unitOfMeasureId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllUnitOfMeasures = async () => {
    try {
        const kendoRequest = getInitialDataQueryState();
        kendoRequest.sort = [{ field: "name", dir: "asc" }];
        const queryParams = toDataSourceRequestString(kendoRequest);

        const response = await axios.get(
            `/General/UnitOfMeasure/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const createUnitsOfMeasures = async (units) => {
    try {
        const response = await axios.post(
            `/General/UnitOfMeasure/Create`,
            units
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateUnitsOfMeasures = async (units) => {
    try {
        const response = await axios.put(
            `/General/UnitOfMeasure/Update`,
            units
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteUnitOfMeasure = async (unitOfMeasureId) => {
    try {
        return await axios.delete(
            `/General/UnitOfMeasure/Delete?id=${unitOfMeasureId}`
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};
