import React, { useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PhoneTypeDashboardPage from "./PhoneTypes/PhoneTypeDashboardPage";
import { ContentHeader } from "../../../../components/ContentHeader";
import PaymentTypeDashboardPage from "./PaymentTypes/PaymentTypeDashboardPage";
import SocialMediaDashboardPage from "./SocialMedias/SocialMediaDashboardPage";
import UnitOfMeasureDashboardPage from "./UnitOfMeasures/UnitOfMeasureDashboardPage";

const GeneralTabPage = () => {
    const [selected, setSelected] = useState(0);

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="General Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <TabStrip
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    className="tabs"
                >
                    <TabStripTab title="Phone Type">
                        <PhoneTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Payment Types">
                        <PaymentTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Social Medias">
                        <SocialMediaDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Unit Of Measures">
                        <UnitOfMeasureDashboardPage />
                    </TabStripTab>
                </TabStrip>
            </Card>
        </div>
    );
};

export default GeneralTabPage;
