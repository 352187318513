import React from "react";
import { CardHeader, CardTitle } from "@progress/kendo-react-layout";
import { Button, Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import { ListBox } from "@progress/kendo-react-listbox";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { nameValidator } from "../../../../resources/Deprecated/formValidators";
import ItemCustomizationService from "../../../../services/Deprecated/inventory/ItemCustomizationService";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { CategoryCustomizationsAndListsContext } from "../../../../providers/Deprecated/Inventory/Item/CategoryCustomizationsAndLists";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";

const SELECTED_FIELD = "selected";

export const NameAndDescriptionForm = ({
    title,
    visible,
    loaderVisible,
    onSubmit,
    onCancel,
    initialValues,
}) => {
    const buttonColor = initialValues ? "info" : "success";
    const buttonIcon = initialValues ? "k-icon k-i-save" : "k-icon k-i-plus";
    const buttonText = initialValues ? "Update" : "Create";
    return (
        <>
            {visible && (
                <Form
                    onSubmitClick={onSubmit}
                    initialValues={initialValues}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={title}
                            onClose={onCancel}
                            size={"small"}
                        >
                            <FormElement>
                                <Field
                                    name={"name"}
                                    component={FormInput}
                                    label={"Name:"}
                                    validator={nameValidator}
                                />
                                <Field
                                    name={"description"}
                                    component={FormInput}
                                    label={"Description:"}
                                />
                                {!loaderVisible ? (
                                    <div className="k-form-buttons">
                                        <Button
                                            type={"button"}
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={onCancel}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type={"submit"}
                                            themeColor={buttonColor}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                            icon={buttonIcon}
                                            disabled={!formRenderProps.valid}
                                        >
                                            {buttonText}
                                        </Button>
                                    </div>
                                ) : (
                                    <Loader
                                        style={{ marginTop: "25px" }}
                                        type="infinite-spinner"
                                    />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                ></Form>
            )}
        </>
    );
};

export const CustomizationListsCustomizations = ({
    handleItemClick,
    toggleRefreshList,
}) => {
    const context = React.useContext(CategoryCustomizationsAndListsContext);
    const { bool: nameCreateFormVisible, toggle: toggleNameCreateFormVisible } =
        useToggle();
    const { bool: nameUpdateFormVisible, toggle: toggleNameUpdateFormVisible } =
        useToggle();
    const {
        bool: deleteConfirmationVisible,
        toggle: toggleDeleteConfirmationVisible,
    } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();

    /**
     * Deletes the customization item
     * @returns {Promise<void>}
     */
    const onDeleteClick = () => {
        toggleLoaderVisible();

        ItemCustomizationService.deleteCustomization(
            context.selectedItems.customization.id
        )
            .then(() => {
                toggleDeleteConfirmationVisible();
            })
            .finally(() => {
                toggleRefreshList();
                toggleLoaderVisible();
            });
    };

    /**
     * Creates the customization item
     * @param {Object} dataItem
     * @returns {Promise<void>}
     */
    const onCreateClick = (dataItem) => {
        toggleLoaderVisible();

        dataItem.values.customizationListId =
            context.selectedItems.customizationList.id;

        ItemCustomizationService.createCustomization(dataItem.values)
            .then(() => {
                toggleNameCreateFormVisible();
            })
            .finally(() => {
                toggleRefreshList();
                toggleLoaderVisible();
            });
    };

    /**
     * Updates the customization item
     * @param {Object} dataItem
     * @returns {Promise<void>}
     */
    const onUpdateClick = (dataItem) => {
        toggleLoaderVisible();

        dataItem.values.customizationListId =
            context.selectedItems.customizationList.id;

        ItemCustomizationService.updateCustomization(dataItem.values)
            .then(() => {
                toggleNameUpdateFormVisible();
            })
            .finally(() => {
                toggleRefreshList();
                toggleLoaderVisible();
            });
    };

    /**
     * Sets the display of the customization Item to give it
     * Bold text and a tooltip with the description
     * @param {Object} props
     * @returns {Element}
     * @constructor
     */
    const CustomizationDisplay = (props) => {
        let { dataItem, ...others } = props;
        return (
            <Tooltip anchorElement="target" position="top">
                <li {...others} title={dataItem.description}>
                    <span
                        style={{
                            fontWeight: "bold",
                            textAlign: "left",
                        }}
                        title={dataItem.description}
                    >
                        {dataItem.name}
                    </span>
                </li>
            </Tooltip>
        );
    };

    return (
        <div
            style={{
                height: "260px",
                overflow: "auto",
            }}
        >
            <CardHeader>
                <CardTitle>Customizations</CardTitle>
            </CardHeader>
            <Toolbar>
                <ToolbarItem>
                    <Button
                        icon={"plus"}
                        themeColor={"success"}
                        onClick={toggleNameCreateFormVisible}
                        disabled={!context.selectedItems.customizationList}
                    >
                        Create
                    </Button>
                </ToolbarItem>
                <ToolbarItem>
                    <Button
                        icon={"delete"}
                        themeColor={"error"}
                        onClick={toggleDeleteConfirmationVisible}
                        disabled={!context.selectedItems.customization}
                    >
                        Delete
                    </Button>
                </ToolbarItem>
                <ToolbarItem>
                    <Button
                        icon={"pencil"}
                        themeColor={"info"}
                        onClick={toggleNameUpdateFormVisible}
                        disabled={!context.selectedItems.customization}
                    >
                        Rename
                    </Button>
                </ToolbarItem>
            </Toolbar>
            <ListBox
                style={{
                    height: "165px",
                    width: "100%",
                }}
                data={context.listBoxState.customizations}
                textField="name"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => handleItemClick(e, "customizations")}
                item={CustomizationDisplay}
                draggable={false}
            />
            {/* Create Name Form */}
            <NameAndDescriptionForm
                visible={nameCreateFormVisible}
                onCancel={toggleNameCreateFormVisible}
                onSubmit={onCreateClick}
                loaderVisible={loaderVisible}
                title={"Create Customization"}
            />
            {/* Update Name Form */}
            <NameAndDescriptionForm
                visible={nameUpdateFormVisible}
                onCancel={toggleNameUpdateFormVisible}
                onSubmit={onUpdateClick}
                loaderVisible={loaderVisible}
                initialValues={context.selectedItems.customization}
                title={"Update Customization"}
            />
            {/* Delete Confirmation */}
            <ConfirmDialogue
                visible={deleteConfirmationVisible}
                onConfirm={onDeleteClick}
                onCancel={toggleDeleteConfirmationVisible}
                loaderVisible={loaderVisible}
            />
        </div>
    );
};
