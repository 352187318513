import React from "react";

/**
 * @function EventTypeDatesDetailRow
 * @param props.dataItem {object}
 * @return {Element}
 * @constructor
 */
export const EventTypeDatesDetailRow = (props) => {
    const dataItem = props.dataItem;
    return (
        <section
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: 5,
                padding: "0 10px",
            }}
        >
            <i className={"k-icon k-i-caret-alt-right"} />
            {dataItem.specification ?? "No specification provided"}
        </section>
    );
};
