import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { ContentHeader } from "../../../../components/ContentHeader";
import { ContactInput } from "../../../../components/FormInputs/ContactInput";

const ContactInformationForm = ({ formRenderProps }) => {
    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{
                rows: "10px",
                cols: "15px",
            }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Contact Information:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                <ContentHeader
                    title={"Primary"}
                    style={{ textAlign: "left", fontSize: 16 }}
                />
                <ContactInput
                    field={"primaryContact"}
                    isRequired={false}
                    formRenderProps={formRenderProps}
                />
                {/*<ContentHeader*/}
                {/*    title={"Ordering"}*/}
                {/*    style={{ textAlign: "left", fontSize: 16 }}*/}
                {/*/>*/}
                {/*<ContactInput*/}
                {/*    field={"orderingContact"}*/}
                {/*    isRequired={false}*/}
                {/*    formRenderProps={formRenderProps}*/}
                {/*/>*/}
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                <ContentHeader
                    title={"Secondary"}
                    style={{ textAlign: "left", fontSize: 16 }}
                />
                <ContactInput
                    field={"secondaryContact"}
                    isRequired={false}
                    formRenderProps={formRenderProps}
                />
                {/*<ContentHeader*/}
                {/*    title={"Billing"}*/}
                {/*    style={{ textAlign: "left", fontSize: 16 }}*/}
                {/*/>*/}
                {/*<ContactInput*/}
                {/*    field={"billingContact"}*/}
                {/*    isRequired={false}*/}
                {/*    formRenderProps={formRenderProps}*/}
                {/*/>*/}
            </GridLayoutItem>
        </GridLayout>
    );
};

export default ContactInformationForm;
