import axios from "axios";
import { loginApiRequest } from "./authconfig.js";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { ErrorContext } from "../providers/ErrorProvider";
import config from "../config";

const axiosInstance = axios.create({
    baseURL: config.baseUrl,
});

function AxiosInterceptor({ children }) {
    const { instance: msalInstance } = useMsal();
    const errorContext = React.useContext(ErrorContext);

    // Request interceptor
    //
    React.useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            async (config) => {
                // Do something before request is sent
                //
                const account = msalInstance.getAllAccounts()[0];
                const msalResponse = await msalInstance.acquireTokenSilent({
                    ...loginApiRequest,
                    account: account,
                });
                config.headers.Authorization = `Bearer ${msalResponse.accessToken}`;
                return config;
            },
            function (error) {
                // Do something with request error
                //
                errorContext.setErrorObject(error);
            }
        );

        // Response interceptor
        //
        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                //
                return response;
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                //
                errorContext.setErrorObject(error);
                return error;
            }
        );

        // Eject Interceptors for cleanup
        //
        return () => {
            axiosInstance.interceptors.response.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return children;
}

export { AxiosInterceptor, axiosInstance };
