export default class FormHelper {
    /**
     * @function initFormMessages
     */
    static initFormMessages(props) {
        const { touched, validationMessage, hint, id } = props;

        const showValidationMessage = touched && validationMessage;
        const showHint = !showValidationMessage && hint;
        const hintId = showHint ? `${id}_hint` : "";
        const errorId = showValidationMessage ? `${id}_error` : "";

        return { showValidationMessage, showHint, hintId, errorId };
    }
}
