import React, { useContext } from "react";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import LinkTag from "../../../../../../components/LinkTag.jsx";

export const JobDocumentAuditInfo = () => {
    const { formData } = useContext(JobDocumentModalContext);

    return (
        <span
            className={"export-hide"}
            style={{
                fontSize: 16,
                color: "#666666",
                textAlign: "left",
            }}
        >
            <span>Created By: </span>
            <LinkTag
                text={formData.createdBy.name}
                breadCrumbInfo={{
                    uri: `/HumanResources/Employee/${formData.createdBy.id}`,
                    breadcrumbInfo: [
                        {
                            id: formData.createdBy.id,
                            text: formData.createdBy.name,
                        },
                    ],
                }}
            />
            <br />
            <span>Last Edited By: </span>
            <LinkTag
                text={formData.lastEditedBy.name}
                breadCrumbInfo={{
                    uri: `/HumanResources/Employee/${formData.lastEditedBy.id}`,
                    breadcrumbInfo: [
                        {
                            id: formData.lastEditedBy.id,
                            text: formData.lastEditedBy.name,
                        },
                    ],
                }}
            />
        </span>
    );
};
