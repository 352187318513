import React, { useEffect, useState } from "react";
import ItemDashboardPage from "../../Item/ItemDashboardPage";

const ItemCategoryItemList = ({ selectedItem }) => {
    // store categoryId to know when to re-render to prevent state issues with initial filter
    //? causes a re-render on the grid, which is a lil ugly, but better than a non filtering grid
    const [categoryId, setCategoryId] = useState();
    // Setup category ID filter to be passed to stateful grid
    const [categoryIdFilter, setCategoryIdFilter] = useState();

    /**
     * Set the category ID filter to the selected category ID
     * If no category is selected, return an empty filter
     * This will be used to filter the data grid
     * @returns void
     */
    useEffect(() => {
        if (selectedItem?.id === undefined) return;
        if (selectedItem.id === categoryId) return;

        setCategoryId(selectedItem.id);
        setCategoryIdFilter({
            filters: [
                {
                    field: "categoryId",
                    operator: "eq",
                    value: selectedItem?.id,
                },
            ],
            logic: "and",
        });
    }, [setCategoryIdFilter, setCategoryId, categoryId, selectedItem]);

    return (
        categoryId === selectedItem?.id && (
            <ItemDashboardPage initialFilter={categoryIdFilter} />
        )
    );
};

export default ItemCategoryItemList;
