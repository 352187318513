import React, { useRef } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { useModal } from "../../../hooks/useModal";
import { InventoryModal } from "./Components/InventoryModal";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { InventoryType } from "../../../resources/Enums/InventoryType";
import { BaseCell } from "../../../components/GridColumnComponents/BaseCell";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { InventoryDeletionModal } from "./Components/InventoryDeletionModal";

const InventoryDashboardPage = (props) => {
    const inventoryModal = useModal();
    const deletionModal = useModal();
    const searchParams = useRef(new URLSearchParams(props.location.search));

    const buildInitialFilter = () => {
        const filters = [];

        if (searchParams.current.get("itemId")) {
            filters.push({
                field: "item.id",
                operator: "eq",
                value: searchParams.current.get("itemId"),
            });
        }

        return {
            logic: "and",
            filters,
        };
    };

    return (
        <div className={"JustifyLeftAlignLeft FlexColumn LargeGap"}>
            <InventoryModal {...inventoryModal} />
            <InventoryDeletionModal {...deletionModal} />
            <ActionButton
                onClick={() => inventoryModal.open()}
                buttonText={"Add Inventory"}
                icon={"k-icon k-i-plus"}
                permissions={[PermissionsEnum.CreateInventory]}
            />
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={"/Inventory/Inventory/GetAll?"}
                searchFields={["name"]}
                resizable={true}
                initialSort={[
                    {
                        field: "type",
                        dir: "asc",
                    },
                ]}
                initialFilter={buildInitialFilter()}
            >
                <GridColumn
                    className={"k-table-td"}
                    field={"storageLocation.name"}
                    title={"Location:"}
                    width={250}
                    minResizableWidth={100}
                    locked={true}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"name"}
                    title={"Item:"}
                    width={300}
                    minResizableWidth={100}
                    locked={true}
                    cell={(props) => (
                        <LinkCell
                            uri={`/Inventory/Item/${props.dataItem.item.id}`}
                            {...props}
                            dataItem={props.dataItem.item}
                        />
                    )}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"type"}
                    title={"Status:"}
                    width={150}
                    minResizableWidth={50}
                    cell={(props) => (
                        <EnumCell enumerable={InventoryType} {...props} />
                    )}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"name"}
                    title={"Vendor:"}
                    width={250}
                    minResizableWidth={100}
                    cell={(props) => (
                        <LinkCell
                            uri={`/Inventory/Vendor/${props.dataItem.vendor.id}`}
                            {...props}
                            dataItem={props.dataItem.vendor}
                        />
                    )}
                />
                <GridColumn
                    field="cost.amount"
                    title="Avg Cost:"
                    width={125}
                    minResizableWidth={50}
                    format={"{0:c}"}
                />
                <GridColumn
                    field="width"
                    title="Width:"
                    width={125}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.width
                                ? `${props.dataItem.width} Inch.`
                                : undefined}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="length"
                    title="Length:"
                    width={125}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.length
                                ? `${props.dataItem.length} Inch.`
                                : undefined}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="quantity"
                    title="QTY:"
                    width={100}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.quantity} Each
                        </BaseCell>
                    )}
                />
                <GridColumn
                    width={150}
                    resizable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={
                                props.dataItem.type !==
                                    InventoryType.Enum.Allocated && [
                                    {
                                        buttonText: "Delete Inventory",
                                        onClick: () =>
                                            deletionModal.open(undefined, {
                                                inventoryIds:
                                                    props.dataItem.inventoryIds,
                                                quantity:
                                                    props.dataItem.quantity,
                                            }),
                                        permissions: [
                                            PermissionsEnum.DeleteInventory,
                                        ],
                                    },
                                ]
                            }
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default InventoryDashboardPage;
