import React from "react";
import styles from "../Scheduler.module.scss";
import { SchedulerColumn } from "./SchedulerColumn.jsx";
import dayjs from "dayjs";

export const SchedulerGrid = ({ events, startDate, numOfDays = 7 }) => {
    const daysArray = Array.from({ length: numOfDays }, (_, i) =>
        dayjs(startDate).add(i, "days")
    );
    return (
        <div className={styles.grid}>
            {daysArray.map((date, index) => (
                <SchedulerColumn
                    key={index}
                    date={date}
                    events={events.filter(
                        (e) => e.eventDate === date.format("YYYY-MM-DD")
                    )}
                />
            ))}
        </div>
    );
};
