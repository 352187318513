export class TimeClockHelper {
    /**
     * @function grabTimeClockContentTitle
     * @description Get the title for the time clock content header
     * @return {string}
     */
    static grabTimeClockContentTitle = (isStationary) => {
        return isStationary ? "Stationary Time Clock" : "Time Clock";
    };

    static grabTimeClockSubmitButtonText = (isClockedIn) => {
        return isClockedIn ? "Clock Out" : "Clock In";
    };
}
