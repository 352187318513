import React, { useContext } from "react";
import { CustomerContext } from "../../../../../../providers/Deprecated/Customer/CustomerProvider.jsx";
import { BreadcrumbContext } from "../../../../../../providers/BreadCrumbProvider.jsx";

export const JobDocumentModalCustomerDisplay = () => {
    const { customer } = useContext(CustomerContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);

    return (
        customer && (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <a
                    className={"text-overflow Link AlignTextLeft"}
                    onClick={() =>
                        handleRouteChange({
                            uri: `/Customer/${customer.id}`,
                            breadcrumbInfo: [
                                {
                                    id: customer.id,
                                    text: customer.combinedName,
                                },
                            ],
                        })
                    }
                >
                    {customer.combinedName}
                </a>
                <span className={"AlignTextLeft"}>
                    {`(${customer.primaryContact.phone.phoneNumberType.name}) ${customer.primaryContact.phone.phoneNumber}`}
                </span>
                <span className={"AlignTextLeft"}>
                    {customer.primaryContact.email}
                </span>
            </div>
        )
    );
};
