import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getAllStatuses = async () => {
    try {
        return formatServiceResponse({
            status: 200,
            data: {
                data: [
                    { id: 0, name: "Active" },
                    { id: 1, name: "Inactive" },
                ],
            },
        });
    } catch (error) {
        return formatServiceResponse(error);
    }
};
