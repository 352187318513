import React, { useContext, useEffect, useState } from "react";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import {
    createTimeClockEntry,
    getTimeClock,
    updateTimeClockEntry,
} from "../../../../services/Deprecated/humanResources/timeClockServices";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
    FormDropDown,
    FormTimePicker,
} from "../../../../components/Deprecated/FormComponents";
import { getAllJobs } from "../../../../services/Deprecated/customer/jobServices";
import CalendarService from "../../../../services/Deprecated/calendar/CalendarService";
import { getCurrentAvailableTasks } from "../../../../services/Deprecated/humanResources/employeeServices";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import EmployeeTimeClockService from "../../../../services/Deprecated/humanResources/EmployeeTimeClockService";

dayjs.extend(utc);

/**
 * @function TimeClockEntryModal
 * @description
 * Modal component for editing, does not currently support creating new time clock entries
 * @param {string} props.id - The id of the time clock entry to edit
 * @param {function} props.close - The function to close the modal
 * @param {boolean} props.visible - Whether the modal is visible
 * @param {boolean} props.isUpdate - Whether the modal is in update mode
 * @param {boolean} props.isCreate - Whether the modal is in create mode
 * @param {Object} props.record - The record to base the time clock entry on if creating
 * @return {Element}
 * @constructor
 */
export const TimeClockEntryModal = (props) => {
    const { id, close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [jobs, setJobs] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [timeAlreadyExists, setTimeAlreadyExists] = useState(false);
    const [formRefreshKey, setFormRefreshKey] = useState(0);

    // Handles fetch logic for the form
    useEffect(() => {
        if (!visible) return;

        if (id) {
            getTimeClock(id).then((res) => {
                const formattedData = {
                    ...res.data,
                    startTime: dayjs.utc(res.data.startTime).local().toDate(),
                    endTime: res.data.endTime
                        ? dayjs.utc(res.data.endTime).local().toDate()
                        : undefined,
                };

                setInitialFormData(formattedData);
            });
        } else {
            setInitialFormData({});
        }
    }, [visible]);

    useEffect(() => {
        if (!visible) return;

        if (!record?.employee && initialFormData?.employee?.id) return;

        if (!initialFormData?.startTime || !initialFormData?.endTime) return;

        const currentDate = record?.start ?? dayjs(initialFormData.startTime);

        const data = {
            employeeId: record?.employeeId ?? initialFormData.employee?.id,
            start: dayjs(initialFormData.startTime)
                .date(currentDate.date())
                .month(currentDate.month())
                .year(currentDate.year())
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
            end: dayjs(initialFormData.endTime)
                .date(currentDate.date())
                .month(currentDate.month())
                .year(currentDate.year())
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
        };

        if (id) {
            data.voidIds = [id];
        }

        EmployeeTimeClockService.entryExistsBetweenDateTimes(data).then(
            (res) => {
                setTimeAlreadyExists(res.data);
            }
        );
    }, [initialFormData?.startTime, initialFormData?.endTime, visible]);

    // Fetches all jobs
    useEffect(() => {
        if (!visible) return;

        getAllJobs().then((res) => {
            setJobs(res.data);
            setFormRefreshKey(formRefreshKey + 1);
        });
    }, [visible]);

    // Fetches all CalendarEvents on the currently selected Job,
    // if Job changes, fetches all CalendarEvents on the new Job
    useEffect(() => {
        if (!visible) return;

        if (!initialFormData?.job?.id) return;

        CalendarService.getAllJobEvents(initialFormData.job.id).then((res) => {
            setCalendarEvents(
                res.data.map((item) => {
                    const nameFormatted = `${
                        item.eventDate &&
                        dayjs(item.eventDate).format("MM/DD/YYYY")
                    } ${item.eventDateTitle}`;

                    return {
                        ...item,
                        name: nameFormatted,
                    };
                })
            );
            setFormRefreshKey(formRefreshKey + 1);
        });
    }, [visible, initialFormData?.job]);

    // Fetches all available tasks for the currently selected Employee, Job, and CalendarEvent
    useEffect(() => {
        if (!visible) return;

        if (!initialFormData?.job?.id || !initialFormData?.calendarEvent?.id) {
            setTasks([]);
            return;
        }

        getCurrentAvailableTasks({
            employeeId: record?.employeeId ?? initialFormData.employee.id,
            jobId: initialFormData.job.id,
            calendarEventId: initialFormData.calendarEvent.id,
            timePeriod: {
                start: dayjs().startOf("year").utc().format("YYYY-MM-DD"),
                end: dayjs().endOf("year").utc().format("YYYY-MM-DD"),
            },
        }).then((res) => {
            setTasks(res.data);
            setFormRefreshKey(formRefreshKey + 1);
        });
    }, [visible, initialFormData?.calendarEvent]);

    /**
     * @function onSubmit
     * @description
     * Handles the form submission
     * @param props
     */
    const onSubmit = (props) => {
        if (!props.isValid) return;

        if (timeAlreadyExists) return;

        setLoaderVisible(true);

        const data = props.values;

        const startDate = dayjs(data.startTime).utc();
        const formattedData = {
            ...data,
            startTime: startDate,
            endTime: data.endTime
                ? dayjs(data.endTime)
                      .date(startDate.date())
                      .month(startDate.month())
                      .year(startDate.year())
                      .utc()
                : undefined,
            employeeId: record?.employeeId ?? initialFormData.employee.id,
            jobId: data.job?.id,
            calendarEventId: data.calendarEvent?.id,
            taskId: data.task?.id,
        };

        delete formattedData.job;
        delete formattedData.calendarEvent;
        delete formattedData.task;

        // If Id is present, this is an Update operation
        if (id) {
            updateTimeClockEntry(formattedData).then(() => {
                triggerReload();
                close();
                setLoaderVisible(false);
            });
        } else {
            // Ensure times are correctly set for Create to the selected time
            formattedData.startTime = formattedData.startTime
                .date(record.start.date())
                .month(record.start.month())
                .year(record.start.year());

            if (formattedData.endTime) {
                formattedData.endTime = formattedData.endTime
                    .date(record.end.date())
                    .month(record.end.month())
                    .year(record.end.year());
            }

            createTimeClockEntry(formattedData).then(() => {
                triggerReload();
                close();
                setLoaderVisible(false);
            });
        }
    };

    /**
     * @function onClose
     * @description
     * Closes the modal and resets state
     * @returns {void}
     */
    const onClose = () => {
        setTimeAlreadyExists(false);
        setInitialFormData(undefined);
        close();
    };

    /**
     * @function onJobChange
     * @description
     * Handles the job change event, resets the task and calendar event fields
     * @param event
     */
    const onJobChange = (event) => {
        setInitialFormData({
            ...initialFormData,
            jobId: event.target.value?.id,
            job: event.target.value,
            calendarEventId: undefined,
            calendarEvent: undefined,
            taskId: undefined,
            task: undefined,
        });

        setFormRefreshKey(formRefreshKey + 1);
    };

    /**
     * @function onCalendarEventChange
     * @description
     * Handles the calendar event change event, resets the task field
     * @param event
     */
    const onCalendarEventChange = (event) => {
        setInitialFormData({
            ...initialFormData,
            calendarEventId: event.target.value?.id,
            calendarEvent: event.target.value,
            taskId: undefined,
            task: undefined,
        });

        setFormRefreshKey(formRefreshKey + 1);
    };

    /**
     * @function startValidator
     * @description
     * Validates the start time field
     * @param value
     * @return {undefined|string}
     */
    const startValidator = (value) => {
        if (!value) {
            return "Start time is required";
        }

        if (initialFormData.endTime && value > initialFormData.endTime) {
            return "Start time must be before end time";
        }

        if (timeAlreadyExists) {
            return "Time entry already exists within this time frame";
        }

        return undefined;
    };
    /**
     * @function endValidator
     * @description
     * Validates the end time field
     * @param value
     * @return {undefined|string}
     */
    const endValidator = (value) => {
        if (!initialFormData.endTime) {
            return "End time is required";
        }

        if (initialFormData.startTime && value < initialFormData.startTime) {
            return "End time must be after start time";
        }

        if (timeAlreadyExists) {
            return "Time entry already exists within this time frame";
        }

        return undefined;
    };

    return (
        <>
            {visible && initialFormData && (
                <ResponsiveDialog
                    title={
                        id ? "Edit Time Clock Entry" : "Add Time Clock Entry"
                    }
                    onClose={onClose}
                    size={"small"}
                >
                    <Form
                        key={formRefreshKey}
                        initialValues={initialFormData}
                        onSubmitClick={onSubmit}
                        render={() => (
                            <FormElement
                                className={
                                    "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                                }
                            >
                                <Field
                                    component={FormTimePicker}
                                    label="Clocked In"
                                    name="startTime"
                                    format="HH:mm:ss"
                                    validator={startValidator}
                                    placeholder=""
                                    onChange={(e) => {
                                        setInitialFormData({
                                            ...initialFormData,
                                            startTime: e.value,
                                        });
                                    }}
                                />
                                <Field
                                    component={FormTimePicker}
                                    label="Clocked Out"
                                    name="endTime"
                                    format="HH:mm:ss"
                                    validator={endValidator}
                                    placeholder=""
                                    onChange={(e) => {
                                        setInitialFormData({
                                            ...initialFormData,
                                            endTime: e.value,
                                        });
                                    }}
                                />
                                <span style={{ width: "100%" }}>
                                    <Field
                                        name={"job"}
                                        label={"Job"}
                                        component={FormDropDown}
                                        data={jobs}
                                        dataItemKey="id"
                                        textField="name"
                                        onChange={onJobChange}
                                    />
                                </span>
                                <span style={{ width: "100%" }}>
                                    <Field
                                        name={"calendarEvent"}
                                        label={"Calendar Event"}
                                        component={FormDropDown}
                                        data={calendarEvents}
                                        dataItemKey="id"
                                        textField="name"
                                        onChange={onCalendarEventChange}
                                    />
                                </span>
                                <span style={{ width: "100%" }}>
                                    <Field
                                        name={"task"}
                                        label={"Task"}
                                        component={FormDropDown}
                                        data={tasks}
                                        dataItemKey="id"
                                        textField="name"
                                    />
                                </span>
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onClose}
                                    allowSubmit={true}
                                    isCreate={id === undefined}
                                />
                            </FormElement>
                        )}
                    />
                </ResponsiveDialog>
            )}
        </>
    );
};
