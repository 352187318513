import React, { useEffect, useState } from "react";
import { Upload } from "@progress/kendo-react-upload";
import { loginApiRequest } from "../../services/authconfig";
import { useMsal } from "@azure/msal-react";
import { ListView } from "@progress/kendo-react-listview";
import {
    deleteFile,
    getDirectoryContents,
} from "../../services/Deprecated/fileService";
import styles from "./Styles/FileManager.module.scss";
import ConfirmDialogue from "../Deprecated/ConfirmDialogue";
import config from "../../config";
import dayjs from "dayjs";

const FileRenderItem = ({ props, setConfirmVisible, setDeletingFileName }) => {
    /**
     * Converts the given bytes of a file to a more human readable format
     * @param bytes
     * @returns {string}
     */
    const readableBytes = (bytes) => {
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
    };

    /**
     * Handles the delete click by setting confirm to visible
     * and sets the deleting file name
     * @param visible
     * @param fileName
     */
    const handleDeleteClick = (visible, fileName) => {
        setConfirmVisible(visible);
        setDeletingFileName(fileName);
    };

    let file = props.dataItem;
    const formatDate = (date) => {
        return dayjs.utc(date).format("MM/DD/YYYY");
    };

    return (
        <div className={styles.FileListItem}>
            <section className={styles.FileStructure}>
                <a href={file.uri} target={"_blank"} rel={"noreferrer"}>
                    <img
                        width={75}
                        height={75}
                        alt={file.fileName}
                        src={file.uri}
                        loading={"lazy"}
                    />
                </a>
                <span className={styles.FileInformation}>
                    <a
                        id={file.uuid}
                        href={file.uri}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        {file.fileName}
                    </a>
                    <div>{readableBytes(file.size)}</div>
                    <div>{formatDate(file.createdAt)}</div>
                </span>
            </section>
            <button
                className={styles.DeleteBtn}
                style={{ marginRight: "10px" }}
                onClick={() => handleDeleteClick(true, file.fileName)}
            >
                <i className="k-icon k-i-delete k-icon-md"></i>
            </button>
        </div>
    );
};

export const FileManager = (props) => {
    const {
        allowedExtensions,
        disabled,
        saveUri,
        removeUri,
        readUri,
        location,
        height = 300,
    } = props;
    const { instance: msalInstance } = useMsal();
    const [bearer, setBearer] = useState("");
    const [files, setFiles] = useState([]);
    const [refreshFileList, setRefreshFileList] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [deletingFileName, setDeletingFileName] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        /**
         * Fetch the user Token so we can authenticate with the backend
         * @returns {Promise<string>}
         */
        const fetchToken = async () => {
            const account = msalInstance.getAllAccounts()[0];
            const msalResponse = await msalInstance.acquireTokenSilent({
                ...loginApiRequest,
                account: account,
            });

            return msalResponse.accessToken;
        };

        fetchToken().then((t) => setBearer(t));
    }, []);

    useEffect(() => {
        /**
         * Fetch all files in the given location
         * @returns {Promise<any|undefined>}
         */
        const fetchFiles = async () => getDirectoryContents(readUri, location);

        fetchFiles().then((r) => setFiles(r.data ?? []));
    }, [refreshFileList]);

    /**
     * When FileUpload status changes, we want to update the list if successful
     * @param event
     */
    const onStatusChange = (event) => {
        const response = event.response;

        if (response.status >= 200 && response.status <= 299) {
            setRefreshFileList(!refreshFileList);
        }
    };

    /**
     * On Delete Confirm, delete the file, refresh file list, and close the confirm
     */
    const onDeleteFileConfirm = () => {
        setLoaderVisible(true);

        deleteFile(removeUri, location, deletingFileName).then((_) => {
            setRefreshFileList(!refreshFileList);
            setDeletingFileName(undefined);
            setConfirmVisible(false);
            setLoaderVisible(false);
        });
    };

    //! Ensure urls are always using baseUrl when pushing to main
    return (
        <div>
            <ConfirmDialogue
                visible={confirmVisible}
                onConfirm={() => onDeleteFileConfirm()}
                onCancel={() => setConfirmVisible(false)}
                loaderVisible={loaderVisible}
                actionName={"Delete"}
            />
            <Upload
                saveField={"file"}
                removeField={"fileName"}
                restrictions={allowedExtensions}
                disabled={disabled}
                batch={false}
                multiple={false}
                onStatusChange={onStatusChange}
                withCredentials={false} // Deals with CORS issues. Still uses Auth Headers.
                removeMethod={"DELETE"}
                saveHeaders={{
                    Authorization: `Bearer ${bearer}`,
                }}
                removeHeaders={{
                    Authorization: `Bearer ${bearer}`,
                }}
                saveUrl={`${config.baseUrl}${saveUri}?location=${location}`}
                removeUrl={`${config.baseUrl}${removeUri}?location=${location}`}
            />
            <ListView
                data={files}
                item={(props) => (
                    <FileRenderItem
                        props={props}
                        setConfirmVisible={setConfirmVisible}
                        setDeletingFileName={setDeletingFileName}
                    />
                )}
                style={{
                    width: "100%",
                    height: height,
                }}
            />
        </div>
    );
};
