import { axiosInstance as axios } from "../../axiosInstance";
import dayjs from "dayjs";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

/**
 * Retrieves the last time clock entry.
 * @function getChartOfAccounts
 * @async
 * @returns {Object}
 */
export const getLastTimeClockEntry = async (employeeId) => {
    return await axios
        .get(
            `/HumanResources/Employee/TimeClockEntry/GetLastTimeClock?employeeId=${employeeId}`,
            {
                validateStatus: (status) => {
                    return (status >= 200 && status < 300) || status === 404;
                },
            }
        )
        .then((res) => {
            if (res.status === 404) {
                return undefined;
            }

            return res.data.data;
        });
};

/**
 * @function clockTime
 * @description Function to post clock time
 * @returns {Object}
 */

export const clockTime = async (employee, task, job, event) => {
    try {
        const url = `/HumanResources/TimeClockEntry/ClockTime`;
        return await axios.post(url, {
            employeeId: employee.id,
            taskId: task?.id,
            calendarEventId: event?.id,
            jobId: job?.id,
        });
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteTimeClockEntry = async (timeClockEntryId) => {
    try {
        const response = await axios.delete(
            `/HumanResources/TimeClockEntry/Delete?Id=${timeClockEntryId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error deleting time clock entry:", error);
        throw error;
    }
};

export const updateTimeClockEntry = async (timeClockEntry) => {
    try {
        return await axios.put(
            "/HumanResources/TimeClockEntry/Update",
            timeClockEntry
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createTimeClockEntry = async (timeClockEntry) => {
    try {
        return await axios.post(
            "/HumanResources/TimeClockEntry/Create",
            timeClockEntry
        );
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @function getAllTimeClockEntries based on job
 * @description Function to get all time clock entries for a jobEvent
 * @returns {Object}
 */

export const getAllTimeClockEntries = async (jobId, startTime, endTime) => {
    try {
        const response = await axios.get(
            "/HumanResources/TimeClockEntry/GetAll",
            {
                params: {
                    filter: `(jobId~eq~'${jobId}'~and~startTime~gt~datetime'${startTime}'~and~endTime~lt~datetime'${endTime}')`,
                },
            }
        );
        console.log("Time Clock Entries Response:", response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * Gets all TimeClockEntries within the given start and end time.
 * @function getAllTimeClockEntriesByTime
 * @async
 * @param {string} startTime
 * @param {string} endTime
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllTimeClockEntriesByTime = async (startTime, endTime) => {
    const formattedStart = dayjs(startTime).format("YYYY-MM-DDTHH-mm-ss");
    const formattedEnd = dayjs(endTime)
        .endOf("day") //? Grabs end of day to ensure full day TimeClocks are collected
        .format("YYYY-MM-DDTHH-mm-ss");

    return await axios.get("/HumanResources/TimeClockEntry/GetAll", {
        params: {
            filter: `(startTime~gt~datetime'${formattedStart}'~and~endTime~lt~datetime'${formattedEnd}')`,
        },
    });
};

export const getAllTimeClockEntriesForEvent = async (
    calendarEventId,
    startTime,
    endTime
) => {
    try {
        let queryParams = new URLSearchParams({
            calendarEventId,
            startTime,
            endTime,
        }).toString();
        const response = await axios.get(
            `/HumanResources/TimeClockEntry/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllTimeClockEntriesForJob
 * @description Function to get all time clock entries for a specific job ID
 * @param {string} jobId -
 * @returns {Promise<ServiceResponse>}
 */
export const getAllTimeClockEntriesForJob = async (jobId) => {
    try {
        const response = await axios.get(
            `/HumanResources/TimeClockEntry/GetAll?jobId=${jobId}&`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getTimeClock
 * @description
 * Gets a time clock entry by ID
 * @async
 * @param id
 * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
 */
export const getTimeClock = async (id) => {
    const res = await axios.get(`/HumanResources/TimeClockEntry/Get?id=${id}`);
    return formatServiceResponse(res);
};
