import * as React from "react";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "../../services/axiosInstance";
import { Error } from "@progress/kendo-react-labels";
import { Field } from "@progress/kendo-react-form";
import DebounceTextBox from "./DebounceTextBox.jsx";

const useDebounceUniqueValidation = (
    uri,
    searchVal,
    originalVal,
    bodyParameter
) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (!searchVal || searchVal === originalVal) {
            return;
        }

        let data = {};
        data[bodyParameter] = searchVal;

        axios
            .post(uri, data, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((res) => {
                if (!res) {
                    setIsError(false);
                    return;
                }

                setIsError(res.data.data);
            });
    }, [uri, searchVal, originalVal]);

    return isError;
};

const DebounceFormInput = (fieldRenderProps) => {
    const { touched, validationMessage, style, setVal, ...others } =
        fieldRenderProps;
    let showValidationMessage = touched && validationMessage;

    return (
        <div style={style}>
            <DebounceTextBox setter={setVal} timeout={500} {...others} />
            {showValidationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

/**
 * !It is strongly advised to use OriginalVal when working with pre-existing data.
 * !This guarantees that the debounce mechanism will not mistakenly identify duplicates
 * !When the user inputs the same value that already exists on the object
 */
export const DebounceUniqueFormField = ({
    uri,
    name,
    label,
    originalVal,
    bodyParameter,
    failureMsg,
    validator,
    ...props
}) => {
    const [searchVal, setSearchVal] = useState("");
    const isError = useDebounceUniqueValidation(
        uri,
        searchVal,
        originalVal,
        bodyParameter
    );

    const extendedValidator = (value) => {
        const msg = !validator ? "" : validator(value);

        if (!msg && isError) {
            return failureMsg ?? "Value is in error.";
        }

        return msg;
    };

    return (
        <Field
            name={name}
            component={DebounceFormInput}
            label={label}
            validator={extendedValidator}
            setVal={setSearchVal}
            {...props}
        />
    );
};
