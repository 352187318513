import React, { useContext } from "react";
import ConfirmDialogue from "./ConfirmDialogue";
import { useHistory } from "react-router-dom";
import PermissionButton from "./PermissionButton";
import { BreadcrumbContext } from "../../providers/BreadCrumbProvider.jsx";
import { ReloadDataContext } from "../../providers/ReloadDataProvider.jsx";
import { useToggle } from "../../hooks/Deprecated/useToggle.js";

const ActionButtonsCommandCell = ({
    record,
    dataId,
    secondaryDataId,
    action,
    actionName,
    EditComponent,
    viewPage,
    postDeleteRedirect,
    triggerReload,
    additionalButtons,
    requiredPermissionsView,
    requiredPermissionsEdit,
    requiredPermissionsAction,
    isEditable = true,
    isDeletable = true,
    actionDisabledToolTipMessage,
    actionButtonToolTipPosition,
    skipRefreshOnAction = false,
    ...props
}) => {
    const bcContext = useContext(BreadcrumbContext);
    const { bool: visibleConfirm, toggle: toggleConfirmVisible } = useToggle();
    const { bool: visibleEditForm, toggle: toggleEditVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const history = useHistory();
    const setDataReload =
        triggerReload ?? useContext(ReloadDataContext).triggerReload;

    //? If no action name is passed in, default to "Delete"
    //?
    if (!actionName) {
        actionName = "Delete";
    }

    /**
     * @function toggleConfirmDialog
     * @description Function for toggling visibility of the confirm dialog
     */
    const toggleConfirmDialog = () => {
        toggleConfirmVisible();
    };

    /**
     * @function toggleEditForm
     * @description Function for toggling visibility of the edit form
     */
    const toggleEditForm = () => {
        toggleEditVisible();
    };

    /**
     * @function confirmDialog
     * @description Function for confirming the action and toggling the confirm dialog, as well as reloading the data
     * @description If a postDeleteRedirect is passed in, redirect to that page after the action is confirmed
     */
    const confirmDialog = () => {
        toggleLoaderVisible();
        const actionResult = action(dataId, secondaryDataId);

        if (actionResult && typeof actionResult.then === "function") {
            actionResult
                .then(() => {
                    if (!skipRefreshOnAction) {
                        setDataReload(dataId + Math.random());
                    }
                    toggleConfirmDialog();
                    toggleLoaderVisible();
                    if (postDeleteRedirect) {
                        history.push(postDeleteRedirect);
                    }
                })
                .catch((error) => {
                    console.error("Error occurred during action:", error);
                    toggleConfirmDialog();
                    toggleLoaderVisible();
                });
        } else {
            console.warn(
                "Action did not return a Promise. Immediate execution of post-action logic."
            );
            if (!skipRefreshOnAction) {
                setDataReload(dataId + Math.random());
            }
            toggleConfirmDialog();
            toggleLoaderVisible();
            if (postDeleteRedirect) {
                history.push(postDeleteRedirect);
            }
        }
    };

    return (
        <td
            style={{
                display: "flex",
                justifyContent: "start",
                gap: "2px",
                ...props.style,
            }}
        >
            {/* Loop over additional btns and display them */}
            {additionalButtons?.map((btn, index) => (
                <span key={index}>{btn}</span>
            ))}

            {/* View Button */}
            {viewPage !== undefined && (
                <span>
                    <PermissionButton
                        requiredPermissions={requiredPermissionsView}
                        themeColor={"primary"}
                        onClick={() => bcContext.handleRouteChange(viewPage)}
                    >
                        View
                    </PermissionButton>
                </span>
            )}

            {/* Edit Button and Component */}
            {isEditable && EditComponent !== undefined && (
                <span>
                    <PermissionButton
                        requiredPermissions={requiredPermissionsEdit}
                        themeColor={"primary"}
                        onClick={toggleEditForm}
                    >
                        Edit
                    </PermissionButton>
                    <EditComponent
                        visible={visibleEditForm}
                        onCancel={toggleEditForm}
                        record={record}
                        loaderVisible={loaderVisible}
                        toggleLoaderVisible={toggleLoaderVisible}
                        triggerReload={setDataReload}
                    />
                </span>
            )}

            {/* Action Button and Dialogue */}
            {isEditable && action !== undefined && (
                <span>
                    <PermissionButton
                        requiredPermissions={requiredPermissionsAction}
                        themeColor={"primary"}
                        onClick={toggleConfirmDialog}
                        disabled={!isDeletable}
                        disabledToolTipMessage={actionDisabledToolTipMessage}
                        toolTipPosition={actionButtonToolTipPosition}
                    >
                        {actionName}
                    </PermissionButton>
                    <ConfirmDialogue
                        visible={visibleConfirm}
                        onConfirm={confirmDialog}
                        onCancel={toggleConfirmDialog}
                        loaderVisible={loaderVisible}
                        actionName={actionName}
                    />
                </span>
            )}
        </td>
    );
};

export default ActionButtonsCommandCell;
