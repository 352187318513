import React, { createContext, useEffect, useState } from "react";
import { getJobDocument } from "../../../services/Deprecated/customer/jobServices.js";
import { JobDocumentTypeEnum } from "../../../resources/Enums/JobDocumentTypeEnum.js";

export const JobDocumentContext = createContext(null);

const JobDocumentProvider = ({ children }) => {
    //? This is a bit of a hacky way to ensure all relationships are loaded in
    //? We have to load in 6 different relationships, so we use this to count
    //? How many have loaded in, and once they have all loaded in, we toggle the state to true
    //?
    const [relationShipLoadCount, _] = useState(0);
    const [relationshipsLoaded, setRelationshipsLoaded] = useState(false);
    const [toastMessage, _setToastMessage] = useState(null);
    const [runningTotal, setRunningTotal] = useState(0);
    //? This is the job document that is currently selected
    //?
    const [selectedJobDocument, setSelectedJobDocument] = useState({
        id: undefined,
        operation: undefined,
    });
    const [jobDocument, setJobDocument] = useState(undefined);
    const [reloadJobDocument, setReloadJobDocument] = useState(false);
    const [isJobDocumentEditable, setIsJobDocumentEditable] = useState(true);
    const [reloadJobDoc, setReloadJobDoc] = useState(false);

    const [totals, setTotals] = useState({
        subtotal: 0,
        discounts: 0,
        total: 0,
    });

    //? Check to ensure all relationships loaded in
    //? If they have, toggle the relationshipsLoaded state
    //?
    useEffect(() => {
        if (relationShipLoadCount === 8) {
            setRelationshipsLoaded(true);
        }
    }, [relationShipLoadCount]);

    /**
     * @function refreshJobDocData
     * @description Refresh the job document data
     * @returns {void}
     */
    const refreshJobDocData = () => {
        setReloadJobDoc(!reloadJobDoc);
    };

    //? If there is a selectedJobDocument.id, fetch the Job Document associated
    //? with it and set the jobDocument state
    //?
    useEffect(() => {
        if (!selectedJobDocument.id) {
            setJobDocument(undefined);
            return;
        }

        const fetchJobDocument = async (jobDocumentId) => {
            try {
                await getJobDocument(jobDocumentId).then(
                    (jobDocumentResponse) => {
                        setJobDocument(jobDocumentResponse.data);
                        const hasChildDocuments =
                            jobDocumentResponse?.data?.childJobDocuments
                                ?.length > 0;

                        const isInvoice =
                            jobDocumentResponse.data?.type ===
                            JobDocumentTypeEnum.Invoice;

                        // Invoices can never be edited
                        if (isInvoice) {
                            setIsJobDocumentEditable(false);
                            return;
                        }

                        if (hasChildDocuments) {
                            setIsJobDocumentEditable(false);
                            return;
                        }

                        setIsJobDocumentEditable(true);
                    }
                );
            } catch (error) {
                console.error("Error fetching jobDocument details:", error);
            }
        };

        fetchJobDocument(selectedJobDocument.id);
        //? Reload the data when the selectedJobDocument.id changes
        //? Or if the reloadDataContext.reloadData state changes
        //?
    }, [selectedJobDocument.id, reloadJobDocument]);

    const checkForExistingDocument = (
        currentJobDocumentId,
        newJobDocumentType
    ) => {
        // Access the jobDocuments state to get all documents
        const documents = [];

        // Check if any document in the targeted type has the current document as its parent
        const existingDocument = documents.find(
            (doc) => doc.parentJobDocumentId === currentJobDocumentId
        );

        // If an existing document is found, return true, otherwise return false
        return !!existingDocument;
    };

    //? This is the value that will be passed to any component that consumes this context
    //?
    const value = {
        relationshipsLoaded,
        refreshJobDocData,
        selectedJobDocument,
        setSelectedJobDocument,
        jobDocument,
        setReloadJobDocument,
        reloadJobDocument,
        toastMessage,
        runningTotal,
        setRunningTotal,
        isJobDocumentEditable,
        checkForExistingDocument,
        totals,
        setTotals,
    };

    return (
        <JobDocumentContext.Provider value={value}>
            {children}
        </JobDocumentContext.Provider>
    );
};

export default JobDocumentProvider;
