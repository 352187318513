export class CommunicationModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            id: dataItem.id,
            note: dataItem.note,
            leadId: dataItem.lead.id,
            shouldCreateReminder: dataItem.shouldCreateReminder,
            communicationTypeId: dataItem.communicationType.id,
        };
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
        };
    }
}
