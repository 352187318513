import { ContactType } from "./Enums/ContactType";
import StringHelper from "./StringHelper.js";

export default class ContactHelper {
    /**
     * @function build
     * @description Build contact object
     * @param contact {Object} - The contact object
     * @param type {number} - The contact type
     * @return {{address: {}, id, label: string, type, name2: *, name1: (boolean|*)}}
     */
    static build(contact, type) {
        const returnData = {
            id: contact.id,
            label: ContactType.ParseToString(type),
            type: type,
            name1: contact.name1,
            name2: contact.name2,
            address: contact.address ?? {},
        };

        if (!StringHelper.isNullOrEmpty(contact.email)) {
            returnData.email = contact.email;
        }

        if (!StringHelper.isNullOrEmpty(contact.phone?.phoneNumber)) {
            returnData.phone = {
                phoneNumber: contact.phone.phoneNumber,
                typeId: contact.phone.phoneNumberType.id,
            };
        }

        return returnData;
    }
}
