// Import axios library
import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * Add Labor Type.
 * @function addLaborType
 * @async
 * @param {any} laborTypeData
 * @returns {Promise<ServiceResponse>}
 */
export const addLaborType = async (laborTypeData) => {
    try {
        // Send a POST request to the server with the laborType data
        const response = await axios.post(
            "/HumanResources/LaborType/Create",
            laborTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getLaborType = async (id) => {
    try {
        // Send a GET request to the server with the laborType ID
        const response = await axios.get(
            `/HumanResources/LaborType/Get?id=${id}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves all labor types.
 * @function getAllLaborTypes
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllLaborTypes = async () => {
    try {
        const response = await axios.get(`/HumanResources/LaborType/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Update a labor type.
 * @function updateLaborType
 * @param {any} laborTypeData
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const updateLaborType = async (laborTypeData) => {
    try {
        // Send a PUT request to the server with the labor type data
        const response = await axios.put(
            "/HumanResources/LaborType/Update",
            laborTypeData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Delete a labor type.
 * @function deleteLaborType
 * @param {string} laborTypeId
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const deleteLaborType = async (laborTypeId) => {
    try {
        // Send a DELETE request to the server with the labor type ID
        const response = await axios.delete(
            `/HumanResources/LaborType/Delete?id=${laborTypeId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
