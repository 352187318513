import React, { useContext, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    StackLayout,
} from "@progress/kendo-react-layout";
import ItemRelationshipsProvider from "../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import CategoryCustomizationsAndListsProvider, {
    CategoryCustomizationsAndListsContext,
} from "../../../providers/Deprecated/Inventory/Item/CategoryCustomizationsAndLists";
import ItemCategoryTree from "./Components/ItemCategoryTree";
import CategoryCustomizationsAndLists from "./Components/CategoryCustomizationsAndList";
import ItemCategoryItemList from "./Components/ItemCategoryItemList";

const ItemCategoryWrapper = () => {
    return (
        <CategoryCustomizationsAndListsProvider>
            <ItemCategoryPage />
        </CategoryCustomizationsAndListsProvider>
    );
};

const ItemCategoryPage = () => {
    const [select, setSelect] = useState([""]);
    const [selectedItem, setSelectedItem] = useState({});
    const customizationContext = useContext(
        CategoryCustomizationsAndListsContext
    );

    const onItemClick = (event) => {
        // only update state if selection has changed
        if (selectedItem !== event.item.data) {
            setSelect([event.itemHierarchicalIndex]);
            setSelectedItem(event.item.data);
            //? Clear out Customizations and List
            customizationContext.setSelectedItems({
                customizationList: null,
                customization: null,
            });
        }
    };

    const clearSelection = () => {
        setSelect([""]);
        setSelectedItem(null);
        //? Clear out Customizations and List
        customizationContext.setSelectedItems({
            customizationList: null,
            customization: null,
        });
    };

    const firstRowCardStyle = {
        height: 600,
        overflow: "auto",
    };

    // JSX code for rendering the page
    //
    return (
        <div>
            <ItemRelationshipsProvider>
                <StackLayout>
                    {/* Card for displaying Categories Tree View */}
                    <Card className="card-container" style={firstRowCardStyle}>
                        <CardHeader>
                            <CardTitle>Categories</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {/* TreeView component from KendoReact */}
                            <ItemCategoryTree
                                onItemClick={onItemClick}
                                select={select}
                                selectedItem={selectedItem}
                                clearSelection={clearSelection}
                            />
                        </CardBody>
                    </Card>
                    {/* Card for displaying Customizations */}
                    <Card className="card-container" style={firstRowCardStyle}>
                        <CardBody>
                            <CategoryCustomizationsAndLists
                                selectedCategory={selectedItem}
                            />
                        </CardBody>
                    </Card>
                </StackLayout>
                <StackLayout>
                    {/* Card for displaying Customizations */}
                    <Card className="card-container">
                        <CardHeader>
                            <CardTitle>Items</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ItemCategoryItemList selectedItem={selectedItem} />
                        </CardBody>
                    </Card>
                </StackLayout>
            </ItemRelationshipsProvider>
        </div>
    );
};

export default ItemCategoryWrapper;
