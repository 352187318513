import React, { useContext } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";

const TransferBtn = ({ dataItem }) => {
    const paymentProvider = useContext(PaymentContext);

    return (
        <div>
            {dataItem.remainder.amount === dataItem.total.amount && (
                <Button
                    themeColor={"primary"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    onClick={() =>
                        paymentProvider.setTransferringPaymentId(dataItem.id)
                    }
                >
                    Transfer
                </Button>
            )}
        </div>
    );
};

export default TransferBtn;
