import { BaseEnum } from "./BaseEnum";

export class AccountType extends BaseEnum {
    static Enum = {
        Assets: 0,
        Equity: 1,
        Revenue: 2,
        Expenses: 3,
        Liabilities: 4,
        Bank: 5,
        CreditCard: 6,
        COGS: 7,
    };

    /**
     * Determines if the account type is an income type.
     * @param {number} type - The account type.
     * @returns {boolean} Whether or not the account type is an income type.
     * @param type
     * @return {boolean}
     * @constructor
     */
    static IsIncomeType(type) {
        switch (type) {
            case AccountType.Enum.Assets:
                return true;
            case AccountType.Enum.Equity:
                return true;
            case AccountType.Enum.Revenue:
                return true;
            default:
                return false;
        }
    }

    /**
     * Determines if the account type is an expense type.
     * @param {number} type - The account type.
     * @returns {boolean} Whether or not the account type is an expense type.
     * @param type
     * @return {boolean}
     * @constructor
     */
    static IsExpenseType(type) {
        switch (type) {
            case AccountType.Enum.Expenses:
                return true;
            case AccountType.Enum.Liabilities:
                return true;
            case AccountType.Enum.Bank:
                return true;
            case AccountType.Enum.CreditCard:
                return true;
            case AccountType.Enum.COGS:
                return true;
            default:
                return false;
        }
    }
}
