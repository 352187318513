import React, { useContext, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import styles from "./InvoiceDetailComponent.module.scss";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { JobDocumentContext } from "../../../../providers/Deprecated/Customer/JobDocumentProvider";
import { deletePayment } from "../../../../services/Deprecated/customer/paymentServices";
import ConfirmDialogue from "../../../../components/Deprecated/ConfirmDialogue";
import { CustomDateCell } from "../../../../components/Deprecated/CustomGridCells";

const InvoiceDetailComponent = (props) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const jobDocContext = useContext(JobDocumentContext);
    const payments = props.dataItem.appliedPayments;
    const [delCfirmVisible, setDelCfirmVisible] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [deletingPaymentId, setDeletingPaymentId] = useState(undefined);

    const onDeleteConfirm = () => {
        setLoaderVisible(true);

        deletePayment(deletingPaymentId).then(() => {
            setDelCfirmVisible(false);
            setLoaderVisible(false);
            //! Possible refactor opportunity, currently also reloads the customer, and jobs
            reloadDataContext.triggerReload();
            jobDocContext.refreshJobDocData();
        });
    };

    const handleDeleteClick = (paymentId) => {
        setDeletingPaymentId(paymentId);
        setDelCfirmVisible(true);
    };

    return (
        <>
            <ConfirmDialogue
                visible={delCfirmVisible}
                onConfirm={() => onDeleteConfirm()}
                onCancel={() => setDelCfirmVisible(false)}
                loaderVisible={loaderVisible}
                actionName={"Delete"}
            />
            <Grid data={payments}>
                <GridNoRecords> </GridNoRecords>
                <GridColumn
                    width={45}
                    headerClassName={"hide-element"}
                    cell={(props) => {
                        return (
                            <td>
                                <span
                                    className={`k-icon k-i-trash ${styles.RevertPaymentBtn}`}
                                    style={{ color: "red" }}
                                    onClick={() =>
                                        handleDeleteClick(props.dataItem.id)
                                    }
                                />
                            </td>
                        );
                    }}
                />
                <GridColumn
                    field="date"
                    title=" "
                    headerClassName={"hide-element"}
                    cell={(props) => (
                        <CustomDateCell {...props} data={props.dataItem.date} />
                    )}
                />
                <GridColumn
                    field="type"
                    title=" "
                    className={"text-overflow"}
                    headerClassName={"hide-element"}
                />
                <GridColumn
                    field="details"
                    title=" "
                    className={"text-overflow"}
                    headerClassName={"hide-element"}
                />
                <GridColumn
                    field="total.amount"
                    title=" "
                    format="{0:c}"
                    headerClassName={"hide-element"}
                />
            </Grid>
        </>
    );
};

export default InvoiceDetailComponent;
