import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class StorageLocationService
 * @extends BaseService
 * @description Service class that handles all requests to the storage location service
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class StorageLocationService extends BaseService {
    constructor(uri) {
        super(uri);
    }

    /**
     * @method getAllContainingItem
     * @description Method that retrieves all storage locations containing a specific item
     * @param itemId {String} - The ID of the item to search for
     * @param [jobId] {String} - The ID of the job to search for allocations
     * @return {Promise<ServiceResponse>}
     */
    async getAllContainingItem(itemId, jobId) {
        const response = await axios.get(
            `${this.uri}/GetAllContainingItem?itemId=${itemId}&jobId=${jobId}&`
        );
        return formatServiceResponse(response);
    }
}
