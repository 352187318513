import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import "../../AccountingModule.module.scss";
import {
    FormDropDown,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { Checkbox } from "@progress/kendo-react-inputs";
import { AccountType } from "../../../../resources/Enums/AccountType";
import { AccountHelper } from "../../Helpers/AccountHelper";
import { AccountingContext } from "../../Providers/AccountingProvider";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const CoAActionModal = (props) => {
    const { id, record, close, visible } = props;
    const { data } = useContext(AccountingContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [reFilterAccounts, setReFilterAccounts] = useState(false);
    const [selectedAccountType, setSelectedAccountType] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);
    const [refreshParentAccountsKey, setRefreshParentAccountsKey] = useState(0);

    useEffect(() => {
        if (!data.accounts) return;

        const flattenedAccounts = data.accounts.flatMap((acc) =>
            AccountHelper.flatmapAccounts(acc)
        );

        //? Ensure that the only type the user can select is the type selected
        //? And so they cannot select the current account
        setFilteredAccounts(
            flattenedAccounts.filter(
                (a) => a.type === selectedAccountType && a.id !== id
            )
        );

        setRefreshParentAccountsKey(refreshParentAccountsKey + 1);
    }, [selectedAccountType, data?.accounts, reFilterAccounts]);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            ChartOfAccountsService.get(id).then((res) => {
                setInitialValues(res.data);
                setSelectedAccountType(res.data.type);
            });
        } else {
            setInitialValues({});
        }
    }, [id, visible]);

    const onCancel = () => {
        setInitialValues(undefined);
        setSelectedAccountType(undefined);
        setReFilterAccounts(false);
        setFilteredAccounts([]);
        close();
    };

    const onSubmit = (props) => {
        if (!props.isValid) {
            return;
        }

        setLoaderVisible(true);

        const dataItem = props.values;
        dataItem.type = dataItem.type?.value ?? initialValues.type;
        dataItem.parentAccountId = dataItem?.parentAccount?.id;

        if (record?.isCreateSub) {
            dataItem.parentAccountId = record.parentId;
            dataItem.type = record.type;
        }

        if (!id || record?.isCreateSub) {
            ChartOfAccountsService.createAccount(dataItem).then((_) => {
                setLoaderVisible(false);
                onCancel();
                reloadDataContext.triggerReload();
            });
        } else if (id) {
            ChartOfAccountsService.updateAccount(dataItem).then((_) => {
                setLoaderVisible(false);
                onCancel();
                reloadDataContext.triggerReload();
            });
        }
    };

    //? Ensure parentAccount is reset to handle edge cases
    //? Re-roll filtering for a new account type
    const onAccountTypeChange = (formRenderProps) => {
        formRenderProps.onChange("parentAccount", { value: {} });
        //? Set overhead too false to ensure we never have an income account
        //? marked as an overhead expense.
        formRenderProps.onChange("isOverhead", { value: false });
        setSelectedAccountType(formRenderProps.valueGetter("type").value);
        setReFilterAccounts(!reFilterAccounts);
    };

    const inputLengthValidator = (value) => {
        if (!value) {
            return "";
        }

        return value?.length <= 6
            ? ""
            : "Length of code cannot be greater than 6";
    };

    return (
        visible &&
        initialValues && (
            <Form
                onSubmitClick={onSubmit}
                initialValues={initialValues}
                render={(formRenderProps) => (
                    <ResponsiveDialog
                        title={id ? "Edit Account" : "Create Account"}
                        onClose={() => onCancel()}
                        size={"medium"}
                    >
                        <FormElement>
                            <Field
                                name={"name"}
                                component={FormInput}
                                label={"Name:"}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"code"}
                                component={FormInput}
                                label={"Code:"}
                                validator={inputLengthValidator}
                            />
                            {!record?.isCreateSub && !id && (
                                <Field
                                    name={"type"}
                                    component={FormDropDown}
                                    data={AccountType.AsKeyValue()}
                                    dataItemKey="value"
                                    textField="key"
                                    label={"Account Type:"}
                                    onChange={() =>
                                        onAccountTypeChange(formRenderProps)
                                    }
                                    validator={requiredValidator}
                                />
                            )}
                            {id === undefined && !record?.isCreateSub && (
                                <Field
                                    key={refreshParentAccountsKey}
                                    name={"parentAccount"}
                                    component={FormDropDown}
                                    data={filteredAccounts}
                                    dataItemKey="id"
                                    textField="name"
                                    label={"Parent Account:"}
                                />
                            )}
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    margin: "10px",
                                    flexDirection: "column",
                                }}
                            >
                                <Field
                                    name={"isOverHead"}
                                    component={Checkbox}
                                    label={"Is Overhead Expense"}
                                    rounded={"large"}
                                    size={"large"}
                                />
                            </span>
                            <FormButtons
                                isCreate={!id}
                                allowSubmit={formRenderProps.allowSubmit}
                                loaderVisible={loaderVisible}
                                actionOnCancel={onCancel}
                            />
                        </FormElement>
                    </ResponsiveDialog>
                )}
            />
        )
    );
};

export default CoAActionModal;
