import * as React from "react";
import "../../../styles/CommonPositionalStyles.scss";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CustomDateCell } from "../../../components/Deprecated/CustomGridCells";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";

const BalanceDue = (props) => {
    const { data } = props;

    return data !== undefined ? (
        <Grid data={data}>
            <GridColumn
                field="customer.name"
                title="Customer"
                className={"text-overflow"}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        dataItem={props.dataItem.customer}
                        field={"name"}
                        uri={`/customer/${props.dataItem.customer.id}`}
                    />
                )}
            />
            <GridColumn
                field="job.name"
                title="Job"
                className={"text-overflow"}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        dataItem={props.dataItem.job}
                        field={"name"}
                        uri={`/customer/${props.dataItem.customer.id}/job/${props.dataItem.job.id}/`}
                        additionalBreadcrumbInfo={[
                            {
                                id: props.dataItem.customer.id,
                                text: props.dataItem.customer.name,
                            },
                        ]}
                    />
                )}
            />

            <GridColumn
                field="jobCreationDate"
                title="Job Creation Date"
                cell={(props) => (
                    <CustomDateCell
                        {...props}
                        data={props.dataItem.jobCreationDate}
                    />
                )}
            />
            <GridColumn
                field="maxDueDate"
                title="Max Due Date"
                cell={(props) => (
                    <CustomDateCell
                        {...props}
                        data={props.dataItem.maxDueDate}
                    />
                )}
            />
            <GridColumn field="balanceDue" title="Balance" format={"{0:c}"} />
        </Grid>
    ) : (
        <div style={{ margin: "25px" }}>
            <CenterLoader />
        </div>
    );
};

export default BalanceDue;
