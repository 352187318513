import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";
import {getInitialDataQueryState} from "../../../utilities/kendoHelper";
import {toDataSourceRequestString} from "@progress/kendo-data-query";

/**
 * Get Task.
 * @function getTask
 * @async
 * @param {string} eventTaskId
 * @returns {Promise<ServiceResponse>}
 */
export const getTask = async (eventTaskId) => {
    try {
        const response = await axios.get(
            `/Customer/Task/Get?id=${eventTaskId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Get Task.
 * @function getAllTasksByCategoryIds
 * @async
 * @param {string[]} categoryIds
 * @returns {Promise<ServiceResponse>}
 */
export const getAllTasksByCategoryIds = async (categoryIds) => {
    if (!categoryIds || !categoryIds.length) {
        return formatServiceResponse({
            status: 200,
            data: { data: [] },
        });
    }
    const kendoRequest = getInitialDataQueryState();
    kendoRequest.sort = [
        {
            field: "name",
            dir: "asc",
        },
    ];
    kendoRequest.filter = {
        logic: "or",
        filters: [
            ...(categoryIds ?? []).map((id) => ({
                field: "taskCategoryId",
                operator: "eq",
                value: id,
            })),
        ],
    };
    const queryParams = toDataSourceRequestString(kendoRequest);
    try {
        const response = await axios.get(
            `/Customer/Task/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
/**
 * Add Task.
 * @function createTask
 * @async
 * @param {any} eventTaskObject
 * @returns {Promise<ServiceResponse>}
 */
export const createTask = async (eventTaskObject) => {
    try {
        const response = await axios.post(
            `/Customer/Task/Create`,
            eventTaskObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateTask
 * @description Function to update a job event task
 * @param {Object} taskEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateTask = async (taskEditObject) => {
    try {
        const response = await axios.put(
            `/Customer/Task/Update`,
            taskEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function createGeneralTask
 * @description Function to create a new General Task
 * @param {Object} data
 * @returns {Promise<ServiceResponse>}
 */
export const createGeneralTask = async (data) => {
    const response = await axios.post(`/Customer/Task/CreateGeneralTask`, data);
    return formatServiceResponse(response);
};

/**
 * @function deleteTask
 * @description Function to delete an event task
 * @param {String} taskId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteTask = async (taskId) => {
    try {
        const response = await axios.delete(
            `/Customer/Task/Delete?id=${taskId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
