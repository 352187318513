import React, { useContext } from "react";
import dayjs from "dayjs";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import FormField from "../../../../components/Deprecated/StylingField";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { StatusEnum } from "../../../../resources/Enums/StatusEnum";

const EmployeeDetails = () => {
    const employeeContext = useContext(EmployeeContext);

    const formatHireDate = (date) => {
        return dayjs(date).format("MM-DD-YYYY");
    };

    return employeeContext.employee.id ? (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
            }}
        >
            {/* Column 1 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "40%",
                }}
            >
                <div style={{ display: "flex", gap: "10px" }}>
                    <FormField
                        label="Name"
                        value={`
                            ${employeeContext.employee?.firstName}
                            ${employeeContext.employee?.middleName ?? ""}
                            ${employeeContext.employee?.lastName}
                        `}
                    />
                </div>
                <FormField
                    label="Email"
                    value={employeeContext.employee.email}
                />
                <FormField
                    label="Primary Address"
                    value={employeeContext.employee?.address?.address1}
                />

                {employeeContext.employee?.address?.address2 && (
                    <FormField
                        value={employeeContext.employee?.address?.address2}
                    />
                )}
                <FormField value={employeeContext.employee?.address?.city} />
                <FormField value={employeeContext.employee?.address?.state} />
            </div>
            {/* Column 2 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "60%",
                }}
            >
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    <FormField
                        label="Primary Phone Type"
                        value={
                            employeeContext.employee.phoneOne?.phoneNumberType
                                ?.name
                        }
                        textField={"name"}
                    />
                    <FormField
                        label="Primary Phone Number"
                        value={employeeContext.employee.phoneOne?.phoneNumber}
                    />
                </div>
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    {employeeContext.employee?.phoneTwo && (
                        <>
                            <FormField
                                label="Secondary Phone Type"
                                value={
                                    employeeContext.employee.phoneTwo
                                        ?.phoneNumberType?.name
                                }
                            />
                            <FormField
                                label="Secondary Phone Number"
                                value={
                                    employeeContext.employee.phoneTwo
                                        ?.phoneNumber
                                }
                            />
                        </>
                    )}
                </div>
                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    {employeeContext.employee?.phoneThree && (
                        <>
                            <FormField
                                label="Tertiary Phone Type"
                                value={
                                    employeeContext.employee.phoneThree
                                        ?.phoneNumberType?.name
                                }
                            />
                            <FormField
                                label="Tertiary Phone Number"
                                value={
                                    employeeContext.employee.phoneThree
                                        ?.phoneNumber
                                }
                            />
                        </>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        textAlign: "left",
                        width: "100%",
                        paddingRight: "15px",
                    }}
                >
                    <div style={{ flex: "1" }}>
                        <div
                            style={{
                                color:
                                    employeeContext.employee.status.status ===
                                    StatusEnum.Enum.Active
                                        ? "limegreen"
                                        : "red",
                            }}
                        >
                            <FormField
                                label="Employee Status"
                                value={StatusEnum.ParseToString(
                                    employeeContext.employee.status.status
                                )}
                            />
                        </div>
                    </div>

                    <div style={{ flex: "1" }}>
                        <FormField
                            label="Hire Date"
                            value={formatHireDate(
                                employeeContext.employee.status.hireDate
                            )}
                        />
                    </div>
                </div>

                <div style={{ display: "flex", textAlign: "left", gap: "5px" }}>
                    <FormField
                        label="Zip Code"
                        value={employeeContext.employee?.address?.zipCode}
                    />
                    <FormField
                        label="Hourly Rate"
                        value={`$${employeeContext.employee?.hourlyRate?.amount}`}
                    />
                </div>
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};
export default EmployeeDetails;
