import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class TransactionService {
    static async get(id) {
        const res = await axios.get(`/Accounting/Transaction/Get?id=${id}`);
        return formatServiceResponse(res);
    }

    /**
     * Soft Deletes the transaction by provided transactionId.
     * @function deleteTransaction
     * @async
     * @param {string} transactionId
     * @returns {Promise<ServiceResponse>}
     */
    static async deleteTransaction(transactionId) {
        try {
            const response = await axios.post(
                `/Accounting/Transaction/Void?id=${transactionId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Creates new transaction manually.
     * @function createTransaction
     * @async
     * @param {object} transaction
     * @returns {Promise<ServiceResponse>}
     */
    static async createTransaction(transaction) {
        try {
            const response = await axios.post(
                "/Accounting/Transaction/Create",
                transaction
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Toggles the reconciliation status of a transaction by the provided transactionId.
     * @function toggleReconcileTransaction
     * @async
     * @param {string} transactionId
     * @returns {Promise<ServiceResponse>}
     */
    static async toggleReconcileTransaction(transactionId) {
        try {
            const response = await axios.post(
                `/Accounting/Transaction/ToggleReconciliation`,
                { id: transactionId }
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
