import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";

const QuestionHint = ({ hint, position }) => {
    return (
        <Tooltip anchorElement="target" position={position}>
            <div
                style={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                }}
            >
                <span className="k-icon k-i-question" title={hint} />
            </div>
        </Tooltip>
    );
};

export default QuestionHint;
