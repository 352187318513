import React, { useContext, useEffect, useState } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import { useModal } from "../../../hooks/useModal";
import { deleteCustomer } from "../../../services/Deprecated/customer/customerServices";
import { CustomerModal } from "./Components/CustomerModal";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../components/Grids/DataGrid";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { EnumCell } from "../../../components/GridColumnComponents/EnumCell";
import { ActionCell } from "../../../components/GridColumnComponents/ActionCell";
import { CustomerTypeEnum } from "../../../resources/Enums/CustomerStatusEnum";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { ProspectStatus } from "../../../resources/Enums/ProspectStatus";
import { useLocalStorage } from "../../../hooks/useLocalStorage.js";
import { BreadcrumbContext } from "../../../providers/BreadCrumbProvider.jsx";

export const CustomerDashboardPage = () => {
    const { triggerReload } = useContext(ReloadDataContext);
    const customerModal = useModal();
    const localStorage = useLocalStorage();
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const [checkedLocalStorage, setCheckedLocalStorage] = useState(false);

    // If there is a last viewed customer in local storage, navigate to that customer
    useEffect(() => {
        const lastViewedCustomer = localStorage.get("lastViewedCustomer");

        if (lastViewedCustomer) {
            handleRouteChange({
                uri: `/customer/${lastViewedCustomer.value.id}`,
                breadcrumbInfo: [lastViewedCustomer.value],
            });
        }

        setCheckedLocalStorage(true);
    }, []);

    const onDelete = (id) => {
        deleteCustomer(id).then(() => {
            triggerReload();
        });
    };

    return (
        checkedLocalStorage && (
            <span className={"JustifyLeftAlignLeft FlexColumn MediumGap"}>
                <CustomerModal {...customerModal} />
                <ActionButton
                    onClick={() => customerModal.open()}
                    buttonText={"Create Customer"}
                    permissions={[PermissionsEnum.CreateCustomer]}
                />
                <DataGrid
                    uri={`/Customer/Customer/GetAll?`}
                    initialFilter={{
                        logic: "and",
                        filters: [
                            {
                                field: "ProspectStatus",
                                operator: "eq",
                                value: ProspectStatus.Enum.Customer,
                            },
                        ],
                    }}
                    searchFields={["name1", "name2"]}
                >
                    <GridColumn
                        field={"combinedName"}
                        title={"Name"}
                        width={300}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`/customer/${props.dataItem.id}`}
                            />
                        )}
                    />
                    <GridColumn
                        field={"type"}
                        title={"Type"}
                        width={150}
                        cell={(props) => (
                            <EnumCell
                                {...props}
                                enumerable={CustomerTypeEnum}
                            />
                        )}
                    />
                    <GridColumn
                        field={"email"}
                        title={"Email"}
                        width={250}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`mailto:${props.dataItem.email}`}
                                text={props.dataItem.email}
                            />
                        )}
                    />
                    <GridColumn
                        field={"phone.phoneNumber"}
                        title={"Phone"}
                        width={150}
                    />
                    <GridColumn
                        width={125}
                        filterable={false}
                        sortable={false}
                        cell={(props) => (
                            <ActionCell
                                onDelete={() => onDelete(props.dataItem.id)}
                                onEdit={() =>
                                    customerModal.open(props.dataItem.id)
                                }
                                permissions={{
                                    edit: [PermissionsEnum.UpdateCustomer],
                                    delete: [PermissionsEnum.DeleteCustomer],
                                }}
                            />
                        )}
                    />
                </DataGrid>
            </span>
        )
    );
};
