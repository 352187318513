import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";
import { useToggle } from "../../../../hooks/Deprecated/useToggle.js";
import { getAllSocialMedias } from "../../../../services/Deprecated/general/socialMediaService.js";
import { getAllBillingTerms } from "../../../../services/Deprecated/inventory/billingTermService.js";
import { getAllPaymentTypes } from "../../../../services/Deprecated/general/paymentTypeService.js";
import { getAllPhoneTypes } from "../../../../services/Deprecated/general/phoneTypeServices.js";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService.js";
import { getVendor } from "../../../../services/Deprecated/inventory/vendorService.js";

export const VendorContext = createContext(null);

const VendorProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    // Load in the relationships
    const { bool: relationshipsLoading, toggle: toggleRelationshipsLoading } =
        useToggle(true);
    const [relationships, setRelationships] = useState({});
    const [vendor, setVendor] = useState(undefined);
    const [vendorId, setVendorId] = useState();

    // Fetch data on component mount
    //
    useEffect(() => {
        const fetchData = async () => {
            const socialMediasServiceResponse = await getAllSocialMedias();
            const billingTerms = await getAllBillingTerms();
            const paymentTypesServiceResponse = await getAllPaymentTypes();
            const phoneTypes = await getAllPhoneTypes();
            const incomeAccountsServiceResponse =
                await ChartOfAccountsService.getAllIncomeAccounts();
            const expenseAccountServiceResponse =
                await ChartOfAccountsService.getAllExpenseAccounts();

            setRelationships({
                socialMedias: socialMediasServiceResponse.data,
                billingTerms,
                paymentTypes: paymentTypesServiceResponse.data,
                phoneTypes,
                incomeAccounts: incomeAccountsServiceResponse.data,
                expenseAccounts: expenseAccountServiceResponse.data,
            });
        };

        fetchData().then(() => {
            toggleRelationshipsLoading();
        });
    }, []);

    useEffect(() => {
        if (!vendorId) {
            return;
        }

        const fetchVendorData = async (vendorId) => {
            try {
                const vendor = await getVendor(vendorId);
                setVendor(vendor.data);
            } catch (error) {
                console.error("Error fetching vendor details:", error);
            }
        };

        fetchVendorData(vendorId).then(() => {
            console.log("Fetched Vendor Details");
        });
    }, [vendorId, reloadDataContext.reloadData]);

    const value = {
        relationships,
        relationshipsLoading,
        vendor,
        setVendorId,
    };

    return (
        <VendorContext.Provider value={value}>
            {children}
        </VendorContext.Provider>
    );
};

export default VendorProvider;
