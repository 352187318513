import * as React from "react";
import dayjs from "dayjs";
import styles from "./MyWorkDateDisplay.module.scss";

export const MyWorkDateDisplay = () => {
    return (
        <strong className={styles.MyWorkDateDisplay}>
            {new dayjs().format("ddd, MMM DD")}
        </strong>
    );
};
