import React, { useContext } from "react";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { createCustomerCommunication } from "../../../../services/Deprecated/customer/customerCommunicationServices";
import PermissionButton from "../../../../components/Deprecated/PermissionButton";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormTextArea } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { Hint } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

const CommunicationCreationComponent = () => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const reloadDataContext = useContext(ReloadDataContext);
    const customerContext = useContext(CustomerContext);
    const [value, setValue] = React.useState("");
    const max = 500;
    const handleChange = (e) => {
        setValue(e.value);
    };

    const submitCustomerCommunication = (dataItem) => {
        toggleLoaderVisible();

        dataItem.customerId = customerContext.customer.id;

        createCustomerCommunication(dataItem)
            .then(() => {
                reloadDataContext.triggerReload(dataItem);
                toggleCreateVisible();
            })
            .catch((error) => {
                console.error("Error Message", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {/* This is the confirmation toggle, this will be visible once the create button is toggled */}
            <PermissionButton
                requiredPermissions={[PermissionsEnum.CreateCustomer]}
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                onClick={toggleCreateVisible}
                style={{
                    width: "225px",
                    margin: "5px",
                }}
            >
                Create New Communication
            </PermissionButton>
            {createVisible && (
                <Form
                    onSubmit={submitCustomerCommunication}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            Dialog
                            title={"Create Communication"}
                            onClose={toggleCreateVisible}
                            size={"small"}
                        >
                            <FormElement>
                                <fieldset
                                    className={"k-form-fieldset"}
                                    style={{ textAlign: "left" }}
                                >
                                    <label
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Notes:
                                    </label>
                                    <Field
                                        name={"note"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        validator={requiredValidator}
                                        maxLength={max}
                                        value={value}
                                        onChange={handleChange}
                                    />
                                    <Hint direction={"end"}>
                                        {value.length} / {max}
                                    </Hint>
                                </fieldset>
                                {!loaderVisible ? (
                                    <div>
                                        <Button
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={toggleCreateVisible}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            themeColor={"success"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                            disabled={
                                                !formRenderProps.allowSubmit
                                            }
                                            icon={"k-icon k-i-plus"}
                                        >
                                            Create
                                        </Button>
                                    </div>
                                ) : (
                                    <Loader type="infinite-spinner" />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default CommunicationCreationComponent;
