import { NewJobDocumentType } from "../../../../../../resources/Enums/JobDocumentTypeEnum.js";
import StringHelper from "../../../../../../resources/StringHelper.js";
import { formatDateOnlyForNetwork } from "../../../../../../resources/Deprecated/dateHelper.js";
import dayjs from "dayjs";

export class JobDocumentModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        const formatLineItem = (li) => ({
            id: isNaN(li.id) ? li.id : undefined, // Means it's a new item
            description: li.description ?? "",
            quantity: li.quantity,
            total: li.total,
            price: li.price,
            type: li.type,
            index: li.index,
            itemId: li.itemId ?? li.item.id,
            isDetailItem: li.isDetailItem,
            isPackage: li.isPackage,
            documentId: li.documentId,
        });

        return {
            id: dataItem.id,
            documentDate: formatDateOnlyForNetwork(new Date(dataItem.date)),
            salesPersonId: dataItem.salesPerson.id,
            type: dataItem.type,
            name: dataItem.name,
            jobId: dataItem.job.id,
            lineItems: dataItem.lineItems.map((li) => ({
                ...formatLineItem(li),
                children: li.children?.map((child) => ({
                    ...formatLineItem(child),
                })),
            })),
        };
    }

    /**
     * @function formatChangeOrderDataForNetwork
     * @description Format change order data for the network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatChangeOrderDataForNetwork(dataItem) {
        let data = this.formatDataForNetwork(dataItem);

        data.type = NewJobDocumentType.Enum.PendingChangeOrder;
        data.parentDocumentId = data.id;
        data.id = undefined;

        return data;
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        const data = dataItem ?? {};

        return {
            ...data,
            type: data.type ?? NewJobDocumentType.Enum.Estimate,
            date: dayjs(data.documentDate).toDate() ?? new Date(),
            lineItems: data.lineItems?.map((li) => ({
                ...li,
                EXPANDED: li.children.length > 0,
                IN_LINE_EDIT: true,
                children: li.children.map((child) => ({
                    ...child,
                    IN_LINE_EDIT: true,
                })),
            })),
        };
    }

    /**
     * @function generateModalTitle
     * @description Generate the modal title
     * @param record {Object} - The record object to generate a title for
     * @return {`View ${string}`|`Edit ${string}`|`Create ${string}`}
     */
    static generateModalTitle = (record) => {
        const typeStr = NewJobDocumentType.ParseToString(record.type);
        const typeStrFormatted =
            StringHelper.addSpacesToCapitalLetters(typeStr);

        if (record?.childVendorDocuments?.length > 0) {
            return `View ${typeStrFormatted}`;
        }

        if (record.id) {
            return `Edit ${typeStrFormatted}`;
        }

        return `Create ${typeStrFormatted}`;
    };
}
