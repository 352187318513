import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import ActionButtonsCommandCell from "../../../../../components/Deprecated/ActionButtonsCommandCell";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import BusinessInformationProvider, {
    BusinessInformationContext,
} from "../../../../../providers/Deprecated/Customer/BusinessInformationProvider";
import { YesNoBooleanCell } from "../../../../../components/Deprecated/CustomGridCells";
import BusinessInformationService from "../../../../../services/Deprecated/customer/businessInformationService";
import PermissionButton from "../../../../../components/Deprecated/PermissionButton";
import styles from "./businessInformationGrid.module.scss";
import { LinkCell } from "../../../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import BusinessInformationModal from "./Components/BusinessInformationModal";

//? Wrapper to give us access to Provider
const BusinessInformationContextWrapper = () => {
    return (
        <BusinessInformationProvider>
            <BusinessInformationDashboardPage />
        </BusinessInformationProvider>
    );
};

/**
 * Basic structure on how this Grid works:
 * 1. Click "Create" tells provider we are creating which updates its state and opens the modal for create
 * 2. Click "Edit" tells provider we are editing and sends Id, which updates its state and opens the modal for edit
 * 3. Exporting data out of GridState so we can parse through the data to check if any BusinessInfo's exist, which lets
 *    us figure out if we need to set "IsDefault" to true or not.
 */
const BusinessInformationDashboardPage = () => {
    const businessInfoContext = useContext(BusinessInformationContext);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        BusinessInformationService.getAllBusinessInformations().then((res) => {
            setData(res.data);
            businessInfoContext.setExportedBusinessInfos(res.data);
        });
    }, []);

    useEffect(() => {
        const operation = businessInfoContext.selectedBusinessInfo.operation;
        const businessInfo =
            businessInfoContext.selectedBusinessInfo.businessInformation;

        if (operation === "Create") {
            setVisible(true);
            return;
        }

        if (operation === "Edit" && businessInfo) {
            setVisible(true);
            return;
        }

        setVisible(false);
    }, [
        businessInfoContext.selectedBusinessInfo.operation,
        businessInfoContext.selectedBusinessInfo.businessInformation,
    ]);

    return (
        data && (
            <div className={styles.BusinessInfoGridContainer}>
                {visible && <BusinessInformationModal />}
                <span>
                    <PermissionButton
                        onClick={() =>
                            businessInfoContext.setSelectedBusinessInfoToCreate()
                        }
                        themeColor={"primary"}
                        fillMode={"solid"}
                        rounded={"medium"}
                        icon={"k-icon k-i-plus"}
                        style={{
                            minWidth: "100px",
                            padding: "5px",
                            margin: "5px",
                        }}
                        requiredPermissions={[
                            PermissionsEnum.CreateBusinessInformation,
                        ]}
                    >
                        Create Business Information
                    </PermissionButton>
                </span>
                <div style={{ width: "100%" }}>
                    <DataGrid data={data} resizable={true}>
                        <GridColumn
                            width={200}
                            field="nickName"
                            title="Nick Name:"
                            locked={true}
                            filterable={false}
                            className={"text-overflow"}
                        />
                        <GridColumn
                            width={125}
                            field="businessName"
                            title="Company Name:"
                            className={"text-overflow"}
                        />
                        <GridColumn
                            width={300}
                            field="tagLine"
                            title="Tag Line:"
                            className={"text-overflow"}
                        />
                        <GridColumn
                            width={300}
                            field="address.fullAddress"
                            title="Full Address:"
                            className={"text-overflow"}
                            cell={(props) => (
                                <LinkCell
                                    {...props}
                                    uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        props.dataItem.address.fullAddress
                                    )}`}
                                    text={props.dataItem.address.fullAddress}
                                    target="_blank"
                                />
                            )}
                        />
                        <GridColumn
                            width={100}
                            field="isDefault"
                            title="Is Default:"
                            cells={{
                                data: YesNoBooleanCell,
                            }}
                        />
                        <GridColumn
                            width={140}
                            cell={(props) => (
                                <ActionButtonsCommandCell
                                    record={props.dataItem}
                                    dataId={props.dataItem.id}
                                    action={
                                        props.dataItem.isDefault
                                            ? undefined
                                            : BusinessInformationService.deleteBusinessInformation
                                    }
                                    requiredPermissionsAction={[
                                        PermissionsEnum.DeleteBusinessInformation,
                                    ]}
                                    additionalButtons={[
                                        //? Use additional buttons for Edit so we do not load in
                                        //? a full edit modal per record, we only load the btn and the onClick
                                        <PermissionButton
                                            key={0}
                                            onClick={() =>
                                                businessInfoContext.setSelectedBusinessInfoToEdit(
                                                    props.dataItem.id
                                                )
                                            }
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            requiredPermissions={[
                                                PermissionsEnum.UpdateBusinessInformation,
                                            ]}
                                        >
                                            Edit
                                        </PermissionButton>,
                                    ]}
                                />
                            )}
                        />
                    </DataGrid>
                </div>
            </div>
        )
    );
};
export default BusinessInformationContextWrapper;
