import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { ItemAllocationModal } from "./Components/ItemAllocationModal";
import { JobContext } from "../../../../../../providers/Deprecated/Customer/JobProvider";
import { useModal } from "../../../../../../hooks/useModal";
import { DataGrid } from "../../../../../../components/Grids/DataGrid";
import { LinkCell } from "../../../../../../components/GridColumnComponents/LinkCell";
import { BaseCell } from "../../../../../../components/GridColumnComponents/BaseCell";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum";

export const ItemAllocationTabPage = () => {
    const { job } = useContext(JobContext);
    const allocationModal = useModal();

    return (
        <span>
            <ItemAllocationModal {...allocationModal} />
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={`/Customer/Job/Item/GetAllocationList?id=${job?.id}&`}
                initialSort={[
                    {
                        field: "name",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    className={"text-overflow"}
                    field={"name"}
                    title={"Item:"}
                    width={400}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Inventory/Item/${props.dataItem.itemId}`}
                            dataItem={{
                                id: props.dataItem.itemId,
                                name: props.dataItem.name,
                            }}
                        />
                    )}
                />
                <GridColumn
                    field="qtyNeeded"
                    title="Needed:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyNeeded} Each
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyAllocated"
                    title="Allocated:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyAllocated} Each
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyOrderedFromVendor"
                    title="Ordered:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyOrderedFromVendor} Each
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyInStock"
                    title="In Stock:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyInStock} in Stock
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="qtyRemaining"
                    title="Remaining:"
                    cell={(props) => (
                        <BaseCell {...props}>
                            {props.dataItem.qtyRemaining} Each
                        </BaseCell>
                    )}
                />
                <GridColumn
                    width={220}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    buttonText: "Allocate",
                                    icon: "k-icon k-i-plus",
                                    onClick: () =>
                                        allocationModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            remainingNeeded:
                                                props.dataItem.qtyRemaining,
                                            jobId: job.id,
                                        }),
                                    permissions: [
                                        PermissionsEnum.AllocateInventory,
                                    ],
                                    disabled:
                                        props.dataItem.qtyRemaining <= 0 ||
                                        props.dataItem.qtyInStock === 0,
                                },
                                {
                                    buttonText: "DeAllocate",
                                    icon: "k-icon k-i-minus",
                                    onClick: () =>
                                        allocationModal.open(undefined, {
                                            itemId: props.dataItem.itemId,
                                            remainingNeeded:
                                                props.dataItem.qtyAllocated,
                                            jobId: job.id,
                                            isDeallocate: true,
                                        }),
                                    permissions: [
                                        PermissionsEnum.AllocateInventory,
                                    ],
                                    disabled: props.dataItem.qtyAllocated === 0,
                                },
                            ]}
                        />
                    )}
                />
            </DataGrid>
        </span>
    );
};
