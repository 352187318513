import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class CommunicationTypeService {
    /**
     * Retrieves communicationType by provided communicationTypeId.
     * @function get
     * @async
     * @param {string} communicationTypeId
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async get(communicationTypeId) {
        try {
            const response = await axios.get(
                `/Customer/CommunicationType/Get?id=${communicationTypeId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Creates a new communicationType.
     * @function create
     * @async
     * @param {Object} communicationTypeData
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async create(communicationTypeData) {
        try {
            const response = await axios.post(
                "/Customer/CommunicationType/Create",
                communicationTypeData
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Updates an existing communicationType.
     * @function update
     * @async
     * @param {Object} communicationTypeData
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async update(communicationTypeData) {
        try {
            const response = await axios.put(
                "/Customer/CommunicationType/Update",
                communicationTypeData
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Deletes an existing communicationType.
     * @function delete
     * @async
     * @param {string} communicationTypeId
     * @returns {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async delete(communicationTypeId) {
        try {
            const response = await axios.delete(
                `/Customer/CommunicationType/Delete?id=${communicationTypeId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
