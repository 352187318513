import React from "react";

const FormField = ({ label, value }) => {
    const labelStyle = {
        textAlign: "left",
        fontSize: "14px",
        color: "lightslategray",
    };

    const spanStyle = {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "16px",
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                flexGrow: 1,
            }}
        >
            <label style={labelStyle}>{label}</label>
            <span style={spanStyle} className={"text-overflow"}>
                {value ?? " "}&nbsp;
            </span>
        </div>
    );
};

export default FormField;
