import React, { useEffect } from "react";
import { CustomizationListsCustomizations } from "./CustomizationListsCustomizations";
import { CategoryCustomizationLists } from "./CategoryCustomizationLists";
import { CategoryCustomizationsAndListsContext } from "../../../../providers/Deprecated/Inventory/Item/CategoryCustomizationsAndLists";
import ItemCustomizationService from "../../../../services/Deprecated/inventory/ItemCustomizationService";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";

const SELECTED_FIELD = "selected";

const CategoryCustomizationsAndLists = ({ selectedCategory }) => {
    const context = React.useContext(CategoryCustomizationsAndListsContext);
    const { bool: refreshList, toggle: toggleRefreshList } = useToggle();
    const { bool: persistStateRefresh, toggle: togglePersistStateRefresh } =
        useToggle();

    /**
     * Set ListBox state with the Selected Category Customization Lists
     * Set the selected category and reset the selected items
     * @returns {void}
     */
    useEffect(() => {
        const fetchData = async () => {
            const categoryId = selectedCategory?.id;

            if (!categoryId) {
                return;
            }

            try {
                const customizationLists =
                    await ItemCustomizationService.getAllCustomizationListsByCategory(
                        categoryId
                    );
                const customizations =
                    await ItemCustomizationService.getAllCustomizationsByCustomizationList(
                        context.selectedItems?.customizationList?.id
                    );

                context.setListBoxState({
                    customizationLists:
                        customizationLists.data.data.map((item) => {
                            item[SELECTED_FIELD] =
                                item.id ===
                                context.selectedItems?.customizationList?.id;

                            return item;
                        }) || [],
                    customizations:
                        customizations.data.map((item) => {
                            item[SELECTED_FIELD] =
                                item.id ===
                                context.selectedItems?.customization?.id;

                            return item;
                        }) || [],
                });

                if (context.selectedItems?.customizationList) {
                    togglePersistStateRefresh();
                }

                context.setSelectedCategory(selectedCategory);
            } catch (error) {
                console.log("Error", error);
            }
        };

        fetchData();
    }, [selectedCategory, refreshList]);

    useEffect(() => {
        //? Persists the Customizations and Lists being open during CRUD operations
        const currentSelectedListId =
            context.selectedItems?.customizationList?.id;
        const selectedList = context.listBoxState.customizationLists.find(
            (i) => i.customizationListId === currentSelectedListId
        );
        if (selectedList) {
            handleItemClick({ dataItem: selectedList }, "customizationLists");
        }
    }, [persistStateRefresh]);

    /**
     * Handle the click event on the list box items
     * Set the selected item to true and the rest to false
     * Set the selected items in the context
     * @param {object} event - The selected item
     * @param {string} data - Type of the list box
     * @returns {void}
     */
    const handleItemClick = (event, data) => {
        if (data === "customizationLists") {
            const customizationLists =
                context.listBoxState.customizationLists.map((item) => {
                    item.name === event.dataItem.name
                        ? (item[SELECTED_FIELD] = true)
                        : (item[SELECTED_FIELD] = false);

                    return item;
                });

            ItemCustomizationService.getAllCustomizationsByCustomizationList(
                event.dataItem.id
            )
                .then((res) => {
                    const customizationsArr = res.data.map((item) => {
                        item[SELECTED_FIELD] = false;
                        return item;
                    });

                    context.setSelectedItems({
                        customizationList: event.dataItem,
                        customization: null,
                    });
                    context.setListBoxState({
                        customizationLists: customizationLists,
                        customizations: customizationsArr,
                    });
                })
                .catch(console.error);
        }

        if (data === "customizations") {
            const customizationsArr = context.listBoxState.customizations.map(
                (item) => {
                    if (item.name === event.dataItem.name) {
                        item[SELECTED_FIELD] = true;
                        context.setSelectedItems({
                            customizationList:
                                context.selectedItems.customizationList,
                            customization: item,
                        });
                    } else {
                        item[SELECTED_FIELD] = false;
                    }

                    return item;
                }
            );

            context.setListBoxState({
                customizationLists: [
                    ...context.listBoxState.customizationLists,
                ],
                customizations: customizationsArr,
            });
        }
    };

    return (
        <>
            <CategoryCustomizationLists
                handleItemClick={handleItemClick}
                toggleRefreshList={toggleRefreshList}
            />
            <CustomizationListsCustomizations
                handleItemClick={handleItemClick}
                toggleRefreshList={toggleRefreshList}
            />
        </>
    );
};

export default CategoryCustomizationsAndLists;
