import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./InvoiceSummary.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { PaymentContext } from "../../../../../providers/Deprecated/Customer/PaymentProvider";
import { NewJobDocumentType } from "../../../../../resources/Enums/JobDocumentTypeEnum";
import { CustomCell } from "../../../../../components/Deprecated/CustomGridCells";
import { formatUSDCurrency } from "../../../../../resources/Deprecated/currencyHelper";
import { PaymentsHelper } from "../../../../../resources/Deprecated/PaymentHelpers/paymentsHelper";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell.jsx";
import { useModal } from "../../../../../hooks/useModal.js";
import { JobDocumentModalWrapper } from "../../../JobDocuments/Components/JobDocumentModal/JobDocumentModal.jsx";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { DateCell } from "../../../../../components/GridColumnComponents/DateCell.jsx";

const PaymentDisbursement = () => {
    const [data, setData] = useState([]);
    const paymentContext = useContext(PaymentContext);
    const { job } = useContext(JobContext);
    const documentModal = useModal();

    useEffect(() => {
        setData(
            paymentContext.documents.filter(
                (i) => i.type === NewJobDocumentType.Enum.Invoice
            )
        );
    }, [paymentContext.documents]);

    return (
        <Card className={styles.InvoiceCard}>
            <JobDocumentModalWrapper {...documentModal} />
            <CardHeader
                style={{
                    backgroundColor: "#67809F",
                    color: "#fff",
                }}
            >
                <CardTitle>Invoice Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid className={styles.InvoiceGrid} data={data}>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        field="documentNumber"
                        title="Document Number:"
                        cell={(props) => (
                            <BaseCell {...props}>
                                <span
                                    className={"Link"}
                                    onClick={() =>
                                        documentModal.open(props.dataItem.id, {
                                            businessInformation:
                                                job.businessInformation,
                                        })
                                    }
                                >
                                    #{props.dataItem.documentNumber}
                                </span>
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="documentDate"
                        title="Date:"
                        cell={(props) => (
                            <DateCell {...props} format={"MM/DD/YYYY"} />
                        )}
                    />
                    <GridColumn
                        field="total.amount"
                        title="Total:"
                        format="{0:c}"
                    />
                    <GridColumn
                        field="paidFromSalesOrder.amount"
                        title="Amt. Paid From SO:"
                        format="{0:c}"
                    />
                    <GridColumn
                        field="balance"
                        title="Balance:"
                        cell={(props) => {
                            return (
                                <CustomCell>
                                    {formatUSDCurrency(props.dataItem.totalDue)}
                                </CustomCell>
                            );
                        }}
                    />
                    <GridColumn
                        field="totalPaid.amount"
                        title="Total Paid:"
                        cell={(props) => {
                            const balance = props.dataItem.totalDue;
                            const soPayment =
                                props.dataItem.paidFromSalesOrder.amount;
                            const totalPaid = props.dataItem.totalPaid.amount;
                            const paidFormatted = formatUSDCurrency(
                                totalPaid + soPayment
                            );
                            const percentage =
                                PaymentsHelper.getPercentageForDisbursedPayment(
                                    totalPaid + soPayment,
                                    balance
                                );

                            return (
                                <CustomCell>{`${paidFormatted} (${percentage})`}</CustomCell>
                            );
                        }}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default PaymentDisbursement;
