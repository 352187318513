export class ItemAllocationHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            itemId: dataItem.itemId,
            quantity: dataItem.quantity,
            storageLocationId: dataItem.storageLocation.id,
            jobId: dataItem.jobId,
        };
    }
}
