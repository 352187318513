import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";

const initialGroup = [
    {
        field: "vendorName",
    },
];

const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};
const VendorDetailsForOutstandingItemReceiptsGrid = ({ data }) => {
    const [group, setGroup] = React.useState(initialGroup);
    const [resultState, setResultState] = React.useState(
        processWithGroups(data, initialGroup) // Changed from data.vendorId to data
    );
    const [collapsedState, setCollapsedState] = React.useState([]);

    const onGroupChange = React.useCallback(
        (event) => {
            const newGroups = event.group;
            const areNewGroupsUnique = !newGroups.some(
                (item, index) =>
                    newGroups.findIndex(
                        (group) => group.field === item.field
                    ) !== index
            );
            if (areNewGroupsUnique) {
                const newDataState = processWithGroups(data, event.group);
                setGroup(event.group);
                setResultState(newDataState);
            }
        },
        [data]
    );

    const onExpandChange = React.useCallback(
        (event) => {
            const item = event.dataItem;
            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter(
                          (groupId) => groupId !== item.groupId
                      );
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );

    const newData = setExpandedState({
        data: resultState,
        collapsedIds: collapsedState,
    });

    return (
        <Grid
            data={newData}
            groupable={true}
            onGroupChange={onGroupChange}
            group={group}
            onExpandChange={onExpandChange}
            expandField="expanded"
            style={{ height: "520px" }}
        >
            <GridColumn field="documentNumber" title="Number" />
            <GridColumn field="jobName" title="Job" />
            <GridColumn field="vendorName" title="Vendor" />
            <GridColumn field="orderedDate" title="Ordered Date" />
            <GridColumn field="expectedDate" title="Expected Date" />
            <GridColumn field="total" title="Total" format="{0:c}" />
            <GridColumn
                field="employeeWhoOrderedFullName"
                title="Employee Who Ordered:"
            />
        </Grid>
    );
};

export default VendorDetailsForOutstandingItemReceiptsGrid;
