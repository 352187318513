import React, { useContext, useEffect, useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import { deleteVendor } from "../../../services/Deprecated/inventory/vendorService";
import { VendorContext } from "../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import VendorDocumentProvider from "../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import vendorEditForm from "./Components/VendorEditForm";
import VendorTabs from "./Components/VendorTabs";
import VendorItemsGrid from "./Components/VendorItemsGrid";
import VendorPOsGrid from "./Components/VendorPOsGrid";
import VendorIRsGrid from "./Components/VendorIRsGrid";
import VendorBillsGridWrapper from "./Components/VendorBillsGrid";

const VendorDetailsPageWrapper = (props) => {
    return (
        <VendorDocumentProvider>
            <VendorDetailsPage {...props} />
        </VendorDocumentProvider>
    );
};

const VendorDetailsPage = (props) => {
    const [vendorItemsTabSelected, setVendorItemsTabSelected] = useState(0);
    const vendorContext = useContext(VendorContext);
    const vendorId = props?.match?.params?.id;

    useEffect(() => {
        vendorContext.setVendorId(vendorId);
    }, [vendorId]);

    return vendorContext.vendor ? (
        <div>
            <header className="vendor-name-header">
                {vendorContext.vendor.name}
            </header>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card
                        className="option-card-container"
                        style={{ border: "none" }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <ActionButtonsCommandCell
                                        record={vendorContext.vendor}
                                        dataId={vendorId}
                                        action={deleteVendor}
                                        EditComponent={vendorEditForm}
                                        requiredPermissionsEdit={[
                                            PermissionsEnum.UpdateVendor,
                                        ]}
                                        requiredPermissionsAction={[
                                            PermissionsEnum.DeleteVendor,
                                        ]}
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <div className="tab-container">
                        <Card
                            className="card-container"
                            style={{ border: "none" }}
                        >
                            <VendorTabs />
                        </Card>
                    </div>
                </div>
            </div>
            <div className="k-flex-100 flex-container">
                <div className="content-wrapper">
                    <Card className="card-container" style={{ border: "none" }}>
                        <TabStrip
                            selected={vendorItemsTabSelected}
                            onSelect={(e) =>
                                setVendorItemsTabSelected(e.selected)
                            }
                        >
                            <TabStripTab title="Items">
                                <VendorItemsGrid vendorId={vendorId} />
                            </TabStripTab>
                            <TabStripTab title="Purchase Orders">
                                <VendorPOsGrid />
                            </TabStripTab>
                            <TabStripTab title="Item Receipts">
                                <VendorIRsGrid />
                            </TabStripTab>
                            <TabStripTab title="Bills">
                                <VendorBillsGridWrapper />
                            </TabStripTab>
                        </TabStrip>
                    </Card>
                </div>
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default VendorDetailsPageWrapper;
