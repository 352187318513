import React, { useContext, useEffect, useState } from "react";
import { useModal } from "../../../../../hooks/useModal";
import PayrollIntervalModal from "./Components/PayrollIntervalModal";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import FormField from "../../../../../components/Deprecated/StylingField";
import PayrollService from "../../../../../services/Deprecated/humanResources/PayrollService";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import dayjs from "dayjs";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";
import { daysOfTheWeek } from "../../../../../resources/Deprecated/dateHelper";

const PayrollIntervalDashboardPage = () => {
    const payrollModal = useModal();
    const [payrollInterval, setPayrollInterval] = useState(undefined);
    const { reloadData } = useContext(ReloadDataContext);

    useEffect(() => {
        PayrollService.getPayrollInterval().then((res) => {
            if (res.originalResponse.status === 404) {
                setPayrollInterval({});
            } else {
                const startDayOfWeek = daysOfTheWeek[res.data.startDayOfWeek];
                setPayrollInterval({
                    ...res.data,
                    startDayOfWeek,
                    startingDate: dayjs(res.data?.startingDate).format(
                        "MM/DD/YYYY"
                    ),
                });
            }
        });
    }, [reloadData]);

    return payrollInterval ? (
        <div>
            <PayrollIntervalModal {...payrollModal} />
            <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
                <ActionButton
                    onClick={() =>
                        payrollModal.open(undefined, payrollInterval)
                    }
                    buttonText={"Set Payroll Interval"}
                    theme={"primary"}
                    permissions={[PermissionsEnum.SetPayrollInterval]}
                />
                <br />
                <FormField
                    label="Pay Interval"
                    value={payrollInterval?.interval}
                />
                <FormField
                    label="Day Of Week Start"
                    value={payrollInterval?.startDayOfWeek?.name}
                />
                <FormField
                    label="Pay Period Start"
                    value={payrollInterval?.startingDate}
                />
                <FormField
                    label="Minimum Break Duration"
                    value={payrollInterval?.minimumBreakDuration + " Minutes"}
                />
            </span>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default PayrollIntervalDashboardPage;
