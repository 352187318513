import { formatDateOnlyForNetwork } from "../../../../resources/Deprecated/dateHelper";
import dayjs from "dayjs";

export class ReminderModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            id: dataItem.id,
            message: dataItem.message,
            date: formatDateOnlyForNetwork(dayjs(dataItem.date).toDate()),
            leadId: dataItem.lead.id,
            isCompleted: dataItem.isCompleted,
            communicationId: dataItem.communication?.id,
        };
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
            date: dayjs(dataItem.date).format("MM/DD/YYYY"),
        };
    }
}
