import { axiosInstance as axios } from "../../axiosInstance";
import dayjs from "dayjs";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { formatDateOnly } from "../../../resources/Deprecated/dateHelper.js";

export default class ChartOfAccountsService {
    static async get(id) {
        const res = await axios.get(`/Accounting/Account/Get?id=${id}`);
        return formatServiceResponse(res);
    }

    /**
     * Retrieves all non-deleted accounts.
     * @function getChartOfAccounts
     * @async
     * @returns  {Promise<ServiceResponse>}
     */
    static async getChartOfAccounts() {
        try {
            const response = await axios.get(`/Accounting/Account/GetAll`);
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Retrieves the balance sheet based on the date provided
     * @function getBalanceSheet
     * @async
     * @param {object} date
     * @return  {Promise<ServiceResponse>}
     */
    static async getBalanceSheet(date) {
        try {
            const response = await axios.get(
                `/Accounting/Account/GetBalanceSheet?end=${formatDateOnly(
                    date
                )}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Retrieves the income statement based on the time period span provided
     * @function getIncomeStatement
     * @async
     * @param {object} datePeriodTimeSpan
     * @return  {Promise<ServiceResponse>}
     */
    static async getIncomeStatement(datePeriodTimeSpan) {
        try {
            const startingPeriod = {
                start: dayjs(datePeriodTimeSpan.startingPeriod.start).format(
                    "YYYY-MM-DD"
                ),
                end: dayjs(datePeriodTimeSpan.startingPeriod.end).format(
                    "YYYY-MM-DD"
                ),
            };
            const endingPeriod = {
                start: dayjs(datePeriodTimeSpan.endingPeriod.start).format(
                    "YYYY-MM-DD"
                ),
                end: dayjs(datePeriodTimeSpan.endingPeriod.end).format(
                    "YYYY-MM-DD"
                ),
            };

            const queryParams = `StartPeriod.Start=${startingPeriod.start}&StartPeriod.End=${startingPeriod.end}&EndPeriod.Start=${endingPeriod.start}&EndPeriod.End=${endingPeriod.end}`;
            const response = await axios.get(
                `/Accounting/Account/GetIncomeStatement?${queryParams}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(
                error,
                "Failed to fetch income statement"
            );
        }
    }

    /**
     * Sends GET request to get all expense account entities
     * @function getAllExpenseAccounts
     * @async
     * @returns  {Promise<ServiceResponse>}
     */
    static async getAllExpenseAccounts() {
        try {
            const response = await axios.get("/Accounting/Account/GetAll");
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get all income account entities
     * @function getAllIncomeAccounts
     * @async
     * @returns  {Promise<ServiceResponse>}
     */
    static async getAllIncomeAccounts() {
        try {
            const response = await axios.get(
                "/Accounting/Account/GetAll?filter=(type~eq~'0'~or~type~eq~'1'~or~type~eq~'2')"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get all account entities
     * @function getAllAccounts
     * @async
     * @returns  {Promise<ServiceResponse>}
     */
    static async getAllAccounts() {
        try {
            const response = await axios.get("/Accounting/Account/GetAll");
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends POST request to API to create new Account Entity
     * @function createAccount
     * @async
     * @param {object} account
     * @returns  {Promise<ServiceResponse>}
     */
    static async createAccount(account) {
        delete account.parentAccount;
        try {
            const response = await axios.post(
                "/Accounting/Account/Create",
                account
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends PUT request to API to update provided Account Entity
     * @function updateAccount
     * @async
     * @param {object} account
     * @returns  {Promise<ServiceResponse>}
     */
    static async updateAccount(account) {
        delete account.parentAccount;
        try {
            const response = await axios.put(
                "/Accounting/Account/Update",
                account
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Soft Deletes the account by provided accountId.
     * @function deleteAccount
     * @async
     * @param {string} accountId
     * @returns  {Promise<ServiceResponse>}
     * @throws {Error}
     */
    static async deleteAccount(accountId) {
        try {
            const response = await axios.delete(
                `/Accounting/Account/Delete?id=${accountId}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }
}
