import React from "react";
import VendorDocumentProvider from "../../../../providers/Deprecated/Inventory/Vendor/VendorDocumentProvider";
import JobDocumentPortlet from "../../JobDocuments/Components/JobDocumentPortlet.jsx";
import { NewJobDocumentType } from "../../../../resources/Enums/JobDocumentTypeEnum.js";

const JobTransactions = () => {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "left",
                    flexWrap: "wrap",
                    maxWidth: 1800,
                    gap: 20,
                }}
            >
                <JobDocumentPortlet
                    portletName={"Estimates"}
                    jobDocType={NewJobDocumentType.Enum.Estimate}
                    uri={"Customer/Job/Document"}
                />
                <JobDocumentPortlet
                    portletName={"Sales Orders"}
                    jobDocType={NewJobDocumentType.Enum.SalesOrder}
                    uri={"Customer/Job/Document"}
                />
                <JobDocumentPortlet
                    portletName={"Invoices"}
                    jobDocType={NewJobDocumentType.Enum.Invoice}
                    uri={"Customer/Job/Document"}
                />
                <VendorDocumentProvider>
                    <JobDocumentPortlet
                        portletName={"Purchase Orders"}
                        jobDocType={NewJobDocumentType.Enum.VendorDocument}
                        uri={"Inventory/Vendor/Document"}
                    />
                </VendorDocumentProvider>
                <JobDocumentPortlet
                    portletName={"Pending - Change Orders"}
                    jobDocType={NewJobDocumentType.Enum.PendingChangeOrder}
                    uri={"Customer/Job/Document"}
                />
                <JobDocumentPortlet
                    portletName={"Approved - Change Orders"}
                    jobDocType={NewJobDocumentType.Enum.ApprovedChangeOrder}
                    uri={"Customer/Job/Document"}
                />
            </div>
        </div>
    );
};

export default JobTransactions;
