// noinspection JSValidateTypes

import * as React from "react";
import { useEffect, useState } from "react";
import { Input, MaskedTextBox } from "@progress/kendo-react-inputs";

const DebounceTextBox = (props) => {
    const [val, setVal] = useState("");
    const {
        setter,
        timeout,
        valid,
        type,
        label,
        id,
        ariaDescribedBy,
        value,
        onChange,
        ...others
    } = props;

    if (!setter) {
        throw "Setter required to use DebounceTextBox";
    }

    if (!timeout) {
        throw "Timeout required to use DebounceTextBox";
    }

    useEffect(() => {
        if (val === "") {
            return;
        }

        const delaySearch = setTimeout(() => {
            setter(val);
        }, timeout);

        return () => clearTimeout(delaySearch);
    }, [val]);

    return others["mask"] ? (
        <MaskedTextBox
            onChange={(event) => {
                if (onChange !== undefined) {
                    onChange(event);
                }

                setVal(event.value);
            }}
            valid={valid}
            type={type}
            label={label}
            id={id}
            ariaDescribedBy={ariaDescribedBy}
            value={value}
            {...others}
        />
    ) : (
        <Input
            onChange={(event) => {
                if (onChange !== undefined) {
                    onChange(event);
                }

                setVal(event.value);
            }}
            valid={valid}
            type={type}
            label={label}
            id={id}
            ariaDescribedBy={ariaDescribedBy}
            value={value}
            {...others}
        />
    );
};

export default DebounceTextBox;
