import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import LeadService from "../../../services/LeadService";
import { ContentHeader } from "../../../components/ContentHeader";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { LeadModal } from "./Components/LeadModal";
import { useModal } from "../../../hooks/useModal";
import { useHistory } from "react-router-dom";
import { CustomerTypeEnum } from "../../../resources/Enums/CustomerStatusEnum";
import { ReloadDataContext } from "../../../providers/ReloadDataProvider";
import LeadDetailsTab from "./Components/Tabs/LeadDetailsTab";
import LeadCommunicationsTab from "./Components/Tabs/LeadCommunicationsTab";
import LeadRemindersTab from "./Components/Tabs/LeadRemindersTab";

const LeadDetailPage = (props) => {
    const history = useHistory();
    const [leadTabsSelected, setLeadTabsSelected] = useState(0);
    const { reloadData } = useContext(ReloadDataContext);
    const [leadProxyTabsSelected, setLeadProxyTabsSelected] = useState(0);
    const service = useRef(new LeadService("Customer/Lead"));
    const leadModal = useModal();
    const [lead, setLead] = useState(undefined);

    useEffect(() => {
        if (!props?.match?.params?.id) return;

        service.current.get(props?.match?.params?.id).then((res) => {
            setLead(res.data);
        });
    }, [props?.match?.params?.id, reloadData]);

    const onDelete = async () => {
        service.current.delete(lead.id).then(() => {
            history.push("/Lead");
        });
    };

    return (
        lead && (
            <div>
                <ContentHeader
                    title={`${CustomerTypeEnum.ParseToString(lead.type)}: ${
                        lead.combinedName
                    }`}
                    className={"JustifyLeftAlignCenter"}
                />
                <LeadModal {...leadModal} />
                <div className={"JustifyRightAlignCenter"}>
                    <ActionButton
                        buttonText={"Go To Leads"}
                        icon={"k-icon k-i-undo"}
                        onClick={() => history.push("/Lead")}
                    />
                    <ActionButton
                        buttonText={"Delete"}
                        needsConfirmation={true}
                        confirmationText={
                            "Are you sure you want to delete this Lead?"
                        }
                        onClick={onDelete}
                        permissions={[PermissionsEnum.DeleteLead]}
                    />
                    <ActionButton
                        buttonText={"Edit"}
                        onClick={() => leadModal.open(lead.id)}
                        permissions={[PermissionsEnum.UpdateLead]}
                    />
                </div>
                <Card style={{ border: "none" }}>
                    <TabStrip
                        selected={leadTabsSelected}
                        onSelect={(e) => setLeadTabsSelected(e.selected)}
                    >
                        <TabStripTab title="Details">
                            <LeadDetailsTab {...lead} />
                        </TabStripTab>
                        <TabStripTab title="Communications">
                            <LeadCommunicationsTab {...lead} />
                        </TabStripTab>
                    </TabStrip>
                </Card>
                <br />
                <Card style={{ border: "none" }}>
                    <TabStrip
                        selected={leadProxyTabsSelected}
                        onSelect={(e) => setLeadProxyTabsSelected(e.selected)}
                    >
                        <TabStripTab title="Reminders">
                            <LeadRemindersTab {...lead} />
                        </TabStripTab>
                    </TabStrip>
                </Card>
            </div>
        )
    );
};

export default LeadDetailPage;
