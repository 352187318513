import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getAllCategories = async () => {
    try {
        const response = await axios.get("/Inventory/ItemCategory/GetAll");
        const formattedResponse = formatServiceResponse(response);
        if (!formattedResponse.success) {
            return formattedResponse;
        }
        return convertCategoryToKendoTree(formattedResponse.data);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllCategoriesNonTree = async () => {
    try {
        const response = await axios.get("/Inventory/ItemCategory/GetAll");
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

const convertCategoryToKendoTree = function convertCategoryToKendoTree(
    categoryData
) {
    const tree = [];
    const lookup = {};
    categoryData.forEach((item) => {
        const existingLookupData = lookup[item.id];
        const treeItem = {
            text: item.name,
            items: [],
            data: item,
            ...existingLookupData,
        };
        lookup[item.id] = treeItem;
        if (item.parentCategoryId === null) {
            tree.push(treeItem);
        } else {
            if (!lookup[item.parentCategoryId]) {
                lookup[item.parentCategoryId] = { items: [] };
            }
            lookup[item.parentCategoryId].items.push(treeItem);
        }
    });
    return tree;
};

export const createItemCategory = async (categoryName, parentCategoryId) => {
    try {
        const response = await axios.post("/Inventory/ItemCategory/Create", {
            name: categoryName,
            parentCategoryId,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateItemCategoryName = async (
    categoryName,
    categoryId,
    parentCategoryId
) => {
    try {
        const response = await axios.put("/Inventory/ItemCategory/Update", {
            name: categoryName,
            id: categoryId,
            parentCategoryId,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteItemCategory = async (categoryId) => {
    try {
        const response = await axios.delete(
            `/Inventory/ItemCategory/Delete?id=${categoryId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function moveItemCategory
 * @param dataItem.sourceId {string} - The ID of the item to move
 * @param dataItem.destinationId {string} - The ID of the destination item
 * @param dataItem.operation {string} - The operation to perform (before, after, or child)
 * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
 */
export const moveItemCategory = async (dataItem) => {
    const res = await axios.put("/Inventory/ItemCategory/Move", dataItem);
    return formatServiceResponse(res);
};
