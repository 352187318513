export class InventoryModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            id: dataItem.id,
            quantity: dataItem.quantity ?? 1,
            length: dataItem.length !== "" ? dataItem.length : undefined,
            width: dataItem.width !== "" ? dataItem.width : undefined,
            cost: {
                amount: dataItem.cost?.amount ?? 0,
                currency: "USD",
            },
            tax: {
                amount: dataItem.tax?.amount ?? 0,
                currency: "USD",
            },
            adjustment: {
                amount: dataItem.adjustment?.amount ?? 0,
                currency: "USD",
            },
            freight: {
                amount: dataItem.freight?.amount ?? 0,
                currency: "USD",
            },
            storageLocationId: dataItem.storageLocation?.id,
            itemId: dataItem.item?.item?.id,
            vendorId: dataItem.vendor?.id,
        };
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
        };
    }
}
