import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";
import { getAllActiveJobs } from "../../../../services/Deprecated/customer/jobServices.js";
import { getAllVendors } from "../../../../services/Deprecated/inventory/vendorService.js";
import BaseService from "../../../../services/BaseService.js";

export const VendorDocumentContext = createContext(null);

const VendorDocumentProvider = ({ children }) => {
    const { reloadData } = useContext(ReloadDataContext);
    const [data, setData] = useState({});
    const [modalOperation, setModalOperation] = useState(undefined);
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [selectedDocumentId, setSelectedDocumentId] = useState(undefined);
    const [reloadDocument, setReloadDocument] = useState(false);

    //? Load in selectedDocument
    useEffect(() => {
        if (!selectedDocumentId) {
            setSelectedDocumentId(undefined);
            setSelectedDocument(undefined);
            return;
        }

        const service = new BaseService("Inventory/Vendor/Document");
        service.get(selectedDocumentId).then((res) => {
            setSelectedDocument(res.data);
        });
    }, [selectedDocumentId, reloadDocument]);

    //? Load in relationships
    useEffect(() => {
        const fetchData = async () => {
            const jobs = await getAllActiveJobs();
            const vendors = await getAllVendors();

            return { jobs, vendors };
        };

        fetchData().then((res) => setData(res));
    }, [reloadData]);

    const closeModal = () => {
        setModalOperation(undefined);
        setSelectedDocumentId(undefined);
        setSelectedDocument(undefined);
    };

    const value = {
        data,
        modalOperation,
        setModalOperation,
        selectedDocument,
        selectedDocumentId,
        setSelectedDocumentId,
        setReloadDocument,
        reloadDocument,
        closeModal,
    };

    return (
        <VendorDocumentContext.Provider value={value}>
            {children}
        </VendorDocumentContext.Provider>
    );
};

export default VendorDocumentProvider;
