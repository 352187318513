import React from "react";
import { BaseCell } from "./BaseCell";

/**
 * @function YesNoCell
 * @description
 * A cell component that renders a boolean value as "Yes" or "No".
 * This component cannot be used with the "lock" prop on the GridColumn component.
 * @param {Object} props
 * @return {React.JSX.Element}
 * @constructor
 */
export const YesNoCell = (props) => {
    const { dataItem, field, tdProps } = props;

    return (
        <BaseCell {...tdProps}>
            {dataItem[field] === true ? "Yes" : "No"}
        </BaseCell>
    );
};
