import * as React from "react";
import { TreeListCell } from "@progress/kendo-react-treelist";

const CoATLNameColumnCell = (props) => {
    const itemIsRoot = !props.dataItem.parentAccount?.id;
    //? Looks rough but basically capitalize the name if there is no parent (root)
    props.dataItem.name = itemIsRoot
        ? props.dataItem.name.toUpperCase()
        : props.dataItem.name;

    return <TreeListCell {...props} className={"RemoveRightCaret"} />;
};

export default CoATLNameColumnCell;
