import {useEffect, useState} from "react";
import dayjs from "dayjs";
import DashboardService from "../../../services/Deprecated/reporting/DashboardService";

/**
 * Fetches reminders' report for the given employee.
 * @param {string} employeeId The employee ID.
 * @param {string} date The date to fetch the report for.
 * @returns {Object} The reminders for the employee.
 */
export function useRemindersReport(employeeId, date) {
    const [reminders, setReminders] = useState([]);
    let formattedDate;

    if (!employeeId) {
        throw new ReferenceError("EmployeeId is undefined.");
    }

    if (date) {
        formattedDate = dayjs(date).format("YYYY-MM-DD");
    }

    useEffect(() => {
        DashboardService.getRemindersReport({
            employeeId,
            date: formattedDate,
        }).then((response) => {
            setReminders(response);
        });
    }, [date, employeeId]);

    return { reminders };
}
