import * as React from "react";
import { TreeListCell } from "@progress/kendo-react-treelist";

const IncomeStatementTLNameColumnCell = (props) => {
    const { dataItem } = props;

    const style =
        dataItem.isTotalLine || dataItem.isPercentageLine
            ? {
                  fontWeight: "bold",
                  borderTop: "1px solid",
              }
            : undefined;

    return <TreeListCell {...props} style={style} />;
};

export default IncomeStatementTLNameColumnCell;
