import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import { BreadcrumbContext } from "../../../../../../providers/BreadCrumbProvider.jsx";
import styles from "../Styles/VendorDocumentModal.module.scss";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper.jsx";
import { Field, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons.jsx";
import { FormRemoteDropDown } from "../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators.js";
import { useModal } from "../../../../../../hooks/useModal.js";
import { VendorDocumentModalContext } from "../Providers/VendorDocumentModalProvider.jsx";

/**
 * @function VendorAndJobDetailsPortlet
 * @description Displays information for a Document about the Vendor and Job
 * @param props.formRenderProps {object} - form render props
 * @return {React.JSX.Element}
 * @constructor
 */
export const VendorAndJobDetailsPortlet = (props) => {
    const { formRenderProps } = props;
    const { formData } = useContext(VendorDocumentModalContext);
    const { handleRouteChange } = useContext(BreadcrumbContext);
    const vendorAndJobModal = useModal();

    const handleVendorClick = (vendor) => {
        handleRouteChange({
            uri: `/Inventory/Vendor/${vendor.id}`,
            breadcrumbInfo: [{ id: vendor.id, text: vendor.name }],
        });
    };

    const handleJobClick = (job) => {
        handleRouteChange({
            uri: `/Customer/${job.customer.id}/Job/${job.id}`,
            breadcrumbInfo: [
                { id: job.id, text: job.name },
                { id: job.customer.id, text: job.customer.name },
            ],
        });
    };

    return (
        formData && (
            <Card style={{ width: 350, height: 125 }}>
                <VendorAndJobModal {...vendorAndJobModal} />
                <CardBody
                    className={"JustifyLeftAlignLeft FlexColumn SmallGap"}
                >
                    <DisplayTitle
                        formData={formData}
                        modal={vendorAndJobModal}
                        formRenderProps={formRenderProps}
                    />
                    <span className={"JustifyLeftAlignLeft SmallGap"}>
                        <span
                            className={
                                "JustifyRightAlignRight FlexColumn SmallGap"
                            }
                            style={{
                                width: 100,
                            }}
                        >
                            <strong>Vendor:</strong>
                            <strong>Job:</strong>
                        </span>
                        <span
                            className={
                                "JustifyLeftAlignLeft FlexColumn SmallGap"
                            }
                        >
                            <DisplayName
                                text={formData.vendor?.name}
                                onClick={() =>
                                    handleVendorClick(formData.vendor)
                                }
                            />
                            <DisplayName
                                text={formData.job?.name}
                                onClick={() => handleJobClick(formData.job)}
                            />
                        </span>
                    </span>
                </CardBody>
            </Card>
        )
    );
};

/**
 * @function DisplayName
 * @param props.text {string} - text to display
 * @param props.onClick {function} - click event
 * @return {Element}
 * @constructor
 */
const DisplayName = (props) => {
    const { text, onClick } = props;

    return text ? (
        <a className={"Link text-overflow"} onClick={onClick}>
            {text}
        </a>
    ) : (
        <span style={{ color: "red" }}>**Not Provided**</span>
    );
};

/**
 * @function DisplayTitle
 * @description Displays the title for the Vendor and Job Details
 * @param props.formData {object} - form data
 * @param props.modal {object} - modal object
 * @param props.formRenderProps {object} - form render props
 * @return {Element}
 * @constructor
 */
const DisplayTitle = (props) => {
    const { formData, modal, formRenderProps } = props;
    const { canEdit, lineItems } = useContext(VendorDocumentModalContext);

    return canEdit && lineItems.current.length === 0 ? (
        <span
            onClick={() =>
                modal.open(undefined, {
                    ...formData,
                    formRenderProps: formRenderProps,
                })
            }
        >
            <CardTitle className={styles.LinkDisplay}>
                <i
                    className={"k-icon k-i-pencil"}
                    style={{ marginBottom: 4 }}
                />{" "}
                Vendor & Job Details:
            </CardTitle>
        </span>
    ) : (
        <CardTitle>Vendor & Job Details:</CardTitle>
    );
};

/**
 * @function VendorAndJobModal
 * @description Modal for editing a Vendor and Job
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {React.JSX.Element}
 * @constructor
 */
export const VendorAndJobModal = (props) => {
    const { close, visible, record } = props;
    const [data, setData] = useState(undefined);
    const [reloadVendorKey, setReloadVendorKey] = useState(Math.random());
    const { reloadDocumentInformation, setFormData, formData } = useContext(
        VendorDocumentModalContext
    );

    useEffect(() => {
        if (!visible) return;

        setData({
            vendor: record?.vendor,
            job: record?.job,
        });
    }, [visible]);

    const onClose = () => {
        setData(undefined);
        close();
    };

    const onSubmit = () => {
        setFormData({ ...record, ...data });
        reloadDocumentInformation();
    };

    const validate = (data) => {
        return data.vendor !== undefined && data.job !== undefined;
    };

    return (
        visible &&
        data && (
            <ResponsiveDialog
                title={"Edit Vendor & Job"}
                onClose={onClose}
                size={"small"}
            >
                <FormElement>
                    <Field
                        key={reloadVendorKey}
                        url={
                            formData.job
                                ? `Customer/Job/Vendor/GetAll?jobId=${data.job.id}&`
                                : "/Inventory/Vendor/GetAll?"
                        }
                        name={"vendor"}
                        component={FormRemoteDropDown}
                        label={"Vendor:"}
                        validator={requiredValidator}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                            setData({
                                ...data,
                                vendor: e.value,
                                isValid: validate({ ...data, vendor: e.value }),
                            })
                        }
                    />
                    <Field
                        url={"/Customer/Job/GetAll?"}
                        name={"job"}
                        component={FormRemoteDropDown}
                        label={"Job:"}
                        validator={requiredValidator}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            setData({
                                ...data,
                                job: e.value,
                                vendor: undefined,
                                isValid: validate({
                                    ...data,
                                    vendor: undefined,
                                    job: e.value,
                                }),
                            });
                            record.formRenderProps.onChange("vendor", {
                                value: "",
                            });
                            setReloadVendorKey(Math.random());
                        }}
                    />
                </FormElement>
                <FormButtons
                    loaderVisible={false}
                    actionOnCancel={onClose}
                    allowSubmit={data.isValid}
                    isCreate={false}
                    buttonText={"Apply Changes"}
                    onSubmit={onSubmit}
                />
            </ResponsiveDialog>
        )
    );
};
