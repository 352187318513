import React, { useEffect, useState } from "react";
import { InputPrefix, TextBox } from "@progress/kendo-react-inputs";
import { ToggleButton } from "./Buttons/ToggleButton/ToggleButton";

/**
 * @function SearchDebounce
 * @description A search bar that debounces the search term and returns a crafted filter based on the term.
 * @param props.setFilter {function} - The function to set the filter
 * @param props.searchFields {Array} - The fields to search
 * @param [props.baseFilter] {Array} - The base filter to apply
 * @param [props.searchTextId] {string} - The search text ID
 * @param [props.toggleSearchId] {string} - The toggle search ID
 * @return {Element}
 * @constructor
 */
export const SearchDebounce = (props) => {
    const {
        setFilter,
        searchFields = [],
        baseFilter = [],
        searchTextId,
        toggleSearchId,
    } = props;
    const [searchState, setSearchState] = useState({
        searchTerm: undefined,
        searchOperator: "contains",
        refreshSearch: false,
    });

    useEffect(() => {
        if (!searchFields || !searchState.searchTerm) {
            setFilter(baseFilter);
            return;
        }

        const delaySearch = setTimeout(() => {
            const searchTermOperators = searchFields.map((field) => ({
                field,
                operator: searchState.searchOperator,
                value: searchState.searchTerm,
            }));

            const filters = [
                {
                    filters: [
                        {
                            filters: searchTermOperators,
                            logic: "or",
                        },
                    ],
                    logic: "and",
                },
            ];

            if (baseFilter?.filters) {
                for (let i = 0; i < baseFilter.filters.length; i++) {
                    filters[0].filters.push(baseFilter.filters[i]);
                }
            }

            setFilter({ filters: filters });
        }, 500);

        return () => clearTimeout(delaySearch);
    }, [searchState]);

    const onSearchChange = (props) => {
        setSearchState((initialState) => ({
            ...initialState,
            searchTerm: props.target.value,
        }));
    };

    const onToggleOn = () => {
        setSearchState((initialState) => ({
            ...initialState,
            searchOperator: "startswith",
            refreshSearch: Math.random(),
        }));
    };

    const onToggleOff = () => {
        setSearchState((initialState) => ({
            ...initialState,
            searchOperator: "contains",
            refreshSearch: Math.random(),
        }));
    };

    return (
        <span className={"JustifyLeftAlignCenter LargeGap"}>
            <TextBox
                id={searchTextId}
                size={"medium"}
                fillMode={"outline"}
                rounded={"large"}
                onChange={onSearchChange}
                placeholder="Search Records"
                className={"GridSearch"}
                prefix={() => (
                    <InputPrefix>
                        <span className="k-icon k-font-icon k-i-search" />
                    </InputPrefix>
                )}
            />
            <ToggleButton
                id={toggleSearchId}
                onToggleOn={onToggleOn}
                onToggleOff={onToggleOff}
                textStyle={{
                    fontWeight: 400,
                    fontSize: "14px",
                }}
                label={"Exact Match?"}
            />
        </span>
    );
};
