import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class CalendarService {
    static async copy(data) {
        const res = await axios.post(`/Customer/CalendarEvent/Copy`, data);
        return formatServiceResponse(res);
    }

    static async getAllJobEvents(jobId) {
        const res = await axios.get(
            `/Customer/Job/CalendarEvent/GetAll?jobId=${jobId}&`
        );
        return formatServiceResponse(res);
    }
}
