import React, { createContext, useRef, useState } from "react";
import { NewItemTypeEnum } from "../resources/Enums/ItemTypeEnum.js";
import LineItemHelper from "../resources/LineItemHelper.js";

export const DocumentLineItemContext = createContext(null);

const DocumentLineItemProvider = ({ children }) => {
    const [reloadLineItemsKey, setReloadLineItemsKey] = useState(Math.random());
    const runningTotalRef = useRef(0);
    const lineItemsRef = useRef([]);

    const resetLineItemsState = () => {
        lineItemsRef.current = [];
        runningTotalRef.current = 0;
    };

    const flattenLineItems = (items) => {
        return items.reduce((acc, item) => {
            acc.push(item);
            if (item.children && item.children.length > 0) {
                acc.push(...flattenLineItems(item.children));
            }
            return acc;
        }, []);
    };

    const calculateAndSetRunningTotal = (itemsToCalculate) => {
        const discountedItems = LineItemHelper.applyDiscounts(itemsToCalculate);
        const flattenedItems = flattenLineItems(
            discountedItems.filter((i) => i.isActive)
        );
        lineItemsRef.current = discountedItems;
        runningTotalRef.current =
            LineItemHelper.calculateTotalOfItems(flattenedItems);
    };

    const reloadLineItems = () => {
        setReloadLineItemsKey(Math.random());
    };

    const addLineItem = (lineItem, index) => {
        let newIndex = index;
        // If index is not provided, add the new line item before the first document discount
        if (index === undefined) {
            if (LineItemHelper.isDiscountLineItem(lineItem)) {
                newIndex = lineItemsRef.current.length;
            } else {
                let firstFoundDiscountIndex = lineItemsRef.current.findIndex(
                    (i) => LineItemHelper.isDiscountLineItem(i)
                );

                if (firstFoundDiscountIndex === -1) {
                    newIndex = lineItemsRef.current.length;
                } else {
                    newIndex = firstFoundDiscountIndex;
                }
            }
        }

        lineItemsRef.current.splice(newIndex, 0, lineItem);
        reIndexLineItems(lineItemsRef.current);
        calculateAndSetRunningTotal(lineItemsRef.current);
        reloadLineItems();
    };

    const addNoteLineItem = () => {
        const lineItem = {
            id: Math.random(),
            item: {
                isDescriptionEditAllowed: true,
                name: "---NOTE---",
                isActive: true,
            },
            isActive: true,
            type: NewItemTypeEnum.Enum.Note,
            quantity: 1,
            total: { amount: 0, currency: "USD" },
            price: { amount: 0, currency: "USD" },
            IN_LINE_EDIT: true,
        };

        addLineItem(lineItem);
    };

    const removeLineItem = (id) => {
        const flattenedLineItems = flattenLineItems(lineItemsRef.current);
        const li = flattenedLineItems.find((i) => i.id === id);

        if (!li) return;

        const isDiscount = LineItemHelper.isDiscountLineItem(li);
        const parent = flattenedLineItems.find((i) => i.id === li.parentId);
        if (parent && isDiscount) {
            const parent = flattenedLineItems.find((i) => i.id === li.parentId);

            parent.children = parent.children.filter((i) => i.id !== id);

            if (parent.children.length === 0) {
                parent.EXPANDED = false;
                updateLineItem(parent.index, parent);
            }
        } else {
            lineItemsRef.current.splice(li.index, 1);
            reIndexLineItems(lineItemsRef.current);
        }

        calculateAndSetRunningTotal(lineItemsRef.current);
        reloadLineItems();
    };

    const updateLineItem = (index, lineItem) => {
        lineItemsRef.current[index] = {
            ...lineItem,
            total: {
                amount: lineItem.quantity * lineItem.price.amount,
                currency: "USD",
            },
        };

        calculateAndSetRunningTotal(lineItemsRef.current);
        reloadLineItems();
    };

    const moveLineItem = (index, direction) => {
        if (direction === "UP") {
            if (index === 0) return;

            const temp = lineItemsRef.current[index];
            lineItemsRef.current[index] = lineItemsRef.current[index - 1];
            lineItemsRef.current[index - 1] = temp;
        } else {
            if (index === lineItemsRef.current.length - 1) return;

            const temp = lineItemsRef.current[index];
            lineItemsRef.current[index] = lineItemsRef.current[index + 1];
            lineItemsRef.current[index + 1] = temp;
        }

        reIndexLineItems(lineItemsRef.current, false);
        reloadLineItems();
    };

    const reIndexLineItems = (lineItems, shouldSortData = true) => {
        lineItems = shouldSortData
            ? lineItems.sort((a, b) => a.index - b.index)
            : lineItems;
        return lineItems.forEach((lineItem, index) => {
            lineItem.index = index;
        });
    };

    return (
        <DocumentLineItemContext.Provider
            value={{
                lineItemsRef,
                resetLineItemsState,
                reloadLineItemsKey,
                reloadLineItems,
                addLineItem,
                addNoteLineItem,
                removeLineItem,
                updateLineItem,
                moveLineItem,
                reIndexLineItems,
                calculateAndSetRunningTotal,
                runningTotalRef,
            }}
        >
            {children}
        </DocumentLineItemContext.Provider>
    );
};

export default DocumentLineItemProvider;
