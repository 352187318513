import React, { useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ContentHeader } from "../../../../components/ContentHeader";
import PayrollIntervalDashboardPage from "./Payroll/PayrollIntervalDashboardPage";
import MaritalStatusDashboardPage from "./MaritalStatus/MaritalStatusDashboardPage";
import LocationDashboardPage from "./Location/LocationDashboardPage";
import LaborTypeDashboardPage from "./LaborType/LaborTypeDashboardPage";
import IdentificationTypeDashboardPage from "./IdentificationType/IdentificationTypeDashboardPage";

const EmployeeTabPage = () => {
    const [selected, setSelected] = useState(0);

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Employee Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <TabStrip
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    className="tabs"
                >
                    <TabStripTab title="Payroll">
                        <PayrollIntervalDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Identification Types">
                        <IdentificationTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Labor Types">
                        <LaborTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Locations">
                        <LocationDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Marital Statuses">
                        <MaritalStatusDashboardPage />
                    </TabStripTab>
                </TabStrip>
            </Card>
        </div>
    );
};

export default EmployeeTabPage;
