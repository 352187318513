import React, { createContext, useEffect, useState } from "react";
import { getRole } from "../../../../services/Deprecated/authentication/permissionService.js";

export const PermissionContext = createContext(null);

const PermissionListBoxProvider = ({ children }) => {
    // initialize the initial state for each permission group
    const initialListBoxState = {
        assigned: [],
        unassigned: [],
        draggedItem: {},
    };

    const [allPermissions, setAllPermissions] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [initialRolePermissions, setInitialRolePermissions] = useState([]);

    const permissionGroups = [
        {
            name: "Authentication",
            title: "Authentication Permissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000002",
                "10000000-0000-0000-0000-000000000002",
                "20000000-0000-0000-0000-000000000002",
                "30000000-0000-0000-0000-000000000002",
                "40000000-0000-0000-0000-000000000002",
                "50000000-0000-0000-0000-000000000002",
                "60000000-0000-0000-0000-000000000002",
                "70000000-0000-0000-0000-000000000002",
                "80000000-0000-0000-0000-000000000002",
                "20000000-0000-0000-0000-000000000003",
            ],
        },
        {
            name: "Accounting",
            title: "Accounting Permissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000005",
                "40000000-0000-0000-0000-000000000005",
                "60000000-0000-0000-0000-000000000005",
                "70000000-0000-0000-0000-000000000005",
            ],
        },
        {
            name: "Customer",
            title: "Customer Permissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000007",
                "10000000-0000-0000-0000-000000000007",
                "60000000-0000-0000-0000-000000000007",
                "70000000-0000-0000-0000-000000000007",
                "80000000-0000-0000-0000-000000000007",
                "292860a8-0ae1-4d28-ab4b-0321c7a97203",
                "6acaac4d-6fc4-4211-956c-425f777871c4",
                "6891c911-752c-4729-b0ab-a65e41ceb15c",
                "1ef9212e-54c8-4f77-bef7-b1e175d0883a",
                "10000000-0000-0000-0000-000000000009",
                "10000000-0000-0000-0000-000000000010",
                "70000000-0000-0000-0000-000000000014",
                "70000000-0000-0000-0000-000000000013",
                "70000000-0000-0000-0000-000000000010",
                "70000000-0000-0000-0000-000000000004",
                "70000000-0000-0000-0000-000000000003",
                "70000000-0000-0000-0000-000000000002",
                "70000000-0000-0000-0000-000000000009",
            ],
        },
        {
            name: "Human Resources",
            title: "Human Resources Permissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000001",
                "10000000-0000-0000-0000-000000000001",
                "20000000-0000-0000-0000-000000000001",
                "30000000-0000-0000-0000-000000000001",
                "40000000-0000-0000-0000-000000000001",
                "50000000-0000-0000-0000-000000000001",
                "60000000-0000-0000-0000-000000000001",
                "70000000-0000-0000-0000-000000000001",
                "80000000-0000-0000-0000-000000000001",
                "10000000-0000-0000-0000-000000000011",
                "10000000-0000-0000-0000-000000000003",
                "10000000-0000-0000-0000-000000000004",
            ],
        },
        {
            name: "Inventory",
            title: "InventoryPermissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000006",
                "10000000-0000-0000-0000-000000000006",
                "40000000-0000-0000-0000-000000000006",
                "60000000-0000-0000-0000-000000000006",
                "70000000-0000-0000-0000-000000000006",
                "80000000-0000-0000-0000-000000000006",
                "60000000-0000-0000-0000-000000000009",
                "60000000-0000-0000-0000-000000000010",
                "60000000-0000-0000-0000-000000000011",
                "60000000-0000-0000-0000-000000000004",
                "60000000-0000-0000-0000-000000000007",
                "60000000-0000-0000-0000-000000000008",
                "60000000-0000-0000-0000-000000000013",
                "60000000-0000-0000-0000-000000000003",
                "60000000-0000-0000-0000-000000000012",
            ],
        },
        {
            name: "Reports",
            title: "Reports Permissions",
            permissionGroupIds: [
                "30000000-0000-0000-0000-000000000001",
                "731eaef8-e5da-472a-b87c-a8553e1e8c69",
            ],
        },
        {
            name: "Standard",
            title: "Standard Permissions",
            permissionGroupIds: [
                "00000000-0000-0000-0000-000000000008",
                "80000000-0000-0000-0000-000000000001",
                "80000000-0000-0000-0000-000000000002",
                "80000000-0000-0000-0000-000000000003",
                "80000000-0000-0000-0000-000000000006",
                "80000000-0000-0000-0000-000000000007",
            ],
        },
    ];

    // Initialize state for different permission groups
    const [permissionListBoxState, setPermissionListBoxState] = React.useState({
        Authentication: initialListBoxState,
        Customer: initialListBoxState,
        "Human Resources": initialListBoxState,
        Inventory: initialListBoxState,
        Tool: initialListBoxState,
    });

    const setPermissionListBoxStateForPermissionGroupId = (
        permissionGroupId,
        state
    ) => {
        // Here we create a new state by spreading the existing state and updating the specified permission group's state
        const newState = {
            ...permissionListBoxState,
            [permissionGroupId]: state,
        };
        setPermissionListBoxState(newState);
    };
    useEffect(() => {
        if (!selectedRole?.id) return;

        getRole(selectedRole?.id)
            .then((fullRoleResponse) => {
                const initialRoleState = {};
                for (const permissionGroup of permissionGroups) {
                    const assigned = [];
                    const unassigned = [];
                    // Filter permissions based on the desired permissionGroupIds array
                    const filteredPermissions = allPermissions.filter((item) =>
                        permissionGroup.permissionGroupIds.includes(
                            item.group.id
                        )
                    );

                    filteredPermissions.forEach((item) => {
                        // Check if the permission is found in the role's permissions (roleData.rolePermissions)
                        if (
                            fullRoleResponse.data.permissions.find(
                                (x) => x.id === item.id
                            )
                        ) {
                            // If found, add the permission to the "assigned" array
                            assigned.push(item);
                        } else {
                            // If not found, add the permission to the "unassigned" array
                            unassigned.push(item);
                        }
                    });

                    // Sort the assigned and unassigned arrays alphabetically by the 'name' property
                    assigned.sort((a, b) => a.name.localeCompare(b.name));
                    unassigned.sort((a, b) => a.name.localeCompare(b.name));

                    initialRoleState[permissionGroup.name] = {
                        assigned,
                        unassigned,
                        draggedItem: {},
                    };
                }
                setPermissionListBoxState(initialRoleState);
            })
            .catch((e) =>
                console.error("Failed to load selected role from API", e)
            );
    }, [selectedRole, allPermissions]);

    const getAssignedPermissionsCountForGroup = (groupId) => {
        const groupState = permissionListBoxState[groupId];
        return groupState ? groupState.assigned.length : 0;
    };

    const value = {
        permissionListBoxState,
        setPermissionListBoxStateForPermissionGroupId,
        allPermissions,
        setAllPermissions,
        selectedRole,
        setSelectedRole,
        initialRolePermissions,
        setInitialRolePermissions,
        permissionGroups,
        getAssignedPermissionsCountForGroup,
    };

    return (
        <PermissionContext.Provider value={value}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionListBoxProvider;
