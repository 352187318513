import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * @function getAllPermissions
 * @description Function to get all permissions
 * @returns {Promise<ServiceResponse>}
 */
export const getAllPermissions = async () => {
    try {
        const response = await axios.get("/Authentication/Permission/GetAll");
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllPermissions
 * @description Function to get all permissions
 * @returns {Promise<ServiceResponse>}
 */
export const getRole = async (roleId) => {
    try {
        const response = await axios.get(
            `/Authentication/Role/Get?id=${roleId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updatePermissionsRole
 * @description Function to update permissions for RoleDashboardPage
 * @returns {Promise<ServiceResponse>}
 */
export const updatePermissionsRole = async (roleId, assignedPermissionIds) => {
    try {
        const requestData = {
            permissionIds: assignedPermissionIds,
            roleId: roleId,
        };

        const response = await axios.post(
            `/Authentication/Permission/Toggle`,
            requestData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
