import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { PaymentContext } from "../../../../providers/Deprecated/Customer/PaymentProvider";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import clone from "just-clone";
import {
    createPayment,
    disbursePayment,
    updatePayment,
} from "../../../../services/Deprecated/customer/paymentServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import {
    FormDateInput,
    FormDropDown,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import {
    dateValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const PaymentFormModal = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const customerContext = useContext(CustomerContext);
    const reloadDataContext = useContext(ReloadDataContext);
    const paymentContext = useContext(PaymentContext);
    const jobContext = useContext(JobContext);
    const [initialValues, setInitialValues] = useState({});

    //? Set the initial values of the form to the record passed in.
    useEffect(() => {
        let recordCopy = clone(record) ?? {};
        recordCopy.date = recordCopy.date
            ? dayjs(recordCopy.date).format("MM/DD/YYYY")
            : null;

        if (recordCopy.type) {
            const typeObject = paymentContext.paymentTypes.find(
                (type) => type.name === recordCopy.type
            );
            if (typeObject) {
                recordCopy.type = typeObject;
            }
        }

        setInitialValues(recordCopy);
    }, [record, paymentContext.paymentTypes]);

    /**
     * @function onSubmit
     * @description Handles submit of the form. For both Edit and Create.
     * @param dataItem
     */
    const onSubmit = async (dataItem) => {
        toggleLoaderVisible();

        //! This will only work over localhost, or over HTTPS
        if (!dataItem.id) dataItem.id = crypto.randomUUID();
        dataItem.customerId = customerContext.customer.id;
        dataItem.total.currency = "USD";

        if (dataItem.date) {
            dataItem.date = dayjs(dataItem.date, "MM/DD/YYYY").format(
                "YYYY-MM-DD"
            );
        }
        dataItem.type = dataItem.type.name;

        //? The payment is being created
        if (!initialValues.id) {
            await handlePaymentCreate(dataItem);
            //? The payment is being updated
        } else {
            updatePayment(dataItem).then(() => {
                finalizeSubmission();
            });
        }
    };

    /**
     * Handle creation of payment.
     * If the payment is not a job payment, it calls the `createPayment` function with the given data item.
     * Else it creates the customer payment, then fully disburses it to the current job
     * Finally, it calls the `finalizeSubmission` function.
     * @param {Object} dataItem - The data item used for creating payment.
     * @returns {Promise<void>} A promise that resolves when the payment creation and submission finalization is done.
     */
    const handlePaymentCreate = async (dataItem) => {
        const result = await createPayment(dataItem);

        //? Fully disburse payment to job
        if (paymentContext.isJobPayment) {
            const disbursingPayment = {
                paymentId: result.data.id,
                amount: dataItem.total.amount,
                jobId: jobContext.job?.id,
            };

            await disbursePayment(disbursingPayment);
        }

        finalizeSubmission();
    };

    /**
     * @function finalizeSubmission
     * @description Finalizes the submission of the form. Reloads the data and closes the modal.
     */
    const finalizeSubmission = () => {
        reloadDataContext.triggerReload();
        toggleLoaderVisible();
        onCancel();
    };

    /**
     * Handles changes in the amount input field.
     * If the entered value is less than 0, it triggers a change in the form's "type" field to "Refund".
     * @param {object} c - The event object containing the amount value.
     * @param {object} formRenderProps - The props object from the form rendering component.
     * @returns {void}
     */
    const onAmountChange = (c, formRenderProps) => {
        const value = c.value;

        if (value < 0) {
            formRenderProps.onChange("type", {
                value: "Refund",
            });
        }
    };

    return (
        <div>
            {visible && (
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={
                                !initialValues.id
                                    ? "Create Payment"
                                    : "Edit Payment"
                            }
                            onClose={onCancel}
                            size={"medium"}
                        >
                            <FormElement>
                                <Field
                                    name={"date"}
                                    component={FormDateInput}
                                    label={"Date:"}
                                    validator={dateValidator}
                                    hint={"MM/DD/YYYY"}
                                />
                                {!initialValues.id && (
                                    <Field
                                        name={"total.amount"}
                                        component={FormNumericInput}
                                        format={"c2"}
                                        label={"Amount:"}
                                        validator={requiredValidator}
                                        onChange={(c) =>
                                            onAmountChange(c, formRenderProps)
                                        }
                                    />
                                )}
                                <Field
                                    name={"type"}
                                    component={FormDropDown}
                                    data={paymentContext.paymentTypes}
                                    textField="name"
                                    dataItemKey="id"
                                    label={"Type:"}
                                    validator={requiredValidator}
                                />
                                <div style={{ marginTop: 0.5 }}>
                                    <div style={{ textAlign: "left" }}>
                                        <h3
                                            style={{
                                                margin: "0",
                                                marginTop: "8px",
                                                marginBottom: "8px",
                                                borderBottom: "1px solid #ccc",
                                                paddingBottom: "5px",
                                                width: "55%",
                                            }}
                                        >
                                            Details:
                                        </h3>
                                    </div>
                                    <Field
                                        name={"details"}
                                        component={FormTextArea}
                                        autoSize={true}
                                        rows={4}
                                        label={"Details:"}
                                    />
                                </div>
                                {!loaderVisible ? (
                                    <div className="k-form-buttons">
                                        <Button
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={onCancel}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            themeColor={
                                                !initialValues.id
                                                    ? "success"
                                                    : "info"
                                            }
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                            icon={
                                                "k-icon" +
                                                (!initialValues.id
                                                    ? " k-i-plus"
                                                    : " k-i-save")
                                            }
                                            disabled={
                                                !formRenderProps.allowSubmit
                                            }
                                        >
                                            {!initialValues.id
                                                ? "Create"
                                                : "Update"}
                                        </Button>
                                    </div>
                                ) : (
                                    <CenterLoader />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default PaymentFormModal;
