import React from "react";
import { GridHeaderCell } from "@progress/kendo-react-grid";
import QuestionHint from "./QuestionHint";

export const HintHeaderCustomCell = ({ props, hint, position }) => {
    return (
        <GridHeaderCell {...props}>
            {props.children}
            <div
                style={{
                    marginLeft: "10px",
                }}
            >
                <QuestionHint hint={hint} position={position} />
            </div>
        </GridHeaderCell>
    );
};
