import * as React from "react";

const BalanceSheetTLTotalHeaderCell = (props) => {
    const { title } = props;

    return (
        <span
            style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: "10px",
                fontWeight: 1000,
            }}
        >
            {title}
        </span>
    );
};

export default BalanceSheetTLTotalHeaderCell;
