import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * Create Customer Communication.
 * @function createCustomerCommunication
 * @async
 * @param {any} communicationObject
 * @returns {Promise<ServiceResponse>}
 */
export const createCustomerCommunication = async (communicationObject) => {
    try {
        const response = await axios.post(
            `/Customer/Customer/Communication/Create`,
            communicationObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Update Customer Communication.
 * @function updateCustomerCommunication
 * @async
 * @param {any} communicationEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateCustomerCommunication = async (communicationEditObject) => {
    try {
        const response = await axios.put(
            `/Customer/Customer/Communication/Update`,
            communicationEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
