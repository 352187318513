export default class StringHelper {
    /**
     * @function isNullOrEmpty
     * @description Check if a string is null or empty
     * @param value {string} - The string to check
     * @return {boolean}
     */
    static isNullOrEmpty(value) {
        return value === null || value === undefined || value === "";
    }

    /**
     * @function addSpacesToCapitalLetters
     * @description Add spaces before capital letters in a string
     * @param value {string} - The string to modify
     * @return {string}
     */
    static addSpacesToCapitalLetters(value) {
        return value.replace(/([A-Z])/g, " $1").trim();
    }
}
