import { ContactType } from "../../../../resources/Enums/ContactType";
import ContactHelper from "../../../../resources/ContactHelper";

export class LeadModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        let data = {
            id: dataItem.id,
            name1: dataItem.name1,
            name2: dataItem.name2,
            address: dataItem.address,
            sourceId: dataItem.source.id,
            referrerId: dataItem.referrer?.id,
            type: dataItem.type,
            contacts: [],
        };

        data.contacts.push(
            ContactHelper.build(
                dataItem.primaryContact,
                ContactType.Enum.Primary
            )
        );

        if (dataItem.secondaryContact?.name1) {
            data.contacts.push(
                ContactHelper.build(
                    dataItem.secondaryContact,
                    ContactType.Enum.Secondary
                )
            );
        }

        for (let i = 0; i < dataItem.otherContacts?.length ?? 0; i++) {
            let contact = dataItem.otherContacts[i];

            if (!contact.name1) {
                continue;
            }

            data.contacts.push(
                ContactHelper.build(contact, ContactType.Enum.Other)
            );
        }

        return data;
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
        };
    }

    /**
     * @function buildSearchQuery
     * @description Build a search query
     * @param dataItem {Object} - The data item
     * @return {string}
     */
    static buildSearchQuery(dataItem) {
        return `${dataItem.primaryContact.phone?.phoneNumber} ${dataItem.name2} ${dataItem.primaryContact.email}`;
    }
}
