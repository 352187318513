import React, { useState } from "react";
import { Card, TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import BusinessInformationDashboardPage from "./BusinessInformations/BusinessInformationDashboardPage";
import CommunicationTypeDashboardPage from "./CommunicationTypes/CommunicationTypeDashboardPage";
import SourceDashboardPage from "./Sources/SourceDashboardPage";
import EventTypeDashboardPage from "./EventTypes/EventTypeDashboardPage";
import { ContentHeader } from "../../../../components/ContentHeader";
import TaskCategoryDashboardPage from "./TaskCategories/TaskCategoryDashboardPage";
import TaskDashboardPage from "./Tasks/TaskDashboardPage";

const CustomerTabPage = () => {
    const [selected, setSelected] = useState(0);

    return (
        <div>
            <Card style={{ border: "none" }}>
                <ContentHeader
                    title="Customer Module Configuration"
                    className={"AlignTextLeft"}
                />
                <br />
                <TabStrip
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    className="tabs"
                >
                    <TabStripTab title="Business Informations">
                        <BusinessInformationDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Communication Types">
                        <CommunicationTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Sources">
                        <SourceDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Event Types">
                        <EventTypeDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Categories (Skill-Sets)">
                        <TaskCategoryDashboardPage />
                    </TabStripTab>
                    <TabStripTab title="Tasks (Skills)">
                        <TaskDashboardPage />
                    </TabStripTab>
                </TabStrip>
            </Card>
        </div>
    );
};

export default CustomerTabPage;
