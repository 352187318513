import * as React from "react";
import "../../../styles/CommonPositionalStyles.scss";
import "../../../styles/CommonTextStyles.scss";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import "../reporting.module.scss";
import QuestionHint from "../../../components/Deprecated/QuestionHint";
import { useModal } from "../../../hooks/useModal";
import TrafficReportDetails from "./TrafficReportDetails";

const ReportItem = (props) => {
    const { title, subTitle, data, onClick, reportType } = props;

    return (
        <>
            <GridLayoutItem col={1} colSpan={5}>
                <span
                    className={"JustifyLeftAlignCenter SmallGap"}
                    style={{ marginLeft: "5px" }}
                >
                    <QuestionHint hint={subTitle} position={"top"} />
                    <p style={{ margin: 0 }}>{title}</p>
                </span>
            </GridLayoutItem>
            <GridLayoutItem col={1}>
                <a
                    className={"Link"}
                    onClick={() => onClick("Week2Date", reportType)}
                >
                    {data.weekToDate}
                </a>
            </GridLayoutItem>
            <GridLayoutItem col={2}>
                <a
                    className={"Link"}
                    onClick={() => onClick("Month2Date", reportType)}
                >
                    {data.monthToDate}
                </a>
            </GridLayoutItem>
            <GridLayoutItem col={3}>
                <a
                    className={"Link"}
                    onClick={() => onClick("Quarter2Date", reportType)}
                >
                    {data.quarterToDate}
                </a>
            </GridLayoutItem>
            <GridLayoutItem col={4}>
                <a
                    className={"Link"}
                    onClick={() => onClick("Year2Date", reportType)}
                >
                    {data.yearToDate}
                </a>
            </GridLayoutItem>
            <GridLayoutItem col={5}>
                <a
                    className={"Link"}
                    onClick={() => onClick("LastYear", reportType)}
                >
                    {data.lastYear}
                </a>
            </GridLayoutItem>
        </>
    );
};

const TrafficReport = (props) => {
    const { data } = props;
    const detailsModal = useModal();

    /**
     * Opens the modal for the provided date range and record type.
     * @throws ReferenceError if TimeFrame is undefined
     * @throws ReferenceError if ReportType is undefined
     * @param dateRange
     * @param reportType
     */
    const onClick = (dateRange, reportType) => {
        if (!dateRange) {
            throw new ReferenceError(
                "DateRange is a required prop for `onClick` event."
            );
        }

        if (!reportType) {
            throw new ReferenceError(
                "ReportType is a required prop for `onClick` event."
            );
        }

        detailsModal.open(undefined, {
            dateRange: dateRange,
            reportType: reportType,
        });
    };

    return data !== undefined ? (
        <div id={"GridContainer"}>
            {detailsModal.visible && <TrafficReportDetails {...detailsModal} />}
            <GridLayout
                cols={[
                    {
                        width: "auto",
                    },
                    {
                        width: "auto",
                    },
                    {
                        width: "auto",
                    },
                    {
                        width: "auto",
                    },
                    {
                        width: "auto",
                    },
                ]}
            >
                <GridLayoutItem
                    className={"GridHeader"}
                    col={1}
                    style={{ marginLeft: "10px" }}
                >
                    <p>WEEK TO DATE</p>
                </GridLayoutItem>
                <GridLayoutItem className={"GridHeader"} col={2}>
                    <p>MONTH TO DATE</p>
                </GridLayoutItem>
                <GridLayoutItem className={"GridHeader"} col={3}>
                    <p>QUARTER TO DATE</p>
                </GridLayoutItem>
                <GridLayoutItem className={"GridHeader"} col={4}>
                    <p>YEAR TO DATE</p>
                </GridLayoutItem>
                <GridLayoutItem
                    className={"GridHeader"}
                    col={5}
                    style={{ marginRight: "10px" }}
                >
                    <p>LAST YEAR</p>
                </GridLayoutItem>
                <ReportItem
                    title={"Prospects"}
                    subTitle={"Info: Data is missing from Customer"}
                    data={data.prospects}
                    onClick={onClick}
                    reportType={"Prospects"}
                />
                <ReportItem
                    title={"Active Customer"}
                    subTitle={
                        "Info: No Estimate–There is a JOB and nothing more"
                    }
                    data={data.activeCustomersNoEst}
                    onClick={onClick}
                    reportType={"ActiveCustomersNoEst"}
                />
                <ReportItem
                    title={"Active Not Sold Customer"}
                    subTitle={"Info: Not Sold–There are jobs with estimates"}
                    data={data.activeCustomersNotSold}
                    onClick={onClick}
                    reportType={"ActiveCustomersNotSold"}
                />
                <ReportItem
                    title={"Active Sold Customer"}
                    subTitle={
                        "Info: There is at least one Sales Order in any job for these customers"
                    }
                    data={data.activeCustomersSold}
                    onClick={onClick}
                    reportType={"ActiveCustomersSold"}
                />
                <ReportItem
                    title={"Completed Customer"}
                    subTitle={
                        "Info: All jobs have been Archived or Marked Dead"
                    }
                    data={data.completedJobs}
                    onClick={onClick}
                    reportType={"CompletedJobs"}
                />
            </GridLayout>
        </div>
    ) : (
        <div style={{ margin: "25px" }}>
            <CenterLoader />
        </div>
    );
};

export default TrafficReport;
