import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class ItemPackageService {
    static async get(packageId) {
        const response = await axios.get(
            `/Inventory/ItemPackage/Get?id=${packageId}`
        );
        return formatServiceResponse(response);
    }

    static async create(packageData) {
        const response = await axios.post(
            "/Inventory/ItemPackage/Create",
            packageData
        );
        return formatServiceResponse(response);
    }

    static async update(packageData) {
        const response = await axios.put(
            "/Inventory/ItemPackage/Update",
            packageData
        );
        return formatServiceResponse(response);
    }

    static async updateInLineGrid(item) {
        const res = await axios.put(
            `/Inventory/ItemPackage/UpdateInLineGrid`,
            item
        );
        return formatServiceResponse(res);
    }

    static async delete(packageId) {
        const response = await axios.delete(
            `/Inventory/ItemPackage/Delete?id=${packageId}`
        );
        return formatServiceResponse(response);
    }
}
