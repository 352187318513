import React, { useContext } from "react";
import style from "../UserDetails.module.scss";
import FormField from "../../../../../components/Deprecated/StylingField";
import { UserContext } from "../../../../../providers/Deprecated/Authentication/User/UserProvider";
import { CenterLoader } from "../../../../../components/Deprecated/CenterLoader";

const UserDetailsCard = () => {
    const userContext = useContext(UserContext);
    /** @type {User} */
    const user = userContext.user;

    return user ? (
        <div className={style.UserDetailsCardFullContainer}>
            {/* Column 1 */}
            <div className={style.UserDetailsCardHalfContainer}>
                <FormField label="First Name" value={user.firstName} />
                <FormField label="Last Name" value={user.lastName} />
            </div>
            {/* Column 2 */}
            <div className={style.UserDetailsCardHalfContainer}>
                <FormField label="Primary Email" value={user.primaryEmail} />
                <FormField label="Current Role" value={user.role.name} />
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};
export default UserDetailsCard;
