import React, { useCallback } from "react";
import styles from "./Styles/CustomerDetails.module.scss";
import { ContentHeader } from "../../../../../components/ContentHeader.jsx";
import FormField from "../../../../../components/Deprecated/StylingField.jsx";
import { CustomerTypeEnum } from "../../../../../resources/Enums/CustomerStatusEnum.js";
import { addressFormatter } from "../../../../../resources/Deprecated/helpers.js";

/**
 * @function CustomerDetails
 * @param props.customer {Object}
 * @param props.includeContactInformation {boolean} - Include contact information in the details
 * @return {Element}
 * @constructor
 */
const CustomerDetails = (props) => {
    const { customer, includeContactInformation } = props;

    const generateHeaderTitle = useCallback(() => {
        if (!customer) return "Not Assigned";

        if (customer.type === CustomerTypeEnum.Enum.Business) return "Company";

        return "Customer";
    }, [customer]);

    return (
        <div className={styles.InformationContainer}>
            <span className={styles.InformationColumn}>
                <ContentHeader
                    title={generateHeaderTitle()}
                    className={styles.InformationColumnHeader}
                />
                <FormField
                    label="Customer Name"
                    value={customer?.combinedName ?? "N/A"}
                />
                <FormField
                    label="Address"
                    value={
                        customer?.address
                            ? addressFormatter(customer?.address, "short")
                            : "N/A"
                    }
                />
                <FormField
                    label="City"
                    value={customer?.address?.city ?? "N/A"}
                />
                <FormField
                    label="State"
                    value={customer?.address?.state ?? "N/A"}
                />
                <FormField
                    label="Zip"
                    value={customer?.address?.zipCode ?? "N/A"}
                />
            </span>
            {includeContactInformation && (
                <span className={styles.InformationColumn}>
                    <ContentHeader
                        title={"Primary Contact"}
                        className={styles.InformationColumnHeader}
                    />
                    <FormField
                        label="Name"
                        value={customer?.primaryContact?.combinedName ?? "N/A"}
                    />
                    <FormField
                        label="Email"
                        value={customer?.primaryContact?.email ?? "N/A"}
                    />
                    <FormField
                        label="Phone"
                        value={
                            customer.primaryContact?.phone
                                ? `(${customer.primaryContact.phone.phoneNumberType.name}) ${customer.primaryContact.phone.phoneNumber}`
                                : "N/A"
                        }
                    />
                </span>
            )}
            {includeContactInformation && customer?.secondaryContact && (
                <span className={styles.InformationColumn}>
                    <ContentHeader
                        title={"Secondary Contact"}
                        className={styles.InformationColumnHeader}
                    />
                    <FormField
                        label="Name"
                        value={
                            customer?.secondaryContact?.combinedName ?? "N/A"
                        }
                    />
                    <FormField
                        label="Email"
                        value={customer?.secondaryContact?.email ?? "N/A"}
                    />
                    <FormField
                        label="Phone"
                        value={
                            customer.secondaryContact?.phone
                                ? `(${customer.secondaryContact.phone.phoneNumberType.name}) ${customer.secondaryContact.phone.phoneNumber}`
                                : "N/A"
                        }
                    />
                </span>
            )}
        </div>
    );
};

export default CustomerDetails;
