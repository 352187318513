import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { getInitialDataQueryState } from "../../../utilities/kendoHelper";

export default class ItemCustomizationService {
    /**
     * Updates the List Positions.
     * @function reorderIndexPositions
     * @async
     * @param {object} putReorderIndexPositions
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async reorderIndexPositions(putReorderIndexPositions) {
        return await axios.put(
            "/Inventory/CustomizationList/ReorderIndexPositions",
            putReorderIndexPositions
        );
    }

    /**
     * Gets all CustomizationLists by Category
     * @async
     * @param {string} categoryId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getAllCustomizationListsByCategory(categoryId) {
        let kendoRequest = getInitialDataQueryState();
        kendoRequest.sort = [
            {
                field: "indexPosition",
                dir: "asc",
            },
        ];
        kendoRequest.filter = {
            logic: "and",
            filters: [
                {
                    field: "categoryId",
                    operator: "eq",
                    value: categoryId,
                },
            ],
        };
        const queryParams = toDataSourceRequestString(kendoRequest);
        return await axios.get(
            `/Inventory/CustomizationList/GetAll?${queryParams}`
        );
    }

    /**
     * Deletes the provided CustomizationList by Id
     * @async
     * @param {string} customizationListId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deleteCustomizationList(customizationListId) {
        return await axios.delete(
            `/Inventory/CustomizationList/Delete?id=${customizationListId}`
        );
    }

    /**
     * Creates CustomizationList
     * @async
     * @param {object} listCreationObject
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createCustomizationList(listCreationObject) {
        return await axios.post(
            "/Inventory/CustomizationList/Create",
            listCreationObject
        );
    }

    /**
     * Updates CustomizationList
     * @async
     * @param {object} listUpdateObject
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateCustomizationList(listUpdateObject) {
        return await axios.put(
            "/Inventory/CustomizationList/Update",
            listUpdateObject
        );
    }

    /**
     * Deletes customization
     * @async
     * @param {string} customizationId
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async deleteCustomization(customizationId) {
        return await axios.delete(
            `/Inventory/Customization/Delete?id=${customizationId}`
        );
    }

    /**
     * Creates Customization
     * @async
     * @param {object} customizationCreationObject
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async createCustomization(customizationCreationObject) {
        return await axios.post(
            "/Inventory/Customization/Create",
            customizationCreationObject
        );
    }

    /**
     * Updates Customization
     * @async
     * @param {object} customizationUpdateObject
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async updateCustomization(customizationUpdateObject) {
        return await axios.put(
            "/Inventory/Customization/Update",
            customizationUpdateObject
        );
    }

    /**
     * Gets all Customizations by Customization List Id
     * @async
     * @param {string} customizationListId
     * @returns {Promise<ServiceResponse>}
     */
    static async getAllCustomizationsByCustomizationList(customizationListId) {
        if (!customizationListId) {
            return formatServiceResponse({
                status: 200,
                data: { data: [] },
            });
        }
        return formatServiceResponse(
            await axios.get(
                `/Inventory/Customization/GetAll?filter=(customizationListId~eq~'${customizationListId}')`
            )
        );
    }
}
