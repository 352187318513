import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styles from "./MyWorkTask.module.scss";
import { MyWorkContext } from "../../MyWorkContext";
import PermissionButton from "../../../../../components/Deprecated/PermissionButton";
import { Loader } from "@progress/kendo-react-indicators";

export const MyWorkTask = (props) => {
    const { timeClockHandler, switchTimeClockFocus } =
        useContext(MyWorkContext);
    const [isStarted, setIsStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { title, subTitle, clockTimeRecord } = props;

    useEffect(() => {
        const lastTimeClock = timeClockHandler.lastTimeClock;

        // Check to see if the current task is the same as the last time clock, if so its started
        if (lastTimeClock) {
            const onCurrentJob =
                lastTimeClock.job?.id === clockTimeRecord.job?.id;
            const onCurrentTask =
                lastTimeClock.task?.id === clockTimeRecord.task?.id;
            const onCurrentCalendarEvent =
                lastTimeClock.calendarEvent?.id ===
                clockTimeRecord.calendarEvent?.id;

            setIsStarted(
                onCurrentJob && onCurrentTask && onCurrentCalendarEvent
            );
            setIsLoading(false);
        }
    }, [timeClockHandler.lastTimeClock]);

    // Switch the current time clock to the task
    const handleStartTask = () => {
        setIsLoading(true);
        switchTimeClockFocus(
            clockTimeRecord.job,
            clockTimeRecord.task,
            clockTimeRecord.calendarEvent
        );
    };

    // End the current task
    const handleEndTask = () => {
        setIsLoading(true);
        switchTimeClockFocus(
            clockTimeRecord.job,
            null,
            clockTimeRecord.calendarEvent
        );
    };

    const actionDisplay = () => {
        if (isLoading) {
            return (
                <Loader
                    type={"infinite-spinner"}
                    style={{ paddingRight: "30px" }}
                />
            );
        } else if (isStarted) {
            return (
                <PermissionButton
                    requiredPermissions={[]}
                    themeColor={"primary"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    onClick={handleEndTask}
                    style={{
                        fontSize: "1.2rem",
                        width: "100px",
                    }}
                >
                    End
                </PermissionButton>
            );
        } else {
            return (
                <PermissionButton
                    requiredPermissions={[]}
                    themeColor={"success"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    onClick={handleStartTask}
                    style={{
                        fontSize: "1.2rem",
                        width: "100px",
                    }}
                >
                    Start
                </PermissionButton>
            );
        }
    };

    return (
        <div className={styles.TaskContainer + " JustifyBetweenAlignCenter"}>
            <span
                className={
                    "JustifyCenterAlignLeft FlexColumn text-overflow AlignTextLeft"
                }
            >
                <h4>{title}</h4>
                <small>{subTitle}</small>
            </span>
            {actionDisplay()}
        </div>
    );
};
