import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { FormNumericInput } from "../../../../components/Deprecated/FormComponents";
import BaseService from "../../../../services/BaseService";

/**
 * @function InventoryDeletionModal
 * @param props.close {function} - Function to close the modal.
 * @param props.visible {boolean} - Whether the modal is visible.
 * @return {unknown}
 * @constructor
 */
export const InventoryDeletionModal = (props) => {
    const { record, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!visible || !record) return;

        setInitialFormData(record);
    }, [visible]);

    const onClose = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
        close();
    };

    const onSubmit = async (props) => {
        setLoaderVisible(true);

        const values = props.values;

        const service = new BaseService("Inventory/Inventory");
        for (let i = 0; i < values.quantity; i++) {
            await service.delete(record.inventoryIds[i]);
        }

        // This is for now, need to talk to Will
        // const promises = [];
        // for (let i = 0; i < values.quantity; i++) {
        //     promises.push(service.delete(record.inventoryIds[i]));
        // }
        // await Promise.all(promises);

        triggerReload();
        onClose();
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={"Delete Inventory"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <Field
                                    name={"quantity"}
                                    component={FormNumericInput}
                                    label={"Quantity:"}
                                    min={1}
                                    max={initialFormData.quantity}
                                />
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                theme={"error"}
                                buttonText={"Delete"}
                                isCreate={false}
                                icon={"k-icon k-i-trash"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
