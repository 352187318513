import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
    checkRequiredPermissions,
    getUserPermissions,
} from "../../resources/Deprecated/permissionsHelper.js";

const PermissionButton = ({
    onClick,
    requiredPermissions = [],
    children,
    disabled,
    disabledToolTipMessage,
    toolTipPosition = "top",
    ...props
}) => {
    const userPermissions = getUserPermissions() ?? [];
    const hasRequiredPermissions = checkRequiredPermissions(
        userPermissions,
        requiredPermissions
    );

    let tooltipMessage = "";
    if (!hasRequiredPermissions && !disabled) {
        tooltipMessage = "Tip: You do not have this permission.";
    } else if (disabled && disabledToolTipMessage) {
        tooltipMessage = disabledToolTipMessage;
    }

    return (
        <div>
            {(disabled || !hasRequiredPermissions) && (
                <Tooltip anchorElement="target" position={toolTipPosition}>
                    <span title={tooltipMessage}>
                        <Button
                            disabled={disabled || !hasRequiredPermissions}
                            onClick={onClick}
                            {...props}
                        >
                            {children}
                        </Button>
                    </span>
                </Tooltip>
            )}

            {!disabled && hasRequiredPermissions && (
                <Button onClick={onClick} {...props}>
                    {children}
                </Button>
            )}
        </div>
    );
};
export default PermissionButton;
