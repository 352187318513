import React, { useContext, useRef } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { CommunicationModal } from "../../../Communications/Components/CommunicationModal";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../../../components/Grids/DataGrid";
import { ActionCell } from "../../../../../components/GridColumnComponents/ActionCell";
import BaseService from "../../../../../services/BaseService";
import { ReloadDataContext } from "../../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../../hooks/useModal";

const LeadCommunicationsTab = (lead) => {
    const service = useRef(new BaseService("Customer/Lead/Communication"));
    const { triggerReload } = useContext(ReloadDataContext);
    const communicationModal = useModal();

    const onDelete = (id) => {
        service.current.delete(id).then(() => {
            triggerReload();
        });
    };

    return (
        lead && (
            <div className={"JustifyLeftAlignLeft FlexColumn MediumGap"}>
                <CommunicationModal {...communicationModal} />
                <ActionButton
                    buttonText={"Create Communication"}
                    icon={"k-icon k-i-plus"}
                    onClick={() =>
                        communicationModal.open(undefined, { lead: lead })
                    }
                    permissions={[PermissionsEnum.CreateLeadCommunication]}
                />
                <DataGrid
                    sortable={false}
                    style={{
                        maxWidth: 1050,
                    }}
                    uri={`/Customer/Lead/Communication/GetAll?leadId=${lead.id}&`}
                >
                    <GridColumn
                        field={"note"}
                        title={"Note"}
                        width={450}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field={"communicationType.name"}
                        title={"Type"}
                        width={200}
                    />
                    <GridColumn
                        field={"employeeWhoCreated.name"}
                        title={"Created By"}
                        className={"text-overflow"}
                        width={200}
                    />
                    <GridColumn
                        width={180}
                        cell={(props) => (
                            <ActionCell
                                onDelete={() => onDelete(props.dataItem.id)}
                                onEdit={() =>
                                    communicationModal.open(props.dataItem.id)
                                }
                                permissions={{
                                    edit: [
                                        PermissionsEnum.UpdateLeadCommunication,
                                    ],
                                    delete: [
                                        PermissionsEnum.DeleteLeadCommunication,
                                    ],
                                }}
                            />
                        )}
                    />
                </DataGrid>
            </div>
        )
    );
};

export default LeadCommunicationsTab;
