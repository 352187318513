// Import axios library
import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * Add Location
 * @function addLocation
 * @async
 * @param {any} locationData
 * @returns {Promise<ServiceResponse>}
 */
export const addLocation = async (locationData) => {
    try {
        // Send a POST request to the server with the location data
        const response = await axios.post(
            "/HumanResources/Location/Create",
            locationData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Update Location
 * @function updateLocation
 * @async
 * @param locationData
 * @returns {Promise<ServiceResponse>}
 */
export const updateLocation = async (locationData) => {
    try {
        // Send a PUT request to the server with the location data
        const response = await axios.put(
            "/HumanResources/Location/Update",
            locationData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getLocation = async (locationId) => {
    try {
        const response = await axios.get(
            `/HumanResources/Location/Get?id=${locationId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves all locations for Employees
 * @function getAllLocations
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllLocations = async () => {
    try {
        const response = await axios.get(`/HumanResources/Location/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Deletes a location
 * @function deleteLocation
 * @async
 * @param {string} locationId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteLocation = async (locationId) => {
    try {
        // Send a DELETE request to the server with the tool ID
        const response = await axios.delete(
            `/HumanResources/Location/Delete?id=${locationId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
