// Import axios library
import {axiosInstance as axios} from "../../axiosInstance";
import {formatServiceResponse} from "../../../utilities/serviceUtility";

/**
 * Add Customer.
 * @function addCustomer
 * @async
 * @param {any} customerData
 * @returns {Promise<ServiceResponse>}
 */
export const addCustomer = async (customerData) => {
    try {
        const response = await axios.post(
            "/Customer/Customer/Create",
            customerData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Get Customer.
 * @function getCustomer
 * @async
 * @param {any} customerId
 * @returns {Promise<ServiceResponse>}
 */
export const getCustomer = async (customerId) => {
    try {
        const response = await axios.get(
            `/Customer/Customer/Get?id=${customerId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Search Customer.
 * @function searchCustomer
 * @async
 * @param {Object} customerData
 * @param {number} customerType
 * @returns {Promise<ServiceResponse>}
 */
export const searchCustomer = async (customerData, customerType) => {
    try {
        // Send a POST request to the server with the customer data
        const response = await axios.post(
            `/Customer/Customer/Search?q=${customerData}&type=${customerType}&`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to delete a customer
 * @function deleteCustomer
 * @async
 * @param {any} CustomerId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteCustomer = async (CustomerId) => {
    try {
        // Send a DELETE request to the server with the Customer ID
        const response = await axios.delete(
            `/Customer/Customer/Delete?id=${CustomerId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to get all customers.
 * @function getAllCustomers
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllCustomers = async () => {
    try {
        const response = await axios.get(`/Customer/Customer/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to update a customer
 * @function updateCustomer
 * @async
 * @param {any} customerEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateCustomer = async (customerEditObject) => {
    try {
        const response = await axios.put(
            "/Customer/Customer/Update",
            customerEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Create Lead.
 * @function addLeadSourceType
 * @async
 * @param {any} leadSourceData
 * @returns {Promise<ServiceResponse>}
 */
export const addLeadSourceType = async (leadSourceData) => {
    try {
        const response = await axios.post(
            "/Customer/Source/Create",
            leadSourceData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to update a lead source
 * @function updateLeadTypeSourceType
 * @async
 * @param {any} leadSourceEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateLeadTypeSourceType = async (leadSourceEditObject) => {
    try {
        const response = await axios.put(
            "/Customer/Source/Update",
            leadSourceEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to delete a lead source type
 * @function deleteLeadSourceType
 * @async
 * @param {any} leadSourceId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteLeadSourceType = async (leadSourceId) => {
    try {
        // Send a DELETE request to the server with the Customer ID
        const response = await axios.delete(
            `/Customer/Source/Delete?id=${leadSourceId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Create Lead.
 * @function addLeadType
 * @async
 * @param {any} leadData
 * @returns {Promise<ServiceResponse>}
 */
export const addCommunicationReminder = async (leadData) => {
    try {
        const response = await axios.post(
            "/Customer/CommunicationType/Create",
            leadData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to update a lead
 * @function updateCommunicationReminder
 * @async
 * @param {any} leadEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateCommunicationReminder = async (leadEditObject) => {
    try {
        const response = await axios.put(
            "/Customer/CommunicationType/Update",
            leadEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to delete a lead type
 * @function deleteLeadType
 * @async
 * @param {any} leadCommunicationTypeId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteCommunicationReminder = async (leadCommunicationTypeId) => {
    try {
        // Send a DELETE request to the server with the Customer ID
        const response = await axios.delete(
            `/Customer/CommunicationType/Delete?id=${leadCommunicationTypeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteCustomerCommunication
 * @description Function to delete a customer communication
 * @param {String} communicationId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteCustomerCommunication = async (communicationId) => {
    try {
        const response = await axios.delete(
            `/Customer/Customer/Communication/Delete?id=${communicationId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getLeadCommunication = async (leadCommunicationId) => {
    try {
        const response = await axios.get(
            `/Customer/Lead/Communication/Get?id=${leadCommunicationId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
