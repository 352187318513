import { useEffect, useRef } from "react";

/**
 * @function useDidMount
 * @description Returns a boolean indicating if the component has mounted
 * @return {boolean}
 */
export const useDidMount = () => {
    const isMountRef = useRef(false);
    useEffect(() => {
        isMountRef.current = true;
    }, []);
    return isMountRef.current;
};
