import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance.js";
import { formatServiceResponse } from "../utilities/serviceUtility.js";

/**
 * @class JobDocumentService
 * @extends BaseService
 * @description Service class that handles all requests to the job document service
 * @constructor
 */
export default class JobDocumentService extends BaseService {
    constructor() {
        super("Customer/Job/Document");
    }

    /**
     * @function changeType
     * @description Function to change the type of document
     * @param id {string}
     * @param status {NewJobDocumentType.Enum}
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async changeType(id, status) {
        const response = await axios.post(`${this.uri}/ChangeType?`, {
            documentId: id,
            type: status,
        });
        return formatServiceResponse(response);
    }
}
