import React, { useContext } from "react";
import { Field } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import { FormInput } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";

const VendorSocialMediaForm = ({ formRenderProps }) => {
    // Only reason this exists is to add and remove form fields
    // I know the initialState is ugly, but basically it's just an array of numbers
    // The numbers are used to create the form fields based on the amount of objects
    //
    const vendorContext = useContext(VendorContext);
    const [formCount, setFormCount] = React.useState([]);
    const [socialMedias, _setSocialMedias] = React.useState(
        vendorContext.relationships.socialMedias
    );

    /**
     Filters an array of social media objects based on the social media IDs present in another array of vendor social media objects.

     @param {Array<Object>} socialMediaArr - The array of social media objects to be filtered.
     @param {Array<{ socialMedia: { socialMediaId: string } }>} vendorSocialMediasArr - The array of vendor social media objects containing social media IDs.
     @returns {Array<Object>} - The filtered array of social media objects.
      }} dataItem - Dropdown Data from API.
     */
    function filterBySocialMediaId(socialMediaArr, vendorSocialMediasArr) {
        for (let i = 0; i < vendorSocialMediasArr.length; i++) {
            socialMediaArr = socialMediaArr.filter((socialMedia) => {
                return (
                    socialMedia.id !== vendorSocialMediasArr[i]?.socialMedia?.id
                );
            });
        }

        return socialMediaArr;
    }

    /**
     Retrieves filtered data based on the presence of vendor social media objects.

     @returns {Array<Object>} - The filtered array of social media objects.
     */
    function filteredData() {
        if (formRenderProps.valueGetter("vendorSocialMedias") !== undefined) {
            return filterBySocialMediaId(socialMedias, [
                ...Object.values(
                    formRenderProps.valueGetter("vendorSocialMedias")
                ),
            ]);
        } else {
            return socialMedias;
        }
    }

    /**
     * Adds a vendor social media form to the form count.
     */
    const addVendorSocialMedia = () => {
        const vendorSocialMedias =
            formRenderProps.valueGetter("vendorSocialMedias") || [];
        formRenderProps.onChange("vendorSocialMedias", {
            value: [...vendorSocialMedias, { socialMedia: {}, website: "" }],
        });
        setFormCount([...formCount, formCount.length]);
    };

    /**
     * Removes a vendor social media form from the form count.
     */
    const removeVendorSocialMedia = (indexToRemove) => {
        let vendorSocialMedias =
            formRenderProps.valueGetter("vendorSocialMedias") || [];
        vendorSocialMedias = [...vendorSocialMedias];
        vendorSocialMedias.splice(indexToRemove, 1);

        formRenderProps.onChange("vendorSocialMedias", {
            value: vendorSocialMedias,
        });
        setFormCount(vendorSocialMedias.map((_, index) => index));
    };

    return (
        <fieldset
            className={"k-form-fieldset"}
            style={{
                borderTop: "1px solid #ccc",
                paddingTop: "10px",
                marginTop: "20px",
            }}
        >
            <GridLayout
                rows={[{ height: "auto" }, { height: "auto" }]}
                cols={[{ width: "80%" }, { width: "20%" }]}
                gap={{
                    rows: "10px",
                    cols: "15px",
                }}
            >
                {formCount.map((_, index) => (
                    <React.Fragment key={index}>
                        <GridLayoutItem
                            col={1}
                            colSpan={1}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                            }}
                        >
                            <Field
                                name={`vendorSocialMedias[${index}].socialMedia`}
                                component={DropDownList}
                                data={filteredData()}
                                dataItemKey="id"
                                textField="name"
                                style={{ width: "30%" }}
                                label={"Social Media:"}
                            />
                            <Field
                                name={`vendorSocialMedias[${index}].website`}
                                component={FormInput}
                                style={{ width: "70%" }}
                                validator={requiredValidator}
                                label={"Website:"}
                            />
                        </GridLayoutItem>
                        <GridLayoutItem
                            col={2}
                            colSpan={1}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <a
                                style={{
                                    width: "75px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px 5px",
                                    backgroundColor: "#D51923",
                                    color: "#fff",
                                    textDecoration: "none",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    marginRight: "5px",
                                }}
                                onClick={() => removeVendorSocialMedia(index)}
                            >
                                <span
                                    className="k-icon k-i-minus"
                                    style={{ marginRight: "5px" }}
                                ></span>
                                Remove
                            </a>
                        </GridLayoutItem>
                    </React.Fragment>
                ))}
                <GridLayoutItem
                    col={2}
                    colSpan={2}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                    }}
                >
                    <a
                        style={{
                            width: "115px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px 10px",
                            backgroundColor: "#278200",
                            color: "#fff",
                            textDecoration: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginRight: "5px",
                        }}
                        onClick={addVendorSocialMedia}
                    >
                        <span
                            className="k-icon k-i-plus"
                            style={{ marginRight: "5px" }}
                        ></span>
                        Add Website
                    </a>
                </GridLayoutItem>
            </GridLayout>
        </fieldset>
    );
};
export default VendorSocialMediaForm;
