import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import {
    dateValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { DebounceUniqueFormField } from "../../../../components/Deprecated/DebounceFormComponents";
import {
    FormDateInput,
    FormInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    checkRequiredPermissions,
    getUserPermissions,
} from "../../../../resources/Deprecated/permissionsHelper";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";

const RelationshipForm = ({ formRenderProps, editMode }) => {
    const employeeContext = useContext(EmployeeContext);
    const relationships = employeeContext.relationships;

    const [filteredSupervisors, setFilteredSupervisors] = React.useState([]);
    const calculateMaxDate = () => {
        const currentDate = new Date();
        const maxYear = currentDate.getFullYear() - 16;
        return new Date(maxYear, currentDate.getMonth(), currentDate.getDate());
    };

    const maxDate = calculateMaxDate();
    const [payrollId, setPayrollId] = useState("");
    const [identificationNumber, setIdentificationNumber] = useState("");
    const max = 20;
    const handlePayrollIdChange = (e) => {
        setPayrollId(e.value);
    };

    const handleIdentificationNumberChange = (e) => {
        setIdentificationNumber(e.value);
    };

    useEffect(() => {
        const filteredSupervisorsArr = relationships?.employees?.filter(
            (employee) =>
                employee.id !== formRenderProps.valueGetter("employeeId")
        );
        setFilteredSupervisors(filteredSupervisorsArr);
    }, [formRenderProps.valueGetter("employeeId")]);

    const onChangeSupervisors = useCallback(
        (event) => {
            // Capture selected supervisor objects
            const selectedSupervisorObjects = event.value;

            // Update the form data with selected supervisors
            formRenderProps.onChange("employeeSupervisors", {
                value: selectedSupervisorObjects,
            });
        },
        [formRenderProps, relationships]
    );

    const selectedLocationList = React.useMemo(() => {
        // function that returns a subset of the relationships location data
        // that only includes actively selected locations
        return formRenderProps.valueGetter("locations");
    }, [formRenderProps]);

    const onChangeLocations = useCallback(() => {
        // when location multi select changes, unset primary location dropdown
        // if value doesn't exist in multiselect
        const locationMultiSelectValues =
            formRenderProps.valueGetter("locations");
        const primaryLocationValue =
            formRenderProps.valueGetter("primaryLocation");

        if (!locationMultiSelectValues.includes(primaryLocationValue)) {
            // Multiselect Location Does NOT Contain Primary Location ID
            if (locationMultiSelectValues.length) {
                formRenderProps.onChange("primaryLocation", {
                    value: locationMultiSelectValues[0],
                });
            } else {
                formRenderProps.onChange("primaryLocation", { value: null });
            }
        }
    }, [formRenderProps]);

    const ssnValidator = (value) => {
        const parsedVal = value?.replace(/[_-]/g, "");

        if (!parsedVal) {
            return "SSN is a required field.";
        }

        if (parsedVal.length < 9) {
            return "SSN must be 9 characters";
        }

        return "";
    };

    return (
        <GridLayout
            rows={[{ height: "50px" }, { height: "auto" }]}
            cols={[{ width: "48%" }, { width: "48%" }]}
            gap={{
                rows: "10px",
                cols: "15px",
            }}
        >
            <GridLayoutItem col={1} row={1} colSpan={2}>
                <div style={{ textAlign: "center" }}>
                    <h2
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                        }}
                    >
                        Details:
                    </h2>
                </div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1}>
                {/* This is the form fields for Labor Information */}
                <div style={{ textAlign: "left" }}>
                    <h3
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                        }}
                    >
                        Labor Info:
                    </h3>
                </div>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5, marginBottom: 5 }}
                >
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Field
                            name="laborType"
                            component={DropDownList}
                            label="Labor Type:"
                            data={relationships?.laborTypes}
                            dataItemKey="id"
                            textField="name"
                            validator={requiredValidator}
                            style={{ width: "30%" }}
                        />
                        <DebounceUniqueFormField
                            uri={"/HumanResources/Employee/PayrollIdExists"}
                            name={"payrollId"}
                            label={"Payroll ID:"}
                            bodyParameter={"payrollId"}
                            originalVal={useMemo(
                                () => formRenderProps.valueGetter("payrollId"),
                                []
                            )}
                            failureMsg={"PayrollId must be unique."}
                            validator={requiredValidator}
                            onChange={handlePayrollIdChange}
                            style={{
                                width: 200,
                            }}
                        />
                    </div>
                    <Hint direction={"end"}>
                        {payrollId.length} / {max}
                    </Hint>
                </fieldset>
                <div style={{ textAlign: "left" }}>
                    {/* This is the form fields for Sensitive Information */}

                    <h3
                        style={{
                            margin: "0",
                            marginTop: "8px",
                            marginBottom: "8px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                        }}
                    >
                        Personal:
                    </h3>
                </div>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Field
                            name="dateOfBirth"
                            component={FormDateInput}
                            validator={(value, valueGetter) =>
                                dateValidator(
                                    value,
                                    valueGetter,
                                    null,
                                    null,
                                    maxDate
                                )
                            }
                            label="Date of Birth:"
                            hint="MM/DD/YYYY"
                        />
                        {/**
                         Allow users to add a SSN on Employee creation.
                         However, only certain Users can view the SSN and update the SSN.
                         **/}
                        {(!editMode ||
                            checkRequiredPermissions(getUserPermissions(), [
                                PermissionsEnum.ViewSocialSecurityNumber,
                                PermissionsEnum.CreateEmployee,
                            ])) && (
                            <DebounceUniqueFormField
                                uri={"/HumanResources/Employee/SSNExists"}
                                name={"socialSecurityNumber"}
                                label={"SSN:"}
                                bodyParameter={"ssn"}
                                mask={"900-00-0000"}
                                originalVal={useMemo(
                                    () =>
                                        formRenderProps.valueGetter(
                                            "socialSecurityNumber"
                                        ),
                                    []
                                )}
                                failureMsg={"SSN must be unique."}
                                validator={ssnValidator}
                                style={{
                                    width: 200,
                                }}
                            />
                        )}
                    </div>
                    <Field
                        name="maritalStatus"
                        component={DropDownList}
                        label="Marital Status"
                        data={relationships.maritalStatus}
                        dataItemKey="id"
                        textField="name"
                        validator={requiredValidator}
                    />
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Field
                            name={"identificationType"}
                            component={DropDownList}
                            label={"ID Type"}
                            data={relationships?.identification}
                            dataItemKey="id"
                            textField="name"
                            validator={requiredValidator}
                            style={{ width: "40%" }}
                        />
                        <Field
                            name={"identificationNumber"}
                            component={FormInput}
                            label={"ID #:"}
                            validator={requiredValidator}
                            onChange={handleIdentificationNumberChange}
                            maxLength={max}
                            style={{ width: "140%" }}
                        />
                    </div>
                    <Hint direction={"end"}>
                        {identificationNumber.length} / {max}
                    </Hint>
                    <span
                        className={"JustifyLeftAlignLeft LargeGap FlexColumn"}
                    >
                        <Field
                            name={"allowedToDrive"}
                            component={Checkbox}
                            defaultChecked={false}
                            label={"Allowed To Drive"}
                        />
                        <Field
                            name={"isThirdShift"}
                            component={Checkbox}
                            defaultChecked={false}
                            label={"Works Third Shift"}
                        />
                    </span>
                </fieldset>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1}>
                {/* This is the form fields for Location Information */}
                <div style={{ textAlign: "left" }}>
                    <h3
                        style={{
                            margin: "0",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                        }}
                    >
                        Locations:
                    </h3>
                </div>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Field
                            name="locations"
                            component={MultiSelect}
                            label={"Employee Locations"}
                            data={relationships?.locations}
                            dataItemKey={"id"}
                            textField="name"
                            onChange={onChangeLocations}
                            validator={requiredValidator}
                            value={selectedLocationList}
                        />
                        <Field
                            name={"primaryLocation"}
                            component={DropDownList}
                            label={"Primary Location:"}
                            data={selectedLocationList}
                            validator={requiredValidator}
                            dataItemKey={"id"}
                            textField="name"
                        />
                    </div>
                </fieldset>
                <div style={{ textAlign: "left" }}>
                    <h3
                        style={{
                            margin: "0",
                            marginTop: "8px",
                            marginBottom: "8px",
                            borderBottom: "1px solid #ccc",
                            paddingBottom: "5px",
                            width: "55%",
                        }}
                    >
                        Supervisor(s):
                    </h3>
                </div>
                <fieldset
                    className={"k-form-fieldset"}
                    style={{ marginTop: 0.5 }}
                >
                    <Field
                        name={"supervisor"}
                        component={DropDownList}
                        data={filteredSupervisors}
                        dataItemKey={"id"}
                        textField="fullName"
                        placeholder="Employee Supervisor(s)"
                        onChange={onChangeSupervisors}
                    />
                </fieldset>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default RelationshipForm;
