export class AccountHelper {
    /**
     * @function flatmapAccounts
     * @description Maps over the root account and all of its children, returning a flattened array
     * of all the children accounts including their root account.
     * @param rootAccount
     * @typedef account
     * @return {Array<account>}
     */
    static flatmapAccounts(rootAccount) {
        let flatAccounts = [];

        flatAccounts.push(rootAccount);

        if (rootAccount.childAccounts?.length > 0) {
            rootAccount.childAccounts.forEach((childAccount) => {
                const flatChildAccounts = this.flatmapAccounts(childAccount);
                flatAccounts = flatAccounts.concat(flatChildAccounts);
            });
        }

        return flatAccounts;
    }

    /**
     * @function expandTreeList
     * @description Expands all parents with children nodes
     * @param rootAccount
     * @param {string} childrenPropertyName
     * @typedef balanceSheetLineRecord
     */
    static expandTreeList(rootAccount, childrenPropertyName) {
        if (rootAccount[childrenPropertyName]?.length > 0) {
            rootAccount[childrenPropertyName].forEach((childAccount) => {
                this.expandTreeList(childAccount, childrenPropertyName);
            });

            rootAccount.expanded = true;
        }
    }
}
