import React, { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { EventContext } from "../../../../providers/Deprecated/Customer/EventProvider";
import { createEventTaskCategory } from "../../../../services/Deprecated/customer/eventServices";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";

const EventTaskCategoryCreate = ({ taskCategoryDataImport }) => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const [filteredData, setFilteredData] = useState(undefined);
    const { triggerReload } = useContext(ReloadDataContext);
    const eventContext = useContext(EventContext);

    useEffect(() => {
        if (!eventContext.event.id) {
            return;
        }

        //? Grab the Task Category Ids
        //?
        const filteredIds = taskCategoryDataImport.map((taskCategory) => {
            return taskCategory.id;
        });

        const categories = eventContext.relationships.tasksAndCategories;

        //? Set the filtered data to the taskCategories that are not already assigned to the event
        //?
        setFilteredData(
            categories.filter((taskCategory) => {
                return !filteredIds.includes(taskCategory.id);
            })
        );
    }, [taskCategoryDataImport, createVisible]);

    //? This function is called when the form is submitted
    //?
    const submitEventTaskCategory = (dataItem) => {
        toggleLoaderVisible();

        dataItem.calendarEventId = eventContext.event.id;
        dataItem.taskCategoryId = dataItem.taskCategory.id;

        createEventTaskCategory(dataItem)
            .then(() => {
                triggerReload();
                toggleCreateVisible();
            })
            .catch((error) => {
                console.error("Error Message: ", error.message);
                if (error.response) {
                    console.error("Response Data: ", error.response.data);
                    console.error("Response Status: ", error.response.status);
                    console.error("Response Headers: ", error.response.headers);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {/* This is the confirmation toggle, this will be visible once the create button is toggled */}
            <Button
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                icon={"k-icon k-i-plus"}
                onClick={toggleCreateVisible}
                style={{
                    border: "none",
                }}
            >
                Add
            </Button>
            {createVisible && filteredData && (
                <Form
                    onSubmit={submitEventTaskCategory}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Add Event Task Category"}
                            onClose={toggleCreateVisible}
                            size={"small"}
                        >
                            <FormElement>
                                <fieldset className={"k-form-fieldset"}>
                                    <Field
                                        name={"taskCategory"}
                                        component={DropDownList}
                                        data={filteredData}
                                        style={{
                                            width: "80%",
                                            marginBottom: "10px",
                                        }}
                                        dataItemKey="id"
                                        textField="name"
                                        label={"Task Category:"}
                                    />
                                </fieldset>
                                {!loaderVisible ? (
                                    <div>
                                        <Button
                                            themeColor={"primary"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            onClick={toggleCreateVisible}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            themeColor={"success"}
                                            fillMode={"solid"}
                                            rounded={"medium"}
                                            style={{
                                                width: "100px",
                                                margin: "10px",
                                            }}
                                            disabled={
                                                !formRenderProps.allowSubmit
                                            }
                                            icon={"k-icon k-i-plus"}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                ) : (
                                    <Loader type="infinite-spinner" />
                                )}
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};

export default EventTaskCategoryCreate;
