import { LogLevel } from "@azure/msal-browser";
import config from "../config";

export const b2cPolicies = {
    names: {
        signUpSignIn: config.azureB2C.policyName,
    },
    authorities: {
        signUpSignIn: {
            authority: config.azureB2C.authorityUrl,
        },
    },
    authorityDomain: config.azureB2C.authorityDomain,
};

export const authScopes = config.azureB2C.authScopes;

export const msalConfig = {
    auth: {
        clientId: config.azureB2C.msal.authClientId, // Client ID
        tenantId: config.azureB2C.msal.authTenantId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin,
        scopes: authScopes,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
    scopes: authScopes,
};
