import React from "react";

/**
 * @function BaseCell
 * @description
 * A cell component that renders its children.
 * @param {Object[]} props - The props for the component
 * @return {React.JSX.Element}
 * @constructor
 */
export const BaseCell = (props) => {
    const { children } = props;

    // set up tdProps selectively from props
    // allows for better kendo control of the td / grid cell
    const tdProps = {
        style: props.style,
        id: props.id,
        colSpan: props.colSpan,
        field: props.field,
        className: props.className,
        columnindex: props.columnindex,
        columnscount: props.columnscount,
        rowtype: props.rowtype,
        level: props.level,
        dataindex: props.dataindex,
        ariacolumnindex: props.ariacolumnindex,
    };

    return <td {...tdProps}>{children}</td>;
};
