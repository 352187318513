import React, { useState } from "react";
import styles from "./ToggleButton.module.scss";

/**
 * @function ToggleButton
 * @description A toggle button component
 * @param {Function} onToggleOn - Callback function when toggled on
 * @param {Function} onToggleOff - Callback function when toggled off
 * @param {string} label - The label for the toggle button
 * @param {Boolean} [toggled] - The optional initial state of the toggle button
 * @param {Object} [textStyle] - The optional style for the label
 * @param {string} id - The id for the input element
 * @param others
 * @return {Element}
 * @constructor
 */
export const ToggleButton = ({
    onToggleOn,
    onToggleOff,
    label,
    toggled = false,
    textStyle,
    id,
    ...others
}) => {
    const [isToggled, setIsToggled] = useState(toggled);

    const onClick = () => {
        setIsToggled(!isToggled);

        if (isToggled) {
            onToggleOff();
        } else {
            onToggleOn();
        }
    };

    return (
        <div className={styles.toggleButton} {...others}>
            <label>
                <input
                    id={id}
                    type="checkbox"
                    defaultChecked={isToggled}
                    onClick={onClick}
                    value={isToggled}
                />
                <span />
                <strong style={textStyle}>{label}</strong>
            </label>
        </div>
    );
};
