import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { getAllRoles } from "../../../../services/Deprecated/authentication/roleService";
import { changeUserRole } from "../../../../services/Deprecated/authentication/userService";
import PermissionButton from "../../../../components/Deprecated/PermissionButton";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormDropDown } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import BasicFormButtons from "../../../../components/Deprecated/BasicFormButtons";
import { UserContext } from "../../../../providers/Deprecated/Authentication/User/UserProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";

const ChangeUserRoleModal = () => {
    const { user } = useContext(UserContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [visible, setVisible] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        //? Only load in the roles if the modal is open
        if (!visible) {
            return;
        }

        getAllRoles().then((res) => {
            setRoles(res.data);
        });
    }, [visible]);

    const onSubmit = (props) => {
        setLoaderVisible(true);

        const data = { userId: user.id, roleId: props.role?.id };
        changeUserRole(data).then((_) => {
            triggerReload();
            setVisible(false);
            setLoaderVisible(false);
        });
    };

    return (
        <>
            <PermissionButton
                requiredPermissions={[PermissionsEnum.TimeClockAdmin]}
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                onClick={() => setVisible(true)}
                iconClass={"k-icon k-i-gear"}
            >
                Change User Role
            </PermissionButton>
            {visible && (
                <ResponsiveDialog
                    title={"Change User RoleDashboardPage"}
                    onClose={() => setVisible(false)}
                    size={"small"}
                >
                    <Form
                        initialValues={user}
                        onSubmit={onSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <Field
                                    name={"role"}
                                    component={FormDropDown}
                                    data={roles}
                                    dataItemKey="id"
                                    textField="name"
                                    label={"RoleDashboardPage:"}
                                    validator={requiredValidator}
                                />
                                <BasicFormButtons
                                    loaderVisible={loaderVisible}
                                    isCreate={false}
                                    onCancel={() => setVisible(false)}
                                    allowSubmit={!formRenderProps.allowSubmit}
                                />
                            </FormElement>
                        )}
                    />
                </ResponsiveDialog>
            )}
        </>
    );
};

export default ChangeUserRoleModal;
