import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import clone from "just-clone";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { axiosInstance as axios } from "../../services/axiosInstance.js";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Error, Hint } from "@progress/kendo-react-labels";
import FormHelper from "./Helpers/FormHelper.js";

const DEFAULT_TEXT_FIELD = "name";
const DEFAULT_ITEM_KEY = "id";
const SKIP = 0;
const TAKE = 400;
const TOTAL = 100;

/**
 * @function RemoteDropDown
 * @param fieldRenderProps.textField {string} - The field to display in the dropdown
 * @param fieldRenderProps.dataItemKey {string} - The key to use as the unique identifier for the data item
 * @param fieldRenderProps.searchFields {string[]} - The fields to search on when filtering the dropdown
 * @param fieldRenderProps.useExactMatch {boolean} - Whether to use an exact match when searching
 * @param fieldRenderProps.url {string} - The URL to fetch the data from
 * @param fieldRenderProps.dataMutator {function} - A function to mutate the data before setting it
 * @param fieldRenderProps.validationMessage {string} - The validation message to display
 * @param fieldRenderProps.onFilterChange {function} - A function to call when the filter changes
 * @param fieldRenderProps.touched {boolean} - Whether the field has been touched
 * @param fieldRenderProps.id {string} - The ID of the field
 * @param fieldRenderProps.hint {string} - The hint to display
 * @param fieldRenderProps.disabled {boolean} - Whether the field is disabled
 * @param fieldRenderProps.onFocus {function} - A function to call when the field is focused
 * @param fieldRenderProps.initialFilters {object[]} - The initial filters to apply to the data
 * @param fieldRenderProps.initialSort {object[]} - The initial sort to apply to the data
 * @param fieldRenderProps.others {object} - Any other props to pass to the ComboBox
 * @return {Element}
 * @constructor
 */
export const RemoteDropDown = (fieldRenderProps) => {
    const {
        textField = DEFAULT_TEXT_FIELD,
        dataItemKey = DEFAULT_ITEM_KEY,
        searchFields = [DEFAULT_TEXT_FIELD],
        useExactMatch,
        url,
        dataMutator,
        validationMessage,
        onFilterChange,
        touched,
        id,
        hint,
        disabled,
        onFocus,
        initialFilters = [],
        initialSort = [
            {
                field: fieldRenderProps.textField ?? DEFAULT_TEXT_FIELD,
                dir: "asc",
            },
        ],
        ...others
    } = fieldRenderProps;
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [dataState, setDataState] = useState({
        skip: SKIP,
        take: TAKE,
        total: TOTAL,
        loading: false,
        filter: [],
        sort: initialSort,
    });

    const { showValidationMessage, showHint, hintId, errorId } =
        FormHelper.initFormMessages({ touched, id, hint, validationMessage });

    // Fetch data when the component is mounted
    // Also fetch data when the dataState changes (filter, page change, ect), or manually reload the data
    useEffect(() => {
        // if disabled, don't fetch data
        if (disabled) {
            setDataState({
                ...dataState,
                loading: false,
            });
        }

        fetchData();
    }, [dataState.sort, dataState.filter, disabled]);

    const fetchData = useMemo(() => {
        return () => {
            // if not actively loading, stop request
            if (!dataState.loading) return;

            const requestDataState = clone(dataState);
            if (requestDataState.filter) {
                requestDataState.filter = {
                    logic: "and",
                    filters: Array.isArray(requestDataState.filter)
                        ? requestDataState.filter
                        : [requestDataState.filter],
                };
            }

            const requestUrl =
                url + `${toDataSourceRequestString(requestDataState)}`;

            axios
                .get(requestUrl)
                .then(({ data }) => {
                    if (dataMutator) {
                        data.data = dataMutator(data.data);
                    }

                    setData(data.data);
                    setDataState({
                        ...dataState,
                        total: data.total,
                    });
                })
                .finally(() => {
                    setDataState({
                        ...dataState,
                        loading: false,
                    });
                });
        };
    }, [dataState, url]);

    //  VCS 2246 Curb Mounted Skylight
    // 160 Complete Top End Repair

    const onDropdownFilterChange = (event) => {
        setSearchTerm(event.filter.value);

        if (typeof onFilterChange === "function") {
            onFilterChange(event);
        }
    };

    useEffect(() => {
        if (!searchTerm || disabled) {
            setDataState({
                ...dataState,
                filter: initialFilters,
                loading: true,
            });
            return;
        }

        const delaySearch = setTimeout(() => {
            const searchTermOperators = (
                searchFields ?? [DEFAULT_TEXT_FIELD]
            ).map((field) => ({
                field,
                operator: useExactMatch ? "startswith" : "contains",
                value: searchTerm,
            }));

            const filters = [
                {
                    filters: [
                        {
                            filters: searchTermOperators ?? [],
                            logic: "or",
                        },
                    ],
                    logic: "and",
                },
            ];

            if (initialFilters.length > 0) {
                for (let i = 0; i < initialFilters.length; i++) {
                    filters[0].filters.push(initialFilters[i]);
                }
            }

            setDataState({
                ...dataState,
                filter: filters,
                skip: 0, //reset pagination on filter change
                take: TAKE,
                loading: true,
            });
        }, 500);

        return () => clearTimeout(delaySearch);
    }, [searchTerm, disabled]);

    const onOpen = (_event) => {
        setDataState({
            ...dataState,
            skip: 0,
            take: TAKE,
            loading: true,
        });
    };

    const onClose = (_event) => {
        setDataState({
            ...dataState,
            skip: 0,
            take: TAKE,
            loading: false,
        });
    };

    const ddlRef = React.useRef();
    const onDropdownFocus = (event) => {
        // sometimes forms override onFocus, so we need to make sure to do
        // the passed in version, and the ref call to open the DDL
        if (typeof onFocus == "function") {
            onFocus();
        }

        // wait a split second before opening to allow the onOpen to fire
        // if it isn't open, use toggleBtnClick to open the DDL on focus
        setTimeout(() => {
            if (!event.target.state.opened) {
                ddlRef?.current?.toggleBtnClick();
            }
        }, 100);
    };

    return (
        <div>
            <ComboBox
                ref={ddlRef}
                data={data}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                dataItemKey={dataItemKey}
                textField={textField}
                filterable={true}
                onOpen={onOpen}
                onClose={onClose}
                onFilterChange={onDropdownFilterChange}
                onFocus={onDropdownFocus}
                loading={dataState.loading}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </div>
    );
};
