import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class JobPaymentServices {
    static async get(id) {
        const res = await axios.get(`/Customer/Job/Billing/Get?id=${id}`);
        return formatServiceResponse(res);
    }

    static async delete(id) {
        const res = await axios.delete(`/Customer/Job/Billing/Delete?id=${id}`);
        return formatServiceResponse(res);
    }
}
