import React, { useCallback, useContext } from "react";
import { JobDocumentModalContext } from "../Providers/JobDocumentModalProvider.jsx";
import { DataGrid } from "../../../../../../components/Grids/DataGrid.jsx";
import { GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton.jsx";
import { CenterLoader } from "../../../../../../components/Deprecated/CenterLoader.jsx";
import { useModal } from "../../../../../../hooks/useModal.js";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell.jsx";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum.js";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { JobDocumentAddLineItemModal } from "./JobDocumentAddLineItemModal.jsx";
import { EditableTextCell } from "../../../../../../components/GridColumnComponents/EditableTextCell.jsx";
import { EditableNumericCell } from "../../../../../../components/GridColumnComponents/EditableNumericCell.jsx";
import RowRenderHighlighter from "../../../../../../components/Deprecated/RowRenderHighlighter.jsx";
import { JobDocumentAddDiscountModal } from "./JobDocumentAddDiscountModal.jsx";
import { NewItemTypeEnum } from "../../../../../../resources/Enums/ItemTypeEnum.js";
import LineItemHelper from "../../../../../../resources/LineItemHelper.js";
import { JobDocumentDetailGrid } from "./JobDocumentDetailGrid.jsx";

/**
 * @function JobDocumentLineItems
 * @description Component to display line items for a vendor document
 * @return {Element}
 * @constructor
 */
export const JobDocumentLineItems = () => {
    const {
        canEdit,
        submitLoaderVisible,
        onModalClose,
        closeAfterSubmit,
        formData,
        hasPendingChanges,
    } = useContext(JobDocumentModalContext);
    const {
        lineItemsRef,
        addNoteLineItem,
        removeLineItem,
        updateLineItem,
        moveLineItem,
        reloadLineItemsKey,
        runningTotalRef,
        reloadLineItems,
    } = useContext(DocumentLineItemContext);
    const itemModal = useModal();
    const discountModal = useModal();

    const isSaveDisabledCallback = useCallback(() => {
        if (submitLoaderVisible) {
            return submitLoaderVisible;
        }

        return !formData.salesPerson || !formData.name;
    }, [formData.salesPerson, formData.name, submitLoaderVisible]);

    const isDescriptionEditAllowed = (dataItem) => {
        if (!dataItem.isActive) return false;
        if (LineItemHelper.isNoteLineItem(dataItem)) return true;
        return !!dataItem.item?.isDescriptionEditAllowed;
    };

    const isQuantityEditAllowed = (dataItem) => {
        const type = dataItem.type;

        if (!dataItem.isActive) return false;
        switch (type) {
            case NewItemTypeEnum.Enum.Item:
                return true;
            default:
                return false;
        }
    };

    const isPriceEditAllowed = (dataItem) => {
        const type = dataItem.type;

        if (!dataItem.isActive) return false;
        switch (type) {
            case NewItemTypeEnum.Enum.Item:
                return true;
            case NewItemTypeEnum.Enum.CashDiscount:
                return true;
            default:
                return false;
        }
    };

    const isMovementUpAllowed = (dataItem) => {
        if (dataItem.index === 0) return false;

        return !LineItemHelper.isDiscountLineItem(dataItem);
    };

    const isMovementDownAllowed = (dataItem) => {
        if (dataItem.index === lineItemsRef.current.length - 1) return false;

        if (LineItemHelper.isDiscountLineItem(dataItem)) return false;

        const nextItem = lineItemsRef.current[dataItem.index + 1];

        if (!nextItem.isActive) return false;

        return !LineItemHelper.isDiscountLineItem(nextItem);
    };

    const generateMaxValueForQty = (dataItem) => {
        if (dataItem.type === NewItemTypeEnum.Enum.PercentageDiscount)
            return 100;

        return 999;
    };

    const generateMaxValueForPrice = (dataItem) => {
        if (dataItem.type === NewItemTypeEnum.Enum.CashDiscount)
            return runningTotalRef.current;

        return 999999;
    };

    const onExpansion = (event) => {
        const dataItem = event.dataItem;
        dataItem.EXPANDED = !dataItem.EXPANDED;
        lineItemsRef.current = [...lineItemsRef.current];
        reloadLineItems();
    };

    return (
        <DataGrid
            key={reloadLineItemsKey}
            detail={JobDocumentDetailGrid}
            expandField={"EXPANDED"}
            onExpandChange={onExpansion}
            style={{
                maxWidth: "99.99%",
                minHeight: 125,
            }}
            useInlineEdit={true}
            data={lineItemsRef.current}
            resizable={true}
            initialSort={[]}
            disabledPagination={true}
            showSaveChangesButtonGroup={false}
            rowRender={(row, props) =>
                RowRenderHighlighter(row, {
                    isDanger:
                        !props.dataItem.item.isActive ||
                        !props.dataItem.isActive,
                })
            }
        >
            <GridToolbar>
                <span>
                    {submitLoaderVisible && (
                        <span
                            className={
                                "JustifyCenterAndAlignCenter export-hide"
                            }
                        >
                            <CenterLoader />
                        </span>
                    )}
                    <JobDocumentAddLineItemModal {...itemModal} />
                    <JobDocumentAddDiscountModal {...discountModal} />
                    {canEdit && hasPendingChanges && (
                        <>
                            <ActionButton
                                permissions={[
                                    PermissionsEnum.UpdateJobDocument,
                                ]}
                                theme={"primary"}
                                buttonText={"Save"}
                                disabled={isSaveDisabledCallback()}
                                className={"export-hide"}
                                disabledToolTipMessage={
                                    submitLoaderVisible
                                        ? "Loading..."
                                        : "Sales Person and Title are required"
                                }
                            />
                            <ActionButton
                                permissions={[
                                    PermissionsEnum.UpdateJobDocument,
                                ]}
                                theme={"primary"}
                                buttonText={"Save & Close"}
                                onClick={() =>
                                    (closeAfterSubmit.current = true)
                                }
                                className={"export-hide"}
                                disabled={isSaveDisabledCallback()}
                                disabledToolTipMessage={
                                    submitLoaderVisible
                                        ? "Loading..."
                                        : "Sales Person and Title are required"
                                }
                            />
                        </>
                    )}
                    <ActionButton
                        permissions={[]}
                        theme={"primary"}
                        buttonText={"Close"}
                        type={"button"}
                        disabled={submitLoaderVisible}
                        onClick={onModalClose}
                        className={"export-hide"}
                    />
                </span>
                {canEdit && (
                    <>
                        {/* Spacer, yes, I hate this */}
                        <span
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                        <span>
                            <ActionButton
                                permissions={[]}
                                theme={"primary"}
                                buttonText={"Add Discount"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={() => discountModal.open()}
                                className={"export-hide"}
                                disabled={runningTotalRef.current === 0}
                                disabledToolTipMessage={"No items to discount"}
                            />
                            <ActionButton
                                permissions={[]}
                                theme={"primary"}
                                buttonText={"Add Note"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={() => addNoteLineItem()}
                                className={"export-hide"}
                            />
                            <ActionButton
                                permissions={[]}
                                theme={"success"}
                                buttonText={"Add Item"}
                                icon={"k-icon k-i-plus"}
                                type={"button"}
                                onClick={() => itemModal.open()}
                                className={"export-hide"}
                            />
                        </span>
                    </>
                )}
            </GridToolbar>
            <GridColumn
                field={"item.name"}
                title={"Item:"}
                width={300}
                filterable={false}
                sortable={false}
                minResizableWidth={150}
                editable={false}
                locked={true}
            />
            <GridColumn
                className={"k-table-td"}
                field="description"
                title="Description:"
                minResizableWidth={100}
                cell={(props) => (
                    <EditableTextCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        placeholder={"Enter a description..."}
                        disabled={
                            !canEdit ||
                            !isDescriptionEditAllowed(props.dataItem)
                        }
                    />
                )}
            />
            <GridColumn
                field="quantity"
                title="Qty:"
                width={125}
                minResizableWidth={50}
                cell={(props) => (
                    <EditableNumericCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        min={1}
                        format={
                            props.dataItem.type ===
                            NewItemTypeEnum.Enum.PercentageDiscount
                                ? "p"
                                : "n0"
                        }
                        max={generateMaxValueForQty(props.dataItem)}
                        onBlur={() =>
                            updateLineItem(props.dataItem.index, props.dataItem)
                        }
                        disabled={
                            !canEdit || !isQuantityEditAllowed(props.dataItem)
                        }
                    />
                )}
            />
            <GridColumn
                field="price.amount"
                title="Price:"
                width={150}
                minResizableWidth={50}
                cell={(props) => (
                    <EditableNumericCell
                        {...props}
                        editField={"IN_LINE_EDIT"}
                        min={0}
                        format={"c2"}
                        max={generateMaxValueForPrice(props.dataItem)}
                        onBlur={() =>
                            updateLineItem(props.dataItem.index, props.dataItem)
                        }
                        disabled={
                            !canEdit || !isPriceEditAllowed(props.dataItem)
                        }
                    />
                )}
            />
            <GridColumn
                field="total.amount"
                title="Total:"
                width={100}
                minResizableWidth={50}
                editable={false}
                format={"{0:c2}"}
            />
            {canEdit && (
                <GridColumn
                    width={175}
                    resizable={false}
                    filterable={false}
                    editable={false}
                    sortable={false}
                    cell={(props) => (
                        <ActionCell
                            additionalActions={[
                                {
                                    permissions: [],
                                    onClick: () =>
                                        moveLineItem(
                                            props.dataItem.index,
                                            "UP"
                                        ),
                                    icon: "k-icon k-i-arrow-up",
                                    type: "button",
                                    className: "export-hide",
                                    disabled: !isMovementUpAllowed(
                                        props.dataItem
                                    ),
                                },
                                {
                                    permissions: [],
                                    onClick: () =>
                                        moveLineItem(
                                            props.dataItem.index,
                                            "DOWN"
                                        ),
                                    icon: "k-icon k-i-arrow-down",
                                    type: "button",
                                    className: "export-hide",
                                    disabled: !isMovementDownAllowed(
                                        props.dataItem
                                    ),
                                },
                                {
                                    permissions: [],
                                    onClick: () =>
                                        discountModal.open(undefined, {
                                            parent: props.dataItem,
                                        }),
                                    icon: "k-icon k-i-dollar",
                                    theme: "success",
                                    type: "button",
                                    style: { marginRight: 2 },
                                    className: "export-hide",
                                    disabled:
                                        props.dataItem.type !==
                                        NewItemTypeEnum.Enum.Item,
                                },
                                {
                                    permissions: [],
                                    onClick: () =>
                                        itemModal.open(undefined, {
                                            index: props.dataItem.index + 1,
                                        }),
                                    icon: "k-icon k-i-plus",
                                    theme: "success",
                                    type: "button",
                                    style: { marginRight: 2 },
                                    className: "export-hide",
                                    disabled: LineItemHelper.isDiscountLineItem(
                                        props.dataItem
                                    ),
                                },
                                {
                                    permissions: [],
                                    onClick: () =>
                                        removeLineItem(props.dataItem.id),
                                    icon: "k-icon k-i-delete",
                                    theme: "error",
                                    type: "button",
                                    className: "export-hide",
                                },
                            ]}
                        />
                    )}
                />
            )}
        </DataGrid>
    );
};
