import React from "react";
import dayjs from "dayjs";
import { utcToLocalFormatter } from "../../../../../../resources/Deprecated/stringFormatter";

export const EventInformation = (props) => {
    const { initialData } = props;

    const formatAppointmentTime = (dataItem) => {
        if (dataItem.appointmentTime) {
            return `${utcToLocalFormatter(
                dataItem.appointmentTime.startTime,
                "h:mm A"
            )} - ${utcToLocalFormatter(
                dataItem.appointmentTime.endTime,
                "h:mm A"
            )}`;
        }
    };

    return (
        <span className={"DisplayFlex JustifyAround"}>
            <span className={"DisplayFlex LargeGap"} style={{ width: "300px" }}>
                <span
                    className={
                        "DisplayFlex AlignItemsStart FlexColumn SmallGap"
                    }
                >
                    <strong>CUSTOMER:</strong>
                    <strong>DATE:</strong>
                    <strong>TIME:</strong>
                    <strong>SPECS:</strong>
                </span>
                <span
                    className={
                        "DisplayFlex AlignItemsStart FlexColumn  SmallGap"
                    }
                >
                    <p className={"ZeroMargin text-overflow"}>
                        {initialData?.customer?.combinedName ?? "N/A"}
                    </p>
                    <p className={"ZeroMargin"}>
                        {dayjs(initialData?.eventDate).format("MM/DD/YYYY")}
                    </p>
                    <p className={"ZeroMargin"}>
                        {formatAppointmentTime(initialData) ?? "N/A"}
                    </p>
                    <p className={"ZeroMargin text-overflow"}>
                        {initialData?.specification ?? "N/A"}
                    </p>
                </span>
            </span>
            <span className={"DisplayFlex LargeGap"} style={{ width: "300px" }}>
                <span
                    className={
                        "DisplayFlex AlignItemsStart FlexColumn SmallGap"
                    }
                >
                    <strong>ASSIGNED:</strong>
                </span>
                <span
                    className={
                        "DisplayFlex AlignItemsStart FlexColumn SmallGap"
                    }
                >
                    <p className={"ZeroMargin text-overflow"}>
                        {initialData?.laborers
                            ?.map((laborer) => laborer.name)
                            .join(", ")}
                    </p>
                </span>
            </span>
        </span>
    );
};
