import React, { useContext, useEffect, useState } from "react";
import { TreeList } from "@progress/kendo-react-treelist";
import { YesNoCell } from "../../../../../../components/GridColumnComponents/YesNoCell";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { getEventType } from "../../../../../../services/Deprecated/calendar/eventTypeServices";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../../../hooks/useModal";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum";
import { ActionCell } from "../../../../../../components/GridColumnComponents/ActionCell";
import { AddTaskCategoryModal } from "../../TaskCategories/Components/AddTaskCategoryModal";
import { removeTaskCategoryFromEventType } from "../../../../../../services/Deprecated/calendar/eventTaskCategory";

const DefaultState = {
    data: [],
};

const EventTypeTaskAndCategoriesModal = (props) => {
    const { id, close, visible } = props;
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [state, setState] = useState(DefaultState);
    const { triggerReload, reloadData } = useContext(ReloadDataContext);
    const taskCategoryModal = useModal();
    const columns = [
        {
            field: "name",
            title: "Name",
            width: "250px",
            expandable: true,
        },
        {
            field: "isAutoAssigned",
            title: "Is Auto-Assigned",
            width: "200px",
            cell: (props) => <YesNoCell {...props} />,
        },
        {
            title: "Actions",
            width: "200px",
            cell: (props) =>
                props.dataItem.tasks ? ( // We cannot remove tasks, only categories
                    <ActionCell
                        onDelete={() => onCategoryRemoval(props.dataItem)}
                        permissions={{
                            delete: [PermissionsEnum.DeleteTaskCategory],
                        }}
                        deleteBtnText={"Remove"}
                    />
                ) : (
                    <ActionCell />
                ),
        },
    ];

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getEventType(id).then((res) => {
                setInitialFormData(res.data);
                setState({
                    ...state,
                    data: res.data.categories.map((category) => {
                        category.expanded = category.tasks.length > 0;

                        return category;
                    }),
                });
            });
        }
    }, [visible, id, reloadData]);

    const onClose = () => {
        setInitialFormData(undefined);
        setState(DefaultState);
        close();
    };

    const onCategoryRemoval = (category) => {
        removeTaskCategoryFromEventType(id, category.id).then(() => {
            triggerReload();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={`${initialFormData.name} Categories and Tasks`}
                onClose={onClose}
                size={"medium"}
            >
                <span className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
                    <AddTaskCategoryModal {...taskCategoryModal} />
                    <ActionButton
                        buttonText={"Add Category (Skill-Set)"}
                        icon={"k-icon k-i-plus"}
                        onClick={() =>
                            taskCategoryModal.open(undefined, {
                                eventType: initialFormData,
                            })
                        }
                        permissions={[PermissionsEnum.CreateTaskCategory]}
                    />
                    <TreeList
                        expandField={"expanded"}
                        subItemsField={"tasks"}
                        data={state.data}
                        columns={columns}
                    />
                </span>
            </ResponsiveDialog>
        )
    );
};

export default EventTypeTaskAndCategoriesModal;
