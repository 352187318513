import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { JobDocumentModalHelper } from "../Helpers/JobDocumentModalHelper.js";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider.jsx";
import JobDocumentService from "../../../../../../services/JobDocumentService.js";
import { NewJobDocumentType } from "../../../../../../resources/Enums/JobDocumentTypeEnum.js";
import { DocumentLineItemContext } from "../../../../../../providers/DocumentLineItemProvider.jsx";
import { AuthContext } from "../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { JobContext } from "../../../../../../providers/Deprecated/Customer/JobProvider.jsx";
import { ConfirmationDialog } from "../../../../../../components/Dialogs/ConfirmationDialog.jsx";
import { ToastContext } from "../../../../../../providers/ToastProvider.jsx";
import { checkRequiredPermissions } from "../../../../../../resources/Deprecated/permissionsHelper.js";
import { PermissionsEnum } from "../../../../../../resources/Enums/PermissionsEnum.js";
import { JobDocumentHelper } from "../../../../../../resources/JobDocumentHelper.js";

export const JobDocumentModalContext = createContext(null);

const CONFIRMATION_INITIAL_STATE = {
    confirmationButtonText: "Yes",
    confirmationButtonThemeColor: "error",
    cancelButtonText: "No",
    visible: false,
    msg: "",
    action: () => {},
};

const JobDocumentModalProvider = ({ children }) => {
    const { triggerReload } = useContext(ReloadDataContext);
    const {
        reIndexLineItems,
        calculateAndSetRunningTotal,
        lineItemsRef,
        resetLineItemsState,
        reloadLineItemsKey,
        reloadLineItems,
    } = useContext(DocumentLineItemContext);
    const { user } = useContext(AuthContext);
    const { job } = useContext(JobContext);
    const { showToast } = useContext(ToastContext);
    const [reloadDocumentKey, setReloadDocumentKey] = useState(Math.random());
    const [formData, setFormData] = useState(undefined);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [submitLoaderVisible, setSubmitLoaderVisible] = useState(false);
    const closeAfterSubmit = useRef(false);
    const service = useRef(new JobDocumentService());
    const pdfContainerRef = useRef(null);
    const [modalState, setModalState] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState(
        CONFIRMATION_INITIAL_STATE
    );
    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const dataHasInitialized = useRef(false);
    const hasPendingChangeOrder = useMemo(
        () => JobDocumentHelper.hasPendingChangeOrder(formData),
        [modalState?.id, modalState?.visible, formData?.childJobDocuments]
    );
    const hasChildrenExceptOrders = useMemo(
        () => JobDocumentHelper.hasChildrenExceptOrders(formData),
        [modalState?.id, modalState?.visible, formData?.childJobDocuments]
    );

    // Initialize FormData and LineItems
    useEffect(() => {
        if (!modalState.visible) return;

        if (modalState.id) {
            service.current.get(modalState.id).then((res) => {
                const data = JobDocumentModalHelper.formatDataForModal({
                    ...res.data,
                    businessInformation: modalState.record.businessInformation,
                });
                // Initialize LineItems
                resetLineItemsState();

                // If ChangeOrder we want to push deleted records to the bottom
                if (JobDocumentHelper.isChangeOrder(data)) {
                    lineItemsRef.current = data.lineItems.sort((a) =>
                        a.item.isActive ? -1 : 1
                    );
                } else {
                    lineItemsRef.current = data.lineItems;
                }

                calculateAndSetRunningTotal(lineItemsRef.current);
                reIndexLineItems(lineItemsRef.current);
                delete data.lineItems;

                // If the ID has changed, we have switched documents by changing the type
                if (formData?.id && formData.id !== data.id) reloadLineItems();

                setFormData(data);
                dataHasInitialized.current = true;
            });
        } else {
            const modalData = JobDocumentModalHelper.formatDataForModal({
                ...modalState.record,
                salesPerson: user.activeEmployee,
                createdBy: user.activeEmployee,
                lastEditedBy: user.activeEmployee,
            });
            setFormData(modalData);
            dataHasInitialized.current = true;
        }
    }, [modalState.visible, modalState.id]);

    useEffect(() => {
        if (!modalState.visible) return;
        if (!dataHasInitialized.current) return;

        setCanEdit(
            formData.type !== NewJobDocumentType.Enum.Invoice &&
                formData.type !== NewJobDocumentType.Enum.ApprovedChangeOrder &&
                !(hasChildrenExceptOrders || hasPendingChangeOrder)
        );
        setCanDelete(
            !hasChildrenExceptOrders &&
                modalState?.id &&
                formData?.totalPaid?.amount === 0
        );

        if (formData.type === NewJobDocumentType.Enum.PendingChangeOrder) {
            setConfirmationDialog({
                visible: true,
                confirmationButtonText: "Approve",
                confirmationButtonThemeColor: "success",
                cancelButtonText: "No",
                msg: "Do you wish to approve this Change Order?",
                action: () => {
                    onChangeStatus(
                        NewJobDocumentType.Enum.ApprovedChangeOrder,
                        false
                    );
                    closeAfterSubmit.current = true;
                    onModalClose();
                },
            });
        }

        if (JobDocumentHelper.hasPendingChangeOrder(formData)) {
            setConfirmationDialog({
                visible: true,
                confirmationButtonText: "Okay",
                confirmationButtonThemeColor: "primary",
                hideCancelButton: true,
                msg: "This Sales Order has a pending Change Order, please review the Change Order.",
                action: () => {},
            });
        }
    }, [dataHasInitialized.current, formData?.id]);

    // Handles the determination if there are pending changes to the Document
    useEffect(() => {
        if (!dataHasInitialized.current || !canEdit) return;

        if (formData.type === NewJobDocumentType.Enum.Invoice) {
            setHasPendingChanges(false);
            return;
        }

        setHasPendingChanges(true);
    }, [reloadDocumentKey, reloadLineItemsKey]);

    // Close the modal and reset all state
    const onModalClose = () => {
        if (hasPendingChanges && !closeAfterSubmit.current) {
            setConfirmationDialog({
                visible: true,
                confirmationButtonText: "Yes",
                confirmationButtonThemeColor: "error",
                cancelButtonText: "No",
                msg: "You have unsaved changes, do you wish to close?",
                action: () => {
                    resetModalState();
                    modalState.close();
                },
            });
            return;
        }

        resetModalState();
        modalState.close();
    };

    const resetModalState = () => {
        setConfirmationDialog(CONFIRMATION_INITIAL_STATE);
        setHasPendingChanges(false);
        dataHasInitialized.current = false;
        setFormData(undefined);
        setSubmitLoaderVisible(false);
        resetLineItemsState();
        closeAfterSubmit.current = false;
    };

    const onModalSubmit = (e) => {
        setSubmitLoaderVisible(true);

        let values = {
            ...e.values,
            id: modalState.id,
            lineItems: lineItemsRef.current,
            job: job,
        };

        const isSalesOrder =
            formData.type === NewJobDocumentType.Enum.SalesOrder;
        const hasAdminPermissionToEdit = checkRequiredPermissions(
            user.role.permissions,
            [PermissionsEnum.AdminEditSalesOrder]
        );
        if (isSalesOrder && !hasAdminPermissionToEdit) {
            setConfirmationDialog({
                visible: true,
                confirmationButtonText: "Yes, Create",
                confirmationButtonThemeColor: "success",
                cancelButtonText: "No",
                msg: "Cannot directly edit the Sales Order, do you wish to create a Change Order instead?",
                action: () => {
                    values =
                        JobDocumentModalHelper.formatChangeOrderDataForNetwork(
                            values
                        );
                    service.current.create(values).then((res) => {
                        if (res.success) {
                            if (closeAfterSubmit.current) {
                                handleSubmitRes(res);
                                return;
                            }

                            showToast("Document saved successfully", "success");
                            triggerReload();
                            setModalState({
                                ...modalState,
                                id: res.data.id,
                            });
                        }
                    });
                },
            });
            return;
        }

        values = JobDocumentModalHelper.formatDataForNetwork(values);
        const request = modalState.id
            ? service.current.update(values)
            : service.current.create(values);
        request.then(handleSubmitRes);
    };

    const handleSubmitRes = (res) => {
        if (!res.success) {
            setSubmitLoaderVisible(false);
            return;
        }

        triggerReload();
        setHasPendingChanges(false);
        showToast("Document saved successfully", "success");

        if (closeAfterSubmit.current) {
            onModalClose();
        } else {
            setSubmitLoaderVisible(false);
            // If creating, we need to reset the ID so we can begin updating
            if (!modalState.id) {
                setModalState({
                    ...modalState,
                    id: res.data.id,
                });
            }
        }
    };

    // Handle delete operation
    const onDelete = () => {
        setHasPendingChanges(false);
        service.current.delete(modalState.id).then((res) => {
            triggerReload();

            if (res.success) {
                onModalClose();
            }
        });
    };

    // Change the status of the document
    const onChangeStatus = (type, switchDocument = true) => {
        setSubmitLoaderVisible(true);
        service.current.changeType(modalState.id, type).then((res) => {
            if (res.success) {
                triggerReload();

                if (switchDocument) {
                    setModalState({
                        ...modalState,
                        id: res.data.id,
                    });
                }
                setSubmitLoaderVisible(false);
            }
        });
    };

    // Reloads the document information
    // Does not reload the LineItems
    const reloadDocumentInformation = () => {
        setReloadDocumentKey(Math.random());
    };

    return (
        <JobDocumentModalContext.Provider
            value={{
                reloadDocumentKey,
                reloadDocumentInformation,
                formData,
                setFormData,
                canEdit,
                canDelete,
                setModalState,
                modalState,
                onModalClose,
                onModalSubmit,
                onDelete,
                submitLoaderVisible,
                closeAfterSubmit,
                onChangeStatus,
                pdfContainerRef,
                hasPendingChanges,
                hasPendingChangeOrder,
                hasChildrenExceptOrders,
                setHasPendingChanges,
            }}
        >
            <ConfirmationDialog
                visible={confirmationDialog.visible}
                actionOnConfirm={() => {
                    confirmationDialog.action();
                    setConfirmationDialog({
                        ...confirmationDialog,
                        visible: false,
                    });
                    setSubmitLoaderVisible(false);
                }}
                actionOnCancel={() => {
                    setConfirmationDialog({
                        ...confirmationDialog,
                        visible: false,
                    });
                    setSubmitLoaderVisible(false);
                }}
                confirmationText={confirmationDialog.msg}
                confirmationButtonText={
                    confirmationDialog.confirmationButtonText
                }
                confirmationButtonThemeColor={
                    confirmationDialog.confirmationButtonThemeColor
                }
                cancelButtonText={confirmationDialog.cancelButtonText}
                hideCancelButton={confirmationDialog.hideCancelButton}
                cancelButtonThemeColor={"primary"}
            />
            {children}
        </JobDocumentModalContext.Provider>
    );
};

export default JobDocumentModalProvider;
