import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import dayjs from "dayjs";
import { AuthContext } from "../../Authentication/User/AuthProvider.jsx";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";
import {
    checkRequiredPermissions,
    getUserPermissions,
} from "../../../../resources/Deprecated/permissionsHelper.js";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { getAllEmployees } from "../../../../services/Deprecated/humanResources/employeeServices.js";
import HRTaskService from "../../../../services/Deprecated/humanResources/HRTaskService.js";
import { getAllJobs } from "../../../../services/Deprecated/customer/jobServices.js";
import { getAllEvents } from "../../../../services/Deprecated/customer/eventServices.js";

export const TimeClockContext = createContext(null);

const TimeClockProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { reloadData } = useContext(ReloadDataContext);
    const [employees, setEmployees] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState(undefined);
    const [events, setEvents] = useState([]); // Unfiltered to a selected job
    const [jobEvents, setJobEvents] = useState([]); // Filtered to a selected job
    const [selectedEmployee, setSelectedEmployee] = useState(undefined);
    const [isEmployeeClockedIn, setEmployeeIsClockedIn] = useState(false);
    const userPermissions = getUserPermissions();
    const isAdministrator = useCallback(
        checkRequiredPermissions(userPermissions, [
            PermissionsEnum.TimeClockAdmin,
        ]),
        [user]
    );
    const isStationaryTimeClockUser = useCallback(
        checkRequiredPermissions(userPermissions, [
            PermissionsEnum.CanActAsStationaryTimeClock,
        ]),
        [user]
    );

    useEffect(() => {
        // If a User is an Admin, we want to allow them to select any Employee
        if (isAdministrator) {
            getAllEmployees().then((serviceResponse) => {
                const formattedEmployees = serviceResponse.data.map(
                    (employee) => ({
                        ...employee,
                        displayName: `${employee.fullName} (${employee.payrollId})`,
                    })
                );
                setEmployees(formattedEmployees);
            });
        }

        // We do not want to set default employee if
        // the user is a StationaryTimeClockUser
        if (!isStationaryTimeClockUser || isAdministrator) {
            setSelectedEmployee(user.activeEmployee);
        }

        HRTaskService.getAllTasks().then((res) => setTasks(res.data));
        getAllJobs("isMarkedDead~eq~'false'~and~isArchived~eq~'false'").then(
            (res) => setJobs(res.data)
        );
        getAllEvents({
            start: dayjs.utc().startOf("day").toDate(),
            end: dayjs.utc().endOf("day").toDate(),
        }).then((res) => {
            setEvents(res.data);
            setJobEvents(res.data);
        });
    }, [reloadData]);

    /**
     * Filter out Events by JobId if SelectedJobId is populated
     */
    useEffect(() => {
        if (!selectedJobId) {
            return;
        }

        setJobEvents(events.filter((e) => e.job?.id === selectedJobId));
    }, [selectedJobId]);

    const value = {
        employees,
        tasks,
        jobs,
        jobEvents,
        setSelectedJobId,
        selectedEmployee,
        setSelectedEmployee,
        isEmployeeClockedIn,
        setEmployeeIsClockedIn,
        isAdministrator,
        isStationaryTimeClockUser,
    };

    return (
        <TimeClockContext.Provider value={value}>
            {children}
        </TimeClockContext.Provider>
    );
};
export default TimeClockProvider;
