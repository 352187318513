import React from "react";
import FormField from "../../../../components/Deprecated/StylingField";

export const ClockInInformation = (props) => {
    const { data } = props;

    return (
        <div className={"JustifyLeftAlignLeft FlexColumn MediumGap"}>
            <FormField label="Employee" value={data?.employee?.name} />
            <FormField label="Job" value={data?.job?.name} />
            <FormField label="Event" value={data?.calendarEvent?.name} />
            <FormField label="Task" value={data?.task?.name} />
        </div>
    );
};
