import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../../../../resources/Deprecated/formValidators";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import { FormInput } from "../../../../../../components/Deprecated/FormComponents";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import {
    createItemType,
    getItemType,
    updateItemType,
} from "../../../../../../services/Deprecated/inventory/itemService";

export const ItemTypeModal = (props) => {
    const { id, isUpdate, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (!visible) return;

        if (id) {
            getItemType(id).then((res) => {
                setInitialFormData(res.data);
            });
        } else {
            setInitialFormData({});
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        if (isUpdate) {
            updateItemType(dataItem).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        } else {
            createItemType(dataItem).then(() => {
                triggerReload();
                setLoaderVisible(false);
                onClose();
            });
        }
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={isUpdate ? "Edit ItemType" : "Add ItemType"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            <div style={{ width: "100%" }}>
                                <Field
                                    name={"name"}
                                    component={FormInput}
                                    label={"Name"}
                                    validator={requiredValidator}
                                />
                            </div>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!isUpdate}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
