import dayjs from "dayjs";

export class EventHelper {
    static formatEventForNetwork(event) {
        const clonedEvent = { ...event };

        clonedEvent.eventTypeId = clonedEvent.eventType.id;
        clonedEvent.customerId = clonedEvent.customer?.id;
        clonedEvent.jobDocumentId = clonedEvent.jobDocument?.id;
        clonedEvent.jobId = clonedEvent.job?.id;

        if (clonedEvent.eventDate) {
            clonedEvent.eventDate = dayjs(clonedEvent.eventDate).format(
                "YYYY-MM-DD"
            );
        }

        delete clonedEvent.job;
        delete clonedEvent.jobDocument;
        delete clonedEvent.customer;
        delete clonedEvent.appointmentTime;
        delete clonedEvent.eventType;

        return clonedEvent;
    }
}
