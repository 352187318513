import React, { createContext, useState } from "react";

export const InventoryContext = createContext(null);

const InventoryProvider = ({ children }) => {
    // Load in the relationships
    //
    // const { bool: relationshipsLoading, toggle: toggleRelationshipsLoading } = useToggle(true);
    const [relationships, setRelationships] = useState({});

    const value = {
        relationships,
        relationshipsLoading: false,
    };

    return (
        <InventoryContext.Provider value={value}>
            {children}
        </InventoryContext.Provider>
    );
};

export default InventoryProvider;
