import { Button } from "@progress/kendo-react-buttons";
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
    Avatar,
} from "@progress/kendo-react-layout";
import React, { useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { SwitchTenant } from "./SwitchTenant";
import { AuthContext } from "../../providers/Deprecated/Authentication/User/AuthProvider";
import SignInButton from "../../pages/Login";

const Header = (props) => {
    const [isMobileView, setIsMobileView] = useState(false);
    const { instance } = useMsal();
    const authContext = useContext(AuthContext);
    const user = authContext.user;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSignOut = () => {
        // Handle the sign-out logic here
        instance.logoutRedirect().catch(console.error);
    };
    return (
        <div className="header">
            <AppBar themeColor="inherit">
                <AppBarSection>
                    <Button icon="menu" onClick={props.onMenuClick} />
                </AppBarSection>
                {!isMobileView && ( // Render only if not in mobile view
                    <AppBarSection>
                        <p className="title">Clear Business Insight</p>
                    </AppBarSection>
                )}
                <AppBarSpacer />
                <AppBarSection>
                    <SwitchTenant />
                </AppBarSection>
                {!isMobileView && ( // Render only if not in mobile view
                    <React.Fragment>
                        <AppBarSection>
                            <Avatar
                                type="icon"
                                size="medium"
                                rounded="large"
                                style={{
                                    margin: "10px",
                                }}
                            >
                                <span className="k-icon k-i-user" />
                            </Avatar>
                        </AppBarSection>
                        <AppBarSection>
                            <span style={{ color: "white" }}>
                                {user?.primaryEmail}
                            </span>
                        </AppBarSection>
                        <AppBarSection>
                            <SignInButton onSignOut={handleSignOut} />
                        </AppBarSection>
                    </React.Fragment>
                )}
            </AppBar>
        </div>
    );
};

export default Header;
