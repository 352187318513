import React, { useContext, useState } from "react";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../components/Buttons/FormButtons";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
    FormDateInput,
    FormDropDown,
    FormNumericInput,
} from "../../../../../../components/Deprecated/FormComponents";
import {
    dateValidator,
    requiredValidator,
} from "../../../../../../resources/Deprecated/formValidators";
import PayrollService from "../../../../../../services/Deprecated/humanResources/PayrollService";
import dayjs from "dayjs";
import { daysOfTheWeek } from "../../../../../../resources/Deprecated/dateHelper";

const PayrollIntervalModal = (props) => {
    const { record, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [radioValue, setRadioValue] = useState(
        record?.interval ?? "BIWEEKLY"
    );

    const onSubmit = (props) => {
        if (!props.isValid) return;

        setLoaderVisible(true);

        PayrollService.setPayrollInterval({
            interval: radioValue,
            startDayOfWeek: props.values.startDayOfWeek.id,
            startingDate: dayjs(props.values.startingDate).format("YYYY-MM-DD"),
            minimumBreakDuration: props.values.minimumBreakDuration,
        }).then(() => {
            triggerReload();
            close();
            setLoaderVisible(false);
        });
    };

    const handleRadioButtons = (props) => setRadioValue(props.value);

    return (
        visible && (
            <ResponsiveDialog
                title={"Set Payroll Interval"}
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    initialValues={record}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement>
                            <span
                                className={
                                    "JustifyLeftAlignLeft SmallGap FlexColumn"
                                }
                            >
                                <p
                                    className={"ZeroMargin"}
                                    style={{ fontWeight: "500" }}
                                >
                                    When do you pay?
                                </p>
                                <span>
                                    <RadioButton
                                        name="WEEKLY"
                                        value="WEEKLY"
                                        checked={radioValue === "WEEKLY"}
                                        label="Weekly"
                                        onChange={handleRadioButtons}
                                    />
                                </span>
                                <span>
                                    <RadioButton
                                        name="BIWEEKLY"
                                        value="BIWEEKLY"
                                        checked={radioValue === "BIWEEKLY"}
                                        label="Bi-Weekly"
                                        onChange={handleRadioButtons}
                                    />
                                </span>
                                <br />
                                <p
                                    className={"ZeroMargin"}
                                    style={{ fontWeight: "500" }}
                                >
                                    What day of the week does your pay period
                                    start on?
                                </p>
                                <Field
                                    name={"startDayOfWeek"}
                                    component={FormDropDown}
                                    data={daysOfTheWeek}
                                    dataItemKey="id"
                                    textField="name"
                                    style={{ width: 250 }}
                                    validator={requiredValidator}
                                />
                                <br />
                                <p
                                    className={"ZeroMargin"}
                                    style={{ fontWeight: "500" }}
                                >
                                    When do you want your pay period to start
                                    generating?
                                </p>
                                <Field
                                    name="startingDate"
                                    component={FormDateInput}
                                    validator={dateValidator}
                                    hint="MM/DD/YYYY"
                                    isRequired={true}
                                />
                                <p
                                    className={"ZeroMargin"}
                                    style={{ fontWeight: "500" }}
                                >
                                    What is the minimum minutes for a break?
                                </p>
                                <Field
                                    name={"minimumBreakDuration"}
                                    component={FormNumericInput}
                                    min={0}
                                    defaultValue={20}
                                    validator={requiredValidator}
                                />
                            </span>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={true}
                                buttonText={"Submit"}
                                theme={"primary"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default PayrollIntervalModal;
