import React, { createContext } from "react";

export const CategoryCustomizationsAndListsContext = createContext(null);

const CategoryCustomizationsAndListsProvider = ({ children }) => {
    const [listBoxState, setListBoxState] = React.useState({
        customizationLists: [],
        customizations: [],
    });
    const [selectedItems, setSelectedItems] = React.useState({
        customizationList: null,
        customization: null,
    });
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    // The value prop of the context provider is the object that will be
    // available to any component that consumes the context
    //
    const value = {
        listBoxState,
        setListBoxState,
        selectedItems,
        setSelectedItems,
        selectedCategory,
        setSelectedCategory,
    };

    return (
        <CategoryCustomizationsAndListsContext.Provider value={value}>
            {children}
        </CategoryCustomizationsAndListsContext.Provider>
    );
};

export default CategoryCustomizationsAndListsProvider;
