import React, { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { CustomerContext } from "../../../../providers/Deprecated/Customer/CustomerProvider";
import { CustomerTypeEnum } from "../../../../resources/Enums/CustomerStatusEnum";
import { addressesMatch } from "../../../../resources/Deprecated/helpers";
import {
    addJob,
    getJob,
    updateJob,
} from "../../../../services/Deprecated/customer/jobServices";
import JobNetworkFormatter from "../Helpers/JobNetworkFormatter";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import JobInformation from "./JobInformation";
import JobContact from "./JobContact";
import { FormButtons } from "../../../../components/Buttons/FormButtons";

const JobModal = (props) => {
    const {
        visible,
        close,
        id,
        isCreate,
        isUpdate,
        record,
        setRecord,
        onSuccessCallback,
        onFailureCallback,
    } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const customerContext = useContext(CustomerContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(null);
    const isBusinessCustomer =
        customerContext.customer?.type === CustomerTypeEnum.Enum.Business;

    // Fetch job data on update/edit
    useEffect(() => {
        if (isUpdate && id && visible) {
            const fetchJob = async () => {
                try {
                    const response = await getJob(id);
                    const jobData = response.data;

                    const initialRecord = { ...jobData };

                    if (
                        jobData.primaryContact?.id &&
                        jobData.primaryContact.id ===
                            customerContext.customer?.primaryContact?.id
                    ) {
                        initialRecord.isUsingCustomerPrimaryContact = true;
                    }
                    if (
                        jobData.secondaryContact?.id &&
                        jobData.secondaryContact.id ===
                            customerContext.customer?.secondaryContact?.id
                    ) {
                        initialRecord.isUsingCustomerSecondaryContact = true;
                    }

                    initialRecord.isUsingCustomerAddress = addressesMatch(
                        jobData.address,
                        customerContext.customer.address
                    );

                    setInitialFormValues(initialRecord);
                } catch (error) {
                    console.error("Error fetching job:", error);
                }
            };
            fetchJob();
        }
    }, [id, isUpdate, setInitialFormValues, customerContext.customer, visible]);

    // Prepare default state for job creation
    useEffect(() => {
        if (isCreate && visible) {
            const defaultFormValues = {
                jobName: "",
                jobDescription: "",
                address: {
                    address: "",
                    city: "",
                    state: "",
                    zip: "",
                },
                businessInformation:
                    customerContext.relationships?.businessInfos?.find(
                        (val) => val.isDefault
                    ),
            };

            if (record?.customer) {
                defaultFormValues.customer = record.customer;
            } else {
                defaultFormValues.customer = customerContext.customer;
            }

            if (isBusinessCustomer) {
                defaultFormValues.primaryContact = {};
                defaultFormValues.secondaryContact = {};
            }

            setInitialFormValues(defaultFormValues);
        }
    }, [
        customerContext.relationshipsLoading,
        isCreate,
        setInitialFormValues,
        visible,
        isBusinessCustomer,
    ]);

    // Handle form submission for both create and update
    const onFormSubmit = async (dataItem) => {
        setLoaderVisible(true);

        const formattedJob = JobNetworkFormatter.FormatJob(dataItem);
        const service = id ? updateJob : addJob;

        service(formattedJob).then((res) => {
            triggerReload();
            resetForm();

            if (res.success) {
                onSuccessCallback();
            } else {
                onFailureCallback();
            }
        });
    };

    // Reset form and close modal
    const resetForm = () => {
        close();
        setRecord(undefined);
        setInitialFormValues(null);
        setLoaderVisible(false);
    };

    return (
        visible &&
        initialFormValues && (
            <ResponsiveDialog
                title={
                    isCreate
                        ? "Create Job"
                        : `Edit Job: ${initialFormValues?.name || "Job"}`
                }
                onClose={resetForm}
                visible={visible}
                size="large"
            >
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={initialFormValues}
                    render={(formRenderProps) => (
                        <FormElement>
                            <span className={"JustifyLeftAlignLeft LargeGap"}>
                                <span
                                    style={{
                                        minWidth:
                                            isBusinessCustomer || isUpdate
                                                ? "50%"
                                                : "100%",
                                    }}
                                >
                                    <JobInformation
                                        formRenderProps={formRenderProps}
                                        job={initialFormValues}
                                        values={{
                                            ...initialFormValues,
                                            customer: customerContext.customer,
                                        }}
                                    />
                                </span>
                                {(isBusinessCustomer || isUpdate) && (
                                    <span
                                        style={{
                                            minWidth: "45%",
                                        }}
                                    >
                                        <JobContact
                                            formRenderProps={formRenderProps}
                                            job={initialFormValues}
                                        />
                                    </span>
                                )}
                            </span>
                            <FormButtons
                                actionOnCancel={resetForm}
                                isCreate={!id}
                                allowSubmit={formRenderProps.allowSubmit}
                                loaderVisible={loaderVisible}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default JobModal;
