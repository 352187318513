import React, { createContext, useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../ReloadDataProvider";
import { useToggle } from "../../../hooks/Deprecated/useToggle.js";
import { getAllEmployees } from "../../../services/Deprecated/humanResources/employeeServices.js";
import {
    getAllTasksAndTaskCategoriesForEventType,
    getEvent,
} from "../../../services/Deprecated/customer/eventServices.js";

export const EventContext = createContext(null);

const EventProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const {
        bool: relationshipsLoading,
        setTrue: setRelationshipsLoading,
        setFalse: unsetRelationshipsLoading,
    } = useToggle();
    const [relationships, setRelationships] = useState({});
    const [employees, setEmployees] = useState({});
    const [tasksAndCategories, setTasksAndCategories] = useState({});
    const [event, setEvent] = useState({});
    const [eventId, setEventId] = useState();
    const [manualReload, setManualReload] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setRelationshipsLoading();

            const employees = await getAllEmployees();
            setEmployees(employees);
        };

        fetchData().then(() => {
            unsetRelationshipsLoading();
        });
    }, []);

    useEffect(() => {
        if (!eventId) {
            setEvent({});
            return;
        }

        const fetchEventData = async (eventId) => {
            try {
                const eventObj = await getEvent(eventId);
                const tasksAndCategories =
                    await getAllTasksAndTaskCategoriesForEventType(
                        eventObj.data.eventType.id
                    );
                setTasksAndCategories(tasksAndCategories.data);
                setEvent(eventObj.data);
            } catch (error) {
                console.error("Error fetching event details:", error);
            }
        };

        fetchEventData(eventId).then(() => {
            console.log("Fetched Event Details");
        });
    }, [eventId, reloadDataContext.reloadData, manualReload]);

    useEffect(() => {
        setRelationships({ employees, tasksAndCategories });
    }, [employees, tasksAndCategories]);
    const calendarEventId = event.id || ""; // Set a default value if not present

    const value = {
        relationships,
        setRelationships,
        relationshipsLoading,
        event,
        setEventId,
        setManualReload,
        calendarEventId,
    };

    return (
        <EventContext.Provider value={value}>{children}</EventContext.Provider>
    );
};

export default EventProvider;
