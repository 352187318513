import React, { useEffect } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import BaseInformationDisplay from "./BaseInformationDisplay";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import ItemCustomizationService from "../../../../services/Deprecated/inventory/ItemCustomizationService";
import {
    checkForDuplicatesInArr,
    filterArrayByValue,
} from "../../../../resources/Deprecated/helpers";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameAndDuplicateValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";

const MultiItemForm = ({ formRenderProps, categoryId }) => {
    const [selectedCustomizationList, setSelectedCustomizationList] =
        React.useState({});
    const [itemCustomizationLists, setItemCustomizationLists] = React.useState(
        []
    );
    const [customizationLists, setCustomizationLists] = React.useState([]);

    // Trick form to correctly render the fields with values
    //
    React.useEffect(() => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        if (returnedTarget[0] === undefined) {
            setFormBaseValues(0, returnedTarget);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (categoryId === undefined) {
                    return;
                }
                const data =
                    await ItemCustomizationService.getAllCustomizationListsByCategory(
                        categoryId
                    );
                setCustomizationLists(data.data.data);
            } catch (error) {
                console.log("Error");
            }
        };

        fetchData();
    }, []);

    // Add the selected customization list to the customization lists array
    //
    const addCustomizationList = () => {
        ItemCustomizationService.getAllCustomizationsByCustomizationList(
            selectedCustomizationList.id
        ).then((res) => {
            selectedCustomizationList.customizations = res.data;
            if (JSON.stringify(selectedCustomizationList) !== "{}") {
                const newCustomizationsList = [
                    ...itemCustomizationLists,
                    selectedCustomizationList,
                ];
                setItemCustomizationLists(newCustomizationsList);
                setSelectedCustomizationList({});
            }
        });
    };

    // Remove the selected customization list from the customization lists array
    //
    const removeCustomizationList = (customizationIndex) => {
        itemCustomizationLists.splice(customizationIndex, 1);
        setItemCustomizationLists(itemCustomizationLists);

        const items = formRenderProps.valueGetter("items");
        let returnedTarget = Object.assign([], items);

        returnedTarget = returnedTarget.map((item) => {
            item.customizations.splice(customizationIndex, 1);
            return item;
        });

        formRenderProps.onChange("items", {
            value: returnedTarget,
        });
    };

    // Clear the names and descriptions of all items
    //
    const clearNamesAndDescriptions = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            returnedTarget[i].name = "";
            returnedTarget[i].poDescription = "";
            returnedTarget[i].baseDescription = "";
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    };

    // Add the selected item row
    //
    const addItemRow = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        setFormBaseValues(items.length, returnedTarget);
    };

    // Remove the selected item row
    //
    const removeItemRow = (index) => {
        const items = formRenderProps.valueGetter("items");

        if (items.length !== 1) {
            const items = formRenderProps.valueGetter("items");
            const returnedTarget = Object.assign({}, items);

            delete returnedTarget[index];
            resetIndexes();

            formRenderProps.onChange("items", {
                value: Object.values(returnedTarget),
            });
        }
    };

    // Auto generate names for all items
    //
    const autoGenerateNamesAndDescriptions = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            const generatedString = (
                formRenderProps.valueGetter(`items[${i}].customizations`) || []
            )
                .map((customization) => customization.customization.name)
                .join(" ");

            returnedTarget[i].name =
                formRenderProps.valueGetter("name") + " " + generatedString;
            returnedTarget[i].poDescription =
                formRenderProps.valueGetter("baseDescription") +
                " " +
                generatedString;
            returnedTarget[i].baseDescription =
                formRenderProps.valueGetter("baseDescription") +
                " " +
                generatedString;
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    };

    // Function to DRY up code for setting form base values
    const setFormBaseValues = (index, target) => {
        resetIndexes();
        target[index] = {
            index: index,
            name: formRenderProps.valueGetter("name"),
            purchaseOrderDescription: formRenderProps.valueGetter(
                "purchaseOrderDescription"
            ),
            baseDescription: formRenderProps.valueGetter("baseDescription"),
            retailPrice: formRenderProps.valueGetter("retailPrice"),
            vendorCost: formRenderProps.valueGetter("retailPrice"),
            manufacturerNumber:
                formRenderProps.valueGetter("manufacturerNumber"),
            customizations: [],
        };

        formRenderProps.onChange("items", {
            value: Object.values(target),
        });
    };

    // Loop over each item record, reset the index
    //
    const resetIndexes = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            returnedTarget[i].index = i;
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    };

    // Check for duplicate names in Grid
    //
    function checkForDuplicateNames(name) {
        const items = formRenderProps.valueGetter("items");

        if (items !== undefined) {
            const itemNames = items.map((item) => item.name);

            return checkForDuplicatesInArr(itemNames, name);
        }

        return false;
    }

    return (
        <GridLayout
            rows={[{ height: "auto" }, { height: "auto" }]}
            cols={[{ width: "60%" }, { width: "40%" }]}
        >
            <GridLayoutItem col={1} row={1} colSpan={1}>
                {/* Base Information Display */}
                <BaseInformationDisplay formRenderProps={formRenderProps} />
            </GridLayoutItem>
            <GridLayoutItem
                col={1}
                row={2}
                colSpan={1}
                style={{ marginBottom: "50px" }}
            >
                {/* Customization Buttons */}
                <Button
                    themeColor={"success"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    style={{ width: "auto" }}
                    icon={"k-icon k-i-plus"}
                    onClick={addItemRow}
                >
                    Add Item Row
                </Button>
                <Button
                    themeColor={"info"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    style={{ width: "auto", margin: "0 5px 0 5px" }}
                    icon={"k-icon k-i-refresh"}
                    onClick={autoGenerateNamesAndDescriptions}
                >
                    Auto Generate Names/Descriptions
                </Button>
                <Button
                    themeColor={"primary"}
                    fillMode={"solid"}
                    rounded={"medium"}
                    style={{ width: "auto" }}
                    icon={"k-icon k-i-undo"}
                    onClick={clearNamesAndDescriptions}
                >
                    Clear Names/Descriptions
                </Button>
            </GridLayoutItem>
            <GridLayoutItem
                col={2}
                row={1}
                colSpan={1}
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "25px",
                    marginTop: "25px",
                }}
            >
                {/* Customizations Dropdown */}
                <DropDownList
                    data={filterArrayByValue(
                        customizationLists,
                        itemCustomizationLists
                    )}
                    dataItemKey="id"
                    textField="name"
                    style={{ maxWidth: 250 }}
                    onChange={(e) =>
                        setSelectedCustomizationList(e.target.value)
                    }
                />
                <ActionButton
                    icon={"k-icon k-i-plus"}
                    onClick={addCustomizationList}
                    disabled={selectedCustomizationList.id === undefined}
                    buttonText={"Add Customization List"}
                    theme={"success"}
                    disabledToolTipMessage={
                        "Please select a customization list"
                    }
                />
            </GridLayoutItem>
            {/* Item Rows */}
            <GridLayoutItem
                col={1}
                colSpan={2}
                style={{
                    borderTop: "1px solid #ccc",
                    padding: "15px 0 15px 0",
                }}
            >
                <GridLayout
                    style={{
                        border: "2px solid #ccc",
                        padding: "15px",
                        borderRadius: "5px",
                        overflow: "scroll",
                        maxHeight: "30vh",
                    }}
                >
                    {(formRenderProps.valueGetter("items") || []).map(
                        (item, index) => (
                            <GridLayoutItem
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    gap: "25px",
                                    marginTop: "25px",
                                }}
                            >
                                <Button
                                    themeColor={"error"}
                                    fillMode={"solid"}
                                    rounded={"medium"}
                                    style={{ width: "auto", marginTop: 20 }}
                                    icon={"k-icon k-i-delete"}
                                    onClick={() => removeItemRow(index)}
                                    disabled={
                                        formRenderProps.valueGetter("items")
                                            .length === 1
                                    }
                                >
                                    Delete
                                </Button>
                                <Field
                                    name={"items[" + index + "].name"}
                                    component={FormInput}
                                    validator={(value) =>
                                        nameAndDuplicateValidator(
                                            value,
                                            checkForDuplicateNames(value)
                                        )
                                    }
                                    style={{ width: 200 }}
                                    label={"Name"}
                                />
                                <Field
                                    name={
                                        "items[" + index + "].baseDescription"
                                    }
                                    component={FormInput}
                                    validator={requiredValidator}
                                    style={{ width: 200 }}
                                    label={"SE/SO Description"}
                                />
                                <Field
                                    name={
                                        "items[" +
                                        index +
                                        "].purchaseOrderDescription"
                                    }
                                    component={FormInput}
                                    style={{ width: 200 }}
                                    label={"PO Description"}
                                />
                                <Field
                                    name={"items[" + index + "].retailPrice"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Retail Price"}
                                />
                                <Field
                                    name={"items[" + index + "].vendorCost"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Vendor Cost"}
                                />
                                <Field
                                    name={"items[" + index + "].freightCost"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Freight Cost"}
                                />
                                {itemCustomizationLists.map(
                                    (customizationList, index) => (
                                        <span
                                            style={{
                                                marginTop:
                                                    item.index === 0
                                                        ? -30
                                                        : "initial",
                                            }}
                                            key={index}
                                        >
                                            {item.index === 0 && (
                                                <Button
                                                    themeColor={"error"}
                                                    fillMode={"solid"}
                                                    rounded={"medium"}
                                                    style={{ width: "auto" }}
                                                    icon={"k-icon k-i-delete"}
                                                    onClick={() =>
                                                        removeCustomizationList(
                                                            index
                                                        )
                                                    }
                                                >
                                                    Remove List
                                                </Button>
                                            )}
                                            <Field
                                                name={
                                                    "items[" +
                                                    item.index +
                                                    "].customizations[" +
                                                    index +
                                                    "].customization"
                                                }
                                                component={FormDropDown}
                                                data={
                                                    customizationList.customizations
                                                }
                                                dataItemKey="id"
                                                textField="name"
                                                style={{ width: 200 }}
                                                label={customizationList.name}
                                            />
                                        </span>
                                    )
                                )}
                            </GridLayoutItem>
                        )
                    )}
                </GridLayout>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default MultiItemForm;
