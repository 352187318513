import * as React from "react";
import { useEffect, useState } from "react";
import { TreeList } from "@progress/kendo-react-treelist";
import dayjs from "dayjs";
import "../../AccountingModule.module.scss";
import IncomeStatementTLTotalColumnCell from "./IncomeStatementTLTotalColumnCell";
import IncomeStatementTLNameColumnCell from "./IncomeStatementTLNameColumnCell";
import IncomeStatementTLTotalHeaderCell from "./IncomeStatementTLTotalHeaderCell";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import {
    currentMonth,
    currentYear,
    nextMonth,
} from "../../../../resources/Deprecated/dateHelper";
import ChartOfAccountsService from "../../../../services/Deprecated/accounting/ChartOfAccountsService";

//? Default date periods
//? This year
const startingPeriod = {
    start: new Date(currentYear, currentMonth),
    end: new Date(currentYear, nextMonth()),
};
//? Next year
const endingPeriod = {
    start: new Date(currentYear - 1, currentMonth),
    end: new Date(currentYear - 1, nextMonth()),
};

const IncomeStatementTreeList = () => {
    const [data, setData] = useState([]);
    const [datePeriodRange, setDatePeriodRange] = useState({
        startingPeriod: startingPeriod,
        endingPeriod: endingPeriod,
    });

    useEffect(() => {
        const fetchData = async () => {
            const serviceResponse =
                await ChartOfAccountsService.getIncomeStatement(
                    datePeriodRange
                );
            return serviceResponse.data;
        };

        fetchData().then((res) => setData(res));
    }, [datePeriodRange]);

    /**
     * Handles the formatting of date periods for UI purposes
     * @param period
     * @return {`${string} - ${string}`}
     */
    const handlePeriodFormat = (period) => {
        const start = dayjs(period.start).format("MM/DD/YY");
        const end = dayjs(period.end).format("MM/DD/YY");

        return `${start} - ${end}`;
    };

    const columns = [
        {
            field: "name",
            title: " ",
            cell: IncomeStatementTLNameColumnCell,
        },
        {
            field: "startValue",
            title: `${handlePeriodFormat(datePeriodRange.startingPeriod)}`,
            width: 200,
            cell: IncomeStatementTLTotalColumnCell,
            headerCell: IncomeStatementTLTotalHeaderCell,
        },
        {
            field: "endValue",
            title: `${handlePeriodFormat(datePeriodRange.endingPeriod)}`,
            width: 200,
            cell: IncomeStatementTLTotalColumnCell,
            headerCell: IncomeStatementTLTotalHeaderCell,
        },
        {
            field: "movement",
            title: "Movement",
            width: 200,
            cell: IncomeStatementTLTotalColumnCell,
            headerCell: IncomeStatementTLTotalHeaderCell,
        },
    ];

    /**
     * Handles all logic related to start period date picker
     * @param props
     */
    const onStartPeriodChange = (props) => {
        const { value } = props;

        if (!value.start || !value.end) {
            return;
        }

        setDatePeriodRange((prev) => ({
            ...prev,
            startingPeriod: value,
        }));
    };

    /**
     * Handles all logic related to end period date picker
     * @param props
     */
    const onEndPeriodChange = (props) => {
        const { value } = props;

        if (!value.start || !value.end) {
            return;
        }

        setDatePeriodRange((prev) => ({
            ...prev,
            endingPeriod: value,
        }));
    };

    return (
        <div>
            {/* HEADER */}
            <div className={"IncomeStatementHeader"}>
                <span className={"TitleAndDateContainer"}>
                    <p className={"IncomeStatementHeaderTitle"}>
                        Income Statement
                    </p>
                    <small
                        className={"text-overflow"}
                        style={{ textAlign: "left" }}
                    >
                        From period (
                        {handlePeriodFormat(datePeriodRange.startingPeriod)}) to
                        period (
                        {handlePeriodFormat(datePeriodRange.endingPeriod)})
                    </small>
                </span>
                <span className={"PeriodPickersContainer"}>
                    <p style={{ textAlign: "left" }}>Starting Period</p>
                    <DateRangePicker
                        defaultValue={datePeriodRange.startingPeriod}
                        allowReverse={true}
                        swapButton={true}
                        onChange={onStartPeriodChange}
                    />
                    <p style={{ textAlign: "left" }}>Ending Period</p>
                    <DateRangePicker
                        defaultValue={datePeriodRange.endingPeriod}
                        allowReverse={true}
                        swapButton={true}
                        onChange={onEndPeriodChange}
                    />
                </span>
            </div>
            <TreeList
                expandField={"expanded"}
                data={data.lineRecords}
                columns={columns}
            />
        </div>
    );
};

export default IncomeStatementTreeList;
