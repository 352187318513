import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance.js";
import { formatServiceResponse } from "../utilities/serviceUtility.js";

/**
 * @class DetailItemService
 * @extends BaseService
 * @constructor
 */
export default class DetailItemService extends BaseService {
    constructor() {
        super("Inventory/DetailItem");
    }

    /**
     * @method duplicate
     * @description Duplicates an item
     * @param detailItemId {String} - The ID of the detailItem to duplicate
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async duplicate(detailItemId) {
        const res = await axios.post(`${this.uri}/Duplicate?`, {
            detailItemId,
        });
        return formatServiceResponse(res);
    }
}
