import React, { createContext, useContext, useEffect, useState } from "react";
import { useLastTimeClock } from "./Hooks/useLastTimeClock";
import { AuthContext } from "../../../providers/Deprecated/Authentication/User/AuthProvider";
import { clockTime } from "../../../services/Deprecated/humanResources/timeClockServices";
import { useAvailableTasks } from "./Hooks/useAvailableTasks";

export const MyWorkContext = createContext(null);

const MyWorkProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const [currentEmployee, setCurrentEmployee] = useState({
        id: user.activeEmployee.id,
        fullName: user.activeEmployee.name,
    });
    const timeClockHandler = useLastTimeClock(currentEmployee.id);
    // Only used for the initial load for the employees work, they can preform.
    const { availableTasks, isLoadingTasks } = useAvailableTasks({
        employeeId: currentEmployee.id,
    });
    const [isClockedIn, setIsClockedIn] = useState(false);
    const [isOnCalendarEvent, setIsOnCalendarEvent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAdminPanelVisible, setIsAdminPanelVisible] = useState(false);
    const [calendarEvent, setCalendarEvent] = useState(null);
    const [job, setJob] = useState(null);

    // Sets all boolean states based on the last time clock
    useEffect(() => {
        if (timeClockHandler.lastTimeClock) {
            const isClockedIn = timeClockHandler.lastTimeClock.endTime === null;
            setIsClockedIn(isClockedIn);
            setIsOnCalendarEvent(
                isClockedIn &&
                    timeClockHandler.lastTimeClock?.calendarEvent !== null
            );
        } else {
            setIsClockedIn(false);
            setIsOnCalendarEvent(false);
            setIsLoading(false);
        }
    }, [timeClockHandler.lastTimeClock]);

    // Handles the loading state
    useEffect(() => {
        if (!isLoadingTasks && timeClockHandler.lastTimeClock) {
            setIsLoading(false);
        }
    }, [timeClockHandler.lastTimeClock, isLoadingTasks]);

    // Used to make data queries easier
    useEffect(() => {
        if (timeClockHandler.lastTimeClock) {
            setCalendarEvent(timeClockHandler.lastTimeClock.calendarEvent);
            setJob(timeClockHandler.lastTimeClock.job);
        }
    }, [timeClockHandler.lastTimeClock]);

    /**
     * @function switchTimeClockFocus
     * @description Function to switch the time clock focus
     * @param job - the optional job to clock in/out
     * @param task - the optional task to clock in/out
     * @param calendarEvent - the optional calendar event to clock in/out
     * @return {Promise<void>}
     */
    const switchTimeClockFocus = async (job, task, calendarEvent) => {
        clockTime(currentEmployee).then(async () => {
            clockTime(currentEmployee, task, job, calendarEvent).then(() =>
                timeClockHandler.triggerTimeClockReload()
            );
        });
    };

    /**
     * @function toggleClockIn
     * @description Function to toggle the clock in/out
     * @return {Promise<void>}
     */
    const toggleClockIn = async () => {
        return clockTime(currentEmployee).then(() =>
            timeClockHandler.triggerTimeClockReload()
        );
    };

    return (
        <MyWorkContext.Provider
            value={{
                switchTimeClockFocus,
                calendarEvent,
                job,
                currentEmployee,
                setCurrentEmployee,
                isLoading,
                timeClockHandler,
                isOnCalendarEvent,
                isClockedIn,
                availableTasks,
                toggleClockIn,
                isAdminPanelVisible,
                setIsAdminPanelVisible,
            }}
        >
            {children}
        </MyWorkContext.Provider>
    );
};

export default MyWorkProvider;
