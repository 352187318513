import React from "react";
import styles from "../Scheduler.module.scss";
import { SchedulerEvent } from "./SchedulerEvent.jsx";
import { SchedulerColumnHeader } from "./SchedulerColumnHeader.jsx";

export const SchedulerColumn = ({ date, events }) => {
    return (
        <div className={styles.column}>
            <SchedulerColumnHeader date={date} />
            <div className={styles.columnEvents}>
                {events.map((event, index) => (
                    <SchedulerEvent key={index} event={event} />
                ))}
            </div>
        </div>
    );
};
