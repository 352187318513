import { formatUSDCurrency } from "../currencyHelper";

export class PaymentsHelper {
    /**
     * @function getPercentageForDisbursedPayment
     * @description Calculates the percentage of the payment that has been disbursed.
     * @param number1 - The total of the payment
     * @param number2 - The remainder of the total minus payment amounts
     * @returns {string}
     */
    static getPercentageForDisbursedPayment = (number1, number2) => {
        const remainder = number1 - number2;

        //? Solves NaN issue due to zero divisions
        if (remainder === 0) {
            return "0%";
        }

        return Math.round((remainder / number1) * 100) + "%";
    };

    /**
     * @function percentageAndDisbursedDisplay
     * @description Displays the amount disbursed and the percentage of the payment that has been disbursed.
     * @param props
     * @returns {string}
     */
    static percentageAndDisbursedDisplay = (props) => {
        const formattedAmount = formatUSDCurrency(
            Number(
                props.dataItem.total.amount - props.dataItem.remainder.amount
            ).toString()
        );
        const formattedPercentage = this.getPercentageForDisbursedPayment(
            props.dataItem.total.amount,
            props.dataItem.remainder.amount
        );

        return formattedAmount + ` (${formattedPercentage})`;
    };
}
