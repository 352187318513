import React from "react";
import EventTaskCategoriesPortlet from "./EventTaskCategoriesPortlet";
import EventTasksPortlet from "./EventTasksPortlet";

const EventTasksAndCategoriesPortlets = (props) => {
    return (
        <>
            <EventTaskCategoriesPortlet props={props} />
            <EventTasksPortlet props={props} />
        </>
    );
};

export default EventTasksAndCategoriesPortlets;
