import * as React from "react";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";

const IncomeStatementTLTotalColumnCell = (props) => {
    const { dataItem } = props;
    const cellData = String(dataItem[props.field || ""]);
    let displayData;

    if (cellData === "0" && !dataItem.isPercentageLine) {
        displayData = "0.00";
    } else if (cellData !== "0" && !dataItem.isPercentageLine) {
        displayData = formatUSDCurrency(cellData);
    } else {
        displayData = parseFloat(cellData * 100).toFixed(2) + "%";
    }

    const negativeLines = ["Expenses", "Overhead Expenses"];
    //? Add () to add clarity to negative values
    if (
        cellData < 0 ||
        (negativeLines.includes(dataItem.name) && props.field !== "movement")
    ) {
        displayData = `(${displayData})`;
    }

    const totalLineStyle =
        dataItem.isTotalLine || dataItem.isPercentageLine
            ? {
                  fontWeight: "bold",
                  borderTop: "1px solid",
              }
            : undefined;

    return (
        <td style={totalLineStyle}>
            <span
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}
            >
                {displayData}
            </span>
        </td>
    );
};

export default IncomeStatementTLTotalColumnCell;
