import React from "react";

/**
 * @function ContentHeader
 * @description Component that displays a header with a title and a horizontal line underneath
 * @param props.title {string} - The title of the header
 * @return {React.JSX.Element}
 * @constructor
 */
export const ContentHeader = (props) => {
    const { title, ...others } = props;

    return (
        <div>
            <h2 {...others}>{title}</h2>
            <hr
                style={{
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                }}
            />
        </div>
    );
};
