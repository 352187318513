import * as React from "react";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";

export const ColumnMenu = (props) => {
    // JSX code for rendering the page
    //
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};
