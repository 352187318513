import * as React from "react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper.jsx";
import { getAllAvailabilitiesForDate } from "../../../../services/Deprecated/customer/eventServices.js";

const EmployeeList = ({ employees }) => {
    return (
        <ul style={{ columns: 3 }}>
            {employees.map((employee) => (
                <li key={Math.random()} style={{ width: "max-content" }}>
                    {employee.name}
                </li>
            ))}
        </ul>
    );
};
export const AvailabilityModal = (props) => {
    const { record, close, visible } = props;
    if (!record) return;

    const [employeesNotYetAssigned, setEmployeesNotYetAssigned] = useState([]);
    const [employeesWithAssignments, setEmployeesWithAssignments] = useState(
        []
    );

    const dateJs = dayjs(record.eventDate);

    useEffect(() => {
        getAllAvailabilitiesForDate(record.eventDate).then((res) => {
            if (res.success) {
                setEmployeesNotYetAssigned(
                    res.data?.employeesNotYetAssigned ?? []
                );
                setEmployeesWithAssignments(
                    res.data?.employeesWithAssignments ?? []
                );
            }
        });
    }, [record.eventDate]);

    return (
        visible && (
            <ResponsiveDialog
                title={`Availability for Date: ${dateJs.format("MM/DD/YYYY")}`}
                onClose={close}
                size={"medium"}
            >
                {employeesNotYetAssigned.length > 0 && (
                    <>
                        <h2>AVAILABLE EMPLOYEES NOT YET ASSIGNED TO A JOB</h2>
                        <EmployeeList employees={employeesNotYetAssigned} />
                    </>
                )}
                {employeesWithAssignments.length > 0 && (
                    <>
                        <h2>EMPLOYEES WITH JOB ASSIGNMENTS</h2>
                        <EmployeeList employees={employeesWithAssignments} />
                    </>
                )}
            </ResponsiveDialog>
        )
    );
};
