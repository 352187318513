import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { CenterLoader } from "./CenterLoader";

const BasicFormButtons = (props) => {
    const { loaderVisible, isCreate, onCancel, allowSubmit, customName } =
        props;

    return !loaderVisible ? (
        <div style={{ marginTop: "20px" }}>
            <Button
                themeColor={"primary"}
                fillMode={"solid"}
                rounded={"medium"}
                style={{
                    width: "100px",
                    margin: "10px",
                }}
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Button
                themeColor={
                    customName ? "primary" : isCreate ? "success" : "info"
                }
                fillMode={"solid"}
                rounded={"medium"}
                icon={
                    customName
                        ? undefined
                        : `k-icon ${isCreate ? "k-i-plus" : "k-i-save"}`
                }
                style={{
                    minWidth: "100px",
                    margin: "10px",
                }}
                disabled={allowSubmit}
            >
                {customName ? customName : isCreate ? "Create" : "Update"}
            </Button>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default BasicFormButtons;
