import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance.js";
import { formatServiceResponse } from "../utilities/serviceUtility.js";

/**
 * @class RoleService
 * @extends BaseService
 * @description Service class that handles all requests to the role service
 * @constructor
 */
export default class RoleService extends BaseService {
    constructor() {
        super("Authentication/Role");
    }

    /**
     * @method duplicate
     * @description Duplicates a Role
     * @param roleId {String} - The ID of the role to duplicate
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async duplicate(roleId) {
        const res = await axios.post(`${this.uri}/Duplicate?`, {
            roleId,
        });
        return formatServiceResponse(res);
    }
}
