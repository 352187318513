import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance.js";
import { formatServiceResponse } from "../utilities/serviceUtility.js";

/**
 * @class ItemPackageService
 * @extends BaseService
 * @constructor
 */
export default class ItemPackageService extends BaseService {
    constructor() {
        super("Inventory/ItemPackage");
    }

    /**
     * @method duplicate
     * @description Duplicates an item
     * @param itemPackageId {String} - The ID of the itemPackage to duplicate
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async duplicate(itemPackageId) {
        const res = await axios.post(`${this.uri}/Duplicate?`, {
            itemPackageId,
        });
        return formatServiceResponse(res);
    }
}
