import React from "react";
import { ContentHeader } from "../../../../../components/ContentHeader.jsx";
import styles from "./Styles/JobDetails.module.scss";
import CustomerDetails from "../../../Customers/Components/CustomerDetails/CustomerDetails.jsx";
import FormField from "../../../../../components/Deprecated/StylingField.jsx";

/**
 * @function JobDetails
 * @param props.job {Object}
 * @param props.customer {Object}
 * @param props.includeJobContactInformation {boolean} - Include job contact information in the details
 * @param props.includeCustomerContactInformation {boolean} - Include customer contact information in the details
 * @return {Element}
 * @constructor
 */
const JobDetails = (props) => {
    const {
        job,
        customer,
        includeJobContactInformation,
        includeCustomerContactInformation,
    } = props;

    return (
        <div className={styles.InformationContainer}>
            <span className={styles.InformationColumn}>
                <CustomerDetails
                    customer={customer}
                    includeContactInformation={
                        includeCustomerContactInformation
                    }
                />
            </span>
            <span className={styles.InformationColumn}>
                <ContentHeader
                    title={job ? "Job" : "Not Assigned"}
                    className={styles.InformationColumnHeader}
                />
                <FormField label="Job Name" value={job?.name ?? "N/A"} />
                <FormField
                    label="Displayed Business Information"
                    value={job?.businessInformation?.nickName ?? "N/A"}
                />
                <FormField
                    label="Description"
                    value={job?.description ?? "N/A"}
                />
            </span>
            {includeJobContactInformation && (
                <span className={styles.InformationColumn}>
                    <ContentHeader
                        title={"Primary Contact"}
                        className={styles.InformationColumnHeader}
                    />
                    <FormField
                        label="Name"
                        value={job?.primaryContact?.combinedName ?? "N/A"}
                    />
                    <FormField
                        label="Email"
                        value={job?.primaryContact?.email ?? "N/A"}
                    />
                    <FormField
                        label="Phone"
                        value={
                            job.primaryContact?.phone
                                ? `(${job.primaryContact.phone.phoneNumberType.name}) ${job.primaryContact.phone.phoneNumber}`
                                : "N/A"
                        }
                    />
                </span>
            )}
            {includeJobContactInformation && job?.secondaryContact && (
                <span className={styles.InformationColumn}>
                    <ContentHeader
                        title={"Secondary Contact"}
                        className={styles.InformationColumnHeader}
                    />
                    <FormField
                        label="Name"
                        value={job?.secondaryContact?.combinedName ?? "N/A"}
                    />
                    <FormField
                        label="Email"
                        value={job?.secondaryContact?.email ?? "N/A"}
                    />
                    <FormField
                        label="Phone"
                        value={
                            job.secondaryContact?.phone
                                ? `(${job.secondaryContact.phone.phoneNumberType.name}) ${job.secondaryContact.phone.phoneNumber}`
                                : "N/A"
                        }
                    />
                </span>
            )}
        </div>
    );

    // job.id && (
    //     <div className={"JustifyLeftAlignLeft LargeGap"}>
    //         <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
    //             <FormField
    //                 label="Customer Name"
    //                 value={customer.combinedName}
    //             />
    //             <FormField
    //                 label="Customer Address"
    //                 value={customer.address.fullAddress}
    //             />
    //             <FormField
    //                 label="Displayed Business Information"
    //                 value={job.businessInformation?.nickName}
    //             />
    //             <FormField label="Description" value={job.description} />
    //         </span>
    //         <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
    //             <FormField
    //                 label="Primary Contact"
    //                 value={job.primaryContact?.combinedName}
    //             />
    //             {job?.primaryContact?.email && (
    //                 <FormField
    //                     label="Email"
    //                     value={job.primaryContact.email}
    //                 />
    //             )}
    //             {job?.primaryContact?.phone && (
    //                 <FormField
    //                     label="Phone"
    //                     value={`(${job.primaryContact.phone.phoneNumberType.name}) ${job.primaryContact.phone.phoneNumber}`}
    //                 />
    //             )}
    //         </span>
    //         <span className={"JustifyLeftAlignLeft SmallGap FlexColumn"}>
    //             <FormField
    //                 label="Secondary Contact"
    //                 value={job?.secondaryContact?.combinedName}
    //             />
    //             {job?.secondaryContact?.email && (
    //                 <FormField
    //                     label="Email"
    //                     value={job.secondaryContact.email}
    //                 />
    //             )}
    //             {job?.secondaryContact?.phone && (
    //                 <FormField
    //                     label="Phone"
    //                     value={`(${job.secondaryContact.phone.phoneNumberType.name}) ${job.secondaryContact.phone.phoneNumber}`}
    //                 />
    //             )}
    //         </span>
    //     </div>
};

export default JobDetails;
