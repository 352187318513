import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { VendorDocumentModalHelper } from "./Helpers/VendorDocumentModalHelper.js";
import { BusinessInformationDisplay } from "../../../../../components/Pages/Documents/BusinessInformationDisplay.jsx";
import { DocumentInformationDisplay } from "../../../../../components/Pages/Documents/DocumentInformationDisplay.jsx";
import { Card, CardBody } from "@progress/kendo-react-layout";
import styles from "./Styles/VendorDocumentModal.module.scss";
import { EmployeeDetailsPortlet } from "./Components/EmployeeDetailsPortlet.jsx";
import { ShippingInformation } from "./Components/ShippingInformation.jsx";
import { VendorAndJobDetailsPortlet } from "./Components/VendorAndJobDetailsPortlet.jsx";
import VendorDocumentModalProvider, {
    VendorDocumentModalContext,
} from "./Providers/VendorDocumentModalProvider.jsx";
import { VendorDocumentLineItems } from "./Components/VendorDocumentLineItems.jsx";
import { ExpectedDeliveryDate } from "./Components/ExpectedDeliveryDate.jsx";
import { AuthContext } from "../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { VendorDocumentType } from "../../../../../resources/Enums/VendorDocumentType.js";
import { DetailsHamburger } from "../../../../../components/Buttons/DetailsHamburger.jsx";
import { PDFExport } from "@progress/kendo-react-pdf";
import { exportPDF } from "../../../../../resources/Deprecated/helpers.js";

// Wraps the VendorDocumentModal component with the VendorDocumentProvider
export const VendorDocumentModalWrapper = (props) => {
    return (
        <VendorDocumentModalProvider>
            <VendorDocumentModal {...props} />
        </VendorDocumentModalProvider>
    );
};

/**
 * @function VendorDocumentModal
 * @param props.id {string} - id of the record
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {JSX.Element}
 * @constructor
 */
const VendorDocumentModal = (props) => {
    const {
        formData,
        reloadDocumentKey,
        setModalState,
        onModalClose,
        modalState,
        onModalSubmit,
        onDelete,
        canDelete,
        canEdit,
        canReceive,
        setFormData,
        reloadDocumentInformation,
        onChangeStatus,
        pdfContainerRef,
    } = useContext(VendorDocumentModalContext);
    const [options, setOptions] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        setModalState(props);
    }, [props.visible]);

    useEffect(() => {
        if (!modalState.id) return;

        const optionsArray = [
            {
                icon: "print",
                actionName: "Export PDF",
                action: () => {
                    exportPDF(
                        `${
                            formData?.vendor?.name
                        }-${new Date().toLocaleDateString()}`,
                        pdfContainerRef
                    );
                },
            },
        ];

        if (canEdit && modalState?.id) {
            optionsArray.push({
                icon: "redo",
                actionName: "Convert to Receipt",
                action: () => {
                    onChangeStatus(VendorDocumentType.Enum.ItemReceipt);
                },
            });
        }

        if (canReceive && modalState?.id) {
            optionsArray.push({
                icon: "redo",
                actionName: "Convert to Bill",
                action: () => {
                    onChangeStatus(VendorDocumentType.Enum.VendorBill);
                },
            });
        }

        if (canEdit && !formData?.confirmedBy) {
            optionsArray.push({
                icon: "checkmark",
                actionName: "Confirm Order",
                action: () => {
                    setFormData({
                        ...formData,
                        confirmedBy: user.activeEmployee,
                    });
                    reloadDocumentInformation();
                },
            });
        }

        if (canDelete) {
            optionsArray.push({
                icon: "delete",
                actionName: "Delete",
                action: () => onDelete(),
            });
        }

        setOptions(optionsArray);
    }, [formData, canDelete]);

    return (
        modalState?.visible &&
        formData && (
            <Form
                key={reloadDocumentKey}
                onSubmitClick={onModalSubmit}
                initialValues={formData}
                render={(formRenderProps) => (
                    <PDFExport>
                        <ResponsiveDialog
                            title={VendorDocumentModalHelper.generateModalTitle(
                                formData
                            )}
                            onClose={onModalClose}
                            size={"extraLarge"}
                        >
                            <FormElement>
                                <span
                                    className={
                                        styles.VendorDocumentModalContainer
                                    }
                                    ref={pdfContainerRef}
                                >
                                    <Card style={{ width: "100%" }}>
                                        <CardBody
                                            className={`JustifyLeftAlignLeft ${styles.InfoCardBody}`}
                                        >
                                            <BusinessInformationDisplay
                                                formData={formData}
                                            />
                                            <span
                                                className={
                                                    styles.InfoDocumentDisplay
                                                }
                                            >
                                                <DocumentInformationDisplay
                                                    formData={formData}
                                                    type={VendorDocumentType.ParseToString(
                                                        formData.type
                                                    )}
                                                />
                                                <ExpectedDeliveryDate />
                                                <ShippingInformation />
                                            </span>
                                            {options.length > 0 && (
                                                <span className={"export-hide"}>
                                                    <DetailsHamburger
                                                        options={options}
                                                        text={"Details"}
                                                    />
                                                </span>
                                            )}
                                        </CardBody>
                                    </Card>
                                    <span
                                        className={
                                            "JustifyLeftAlignRight MediumGap"
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <span
                                            className={
                                                "JustifyLeftAlignRight MediumGap"
                                            }
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <EmployeeDetailsPortlet />
                                            <VendorAndJobDetailsPortlet
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    </span>
                                    <VendorDocumentLineItems />
                                </span>
                            </FormElement>
                        </ResponsiveDialog>
                    </PDFExport>
                )}
            />
        )
    );
};
