export class BaseEnum {
    static Enum = {};

    /**
     * Parses a passed Number Enum to paired Enum string.
     * @param {number} e
     * @return {string}
     * @constructor
     */
    static ParseToString(e) {
        if (isNaN(e)) {
            throw "Non-Number passed to BaseEnum.ParseToString";
        }

        for (const [key, value] of Object.entries(this.Enum)) {
            if (value === e) {
                return key;
            }
        }
    }

    /**
     * Returns Enum as a list of KeyValue pairs.
     *
     * Example:
     * {
     *     key: 0,
     *     value: "Assets"
     * }
     * @return {*[]}
     * @constructor
     */
    static AsKeyValue() {
        const keyValue = [];

        for (const [key, value] of Object.entries(this.Enum)) {
            keyValue.push({
                key: key,
                value: value,
            });
        }

        return keyValue;
    }
}
