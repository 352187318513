import React, { useRef } from "react";
import {
    moveTreeViewItem,
    TreeView,
    TreeViewDragAnalyzer,
    TreeViewDragClue,
} from "@progress/kendo-react-treeview";

const SEPARATOR = "_";

/**
 * @function DragAndDropTreeView
 * @description Component that displays a TreeView with drag and drop functionality enabled
 * Currently only supports moving items as children of other items
 * @param props.data {Array} - The data source for the TreeView
 * @param props.setData {Function} - The function to update the data source
 * @param props.subField {string} - The field name that contains the children of each item
 * @param props.onDrop {Function} - The function to call when an item is dropped
 * @param props.dragCluePos {Object} - The position of the drag-and-drop clue icon
 * @param props.others {Object} - Additional props to pass to the TreeView
 * @return {React.JSX.Element}
 * @constructor
 */
export const DragAndDropTreeView = (props) => {
    const { data, setData, onDrop = undefined, dragCluePos, ...others } = props;
    const dragClue = useRef();

    /**
     * @function onDragOver
     * @description Event handler for the onItemDragOver event of the TreeView
     * Displays the drag-and-drop clue icon slightly above the current mouse position
     * @param event {Object} - The event object provided by Kendo
     * @return {void}
     * @constructor
     */
    const onDragOver = (event) => {
        // Display the drag-and-drop clue icon
        dragClue.current.show(
            event.pageY - dragCluePos.y,
            event.pageX - dragCluePos.x,
            event.item.text,
            getClueClassName(event)
        );
    };

    /**
     * @function onDragEnd
     * @description Event handler for the onItemDragEnd event of the TreeView.
     * Updates the data source based on the drag-and-drop operation if allowed.
     * Hides the drag-and-drop clue icon, and calls the onDrop function if provided
     * @param event
     * @return {void}
     * @constructor
     */
    const onDragEnd = (event) => {
        dragClue.current.hide(); // Hide the drag-and-drop clue icon
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        const operation = eventAnalyzer.getDropOperation();
        let destinationIndex =
            eventAnalyzer.destinationMeta.itemHierarchicalIndex;
        const sourceIndex = event.itemHierarchicalIndex;

        // If allowed, update the data source
        if (eventAnalyzer.isDropAllowed) {
            const updatedTree = moveTreeViewItem(
                sourceIndex,
                data,
                operation,
                destinationIndex
            );

            setData(updatedTree);

            // Call the onDrop function if provided
            if (onDrop) {
                const destination = getDragItemDestination(
                    destinationIndex,
                    data
                );

                onDrop({
                    source: {
                        ...event.item,
                    },
                    destination: {
                        ...destination,
                    },
                    operation: operation,
                });
            }
        }
    };

    /**
     * @function getDragItemDestination
     * @description Retrieves the destination item based on the itemHierarchicalIndex
     * @param itemIndex {string} - The itemHierarchicalIndex of the destination
     * @param data {Array} - The data source of the TreeView
     * @return {[Object]|*}
     */
    const getDragItemDestination = (itemIndex, data) => {
        const indexes = itemIndex.split(SEPARATOR);
        let destination = data[Number(indexes[0])];

        for (let i = 1; i < indexes.length; i++) {
            destination = destination[props.subField][Number(indexes[i])];
        }

        return destination;
    };

    /**
     * @function getSiblingCount
     * @description Retrieves the count of siblings for the item at the specified index
     * @param index {string} - The itemHierarchicalIndex
     * @param data {Array} - The data source of the TreeView
     * @return {Number}
     */
    const getSiblingCount = (index, data) => {
        const indexes = index.split(SEPARATOR);
        let siblings = data;
        for (let i = 0; i < indexes.length; i++) {
            siblings = siblings[Number(indexes[i])][props.subField];
        }
        return Number(siblings.length);
    };

    /**
     * @function getClueClassName
     * @description Renders the appropriate drag-and-drop clue icon based on the event
     * @param event {Object} - The event object provided by Kendo
     * @return {string|string}
     */
    const getClueClassName = (event) => {
        const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
        const { itemHierarchicalIndex: itemIndex } =
            eventAnalyzer.destinationMeta;

        // If the drop is allowed, return the appropriate icon
        if (eventAnalyzer.isDropAllowed) {
            switch (eventAnalyzer.getDropOperation()) {
                case "child":
                    return "k-i-plus";
                case "before":
                    return itemIndex === "0" ||
                        itemIndex.endsWith(`${SEPARATOR}0`)
                        ? "k-i-insert-up"
                        : "k-i-insert-middle";
                case "after": {
                    const lastSiblingIndex =
                        getSiblingCount(itemIndex, data) - 1;
                    return itemIndex.endsWith(`${SEPARATOR}${lastSiblingIndex}`)
                        ? "k-i-insert-down"
                        : "k-i-insert-middle";
                }
                default:
                    break;
            }
        }

        // If the drop is not allowed, return the cancel icon
        return "k-i-cancel";
    };

    return (
        <>
            <TreeView
                data={data}
                draggable={true}
                onItemDragOver={onDragOver}
                onItemDragEnd={onDragEnd}
                {...others}
            />
            <TreeViewDragClue ref={dragClue} />
        </>
    );
};
